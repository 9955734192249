import React from 'react';

function Emailpopup({ isOpen, togglePopup, children }) {
  return (
    <>
      {isOpen && (
        <div className="Emailpopup" onClick={togglePopup}>
          <div className="Emailpopup-cotant" onClick={(e) => e.stopPropagation()}>
            <span className="close" onClick={togglePopup}>&times;</span>
            {children}
          </div>
        </div>
      )}
    </>
  );
}

export default Emailpopup;
