import React, { useEffect, useMemo, useState } from 'react';

const EditFeatureList = (props) => {
    let { feature_list: original_feature_list, activeProductTab, handleChange, loading } = props;
    let [ formattedFeatureList, setFormattedFeatureList ] = useState({});

    useEffect(() => {
        let formattedList = {}
        original_feature_list.forEach(feature => {
            if (!(feature.feature_type in formattedList)) {
                formattedList[feature.feature_type] = [];
            }
            formattedList[feature.feature_type].push(feature);
            
        });
       // console.log ("formattedListformattedListformattedListformattedListformattedListformattedListformattedList" , formattedList)
        setFormattedFeatureList(formattedList)
    }, [original_feature_list]);

    function showFeatureTypeHeading(feature_type) {
        if (activeProductTab === 'UCF') {
            return false;
        } else {
            if (!["Application", "Config"].includes(feature_type)) {
                return false;
            } else if (!formattedFeatureList[feature_type].length) {
                return false;
            }
            let rolesExist = formattedFeatureList[feature_type].filter(v => v.product_type === activeProductTab);
            if (rolesExist && !rolesExist.length) {
                return false;
            }
        }
        return true;
    } 
    let isResultFound = useMemo(() => {
        let featuresByTab = original_feature_list.filter(feature => feature.product_type === activeProductTab);
        return featuresByTab && featuresByTab.length ? true : false;
    }, [original_feature_list, activeProductTab]);

    return (
      <div className="user-list assign-featue-list">
        {formattedFeatureList &&
          Object.keys(formattedFeatureList).map((feature_type) => (
            <>
              {showFeatureTypeHeading(feature_type) ? (
                <span key={feature_type} className="feature-type-header">{feature_type}</span>
              ) : null}
              <ul>
                {formattedFeatureList[feature_type].length > 0
                  ? formattedFeatureList[feature_type]
                      .filter((v) => v.product_type === activeProductTab)
                      .map((data, index) => (
                        <React.Fragment key={data.id}>
                          <li className="custom-control custom-checkbox">
                            <input
                              id={"feature_" + data.value}
                              type="checkbox"
                              className="custom-control-input"
                              onChange={handleChange.bind(
                                this,
                                "feature_data",
                                data.value
                              )}
                              checked={data.isChecked}
                              disabled={data.isCheckboxEnabled}
                            />
                            <label
                              htmlFor={"feature_" + data.value}
                              className="custom-control-label"
                            >
                              {data.label}
                            </label>
                            <div className="user-permission">
                              <div className="input-radio">
                                <input
                                  id={"radio1_" + data.value}
                                  name={"radio_" + data.value}
                                  type="radio"
                                  checked={Number(data.access_type) === 0}
                                  onChange={handleChange.bind(
                                    this,
                                    "additional_feature_radio",
                                    data.value
                                  )}
                                  disabled={data.isEnabled}
                                />
                                <label
                                  htmlFor={"radio1_" + data.value}
                                  className="radio-label"
                                >
                                  View
                                </label>
                              </div>

                              <div className="input-radio">
                                <input
                                  id={"radio2_" + data.value}
                                  name={"radio_" + data.value}
                                  type="radio"
                                  checked={Number(data.access_type) === 1}
                                  onChange={handleChange.bind(
                                    this,
                                    "additional_feature_radio",
                                    data.value
                                  )}
                                  disabled ={data.isEnabled ? true : data.isEditDisabled}
                                />
                                <label
                                  htmlFor={"radio2_" + data.value}
                                  className="radio-label"
                                >
                                  Can Edit
                                </label>
                              </div>
                            </div>
                          </li>
                        </React.Fragment>
                      ))
                  : null}
              </ul>
            </>
          ))}
          {(!loading && !isResultFound) && (
            <span>No Feature found</span>
          )}
      </div>
    );
}

export default EditFeatureList;