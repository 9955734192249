import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import crypto from '../../config/crypto'
import LeadStatusService from "../../services/leadStatus";

const StatusStep = ({ leadDetail }) => {
    const navigate = useNavigate();
    const pathname = useLocation();
    const [lostObj, setLostObj] = useState({})
    const [lostClass, setLostClass] = useState('hexagon-active')
    const [statusList, setStatusList] = useState([]);

    const subStatusDisbursed = Object.values(LeadStatusService.LEAD_SUB_STATUS_MASTER.DISBURSE)

    const handleUpdateStatus = () => {
        const redirecturl = `/lead-detail/update-status/${crypto.encode(leadDetail.id)}`;
        navigate(redirecturl);
    };

    useEffect(() => {
        const breadCrumClone = (leadDetail.lead_breadcrumb) ? [...leadDetail.lead_breadcrumb] : [];
        const LostObj = breadCrumClone.find(status => status.name === "Lost");
        let statuses = [], lostClass = "";
        if (LostObj?.status === "active") {
            statuses = breadCrumClone.filter(obj => obj.status !== "inactive");
            lostClass = "hexagon-lost";
        } else {
            statuses = breadCrumClone.filter(obj => obj.name !== "Lost");
            lostClass = "hexagon-active";
        }
        setLostObj(LostObj);
        setLostClass(lostClass);
        setStatusList(statuses);
    }, [leadDetail.lead_breadcrumb])
    
    return (
        <>
            <div className="stepcard">
                <div className="arrow-container">
                    {statusList.map((status, index) => (
                        <div
                            key={index}
                            className={`hexagon ${status.status == 'active' && lostClass}`}
                        >
                            {status.name}
                        </div>
                    ))}
                </div>
                {lostObj?.status != "active" && !subStatusDisbursed.includes(leadDetail?.sub_status_id) && (
                    <div>
                        <button
                            className="newstatusupdate"
                            onClick={() => handleUpdateStatus()}>
                            Update Status
                        </button>
                    </div>
                )}
            </div>
        </>
    );
}

export default StatusStep;
