import { LEADS_HISTORY_QUERY, GET_ALL_FOLLOWUP } from "../../../services/leads.gql";
import { executeGraphQLQuery } from "../../../common/executeGraphQLQuery";
import LeadStatusService from '../../../services/leadStatus';
import React, { useState, useEffect } from "react";
import { useApolloClient } from "@apollo/client";
import { useSelector } from "react-redux"
import DateFormate from "dateformat";

const ActivityHistory = ({activityHistoryTab}) =>{
  const { LOST,LOGIN,NEW_LEAD,DISBURSED,CALL_BACK,SANCTIONED,INTERESTED_QUALIFIED,INTERESTED_CONTACTED,NOT_INTERESTED_CONTACTED,NOT_INTERESTED_QUALIFIED} = LeadStatusService.LEAD_STATUS_MASTER;
  const { VISIT_REQUESTED } = LeadStatusService.LEAD_SUB_STATUS_MASTER.INTERESTED_CONTACTED;

  const [activityHistory, setActivityHistory] = useState({});
  const [renderingCounts, setRenderingCounts] = useState(0);
  const client = useApolloClient();

  const getActivityHistoryData = async (lead_id, user_id) => {
    try {
      const [allFollowsData, leadsHistoryData] = await Promise.all([
        executeGraphQLQuery(GET_ALL_FOLLOWUP(lead_id, user_id), client),
        executeGraphQLQuery(LEADS_HISTORY_QUERY(lead_id), client),
      ]);
      setActivityHistory({
        followupdata: allFollowsData?.data?.get_followup,
        loanhistorydata: leadsHistoryData?.data?.get_history,
      });
    } catch (error) {
      console.error("Error fetching activity history data:", error);
    }
  };

  const { lead } = useSelector(({ lead }) => ({
      lead,
      leadDetail: lead?.leadDetail?.id,
      remarksHistory: lead.remarksHistory || [],
    })
  );

  useEffect(() => {
    const lead_id = lead?.leadDetail?.id || "";
    const user_id = lead?.leadDetail?.customer?.customer_id;
    getActivityHistoryData(lead_id, user_id);
  }, [lead, renderingCounts]);

  const tab_status = {
    Verified: { is_completed: true, is_active: true },
    New_Lead: { is_completed: false, is_active: false },
    Reject: { is_completed: false, is_active: false },
  };

  const { remarksHistory, leadDetail,userInfo } = useSelector(({ lead, user }) => {
    return {
      remarksHistory: lead.remarksHistory || [],
      leadDetail: lead.leadDetail,
      userInfo: user.user_information,
    }
  });
  let userDetail=userInfo && JSON.parse(userInfo)
  let user_id = userDetail && userDetail.id || 0;

  const handleRenderComponent = () => {
    setRenderingCounts((current) => current + 1);
  }

  return (
    <div className="historymenucard">
      <div id="referenceForRenderComponent" onClick={handleRenderComponent}></div>
      <div className="menu-tab-left-panel-new-design">
        <ul className="overflowhide">
          {activityHistoryTab==="all" && activityHistory.loanhistorydata?.map((item, index) => (
            <li key={index}>
              <p className={ tab_status?.Verified?.is_completed ? "completed" : tab_status?.Verified?.is_active ? "active" : ""}>
                <div className="img-type"></div>
                <div className="statusborder">
                  <div className="historydetails">{item.title}</div>
                  <div className="historydetailsheading">{(item.remark) ? item.remark : ""}</div>
                  <div className="historydetailsheading">{(item?.reason_list && item?.reason_list.length> 0) ? "Reason: "+item?.reason_list[0]?.name : ""}</div>
                  <div className="newstatusdetails">
                    <span>By </span>
                    <span>{item?.user_details?.added_by_name} | </span>
                    <span>{item?.created_date ? DateFormate(item?.created_date,"dd mmm") : ""}, </span>
                    <span>{item?.created_date ? DateFormate(item?.created_date,"h:MM TT") : ""}</span>
                  </div>
                </div>
              </p>
            </li>
          ))}

          {activityHistoryTab === "status_updates" && activityHistory.loanhistorydata?.map((item, index) => {
            const isStatusId = [LOST,LOGIN,NEW_LEAD,DISBURSED,CALL_BACK,SANCTIONED,INTERESTED_QUALIFIED,INTERESTED_CONTACTED,NOT_INTERESTED_CONTACTED,NOT_INTERESTED_QUALIFIED].includes(item.status_id);
            const isSubStatusId = [1, 2, 3, 4, VISIT_REQUESTED].includes(item.sub_status_id);
            return (isStatusId || isSubStatusId) ? (
              <li key={index}>
                <p className={tab_status?.Verified?.is_completed ? "completed" : tab_status?.Verified?.is_active ? "active" : ""}>
                  <div className="img-type"></div>
                  <div className="statusborder">
                    <div className="historydetails">{item.title}</div>
                    <div className="historydetailsheading">{(item.remark) ? item.remark : ""}</div>
                    <div className="newstatusdetails">
                      <span>By </span>
                      <span>{item?.user_details?.added_by_name} | </span>
                      <span>{item?.created_date ? DateFormate(item?.created_date, "dd mmm") : ""}, </span>
                      <span>{item?.created_date ? DateFormate(item?.created_date, "h:MM TT") : ""}</span>
                    </div>
                  </div>
                </p>
              </li>
            ) : null; 
          })}

          {activityHistoryTab==="remark_updates" && remarksHistory?.map((remark, index) => (
            <li key={index}>
              <p className={ tab_status?.Verified?.is_completed ? "completed" : tab_status?.Verified?.is_active ? "active" : ""}>
                <div className="img-type"></div>
                <div className="statusborder">
                  <div className="historydetails">Added Remark</div>
                  <div className="historydetailsheading">{remark.remark}</div>
                  <div className="newstatusdetails">
                    <span>By </span>
                    <span>{remark?.user_details?.added_by_name} | </span>
                    <span>{remark?.created_date ? DateFormate(remark?.created_date,"dd mmm") : ""},</span>
                    <span>{remark?.created_date ? DateFormate(remark?.created_date,"h:MM TT") : ""}</span>
                  </div>
                </div>
              </p>
            </li>
          ))}

        </ul>
      </div>
    </div>
  );
}

export default ActivityHistory;