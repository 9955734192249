import { gql } from "@apollo/client";

export const UPDATE_CALL_FEEDBACK = gql`
mutation
    update_call_feedback($updateCallFeedbackInput: UpdateCallFeedbackInput!,  $partner_id: Float,$user_id:Float, $api_called_by: API_CALL_BY!) {
        update_call_feedback(updateCallFeedbackInput: $updateCallFeedbackInput, partner_id: $partner_id,user_id:$user_id, api_called_by: $api_called_by)
    }`


export const GET_CALL_ATTEMPT_DATA =gql`
mutation
    get_call_attempt_data($partner_id: Float,$lead_id:Float) {
        get_call_attempt_data(partner_id: $partner_id,lead_id:$lead_id)
    }`

export const START_CALLING_QUEUE = (user_id,lead_id,queue) => gql`
  query {
    prioritize_lead(user_id: ${user_id}, lead_id: ${lead_id}, queue: ${queue}){
      lead_id
      priority_id
      user_id
      customer_name
      overdue,
      lead_url
    }
  }
`;