import React, { useEffect, useState } from 'react'
import DateFormate from "dateformat";
import { IS_USER_ACCESS_PAGE } from '../../../../config/constants';
import { executeGraphQLQueryFinex } from '../../../../common/executeGraphQLQueryFinex';
import { GET_USER_LIST_PAYOUT } from '../../../../services/payout.gql';
import Pagination from '../../../elements/pagination';

const StandardTablContent = ({partnerListing,payoutListing,createInvoice,eligiblepaginationdata,onPaginate,productType,financers,pageNo,getPayoutListWithFilters,payoutApprovalIds, setPayoutIdsApproval}) => {

    const [selectedRow, setSelectedRow] = useState(null);
    const [payoutRow, setPayoutRow] = useState(null);
    const [userList, setUserList] = useState([]);
    const [selectedPartnerId, setSelectedPartnerID] = useState(0);
    // const [payoutApprovalIds, setPayoutIdsApproval] = useState([]);
    const [selectedAmount, setSelectedAmount] = useState(0);
    const [payoutListingShow, setPayoutListingShow] = useState(null);

    const handleRowClick = (index) => {
        setSelectedRow(selectedRow === index ? null : index);
    };

    const handlePayoutRowClick = (index) => {
        setPayoutRow(payoutRow === index ? null : index);
    };
    let approve_invoice =  IS_USER_ACCESS_PAGE("payout", "approve_invoice")?.is_edit_access;

    const getDealerList = async () => {
        let user_list = [], userlist = [];
        setUserList([])
        const response = await executeGraphQLQueryFinex(GET_USER_LIST_PAYOUT).catch(
          (err) => console.log(err)
        );
            if (response && response.data && response.data.get_user_list) {
                user_list = response.data.get_user_list;
                user_list && user_list.map((v) => {
                    userlist.push({ value: v.user_id, label: v.name })
                    return v;
                })
                console.log("userlist",userlist)
                setUserList(userlist);
            }
      
    };
    const handlePayoutListing = (index, partnerid) => {
        // setShowEye(selectedRow === index ? null : index);
        if (payoutApprovalIds && payoutApprovalIds.length > 0) {
          if (
            window.confirm(
              "Already added payouts for invoice, Do you want to delete old invoice data"
            )
          ) {
            setPayoutIdsApproval([]);
            setSelectedAmount(0);
          } else {
            return false;
          }
        }
        setPayoutListingShow(payoutListingShow === index ? null : index);
        setSelectedPartnerID(partnerid);
        if (partnerid) {
          getPayoutListWithFilters("", partnerid, 1);
        }    
      };

      const handleApprovalCheckBox = (e,payout_id,selectedRow,amount,partner_id
  ) => {
    let calculated_amount = selectedAmount;
    if (e.target.checked == true) {
      payoutApprovalIds.push(payout_id);
      calculated_amount = parseFloat(selectedAmount) + parseFloat(amount);
    } else {
      const index = payoutApprovalIds.indexOf(payout_id);
      if (index !== -1) {
        payoutApprovalIds.splice(index, 1);
      }
      calculated_amount = parseFloat(selectedAmount) - parseFloat(amount);
    }

    setSelectedAmount(calculated_amount);
    setPayoutIdsApproval(payoutApprovalIds);
  };

    useEffect(() => {
        getDealerList();
      }, []);

  return (
     <div className="clearfix">
              <div className="table-responsive">
                <table className="table table-bordered tablefontsize">
                  <thead>
                    <tr>
                      <th>Partner Details</th>

                      <th>Calculated</th>
                      <th>Payable Amount</th>
                      <th>Action</th>
                      {/* <th colSpan={5}></th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {partnerListing &&
                      partnerListing.map((partner, index) => (
                        <React.Fragment key={index}>
                          <tr>
                            <td>
                              <div style={{ fontSize: 13, fontWeight: 600 }}>
                                Partner Code :{" "}
                                <span> AMK{partner.partner_id}</span>
                              </div>
                              <div style={{ opacity: 0.6 }}>
                                Partner Name :{" "}
                                <span>{partner.partner_name}</span>
                              </div>
                              <div>{partner.partner_status!=1 ? <span style={{color: "red"}}>NOT VERIFIED</span> : ""}</div>
                              {partner.parent_partner_id>0 && <div style={{ opacity: 0.6 }}>
                                Parent Partner : <span>{partner.parent_partner_name} ({partner.parent_partner_id})</span>
                              </div>}
                            </td>
                            <td>
                              <div style={{ fontSize: 13, fontWeight: 600 }}>
                                <div> Disbursed : 
                                  {new Intl.NumberFormat("en-IN").format(
                                    partner.total_disbursal_amount
                                  )}
                                </div>
                                <div> Sanctioned : 
                                  {new Intl.NumberFormat("en-IN").format(
                                    partner.total_sanction_amount
                                  )}
                                </div>
                              </div>
                            </td>
                            <td>
                              <div style={{ fontSize: 13, fontWeight: 600 }}>
                                <span>
                                  {new Intl.NumberFormat("en-IN").format(
                                    partner.total_payout_amount
                                  )}
                                </span>
                                <div style={{ opacity: 0.6 }}>
                                  PAT AMOUNT : <span>{partner.total_pat}</span>
                                </div>
                                <div style={{ opacity: 0.6 }}>
                                  TDS AMOUNT : <span>{partner.total_tds}</span>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div style={{ display: "flex" }}>
                                <div className="btn-group btn-group-small mrg-r10">
                                  <div
                                    className="btn btn-default"
                                    title="Mark as View"
                                    onClick={() =>
                                      handlePayoutListing(
                                        index,
                                        partner.partner_id
                                      )
                                    }
                                  >
                                    <i className="ic-remove_red_eyevisibility icons-normal"></i>
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                          {selectedPartnerId == partner.partner_id && (
                            <tr
                              style={{
                                backgroundColor: "rgb(245,245,245)",
                                paddingRight: "40px",
                                paddingLeft: "40px",
                              }}
                            >
                              <td colSpan={4}>
                                <div className="table-responsive" width={100}>
                                  <table className="table table-bordered tablefontsize">
                                    <thead>
                                      <tr>
                                        <th className="payouttable">
                                          {"Lead ID"}
                                        </th>
                                        <th className="payouttable">
                                          {"Tranche ID"}
                                        </th>
                                        <th className="payouttable">
                                          {"Loan ID"}
                                        </th>
                                        <th className="payouttable">
                                          {"Customer Name"}
                                        </th>
                                        <th className="payouttable">
                                          {"Full. Type"}
                                        </th>
                                        <th className="payouttable">
                                          {"Product"}
                                        </th>
                                        <th className="payouttable">
                                          {"Bank"}
                                        </th>
                                        <th className="payouttable">
                                          {"calculated"}
                                        </th>
                                        <th className="payouttable">
                                          {"Payout"}
                                        </th>
                                        <th className="payouttable">{"TDS"}</th>
                                        <th className="payouttable">{"PAT"}</th>
                                        <th className="payouttable">
                                          {"Paid"}
                                        </th>
                                        <th className="payouttable">
                                          {"Pending"}
                                        </th>
                                        <th className="payouttable">
                                          {"Calc. Date"}
                                        </th>
                                        <th className="payouttable">{""}</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {payoutListing &&
                                        payoutListing.map(
                                          (partnerlead, index) => (
                                            <React.Fragment key={index}>
                                              <tr
                                                onClick={() =>
                                                  handleRowClick(index)
                                                }
                                              >
                                                <td>{partnerlead.lead_id}</td>
                                                <td>
                                                  {partnerlead.tranche_id}
                                                </td>
                                                <td>{partnerlead.app_id}</td>
                                                <td>
                                                  {partnerlead.customer_name}
                                                </td>
                                                <td>
                                                  {partnerlead.fulfillment_type}
                                                </td>
                                                <td>
                                                  {productType &&
                                                    productType.length > 0 &&
                                                    productType.filter(
                                                      (type) =>
                                                        type.id ==
                                                        partnerlead.product_type
                                                    )[0]?.name}
                                                </td>
                                                <td>
                                                  {financers &&
                                                    financers.length > 0 &&
                                                    partnerlead.financer_id &&
                                                    financers.filter(
                                                      (bank) =>
                                                        bank.id ==
                                                        partnerlead.financer_id
                                                    )[0]?.name}
                                                </td>
                                                <td>
                                                  { partnerlead.slab_amount == "sanction_amount" ?  new Intl.NumberFormat(
                                                    "en-IN"
                                                  ).format(
                                                    partnerlead.sanction_amount
                                                  ) : new Intl.NumberFormat(
                                                    "en-IN"
                                                  ).format(
                                                    partnerlead.disbursal_amount
                                                  )}
                                                          <div class="tooltip">
                                                          <div style={{ display: "flex" }} class="btn btn-default" ><i className="ic-info-icon1 icons-normal"></i></div>
                                                          <div class="tooltiptext">
                                                            {partnerlead.slab_date ? <div>Slab Date : {partnerlead.slab_date.replace("_", " ")}</div>:""}
                                                            {partnerlead.slab_amount ? <div>Slab Amount : {partnerlead.slab_amount.replace("_", " ")}</div>:""}
                                                            </div>
                                                        </div>
                                                </td>
                                                <td>
                                                  {new Intl.NumberFormat(
                                                    "en-IN"
                                                  ).format(
                                                    partnerlead.payout_total
                                                  )}
                                                </td>
                                                <td>
                                                  {new Intl.NumberFormat(
                                                    "en-IN"
                                                  ).format(
                                                    partnerlead.tds_total
                                                  )}
                                                </td>
                                                <td>
                                                  {new Intl.NumberFormat(
                                                    "en-IN"
                                                  ).format(
                                                    partnerlead.pat_total
                                                  )}
                                                </td>
                                                <td>
                                                  {new Intl.NumberFormat(
                                                    "en-IN"
                                                  ).format(
                                                    partnerlead.payout_paid
                                                  )}
                                                </td>
                                                <td>
                                                  {new Intl.NumberFormat(
                                                    "en-IN"
                                                  ).format(
                                                    partnerlead.payout_pending
                                                  )}
                                                </td>
                                                <td>
                                                  {partnerlead.slab_date == "sanction_date" ? DateFormate(
                                                    new Date(
                                                      partnerlead.sanction_date
                                                    ),
                                                    "dS mmm yyyy"
                                                  )   : DateFormate(
                                                    new Date(
                                                      partnerlead.disbursal_date
                                                    ),
                                                    "dS mmm yyyy"
                                                  )}
                                                </td>
                                                <td
                                                  style={{
                                                    fontSize: 20,
                                                    cursor: "pointer",
                                                  }}
                                                >
                                                  {selectedRow === index
                                                    ? "-"
                                                    : "+"}
                                                </td>
                                              </tr>
                                              {selectedRow === index ? (
                                                <tr>
                                                  <td colSpan={15}>
                                                    <table className="table table-bordered tablefontsize">
                                                      <thead>
                                                        <tr>
                                                          <th
                                                            onClick={() =>
                                                              handlePayoutRowClick(
                                                                index
                                                              )
                                                            }
                                                            className="payouttable"
                                                            style={{
                                                              fontSize: 20,
                                                              cursor: "pointer",
                                                            }}
                                                          >
                                                            {"ID"}{" "}
                                                            {payoutRow === index
                                                              ? "-"
                                                              : "+"}
                                                          </th>
                                                          <th className="payouttable">
                                                            {"Payout Type"}
                                                          </th>
                                                          <th className="payouttable">
                                                            {"Date"}
                                                          </th>
                                                          <th className="payouttable">
                                                            {"Scheme"}
                                                          </th>
                                                          <th className="payouttable">
                                                            {"Payout Per"}
                                                          </th>
                                                          <th className="payouttable">
                                                            {"Per."}
                                                          </th>
                                                          <th className="payouttable">
                                                            {"Payout Amount"}
                                                          </th>
                                                          <th className="payouttable">
                                                            {"TDS"}
                                                          </th>
                                                          <th className="payouttable">
                                                            {"TDS %"}
                                                          </th>
                                                          <th className="payouttable">
                                                            {"PAT"}
                                                          </th>
                                                          <th className="payouttable">
                                                            Paid Status
                                                          </th>
                                                          <th className="payouttable">
                                                            Approval
                                                          </th>
                                                          <th className="payouttable">
                                                            Force Approved
                                                          </th>
                                                        </tr>
                                                      </thead>

                                                      <tbody>
                                                        {partnerlead.payouts &&
                                                          partnerlead.payouts
                                                            .length > 0 &&
                                                          partnerlead.payouts.map(
                                                            (payout, index) => (
                                                              <React.Fragment
                                                                key={payout.id}
                                                              >
                                                                <tr key={payout.id}>
                                                                  <td>{payout.id}</td>
                                                                  <td>{payout.payout_type}</td>
                                                                  <td>{DateFormate(new Date(payout.created_at),"dS mmm yyyy")}</td>
                                                                  <td>{payout.scheme &&payout.scheme.scheme_name? payout.scheme.scheme_name: ""}</td>
                                                                  <td>{payout.payout_per}</td>
                                                                  <td>{payout.paid_per? payout.paid_per: 100}{" "}%</td>
                                                                  <td>{payout.payout_amount}</td>
                                                                  <td>{payout.tds}</td>
                                                                  <td>{payout.tds_per}%</td>
                                                                  <td>{payout.pat}</td>
                                                                  <td>{payout.paid_status.replace("_"," ")}</td>
                                                                  <td>{approve_invoice ===1 &&(payout.paid_status ==="pending" ? (
                                                                    <input type="checkbox" name="paid_status" label="Verify" id="paid_status" key="paid_status" value={payout.id} disabled={(partner.partner_status!=1)} checked={payoutApprovalIds && payoutApprovalIds.length >0 && payoutApprovalIds.includes(payout.id)}
                                                                      onClick={(e) =>handleApprovalCheckBox(e,payout.id,selectedRow,payout.pat,partner.partner_id)}
                                                                        />
                                                                      ) : (
                                                                        "-"
                                                                      ))}
                                                                  </td>
                                                                  <td>
                                                                    {payout.force_approved==1?<div style={{display:"flex",alignItems:"center",gap:"5px"}}>
                                                                      <span className="force-approve-yes">Yes</span>
                                                                      <div class="tooltip">
                                                                        <div style={{ display: "flex" }} class="btn btn-default" ><i className="ic-info-icon1 icons-normal"></i></div>
                                                                        <div class="tooltiptext">
                                                                          <div>Comment : {payout.comment}</div>
                                                                          {payout.created_by && userList.length > 0 ?<div>Created By : {userList.find((user) => user.value == payout.created_by)?.label||null}</div>:""}
                                                                          {payout.updated_by && userList.length > 0 ?<div>Updated By : {userList.find((user) => user.value == payout.updated_by)?.label||null}</div>:""}
                                                                          {payout?.updated_at && <div>Updated At : {DateFormate(
                                                                            new Date(
                                                                              payout.updated_at
                                                                            ),
                                                                            "dS mmm yyyy"
                                                                          )}</div>}
                                                                            </div>
                                                                        </div>

                                                                    </div>:"No"}
                                                                  </td>
                                                                </tr>
                                                              </React.Fragment>
                                                            )
                                                          )}
                                                      </tbody>
                                                    </table>
                                                  </td>
                                                </tr>
                                              ) : (
                                                ""
                                              )}
                                            </React.Fragment>
                                          )
                                        )}
                                    </tbody>
                                    {payoutApprovalIds && payoutApprovalIds.length > 0 ? (
                                      <tr>
                                        <td colSpan={8}>Total</td>
                                        <td colSpan={2}>{selectedAmount}</td>
                                        <td colSpan={2}>
                                          <button
                                            type="button"
                                            className="btn btn-primary"
                                            onClick={() =>
                                              createInvoice(partner.partner_id)
                                            }
                                            title="View History"
                                          >
                                            Create Invoice
                                          </button>
                                        </td>
                                      </tr>
                                    ) : null}
                                  </table>
                                </div>
                              </td>
                            </tr>
                          )}
                        </React.Fragment>
                      ))}
                    {!partnerListing ||
                      (partnerListing.length === 0 && (
                        <tr>
                          <td colSpan={3}>No payout found!</td>
                        </tr>
                      ))}
                  </tbody>
                  {partnerListing.length !== 0 ?
                  <tr>
                    <td colSpan={3}>
                      {" "}
                      <Pagination
                        pageCount={
                          eligiblepaginationdata &&
                          eligiblepaginationdata.totalpage
                            ? eligiblepaginationdata.totalpage
                            : 0
                        }
                        activePage={pageNo ? pageNo : 1}
                        onPaginate={onPaginate}
                      />
                    </td>
                    <td>
                      Total:{" "}
                      {eligiblepaginationdata &&
                      eligiblepaginationdata.totalrecords
                        ? eligiblepaginationdata.totalrecords
                        : 0}{" "}
                    </td>
                  </tr> :null }
                </table>
              </div>
            </div>
  )
}

export default StandardTablContent
