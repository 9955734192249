import { useApolloClient } from "@apollo/client";
import dateFormat from "dateformat";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { executeGraphQLQuery } from "../../../common/executeGraphQLQuery";
import { GET_CIBIL_INFO } from "../../../services/customer.gql";
import OpenModal from "../Modal/Open.modal";
import CrifOtpModal from "../Modal/crif-otp.modal";
import CrifViewModal from "../Modal/crif-view.modal";
const PAN_REGEX = /^[a-zA-Z]{3}[PCHFATBLJGpchfatbljg]{1}[a-zA-Z]{1}[0-9]{4}[a-zA-Z]{1}$/;

const CibilCrif = ({ lead, activeTab, userListMaster }) => {
    const [crifConsentModal, setCrifConsentModal] = useState(false);
    const [crifReportModal, setCrifReportModal] = useState(false);
    const [crifCibil, setCrifCibil] = useState({})
    const [crifActiveDetails, setCrifActiveDetails] = useState({ type: null })
    const client = useApolloClient();

    const user_information = localStorage.getItem("user_information");
    const authUser = user_information && JSON.parse(user_information);
    const user_id = authUser?.id || 1;

    const handleCloseModal = () => {
        if (crifConsentModal) {
            setCrifConsentModal(false);
        }
        if (crifReportModal) {
            setCrifReportModal(false);
        }
    }

    const fetchCrifCibilData = async () => {
        const panNo = crifActiveDetails.pan_card;
        const mobile = crifActiveDetails.mobile;
        const partnerId = crifActiveDetails.partner_id;
        const leadId = crifActiveDetails.lead_id;
        const userId = crifActiveDetails.user_id;

        let errMsg = null
        if (!panNo) {
            errMsg = `PAN not found.`;
        } else if (!mobile) {
            errMsg = `Mobile not found.`;
        } else if (!partnerId) {
            errMsg = `Partner ID not found.`;
        } else if (!leadId) {
            errMsg = `Lead ID not found.`;
        } else if (!userId) {
            errMsg = `User not found.`;
        } else {
            await executeGraphQLQuery(GET_CIBIL_INFO(panNo, 'crif_report', mobile, partnerId, leadId, userId), client)
                .then((result) => {
                    if (result?.data?.cibil_info?.crif_report?.cta === 'Success' && result?.data?.cibil_info?.crif_report?.consent === 1 && result?.data?.cibil_info?.crif_report?.score && result?.data?.cibil_info?.crif_report?.report) {
                        setCrifCibil(result.data.cibil_info.crif_report)
                    }
                })
                .catch((error) => {
                    console.error("\n\n fetchCrifCibilData >> error => ", error)
                });
        }
        // if (errMsg) {
        //     toast.error(errMsg)
        // }
    }

    const checkCibilCrifReport = async () => {
        setCrifConsentModal(true);
    }

    const approveCrifConsent = async (crifCibilReport) => {
        setCrifConsentModal(false)
        if (crifCibilReport && crifCibilReport?.report) {
            setCrifCibil(crifCibilReport.report)
        } else {
            await fetchCrifCibilData();
        }
        toast.success(`Crif report successfully fetched.`)
        await showCrifReport()
    }

    const showCrifReport = async () => {
        setCrifReportModal(true)
    }

    useEffect(() => {
        setCrifActiveDetails({ type: null });
        setCrifCibil({});
        if (activeTab === 'customer') {
            setCrifActiveDetails({
                id: lead?.leadDetail?.customer?.customer_id,
                customer_id: lead?.leadDetail?.customer?.customer_id,
                label: lead?.leadDetail?.customer?.first_name + ' ' + lead?.leadDetail?.customer?.last_name,
                value: lead?.leadDetail?.customer?.customer_id,
                first_name: lead?.leadDetail?.customer?.first_name,
                middle_name: lead?.leadDetail?.customer?.middle_name || '',
                last_name: lead?.leadDetail?.customer?.last_name || '',
                dob: lead?.leadDetail?.customer?.dob ? dateFormat(lead?.leadDetail?.customer?.dob, "dd mmm, yyyy") : null,
                type: 'customer',
                cibil_status: lead?.leadDetail?.customer?.cibil_status,
                pan_card: lead?.leadDetail?.customer?.pancard_no && PAN_REGEX.test(lead.leadDetail.customer.pancard_no) ? lead.leadDetail.customer.pancard_no : null,
                email: lead?.leadDetail?.customer?.email,
                mobile: lead?.leadDetail?.customer?.mobile,
                partner_id: `${lead?.leadDetail?.partner_id || 1}`,
                lead_id: lead.leadDetail.id,
                user_id,
                activeTab
            })
        } else {
            const userListActive = userListMaster.find((item) => item.type === activeTab)
            if (userListActive?.id) {
                const data = { ...userListActive }
                data.type = 'coapplicant'
                data.customer_id = lead?.leadDetail?.customer?.customer_id
                data.partner_id = `${lead?.leadDetail?.partner_id || 1}`
                data.lead_id = lead.leadDetail.id
                data.user_id = user_id;
                data.activeTab = activeTab
                setCrifActiveDetails(data);
            }
        }
    }, [activeTab])

    useEffect(() => {
        if (!!crifActiveDetails?.activeTab) {
            fetchCrifCibilData()
        }
    }, [crifActiveDetails?.activeTab])

    return (
        <>
            <div>
                {/* {JSON.stringify(crifActiveDetails)} */}
                {!!crifActiveDetails?.type ? (
                    <>
                        {!!(crifCibil?.consent && crifCibil?.score && crifCibil?.report) ? (
                            <div style={{ textAlign: "center", marginTop: '100px' }}>
                                <button
                                    onClick={showCrifReport}
                                    className="cibil-fetch-btn">
                                    View CRIF Report
                                </button>
                            </div>
                        ) : (
                            <div style={{ textAlign: "center", marginTop: '100px' }}>
                                <button
                                    onClick={checkCibilCrifReport}
                                    className="cibil-fetch-btn">
                                    Check CRIF Report
                                </button>
                            </div>
                        )}
                    </>
                ) : (
                    <div style={{ textAlign: "center", marginTop: '100px' }}>
                        No Data exist for fetching CRIF
                    </div>
                )}
            </div>

            <OpenModal
                show={crifConsentModal || crifReportModal}
                handleClose={handleCloseModal}
                removeHeight={true}
                width={!!(crifCibil?.consent && crifCibil?.score && crifCibil?.report) ? 1200 : 400}
            >
                {!!(!(crifCibil?.consent && crifCibil?.score && crifCibil?.report) && crifConsentModal) && (<CrifOtpModal handleClose={handleCloseModal} approveCrifConsent={approveCrifConsent} crifActiveDetails={crifActiveDetails} lead={lead} setCrifCibil={setCrifCibil} />)}
                {!!(crifCibil?.consent && crifCibil?.score && crifCibil?.report) && (<CrifViewModal handleClose={handleCloseModal} crifActiveDetails={crifActiveDetails} crifCibil={crifCibil} />)}
            </OpenModal>
        </>
    );
};

export default CibilCrif;
