import React, { useEffect, useMemo, useState } from 'react';
import Formsy, { addValidationRule } from 'formsy-react';
import { useDispatch, useSelector } from 'react-redux';
import DocumentGallery from '../DocumentGallery';
import FormFields from "../formFields/customerDetailsFormFields.json";
import FormsyInputField from '../../elements/FormsyInputField';
import FormsySelect from '../../elements/FormsySelect';
import LeadRemarkHistory from '../../lead-list/LeadRemarkHistory';
import { getLoanDetail,getMasterCityState,setCityList } from '../../../store/action/allAction';
import Modal from '../../elements/Modal';
import { SAVE_CUST_DETAILS } from '../../../services/customer.gql';
import { executeGraphQLMutation } from '../../../common/executeGraphQLQuery';
import { toast } from 'react-toastify';
import { useApolloClient } from '@apollo/client';
import { useOutletContext, useNavigate, useParams, useLocation } from 'react-router-dom';
import { CHECK_ASSIGN_USER, IS_USER_ACCESS_PAGE,DISBURSE_REJECTION_ID } from '../../../config/constants';
import GeneralService from '../../../services/generalService';
import { GET_BUILDER_LIST, GET_PROJECT_LIST } from '../../../services/bre.gql';
import crypto from "../../../config/crypto";

const FORM_FIELDS = FormFields["PROPERTY_IDENTIFIED"];

addValidationRule("matchAccNo", (values, value) => {
  if (values.account_number) {
    return !values.re_account_number ? 'Please Re-Enter Account Number' : values.account_number !== values.re_account_number ? "Account No. does not match" : true
  }
  return true;
})
const PropertyIdentified = (props) => {
  const [propertyIdentified, setPropertyIdentified] = useState({});
  const [allowSubmit, setAllowSubmit] = useState(false);
  const [ViewTimeLine, setViewTimeLine] = useState(false);
  const [showremarkform, setShowremarkform] = useState(false);
  const [isPropertyIdentified, setIsPropertyIdentified] = useState("");
  const { masterdata, lead, remarksHistory } = useSelector(({ masterdata, lead }) => ({
    masterdata,
    lead,
    remarksHistory: lead.remarksHistory || []
  }));
  const [isFormTouch,setTouch] = useState({})
  const client = useApolloClient();
  const dispatch = useDispatch();
  const location = useLocation();
  const getContext = useOutletContext();
  const documentCategoryList = getContext.docList || []
  let navigate = useNavigate();
  // let { lead_id } = useParams();
  let accessCondition = !IS_USER_ACCESS_PAGE("propertyIdentified", "customer-bank-details")?.is_edit_access;
  if (accessCondition !== 1) {
    accessCondition = CHECK_ASSIGN_USER(lead?.leadDetail?.assign_to);
  }
  const user_information = localStorage.getItem('user_information');
  let authUser = user_information && JSON.parse(user_information);
  let user_id = authUser?.id || 1;

  const getBuilderList = async (key_name, value) => {
    try {
      let cityId = null,
        builderId = null;
      if (key_name === "builder") {
        cityId = +value;
        builderId = null;
      } else if (key_name === "project") {
        cityId = +propertyIdentified["property_city"] || null;
        builderId = +value;
      }
      const variables = {
        [`${key_name}Params`]: {
          ...(cityId ? { cityId } : {}),
          ...(builderId ? { builderId } : {}),
        },
      };
      const mutation =
        key_name === "builder" ? GET_BUILDER_LIST : GET_PROJECT_LIST;
      const resp = await executeGraphQLMutation(mutation, variables, client);
      let optionList = [];
      if (resp.data?.[`get_${key_name}_name`]?.data) {
        const responseData = resp.data?.[`get_${key_name}_name`];
        optionList = responseData.data?.map((obj) => {
          return {
            id: obj[`${key_name}_id`],
            value: obj[`${key_name}_id`],
            label: obj[`${key_name}_name`],
          };
        });
        return optionList;
      } else {
        toast.error(resp.data?.[`get_${key_name}_name`]?.message);
      }
    } catch (error) {
      console.log(`🔻 ERROR : ${error.message}`);
      toast.error("Internal server error");
      return [];
    }
  };

  const setBuilderAndProjectList = async (key_name, value) => {
    if (lead?.leadDetail?.lead_details?.is_property_identified === "yes" && location.pathname.indexOf("property-identified") > -1) {
      const list = await getBuilderList(key_name, value);
      ALL_OPTIONS[`${key_name}_name`] = list;
    }
  };

  useEffect(() => {
    if (lead && lead.leadDetail && lead.leadDetail.lead_details) {
      let property_identified = { ...lead.leadDetail.lead_details };
      setPropertyIdentified(property_identified);
      setIsPropertyIdentified(property_identified["is_property_identified"] || "no");
      const filteredSubType = ALL_OPTIONS["property_sub_type"]?.filter(obj => SUB_PROPERTY_MAPPING_OBJ[property_identified["property_type"]]?.includes(obj.value));
      ALL_OPTIONS["filtered_property_sub_type"] = filteredSubType;

      if (property_identified.property_city) {
        setBuilderAndProjectList("builder", property_identified.property_city);
      }
      if (property_identified.builder_name_id) {
        setBuilderAndProjectList("project", property_identified.builder_name_id);
      }
    }
  }, [lead]);

  useEffect(()=>{  
    if(!masterdata.data || !masterdata.data.state_list){
      dispatch(getMasterCityState())
    } 
  },[])

  useEffect(()=>{
    let options = masterdata ? { ...masterdata.data } : {};  
    if (options?.city && options?.city?.length) { 
      let record  = options.city.filter(ob=>ob.state_id == propertyIdentified.property_state).map(data => {
        data.value = data.id
        data.label = data.label
        return data;
      }) 
      ALL_OPTIONS["city_list"] = record;
      dispatch(setCityList(record))
    } 
  },[propertyIdentified.property_state]) 

  useEffect(() => {
    if (propertyIdentified.property_city) {
      setBuilderAndProjectList("builder", propertyIdentified.property_city);
    }
  }, [propertyIdentified.property_city]);

  let ALL_OPTIONS = useMemo(() => {
    let options = masterdata ? { ...masterdata.data } : {};
    const existingPropertyType = propertyIdentified["property_type"] || null;
    options["property_sub_type"] = options["property_sub_type"]?.map((obj) => ({
      ...obj,
      value: +obj.id,
    }));
    if (existingPropertyType) {
      const filteredSubType = options["property_sub_type"]?.filter((obj) =>
        SUB_PROPERTY_MAPPING_OBJ[existingPropertyType].includes(obj.value)
      );
      options["filtered_property_sub_type"] = filteredSubType;
    } 
    return options;
  }, []);
  // }, [masterdata]);

  /** 
   * key: property_type
   * value: list of sub_properties
  */
  const SUB_PROPERTY_MAPPING_OBJ = {
    9: [1, 2, 3],
    11: [1, 4],
    12: [1, 4],
    13: [1, 2, 3, 4],
    14: [1, 2, 3, 4],
  }

  const handleInputChange = event => {
    if (event.target.id) {
      setTouch({
        ...isFormTouch,
        [event.target.id]:true
      })
      setPropertyIdentified(currentValue => ({
        ...currentValue,
        [event.target.id]: event.target.value
      }))
      if(event.target.id =='property_pincode' && event.target.value.length ==6){
        setCityStateByPin(event.target.value)
      }
    }
  }
  const setCityStateByPin = async (pincode)=>{
    try {
      let response = await GeneralService.stateCityByPincode(pincode);
      if (response?.data?.data?.length) {
        let item = response.data.data[0]  
        setPropertyIdentified({
          ...propertyIdentified,
          property_pincode: pincode,
          property_state: item?.state_id,
          property_city: item?.city_id
        })
      }  
    } catch (error) {
      console.error('Error :', error);
    }
  }

  const handlePatternFormatChange = (data, field_name) => {
    if (field_name && data.value !== undefined) {
      setTouch({
        ...isFormTouch,
        [field_name]:true
      })
      setPropertyIdentified(currentValue => ({
        ...currentValue,
        [field_name]: data.value
      }))
    }
  }

  const handleSelectChange = async (data, field_name) => {
    if (data && field_name) {
      if (field_name === "is_property_identified") {
        setIsPropertyIdentified(data.value);
      }
      if (field_name === "property_type") {
        const filteredSubType = ALL_OPTIONS["property_sub_type"].filter(obj => SUB_PROPERTY_MAPPING_OBJ[data.value].includes(obj.value));
        ALL_OPTIONS["filtered_property_sub_type"] = filteredSubType;
      }
      if (field_name === "property_city") {
        const list = await getBuilderList("builder", data.value);
        ALL_OPTIONS["builder_name"] = list;
      }
      if (field_name === "builder_name_id") {
        const list = await getBuilderList("project", data.value);
        ALL_OPTIONS["project_name"] = list;
      }
      setPropertyIdentified(currentValue => ({
        ...currentValue,
        [field_name]: data.value
      }))
    }
  }

  const handleSubmit = () => {
    let lead_id = lead?.leadDetail?.id || "";
    let mutation = SAVE_CUST_DETAILS(user_id),
      variables = {
        api_called_by: 'web',
        LeadAdditionalInput: {
          lead_id: lead_id,
          lead_details: {
            is_property_identified: propertyIdentified.is_property_identified,  
            property_value: propertyIdentified.property_value,
            property_type: +propertyIdentified.property_type,
            usage_type: +propertyIdentified.usage_type,
            agreement_type: +propertyIdentified.agreement_type, 
            property_pincode: propertyIdentified.property_pincode,
            property_city: propertyIdentified.property_city,
            property_state: propertyIdentified.property_state,
            property_address1: propertyIdentified.property_address1,
            property_address2: propertyIdentified.property_address2,
            property_agreement_value: propertyIdentified.property_agreement_value,
            property_sub_type: +propertyIdentified.property_sub_type,
            builder_name_id: propertyIdentified.builder_name_id,
            project_name: propertyIdentified.project_name,
            expected_property_value: propertyIdentified.expected_property_value,
          }
        }
      };

    executeGraphQLMutation(mutation, variables, client).then(resp => {
      let response = resp?.data?.LeadAdditionalInput || null;
      if (response && !response.success) {
        throw new Error(response.error || "Something went wrong");
      }
      toast.success("Success");
      dispatch(getLoanDetail(lead_id, client))
    })
      .catch(err => {
        toast.error(err.message);
      })
  }

  let PropertyIdentifiedForm = FORM_FIELDS;

  // All the conditions can be combined here for button enable/disable
  const isSubmitDisabled = useMemo(() => {

    return lead.leadDetail.sub_status_id == 2 ? false : true;

  }, [allowSubmit]);

  const showModalViewTimeLine = () => {
    setViewTimeLine(true);
    setShowremarkform(true);
    document.body.classList.add("overflow-hidden");
  };

  const hideModalViewTimeLine = () => {
    setViewTimeLine(false);
    document.body.classList.remove("overflow-hidden");
  };

  const getSelectList = (ALL_OPTIONS,key)=>{
    /* if(key =='city_list'){
      let options = masterdata ? { ...masterdata.data } : {}; 
      if (options?.city && options?.city?.length) {
        let record  = options.city.filter(ob=>ob.state_id==data.value).map(data => {
          data.value = data.id
          data.label = data.label
          return data;
        })
        return record;
    }else{
      return ALL_OPTIONS && ALL_OPTIONS[key]
    } */
      
  }

  const movetoNext = ()=>{ 
    let lead_id = lead?.leadDetail?.id || ""; 
    handleSubmit(); 
    if(allowSubmit){
      navigate(`/lead-detail/customer-details/offer-detail/${crypto.encode(lead_id)}`); 
    }   
  }

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    let isPropIdentified = "no";
    if (checked) {
      isPropIdentified = "yes";
    }
    setIsPropertyIdentified(isPropIdentified);
    setPropertyIdentified((currentValue) => ({
      ...currentValue,
      [name]: isPropIdentified,
    }));
  }

  const sectionInfo = { section: "Customer details", sub_section: "Bank details" }
  const rejectDisable = (lead.leadDetail.reject_reason_id > 0 && !DISBURSE_REJECTION_ID.includes(lead.leadDetail.reject_reason_id) && [2, 3].includes(lead.leadDetail.status_id)) ? true : false 
  const  IS_LOST = (lead?.leadDetail?.status_id ==5)? true: false 
  let newPropertyIdentifiedForm  = isPropertyIdentified === "yes" ? PropertyIdentifiedForm : []
  return (
    <div className="image-form-outer">
      <div className="image-tab-panel">
        <DocumentGallery documentCategoryList={documentCategoryList} doc_id="5" />
      </div>
      <div className="form-tab-right-panel">
        <div className="lead-detail-from-outer">
          <div className="lead-form-heading">
            {/* <span className="main-heading">Customer Details</span> */}
            <h2>Property Details</h2>
          </div>

          <div className="property-identified">
            <label>Property Identified ?</label>
            <label className="toggle-label">No</label>
            <label className="toggle-switch">
              <input
                type="checkbox"
                name="is_property_identified"
                id="is_property_identified"
                value="Yes"
                checked={isPropertyIdentified === "yes" ? true : false}
                onChange={handleCheckboxChange}
              />
              <span className="slider"></span>
            </label>
            <label className="toggle-label">Yes</label>
          </div>

          <Formsy
            className="lead-form-filed"
            autoComplete="off"
            onValid={() => setAllowSubmit(true)}
            onInvalid={() => setAllowSubmit(false)}
            onValidSubmit={handleSubmit}
          >
            <div className="row">

              {PropertyIdentifiedForm.map((field,index) => (
                (isPropertyIdentified === "" || isPropertyIdentified === null && (field.name !== "is_property_identified")) ||
                (isPropertyIdentified === "no" && field.id === "property_aggrement_value") || 
                (isPropertyIdentified === "yes" && field.id === "expected_property_value") || 
                (isPropertyIdentified === "no" && field.id === "property_value") || 
                (propertyIdentified["property_type"] === 9 && field.name === "project_name") ||
                (propertyIdentified["property_type"] === 11 && field.name === "project_name") || 
                (propertyIdentified["property_type"] === null && field.name === "project_name") || 
                (isPropertyIdentified === "no" && field.id === "project_name") || 
                (propertyIdentified["property_type"] === 9 && field.name === "builder_name_id") ||
                (propertyIdentified["property_type"] === 11 && field.name === "builder_name_id") || 
                (propertyIdentified["property_type"] === null && field.name === "builder_name_id") || 
                (isPropertyIdentified === "no" && field.id === "builder_name_id") || 
                (isPropertyIdentified === "no" && field.id === "property_sub_type") || 
                (isPropertyIdentified === "no" && field.id === "property_address1") ||
                (isPropertyIdentified === "no" && field.id === "property_address2") ? null :
                ["text", "password", "pattern-format", "number-format","number"].includes(field.type) ? (
                  <fieldset class="form-filed col-md-6 " key={index}>
                    <FormsyInputField
                      id={field.id}
                      name={field.name}
                      type={field.type}
                      value={propertyIdentified && propertyIdentified[field.name] || ''}
                      placeholder=" "
                      thousandsGroupStyle="lakh"
                      thousandSeparator={','}
                      decimalSeparator={'.'}
                      label={field.label}
                      maxLength={field.maxLength}
                      onChange={
                        ["pattern-format", "number-format"].includes(field.type)
                          ? (data) => handlePatternFormatChange(data, field.name)
                          : handleInputChange
                      }
                      format={field.ApplyNumberformat ? field.format : ""}
                      validations={(isFormTouch[field.name])? field.validations:null}
                      validationError={
                        propertyIdentified[field.name] || propertyIdentified[field.name] =='' ?  (isFormTouch[field.name])? field.validationError : "" : ""
                      }
                      required={field.required}
                    />
                  </fieldset>
                ) :

                  (isPropertyIdentified === "no" && field.id === "agreement_type") ? null :
                  field.type === "dropdown" ? (
                    <fieldset class="single-select col-md-6" key={index}>
                      <FormsySelect
                        name={field.name}
                        id={field.id}
                        inputProps={{
                         // options: getSelectList(ALL_OPTIONS,field.optionsKey),
                          options: ALL_OPTIONS && ALL_OPTIONS[field.optionsKey],
                          placeholder: field.label,
                          className: "react-select",
                          classNamePrefix: "react-select",
                          value: ALL_OPTIONS && ALL_OPTIONS[field.optionsKey] && ALL_OPTIONS[field.optionsKey].filter(({ value }) => value == propertyIdentified[field.name]),
                          isDisabled: field.readOnly
                        }}
                        required={field.required}
                        value={propertyIdentified[field.name]}
                        onChange={(data) => handleSelectChange(data, field.name)}
                      />
                    </fieldset>
                  ) : null
              ))}

            </div>

            <div className="btn-save-remarks btn-save-next">
              <button type="submit" className="btn-primary" disabled={ IS_LOST}>
                Save
              </button>
              <button  className="btn-primary-outline btn-next" onClick={movetoNext} disabled={ IS_LOST}>
                Next
              </button>
              {/* <button type="button" className="btn-primary-outline" onClick={showModalViewTimeLine}
              disabled={rejectDisable}>Remarks
                <span className="m-xs-l">({remarksHistory && remarksHistory.length || 0})</span>
              </button> */}
            </div>
          </Formsy>
        </div>
      </div>
      <div className="view-timeline-popup">
        <div className="view-timeline-popup">
          <Modal show={ViewTimeLine} handleClose={hideModalViewTimeLine} >
            <div className="modal-header">
              <h2>Remark History</h2>
            </div>
            <LeadRemarkHistory showremarkform={showremarkform} markAsFreezed={getContext.markAsFreezed}
              accessCondition={accessCondition} sectionInfo={sectionInfo} />
          </Modal>
        </div>
      </div>
    </div>
  );
}
export default PropertyIdentified;