import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import secureStorage from '../../config/partnerEncrypt';
import MasterService from '../../services/MasterService';
import {USERS_FOR_VIEW_AMBAK_LEADS, IS_USER_ACCESS_PAGE} from '../../config/constants';
import { getCurrentUserDetails } from './pre-register-lead-manager/utility/getUserInfo';

// import "./webroot/css/main.css"
class PartnerDashboard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            user_type : "",
            countReportLeadDpd: [],
            cardData: [
          ,
                { count: 0, text: 'Pending', icon: 'ic-person_add', link: '/dealer-list?type=ready_for_verification', filter: { status: '4', list_type: 'pending' } },
                { count: 0, text: 'Rejected', icon: 'ic-clearclose', link: '/dealer-list?type=rejected', filter: { status: '4', list_type: 'rejected' } },
                { count: 0, text: 'Inactive', icon: 'ic-access_timequery_builderschedule', link: '/dealer-list?type=inactive', filter: { status: '2', list_type: '' } },
                { count: 0, text: 'Verified', icon: 'ic-check', link: '/dealer-list?type=verified', filter: { status: '1', list_type: '' } },
                { count: 0, text: 'All Saathi', icon: 'ic-person_add', link: '/dealer-list?type=all', filter: { status: '', list_type: 'all' } },
                { count: 0, text: 'My Leads', icon: 'ic-person_add', link: '/pre-register-lead-manager?', filter: { status: '10', list_type: 'preRegisterLeads' } },
                { count: 0, text: 'Sathis Support', icon: 'ic-person_add', link: '/partner-support-listing?', filter: { status: '10', list_type: '' } }
            ],
            role : [
                {role : "admin" , id : 1},
                {role : "caller" , id :2}
            ],
            showDealerCount: false,
            selectedFilter: { status: '', list_type: '' },
            statusClassname: {
                'pending': 'nav-item active',
                'active': 'nav-item ',
                'rejected': 'nav-item',
                'inactive': 'nav-item',
                'all': 'nav-item'
            },
            filterData: {
                status: null,
                list_type: ''
            },
            role_type :"admin",
            filteredCards:[]
        };
        this.cancelAsyncTask = false;
    }

    getCountReportLeadDpd = async () => {
        let user_role_type = "";
        var loginResponseString = localStorage.getItem('user_information');
        if (loginResponseString) { // Check if loginResponseString is valid
            const loginResponse = JSON.parse(loginResponseString);
            
            // Check if role_names exists and is an array

            if (loginResponse.role_names && Array.isArray(loginResponse.role_names)) {
                if (loginResponse.role_names.includes("Partner Support Executive")) {
                   // console.log ("Partner Support Executive")
                    user_role_type = "partner_support_executive";
                } else if (loginResponse.role_names.includes("Partner Admin")) {
                   // console.log ("Partner Admin")
                    user_role_type = "partner_admin";
                }
            }
        }
    
        let parter_dashboard = IS_USER_ACCESS_PAGE("partner", "partner_dashboard")?.is_edit_access;
        let caller_dashboard = IS_USER_ACCESS_PAGE("partner", "caller_dashboard")?.is_edit_access;
    
        let filterData = {};
        if (caller_dashboard == 1 && parter_dashboard == 0 || parter_dashboard == 2) {
            let token = JSON.parse(secureStorage.storage.user_information);
            if (token.yodha_code) {
                let yodha_id = token.yodha_code;
                filterData.assigned_bm_id = yodha_id;
            }
        }
        filterData.user_type = user_role_type;
    
        try {
            const response = await MasterService.post('dealer/dealer/count_dealer_status', filterData);
    
            if (response.data.status === 200) {
                const counts = response.data.data;
                const updatedCardData = this.state.cardData.map(card => {
                    switch (card.text) {
                        case 'Pending':
                            return { ...card, count: counts.pending || 0 };
                        case 'My Leads':
                            return { ...card, count: counts.preRegister || 0 };
                        case 'Rejected':
                            return { ...card, count: counts.rejected || 0 };
                        case 'Inactive':
                            return { ...card, count: counts.inactive || 0 };
                        case 'Verified':
                            return { ...card, count: counts.active || 0 };
                        case 'All Saathi':
                            return { ...card, count: counts.all || 0 };
                        case 'Sathis Support':
                            return { ...card, count: counts.sathisSupport || 0 };
                        default:
                            return card;
                    }
                });
    
                this.setState({ cardData: updatedCardData });
            }
        } catch (error) {
            console.error('Error fetching count data:', error);
        }
    };
    



    updateReduxData = async () => {
        
        if (secureStorage.getItem('loginUserInfo')) {
            var loginResponse = secureStorage.getItem('loginUserInfo');
            this.props.updateStateIntoRedux('LOGIN_USER', loginResponse);
            this.afterLoginSetState(loginResponse)
        } else {
            this.setState({ redirect: true })
        }
    }
    componentDidMount = async () => {
        const loginResponseString = localStorage.getItem('user_information');
        
        if (loginResponseString) {
            const loginResponse = JSON.parse(loginResponseString);
            if (loginResponse.role_names.includes("Partner Support Executive")){
               this.setState({user_type : "partner_support_executive"})
            }
            else if (loginResponse.role_names.includes("Partner Admin")) {
                this.setState({user_type : "partner_admin"})
            }
        } else {
            console.log("No user information found in localStorage");
        }
    
        const userInfo = getCurrentUserDetails();
        const { access_token } = userInfo || {};
        if (!access_token || !localStorage.getItem('isLogin')) {
            localStorage.setItem('isLogin', true);
            window.location.reload();
        }
        await this.handleCards()
        await this.getCountReportLeadDpd();
       
    }
    

    afterLoginSetState = (loginResponse) => {
        let role = loginResponse && loginResponse.data && loginResponse.data.user_data && loginResponse.data.user_data.role || null;
        if (role != "admin") {
            this.setState({ redirect: '/' })
        }
    }


    handleCards = async () => {
        let support_dashboard = IS_USER_ACCESS_PAGE("partner_support", "partner_support")?.is_edit_access;
    
        // Check if support_dashboard is 0 or 2
        if (support_dashboard === 0 || support_dashboard === 2) {
            const updatedFilteredCards = this.state.cardData.filter(card => card.text !== 'Sathis Support');
            
            this.setState({ cardData: updatedFilteredCards });
        }
    }
    
   

    render() {
        let caller_dashboard =  IS_USER_ACCESS_PAGE("partner", "caller_dashboard")?.is_edit_access;
        let access =  IS_USER_ACCESS_PAGE("partner", "caller_dashboard")?.isUserHasRights;
        let parter_dashboard =  IS_USER_ACCESS_PAGE("partner", "partner_dashboard")?.is_edit_access;
        let support_dashboard = IS_USER_ACCESS_PAGE("partner_support" , "partner_support")?.is_edit_access;
        const cardStyles = ['color-one', 'color-two', 'color-three', 'color-four', 'color-five', 'color-one','color-two'];
        let name = "Admin";
        const role_type = this.state.role_type
        
        let token = JSON.parse(secureStorage.storage.user_information)
     
        let fetchHeader = (secureStorage.getItem('loginUserInfo'))
            ? secureStorage.getItem('loginUserInfo')
            : {};
           
            name = token.name
            this.state.filteredCards = this.state.cardData.filter(card => {
                if (parter_dashboard == 1 && caller_dashboard == 0 || caller_dashboard == 2) { 
                    if (
                        card.text === "Pending" ||
                        card.text === "Rejected" ||
                        card.text === "Inactive" ||
                        card.text === "Verified" ||
                        card.text === "All Saathi" ||
                        card.text === "My Leads" ||
                        card.text === "Sathis Support"
                        
                    ) {
                        card.link = `${card.link}&role=admin`;
                        return true;
                        
                    }
                }
                else if ((caller_dashboard == 2 || caller_dashboard == 0) && (parter_dashboard == 2 || parter_dashboard == 0) && support_dashboard == 1)
                    {
                        if (card.text === "Sathis Support"
                   ) {
                            card.link = `${card.link}&role=caller`;
                             
                            return true;
                        }
                    }else if (caller_dashboard == 1 && parter_dashboard == 0 || parter_dashboard== 2) 
                    {
                        if (card.text === "All Saathi" ||
                        card.text === "My Leads") {
                            card.link = `${card.link}&role=caller`;
                             
                            return true;
                        }
                    }
                 else if (caller_dashboard == 0 ||  caller_dashboard == 2 && parter_dashboard === 0 || parter_dashboard === 2){  
                     return null
                }
                else if (caller_dashboard == 1 && parter_dashboard === 1) {
                    if (
                        card.text === "Pending" ||
                        card.text === "Rejected" ||
                        card.text === "Inactive" ||
                        card.text === "Verified" ||
                        card.text === "All Saathi" ||
                        card.text === "My Leads" ||
                        card.text === "Sathis Support"
                    ) {
                        card.link = `${card.link}&role=admin`;
                        return true;
                    }
                }
              
                return false; 
            });
            

        
        
        return (
            <div className="dashboard-bg">
                <div className="dashboard-outer">
                    <div className="heading">
                        <span className="subheading">Hello, {name}</span>
                        <h1>Welcome to Ambak Partner Onboarding</h1>
                    </div>
                    <div className="dashboard-card">
                        {this.state.filteredCards.map((card, index) => (
                            <Link to={card.link} key={index} >
                                <div className={`card color- ${cardStyles[index]}`} key={index}>
                                    <div className='roung-bg'>
                                        <i className={card.icon}></i>
                                    </div>
                                    <span className="count-txt">{card.count}</span>
                                    <span className="card-txt">{card.text}</span>
                                </div>
                            </Link>
                        ))}
                    </div>
                </div>

            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        auth_token: state.authToken,
        user_loggedin: state.userLoggedIn,
        dealer_id: state.dealer_id
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        updateStateIntoRedux: (actionType, stateData) => dispatch({ type: actionType, componentState: stateData })
    };
};

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(PartnerDashboard));