import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import {
  executeGraphQLMutation,
  executeGraphQLQuery
} from "../../common/partnerExecuteGraphQLQuery";
import { executeGraphQLQueryFinex } from "../../common/executeGraphQLQueryFinex.js";
import { toast } from "react-toastify";
import {
  PAYOUT__LIST_WITH_FILTERS,
  PAYOUT_LEADS_COUNTS,
  PARTNER_DISBURSAL_LIST,
  CREATE_INVOICE,
  INVOICE_LISTING,
  INVOICE_LISTING_DATA,
  GET_GST_MATCHED_LIST,
  GET_USER_LIST_PAYOUT,
  GST_INVOICE_LISTING,
  PRE_ELIGIBLE_PARTNER_LIST,
} from "../../services/payout.gql.js";
import secureStorage from "./../../config/encrypt";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import {
  SCHEME_LIST,
  FINEX_MASTER_LIST,
  FINEX_BANK_LIST,
} from "../../services/scheme.gql";
import {
  IS_USER_ACCESS_PAGE
} from "../../config/constants";
import Loader from "../elements/Loader.js";
import { useApolloClient } from "@apollo/client";
import GstTableContent from "./GstTableContent.js";
import PreEligibleTableContent from "./PreEligibleTableContent.js";
import StandardTableContent from "./component/TabsContent/StandardTablContent.js";
import CNDNTableContent from "./component/TabsContent/CNDNTableContent.js";
import StandardOtherTableContent from "./component/TabsContent/StandardOtherTableContent.js";

const PayoutListing = (props) => {
 // console.log("props ===>> ", props)
  const client = useApolloClient();
  const filter_params = props.filter_params;
  const DataFIlter = props.DataFIlter;

  const filterParams = {};
  filterParams[filter_params.filter_params] = isNaN(+filter_params.app_id)
    ? filter_params.app_id
    : +filter_params.app_id;

  let Status = props.state.status;
  const partner_id = props.filterVariables.partner_id;
  const app_id = props.filterVariables.app_id;
  const filterOptions = props.filterOptions;
  let counter = props.counter;

  let buttonClick = props.buttonClick;

  const [filterData, setFilterData] = useState([]);
  const [page_no, setPageNo] = useState(1);
  const [partnerListing, setPartnerData] = useState([]);
  const [invoiceListData, setInvoiceListData] = useState([]);
  const [invoicedata, setInvoicedata] = useState([]);
  const [payoutListing, setPayoutListing] = useState([]);
  const [paginationdata, setPaginations] = useState({});
  const [eligiblepaginationdata, seteligiblepaginationdata] = useState(0);
  const [pageloading, setpageloading] = useState(true);
  const [selectedRow, setSelectedRow] = useState(null);
  const [showEye, setShowEye] = useState(null);
  const [selected_partner_id, setSelectedPartnerID] = useState(0);
  const [payoutListingShow, setPayoutListingShow] = useState(null);
  const [payoutRow, setPayoutRow] = useState(null);
  const [schemeList, setschemeList] = useState([]);
  const [payoutApprovalIds, setPayoutIdsApproval] = useState([]);
  const [showSave, setShowSave] = useState(false);
  const [leads_counts, setLeadsCounts] = useState([]);
  const [totalLeads, setTotalLeads] = useState(0);
  const [productType, setProductType] = useState([]);
  const [financers, setFinancers] = useState([]);
  const [fulfillmenttypes, setFulfillmentTypes] = useState([]);
  const [payout_comments, setPayoutComments] = useState("");
  const [selected_amount, setSelectedAmount] = useState(0);
  const [user_list, setUserList] = useState([]);
  const [financersBanks, setFinancersPersonal] = useState([]);
  const [visible, SetVisible] = useState(true);
  const [originalData, setOriginalData] = useState([]);
  const [gstStatus, setGSTStatus] = useState("matched");
  const [gstStatusData, setGstStatusData] = useState([]);
  const [gstStatusDataPagination, setGstStatusDataPagination] = useState({});
  const [gstStatusDataPageNo, setGstStatusDataPageNo] = useState(1);

  let approve_invoice =  IS_USER_ACCESS_PAGE("payout", "approve_invoice")?.is_edit_access;
//console.log("filter_params",filter_params)

  const updateReduxData = async () => {
    if (secureStorage.getItem("loginUserInfo")) {
      var loginResponse = secureStorage.getItem("loginUserInfo");
      props.updateStateIntoRedux("LOGIN_USER", loginResponse);
      afterLoginSetState(loginResponse);
    } else {
      return <Navigate to={"/login"} />;
    }
  };

  const afterLoginSetState = (loginResponse) => {
    setpageloading(false);
    let role =
      (loginResponse &&
        loginResponse.data &&
        loginResponse.data.user_data &&
        loginResponse.data.user_data.role) ||
      null;
    if (role != "dealercentral") {
      return <Navigate to={"/"} />;
    }
  };

  const handleRowClick = (index) => {
    setSelectedRow(selectedRow === index ? null : index);
    setPayoutComments("");
  };

  const handlePayoutListing = (index, partnerid) => {
    setShowEye(selectedRow === index ? null : index);
    if (payoutApprovalIds && payoutApprovalIds.length > 0) {
      if (
        window.confirm(
          "Already added payouts for invoice, Do you want to delete old invoice data"
        )
      ) {
        setPayoutIdsApproval([]);
        setSelectedAmount(0);
      } else {
        return false;
      }
    }
    setPayoutListingShow(payoutListingShow === index ? null : index);

    setPayoutComments("");
    setSelectedPartnerID(partnerid);
    if (partnerid) {
      getPayoutListWithFilters("", partnerid, 1);
    }

    getInvoiceData(partnerid);
  };

  const getInvoiceList = async (status, partner_id, pageNumber) => {
   // alert(pageNumber)
   if(Status=="pending" && props.activeTab=="cndn"){
      Status='eligible_note'
   }
  
    if (["eligible_note","invoice_created","payment_pending","paid","gst_payment_pending","gst_paid"].includes(Status)) {
      setpageloading(true);
      getDealerList()
      const payout_filters = {
        partner_id: partner_id,
        is_paging: "no",
        status: Status,
        source: "web",
      };
    
      if (filter_params.filter_params === "tranch_id") {
        payout_filters["tranche_id"] = filter_params.app_id;
      }
      if (filter_params.filter_params === "partner_name") {
        payout_filters["partner_name"] = filter_params.app_id;
      }
      if (filter_params.filter_params === "account_number") {
        payout_filters["account_number"] = filter_params.app_id;
      }
      if (filter_params.filter_params === "utr_no") {
        payout_filters["utr_no"] = filter_params.app_id;
      }

      if (filter_params.filter_params === "lead_id") {
        payout_filters["lead_id"] = +filter_params.app_id;
      }

      if (filter_params.filter_params === "payout_id") {
        payout_filters["payout_id"] = +filter_params.app_id;
      }

      if (filter_params.filter_params === "partner_code") {
        payout_filters["partner_ids"] = [+filter_params.app_id];
      }
      if (filter_params.filter_params === "Customer_Name") {
        payout_filters["customer_name"] = filter_params.app_id;
      }
   
      if (filter_params.invoice_creation_date && filter_params.invoice_creation_date!="") {
        payout_filters["invoice_creation_date"] = filter_params.invoice_creation_date;
      }
      
      if (filter_params.signing_date  && filter_params.signing_date!="") {
        payout_filters["signing_date"] = filter_params.signing_date;
      }

      if (filter_params.paid_date  && filter_params.paid_date!="") {
        payout_filters["paid_date"] = filter_params.paid_date;
      }

      if (filter_params.filter_params === "Customer_Name") {
        payout_filters["customer_name"] = filter_params.app_id;
      }
      if (filter_params.filter_params === "app_id") {
        payout_filters["app_id"] = +filter_params.app_id;
      }
     
      if (filter_params.filter_params === "Scheme_ID") {
        payout_filters["scheme_id"] = +filter_params.app_id;
      }

      if (filter_params.disbursal_date_from !== "") {
        payout_filters["disbursal_date_from"] =
          filter_params.disbursal_date_from;
      }
      if (filter_params.disbursal_date_to !== "") {
        payout_filters["disbursal_date_to"] = filter_params.disbursal_date_to;
      }
      if (filter_params.product_type !== "") {
        payout_filters["product_type"] = filter_params.product_type;
      }
      // if (filter_params.Select_Scheme !== "") {
      //   payout_filters["scheme_id"] = filter_params.scheme_id;
      // }

      if (DataFIlter.financier_id && DataFIlter.financier_id.length > 0) {
        payout_filters["financersids"] = DataFIlter.financier_id;
      }
      if (DataFIlter.partnerName && DataFIlter.partnerName.length > 0) {
        payout_filters["partner_ids"] = DataFIlter.partnerName;
      }

      if (status && status != "") payout_filters.paid_status = Status;

      const page_no = pageNumber ? pageNumber : 1
      delete payout_filters.is_paging;
      payout_filters.tab_type=props.activeTab=="cndn"?"CNDN":"STD"
      const variables = {
        filter_params: payout_filters,
        page_no: page_no,
      };
      try {
        let result = "";
        if(Status === 'gst_payment_pending') {  
           result = await executeGraphQLMutation(
            GST_INVOICE_LISTING(page_no),
            variables,
            page_no
          );
          if (result.data.gst_pending_list.length == 0) {
            setPayoutListing([]);
            setPaginations({});
            setpageloading(false);
  
            setInvoiceListData([]); //t
          } else {
            const invoices = result.data.gst_pending_list[0]?.invoices;
            setPayoutListing(invoices);
            setPaginations(result.data.gst_pending_list[0].pagination);
            setInvoiceListData(invoices);
          }
          if (result) {
            if (result.data.gst_pending_list && result.data.gst_pending_list.length > 0) {
              const disbursals = result.data.gst_pending_list[0].invoice;
  
              setPayoutListing(disbursals);
              setpageloading(false);
            }
          } else {
            setInvoiceListData([]);
            setPayoutListing([]);
            setPaginations({});
            setpageloading(false);
          }
    //For Status => invoice_created,payment_pending,paid,gst_paid
        } else {
         result = await executeGraphQLMutation(
          INVOICE_LISTING(page_no),
          variables,
          page_no
        );
        if (result.data.invoice_list.length == 0) {
          setPayoutListing([]);
          setPaginations({});
          setpageloading(false);

          setInvoiceListData([]); //t
        } else {
          const invoices = result.data.invoice_list[0]?.invoices;
          setPayoutListing(invoices);
          setPaginations(result.data.invoice_list[0].pagination);
          setInvoiceListData(invoices);
          setpageloading(false);
        }
        if (result) {
          if (result.data.invoice_list && result.data.invoice_list.length > 0) {
            const disbursals = result.data.invoice_list[0].invoice;

            setPayoutListing(disbursals);
            setpageloading(false);
          }
        } else {
          setInvoiceListData([]);
          setPayoutListing([]);
          setPaginations({});
          setpageloading(false);
        }
      }  
      } catch (error) {
        toast.error("Failed");
        console.error("error", error);
      }
    }
  };

  const getInvoiceData = async (partner_id) => {
    if (["pending","invoice_created","payment_pending","paid","gst_payment_pending","gst_paid"].includes(Status)) {
      setpageloading(true);

      try {
        const result = await executeGraphQLMutation(
          INVOICE_LISTING_DATA(partner_id)
        );
        if (result.data.invoice_payouts.length == 0) {
          setpageloading(false);
        } else {
          const invoices = result.data.invoice_payouts;

          setInvoicedata(invoices);
          setOriginalData(invoices);
          setpageloading(false);
        }
      } catch (error) {
        toast.error("Failed");
        console.error("error", error);
      }
    }
  };

  const getPayoutListWithFilters = async (status, partner_id, pageNumber) => {
    if (Status === "pending") {
      setpageloading(true);
      const payout_filters = {
        partner_id: partner_id,
        is_paging: "no",
        paid_status: "pending",
        source: "web",
      };
      const variables = {
        filter_params: payout_filters,
        page_no: pageNumber ? pageNumber : 1,
      };
      try {
        const result = await executeGraphQLMutation(
          PAYOUT__LIST_WITH_FILTERS,
          variables,
          pageNumber
        );
        if (result.data.getDisbursalLeads.length == 0) {
          setPayoutListing([]);
          setPaginations({});
          setpageloading(false);
        }
        if (result) {
          if (
            result.data.getDisbursalLeads &&
            result.data.getDisbursalLeads.length > 0
          ) {
            const disbursals = result.data.getDisbursalLeads[0].disbursals;
            setPayoutListing(disbursals);
          //  console.log("disbursals",)
            setpageloading(false);
          }
        } else {
          setPartnerData([]);
          setPayoutListing([]);
          setPaginations({});
          setpageloading(false);
        }
      } catch (error) {
        toast.error("Failed");
        console.error(error);
      }
    }
  };

  const getPartnersDisbursalList = async (
    status = "calculated",
    current_page = 1
  ) => {
   
    
    setpageloading(true);
    const payout_filters = { ...filterData };
    if (filter_params.filter_params === "lead_id") {
      payout_filters["lead_id"] = +filter_params.app_id;
    }

    if (filter_params.filter_params === "tranch_id") {
      payout_filters["tranche_id"] = filter_params.app_id;
    }
    if (filter_params.filter_params === "partner_name") {
      payout_filters["partner_name"] = filter_params.app_id;
    }
    
    if (filter_params.filter_params === "partner_code") {
      payout_filters["partner_ids"] = typeof filterParams.partner_code ==='number' ? [filterParams.partner_code] : filter_params.app_id;
    }
    if (filter_params.filter_params === "Customer_Name") {
      payout_filters["customer_name"] = filter_params.app_id;
    }
    if (filter_params.filter_params === "Scheme_ID") {
      payout_filters["scheme_id"] = +filter_params.app_id;
    }
    if (filter_params.disbursal_date_from !== "") {
      payout_filters["disbursal_date_from"] = filter_params.disbursal_date_from;
    }
    if (filter_params.disbursal_date_to !== "") {
      payout_filters["disbursal_date_to"] = filter_params.disbursal_date_to;
    }
    if (filter_params.product_type !== "") {
      payout_filters["product_type"] = filter_params.product_type;
    }
    if (filter_params.Select_Scheme !== "") {
      payout_filters["scheme_id"] = filter_params.scheme_id;
    }

    if (filter_params.filter_params === "payout_id") {
      payout_filters["payout_id"] = +filter_params.app_id;
    }

    if (filter_params.filter_params === "app_id") {
      payout_filters["app_id"] = filter_params.app_id.toString();
    }

    if (DataFIlter.financier_id && DataFIlter.financier_id.length > 0) {
      payout_filters["financersids"] = DataFIlter.financier_id;
    }
    if (DataFIlter.partnerName && DataFIlter.partnerName.length > 0) {
      payout_filters["partner_ids"] = DataFIlter.partnerName;
    }

    // if (status && status != "")
    //     payout_filters.paid_status = "pending"

    payout_filters.status = !Status ? Status : Status;

    const pageNumber = current_page ? current_page : page_no;
    if (
      payout_filters.product_type_ids &&
      payout_filters.product_type_ids.length > 0
    ) {
      payout_filters.product_type = payout_filters.product_type_ids[0];
    }
    if (status && status != "") {
      payout_filters.status = status;
    }
    if (payout_filters.financier_id && payout_filters.financier_id.length > 0) {
      payout_filters.financersids = payout_filters.financier_id;
    }
    props.activeTab=="cndn"?payout_filters.tab_type="CNDN":payout_filters.tab_type="STD"
    delete payout_filters.schemefinancers;
    delete payout_filters.product_type_ids;
    delete payout_filters.financier_id;
    const variables = {
      filter_params: payout_filters,
    };
    if(props.activeTab!=="cndn"){
      if(status==="calculated"){
        setPreEligiblePartner(pageNumber, variables)
      } else {
        setEligiblePartnerDisbursalList(pageNumber, variables)
      }
    }
    
  };
  const setPreEligiblePartner = async (page_no, variables) => {
     try {
        const result = await executeGraphQLMutation(
          PRE_ELIGIBLE_PARTNER_LIST(page_no),
          variables
        );
        
      if (result.data.pre_eligible_partners.length == 0) {
        setPartnerData([]);
        setPayoutListing([]);
        setPaginations({
          "totalpage": 0,
          "totalrecords": 0,
          "nextpage": false,
          "prevpage": false,
        });
       // onPaginate(0)
        setpageloading(false);
      }
      if (result) {
        if (
          result.data.pre_eligible_partners &&
          result.data.pre_eligible_partners.length > 0
        ) {
          const disbursals = result.data.pre_eligible_partners[0].disbursals;
          setPartnerData(disbursals);
          seteligiblepaginationdata(
            result.data.pre_eligible_partners[0].pagination
          );
          setpageloading(false);
        }
      } else {
        setPartnerData([]);
        setPaginations({});
        setpageloading(false);
        setPaginations({
          "totalpage": 0,
          "totalrecords": 0,
          "nextpage": false,
          "prevpage": false,
        });
      }
      } catch (error) {
        console.log("error",error)
      //  toast.error(error);
        setpageloading(false);
      }
  }
  const setEligiblePartnerDisbursalList = async (page_no, variables) => {
    try {
      const result = await executeGraphQLMutation(
        PARTNER_DISBURSAL_LIST(page_no),
        variables
      );
     // console.log("result.data.partner_disbursals",result)
        if (result?.data?.partner_disbursals?.length == 0) {
          setPartnerData([]);
          setPayoutListing([]);
          setPaginations({});
          setpageloading(false);
        }
        if (result) {
          if (
            result.data.partner_disbursals &&
            result.data.partner_disbursals.length > 0
          ) {
            const disbursals = result.data.partner_disbursals[0].disbursals;
            setPartnerData(disbursals);
            seteligiblepaginationdata(
              result.data.partner_disbursals[0].pagination
            );
            setpageloading(false);
          }
        } else {
          setPartnerData([]);
          setPaginations({});
          setpageloading(false);
        }
    } catch (error) {
      console.log("error",error)
    //  toast.error(error);
      setpageloading(false);
    }
  }
  useEffect(() => {
    getDealerList();
    getSchemeList();
    updateReduxData();
    // getPayoutListWithFilters();
    getInvoiceData();
    getFinexMasterData();
    getFinexPersonalBankData();
    getInvoiceList();
  }, []);

useEffect(() => {
  getInvoiceList();
}, [props.openPopup]);

  useEffect(() => {
    getInvoiceList();
    getPartnersDisbursalList(Status,1);
  }, [counter,props.activeTab]);

  useEffect(() => {
    getInvoiceList();
    getPartnersDisbursalList(Status,1);
    setPayoutIdsApproval([]);
    setSelectedAmount(0);
    setSelectedPartnerID(0);
    setPartnerData([]);
    setPayoutListing([]);
    setPaginations({});
    setpageloading(false);
    setSelectedRow(null);
    setInvoiceListData([]);
    setInvoicedata([]);

  }, [buttonClick]);

  useEffect(() => {
    if (Object.keys(filterOptions)) {
      const filteredData = originalData.filter(
        (obj) => obj.id == filterOptions.partner_id
      );
      setOriginalData(invoicedata);
      setInvoicedata(filteredData);
    }
  }, [filterOptions]);

  useEffect(() => {
     const pageNo = gstStatusDataPageNo;
    getGstMatchedList(pageNo);
  }, [gstStatusDataPageNo]);

  useEffect(() => {
    setGstStatusDataPageNo(1);
    getGstMatchedList(1);
  }, [gstStatus]);

  const getSchemeList = async () => {
    const partnerArray = [];
    const schemeData = await executeGraphQLQuery(SCHEME_LIST);
    const data = schemeData.data.getAllSchemsList;
    data &&
      data.map((list) => {
        const partnerData = {
          label: list.id,
          value: list.scheme_name,
        };
        partnerArray.push(partnerData);
      });
    setschemeList(partnerArray);
  };

  const getleadsCounts = async () => {
    const leadscounts = await executeGraphQLQuery(PAYOUT_LEADS_COUNTS);
    const data = leadscounts.data.leads_count;

    let total = 0;
    data &&
      data.map((list) => {
        total = total + parseInt(list.counts);
      });
    if (total > 0) {
      setTotalLeads(total);
    }
    setLeadsCounts(data);
  };

  const createInvoice = async (partner_id) => {
    setpageloading(true);
    const variables = {
      createinvoiceargs: {
        payout_ids: payoutApprovalIds,
        partner_id: partner_id,
      },
    };
    try {
      const result = await executeGraphQLMutation(CREATE_INVOICE, variables);
      if (result && result.data && result.data.create_invoice) {
        setpageloading(false);
        toast.success(result.data.create_invoice || "Success");
        setTimeout(() => {
          window.location.reload(false); // Reload the page after a delay
        }, 1000); // 1-second delay
      } else {
        throw new Error("Invoice creation failed"); // Handle unexpected result structure
      }
    } catch (error) {
      toast.error("Failed to create invoice");
      console.error(error);
      setpageloading(false);
    }
  };

  const getFinexMasterData = async () => {
    const masterData = await executeGraphQLQuery(FINEX_MASTER_LIST()).catch(
      (err) => console.log(err)
    );
    if (masterData && masterData.data && masterData.data.masterdata) {
      const bankData = [];
      const producttypedata = [];
      const fulfillmenttypeData = [];
      const data = masterData.data.masterdata;
      const bank_list =
        data.bank_list && data.bank_list.length > 0 ? data.bank_list : [];
      const product_type =
        data.loan_type && data.loan_type.length > 0 ? data.loan_type : [];
      if (bank_list && bank_list.length > 0) {
        bank_list.map((banks) => {
          bankData.push({
            id: banks.banklang.bank_id,
            name: banks.banklang.bank_name,
          });
        });
        setFinancers(bankData);
      }
      if (product_type && product_type.length > 0) {
        product_type.map((ptype) => {
          producttypedata.push({ id: ptype.id, name: ptype.label });
        });
        setProductType(producttypedata);
      }
      const fulfillmenttype =
        data.fulfillment_type && data.fulfillment_type.length > 0
          ? data.fulfillment_type
          : [];
      if (fulfillmenttype && fulfillmenttype.length > 0) {
        fulfillmenttype.map((ptype) => {
          fulfillmenttypeData.push({ id: ptype.id, name: ptype.label });
        });
        setFulfillmentTypes(fulfillmenttypeData);
      }
    }
  };

  const getGstMatchedList = async (page_no) => {
    const variables = {
      filter_params: {
        matched_status: gstStatus,
      },
      page_no
    };
    if (gstStatus === "matched") {
      return false;
    }
    executeGraphQLMutation(GET_GST_MATCHED_LIST, variables, client)
      .then((response) => {
        if (response?.data?.gst_matching_list) {
          setGstStatusData(response?.data?.gst_matching_list[0].list);
          setGstStatusDataPagination(response?.data?.gst_matching_list[0].pagination);
        }
      })
      .catch((err) => console.log(err));
  };

  const getFinexPersonalBankData = async () => {
    const masterData = await executeGraphQLQuery(FINEX_BANK_LIST()).catch(
      (err) => console.log(err)
    );
    if (masterData && masterData.data && masterData.data.bank_pa) {
      const bankData = [];
      const data = masterData.data.bank_pa;
      const bank_list =
        data.bank_list && data.bank_list.length > 0 ? data.bank_list : [];
      const product_type =
        data.loan_type && data.loan_type.length > 0 ? data.loan_type : [];
      if (bank_list && bank_list.length > 0) {
        bank_list.map((banks) => {
          bankData.push({
            id: banks.banklang.bank_id,
            name: banks.banklang.bank_name,
          });
        });
        setFinancersPersonal(bankData);
      }
    }
  };


  const getDealerList = async () => {
    let user_list = [], userlist = [];
    setUserList(userlist)
    const response = await executeGraphQLQueryFinex(GET_USER_LIST_PAYOUT).catch(
      (err) => console.log(err)
    );
        if (response && response.data && response.data.get_user_list) {
            user_list = response.data.get_user_list;
            user_list && user_list.map((v) => {
                userlist.push({ value: v.user_id, label: v.name })
                return v;
            })
           // console.log("userlist",userlist)
            setUserList(userlist);
        }
  
};

  // const getDealerList = () => {
  //   MasterService.get("account/user/user_list")
  //     .then(function (response) {
  //       if (response.data.status == 200) {
  //         if (response.data.data.length) {
  //           setUserList(response.data.data);
  //         } else {
  //           setUserList([]);
  //         }
  //       }
  //     })
  //     .catch(function (response) {});
  // };

  let role;
  if (secureStorage.getItem("loginUserInfo")) {
    let loginResponse = secureStorage.getItem("loginUserInfo");
    if (loginResponse && loginResponse.data && loginResponse.data.user_data) {
      role = loginResponse.data.user_data.role;
    }
  }

  const onPaginate = (pageNumber) => {
    setPageNo(pageNumber);

    //console.log({pageNumber})
   // console.log("Status ===>> ", Status)
    if(Status === "pending" && props.activeTab=="cndn"){
      Status="eligible_note"
    }

    if (Status === "pending" || Status === "calculated") {
      getPartnersDisbursalList(Status, pageNumber)
    }

    if(Status==="eligible_note" || Status === "invoice_created" ||
    Status === "payment_pending" ||
    Status === "paid" ){
      getInvoiceList(Status, 0, pageNumber)
    }
    
 
  };

  const handleGstClaimedStatus = (status) => {
    setGSTStatus(status);
  };

  const handleGstDataPageNo = (pageNo) => {
    setGstStatusDataPageNo(pageNo);
  }

  return (
    <>
      {pageloading ? <Loader /> : null}

      {Status === "gst_payment_pending" && (
        <>
          <div className="row">
            <div className="col-sm-8">
              <ul className="list-inline nav-tabs">
                <li
                  className={
                    gstStatus === "matched" ? "tabs-item active" : "tabs-item"
                  }
                  onClick={() => handleGstClaimedStatus("matched")}
                >
                  <div>Matched</div>
                </li>
                <li
                  className={
                    gstStatus === "not matched"
                      ? "tabs-item active"
                      : "tabs-item"
                  }
                  onClick={() => handleGstClaimedStatus("not matched")}
                >
                  <div>Not Matched</div>
                </li>
                <li
                  className={
                    gstStatus === "other vendors"
                      ? "tabs-item active"
                      : "tabs-item"
                  }
                  onClick={() => handleGstClaimedStatus("other vendors")}
                >
                  <div>Other Vendors</div>
                </li>
              </ul>
            </div>
          </div>
        </>
      )}

      {Status === "gst_payment_pending" &&
      (gstStatus === "not matched" || gstStatus === "other vendors") ? (
        <>
          <GstTableContent gstStatusData={gstStatusData} gstStatusDataPagination={gstStatusDataPagination} gstStatusDataPageNo={gstStatusDataPageNo} setGstDataPageNo={handleGstDataPageNo} gstStatus = {gstStatus} />
        </>
      ) : (
        <>
          {["gst_payment_pending","gst_paid"].includes(Status) ||(["invoice_created","payment_pending","paid"].includes(Status) && props?.activeTab==="standard") ? (
            <StandardOtherTableContent
              status={Status}
              invoiceListData={invoiceListData}
              handlePayoutListing={handlePayoutListing}
              selectedPartnerId={selected_partner_id}
              invoiceData={invoicedata}
              payoutApprovalIds={payoutApprovalIds}
              setPayoutIdsApproval={setPayoutIdsApproval}
              selectedAmount={selected_amount}
              createInvoice={createInvoice}
              paginationData={paginationdata}
              onPaginate={onPaginate}
              filterData={filterData}
              financers={financers}
              partnerListing={partnerListing}
              userlist = {user_list}
            />
          ) : ["pending","invoice_created","payment_pending","paid"].includes(Status) ? (
            props?.activeTab==="cndn"?
            <CNDNTableContent
                partnerListing={invoiceListData}
                eligiblepaginationdata={eligiblepaginationdata}   
                onPaginate={onPaginate}
                paginationData={paginationdata}
                page_no={filterData.page_no} 
                handleChange={props.reCalculateLeadList}
                Status={Status}
                financers={financers}
                userlist = {user_list}
                getInvoiceData={getInvoiceData}
                invoiceData={invoicedata}
                getInvoiceList={getInvoiceList}
              /> :
              <StandardTableContent
                partnerListing={partnerListing}
                selectedPartnerId={selected_partner_id}
                pageNo={filterData.page_no} 
                handleChange={props.reCalculateLeadList}
                payoutListing={payoutListing}
                payoutApprovalIds={payoutApprovalIds}
                setPayoutIdsApproval={setPayoutIdsApproval}
                createInvoice={createInvoice}
                eligiblepaginationdata={eligiblepaginationdata}   
                onPaginate={onPaginate}
                productType={productType}
                financers={financers}
                getPayoutListWithFilters={getPayoutListWithFilters}
              /> 
          ) : Status === "calculated" ? (
            <PreEligibleTableContent 
                partnerListing={partnerListing}
                eligiblepaginationdata={eligiblepaginationdata}   
                onPaginate={onPaginate}
                page_no={filterData.page_no} 
                handleChange={props.reCalculateLeadList}
                productType={productType}
                financers={financers}
              />  
          ) : null}
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    auth_token: state.authToken,
    user_loggedin: state.userLoggedIn,
    dealer_id: state.dealer_id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateStateIntoRedux: (actionType, stateData) =>
      dispatch({ type: actionType, componentState: stateData }),
  };
};

export default withTranslation("dealer")(
  connect(mapStateToProps, mapDispatchToProps)(PayoutListing)
);
