export default {
    FOLLOW_UPS_WITH: function () {
        return [
            { id: "1", label: "Customer", value: "customer" },
            { id: "2", label: "Financier", value: "financier" },
            { id: "3", label: "Saathi", value: "saathi" },
            { id: "4", label: "Ambak", value: "ambak" },
            { id: "5", label: "Yoddha", value: "yoddha" },
            { id: "6", label: "Builder for Docs", value: "Builder for Docs" },
            { id: "7", label: "Customer because Deal Cancelled/Postponed", value: "Customer because Deal Cancelled/Postponed" },
            { id: "8", label: "Customer because OC not paid", value: "Customer because OC not paid" },
            { id: "9", label: "APF", value: "APF" },
        ]
    },
    FOLLOW_UPS_WITH_UPDATE_FORM: function () {
        return [
            { id: "1", label: "Customer", value: "customer" },
            { id: "2", label: "Financier", value: "financier" },
            { id: "3", label: "Saathi", value: "saathi" },
            { id: "4", label: "Ambak", value: "ambak" },
            { id: "5", label: "Yoddha", value: "yoddha" }
        ]
    }
}