import { executeGraphQLMutation } from "../../../common/executeGraphQLQuery";
import { SAVE_BRE_OFFER } from "../../../services/customer.gql";
import FormFields from "../formFields/breOfferFormFields.json";
import FormsyInputField from "../../elements/FormsyInputField";
import FormsyDatePicker from "../../elements/FormsyDatePicker";
import React, { useState, useMemo, useEffect } from "react";
import FormsySelect from "../../elements/FormsySelect";
import { useApolloClient } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import dateFormat from "dateformat";
import Formsy from "formsy-react";
import { getLoanDetail } from "../../../store/action/allAction";

const BASIC_PROFILE_FIELDS = FormFields["BASIC_DETAILS"];
const SEP_ID = 2;

const BreBasicDetails = ({ setBreBasicDetails, setNextTabActive }) => {
  const [basicDetails, setBasicDetails] = useState({});
  const [isFormTouch, setTouch] = useState({});
  const client = useApolloClient();
  const dispatch = useDispatch();
  const user_information = localStorage.getItem("user_information");
  let authUser = user_information && JSON.parse(user_information);
  let user_id = authUser?.id || 1;
  const { masterdata, lead } = useSelector(({ masterdata, lead }) => ({
    masterdata,
    lead,
  }));
  let ALL_OPTIONS = useMemo(() => {
    const options = masterdata ? { ...masterdata.data } : {};
    options["company_type"] = options["company_type"]?.map((obj) => ({
      ...obj,
      value: obj.id,
    }));
    options["profession"] = options["profession"]?.filter(
      (obj) => obj.value !== SEP_ID
    );
    return options;
  }, [masterdata]);

  const handleInputChange = (event) => {
    const { id, value } = event.target;
    setBasicDetails((currentValue) => ({
      ...currentValue,
      [id]: value,
    }));

    setBreBasicDetails(id, value);
  };

  const handlePatternFormatChange = (data, field_name) => {
    if (data.value && field_name) {
      setTouch({
        ...isFormTouch,
        [field_name]: true,
      });
      setBasicDetails((currentValue) => ({
        ...currentValue,
        [field_name]: data.value,
      }));
      setBreBasicDetails(field_name, data.value);
    }
  };

  const handleSelectChange = (data, field_name) => {
    if (data && field_name) {
      if (field_name.includes("state")) {
        let options = masterdata ? { ...masterdata.data } : {};
        if (options?.city && options?.city?.length) {
          let record = options.city
            .filter((ob) => ob.state_id == data.value)
            .map((data) => {
              data.value = data.id;
              data.label = data.label;
              return data;
            });
          // dispatch(setCityList(record));
        }
      }
      setBreBasicDetails(field_name, data.value);
      setBasicDetails((currentValue) => ({
        ...currentValue,
        [field_name]: data.value,
      }));
    }
  };

  const handleDateChange = (date, field_name) => {
    if (date && field_name) {
      setBasicDetails((currentValue) => ({
        ...currentValue,
        [field_name]: date,
      }));
      setBreBasicDetails(field_name, date);
    }
  };

  useEffect(() => {
    if (lead && lead.leadDetail && lead.leadDetail.customer) {
      let basic_profile = {
        first_name: lead.leadDetail.customer.first_name,
        last_name: lead.leadDetail.customer.last_name,
        phone_number: lead.leadDetail.customer.mobile,
        dob: dateFormat(lead.leadDetail.customer.dob, "dd mmm, yyyy"),
        loan_amount: lead.leadDetail.lead_details?.loan_amount,
        profession: lead.leadDetail.lead_details?.profession,
        work_experience: lead.leadDetail.lead_details?.work_experience,
        company_name: lead.leadDetail.lead_details?.company_name,
        tenure: lead.leadDetail.lead_details?.tenure,
        company_type: lead?.leadDetail?.lead_details?.company_type,
        loan_type: lead?.leadDetail?.loan_type,
        fulfillment_type: lead?.leadDetail?.fulfillment_type,
        industry: lead.leadDetail.customer?.industry,
        business_vintage: lead.leadDetail.customer?.business_vintage_years,
      };
      setBreBasicDetails(null, null, basicDetails);
      setBasicDetails(basic_profile);
    }
  }, [lead]);

  const handleBasicDetails = () => {
    setBreBasicDetails(null, null, basicDetails);
    const lead_id = lead?.leadDetail?.id || "";
    const customer = {
      lead_id,
      first_name: basicDetails.first_name,
      last_name: basicDetails.last_name,
      mobile: basicDetails.phone_number,
      dob: dateFormat(basicDetails.dob, "yyyy-mm-dd"),
      business_vintage_years: +basicDetails.business_vintage,
      industry: basicDetails.industry,
    };
    const lead_details = {
      lead_id,
      loan_amount: basicDetails.loan_amount,
      profession: basicDetails.profession,
      company_type: basicDetails.company_type,
      company_name: basicDetails.company_name,
      ...(basicDetails.work_experience
        ? { work_experience: +basicDetails.work_experience }
        : {}),
    };
    let mutation = SAVE_BRE_OFFER(user_id),
      variables = {
        api_called_by: "web",
        LeadAdditionalInput: {
          lead_id: lead_id,
          customer,
          lead_details,
        },
      };

    executeGraphQLMutation(mutation, variables, client)
      .then((resp) => {
        let response = resp?.data?.save_additional_info || null;
        if (!response) {
          throw new Error(response.error || "Something went wrong");
        }
        dispatch(getLoanDetail(lead_id, client));
        toast.success("Success");
        setNextTabActive();
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  return (
    <>
      <div>
        <Formsy
          className="lead-form-filed"
          autoComplete="off"
          onValid={() => null}
          onInvalid={() => null}
          onValidSubmit={handleBasicDetails}
        >
          <div className="row">
            {BASIC_PROFILE_FIELDS.map((field, index) =>
              (basicDetails["profession"] === 1 && field.name === "industry") ||
              (basicDetails["profession"] === 1 &&
                field.name === "business_vintage") ||
              (basicDetails["profession"] !== 1 &&
                field.name === "work_experience") ||
              (basicDetails["profession"] !== 1 &&
                field.name === "company_name") ||
              (basicDetails["profession"] !== 1 &&
                field.name === "company_category") ? null : [
                  "text",
                  "pattern-format",
                  "number-format",
                  "number",
                ].includes(field.type) ? (
                <fieldset className={"form-filed col-md-6"} key={index}>
                  <FormsyInputField
                    id={field.id}
                    name={field.name}
                    type={field.type}
                    value={(basicDetails && basicDetails[field.name]) || ""}
                    placeholder=" "
                    thousandsGroupStyle="lakh"
                    thousandSeparator={","}
                    decimalSeparator={"."}
                    maxLength={field.maxLength}
                    label={field.label}
                    format={field.ApplyNumberformat ? field.format : ""}
                    validations={
                      isFormTouch[field.name] ? field.validations : null
                    }
                    validationError={
                      basicDetails[field.name] || basicDetails[field.name] == ""
                        ? isFormTouch[field.name]
                          ? field.validationError
                          : ""
                        : ""
                    }
                    readOnly={field.readOnly}
                    required={field.required}
                    onChange={
                      ["number-format"].includes(field.type)
                        ? (data) => handlePatternFormatChange(data, field.name)
                        : handleInputChange
                    }
                  />
                </fieldset>
              ) : field.type === "dropdown" ? (
                <fieldset
                  className={
                    field.fullWidth
                      ? "single-select col-md-6"
                      : "single-select col-md-6"
                  }
                  key={index}
                >
                  <FormsySelect
                    name={field.name}
                    id={field.id}
                    inputProps={{
                      options: [field.optionsKey],
                      options: ALL_OPTIONS && ALL_OPTIONS[field.optionsKey],
                      placeholder: field.label,
                      className: "react-select",
                      classNamePrefix: "react-select",
                      value:
                        ALL_OPTIONS &&
                        ALL_OPTIONS[field.optionsKey] &&
                        ALL_OPTIONS[field.optionsKey].filter(
                          ({ value }) => value == basicDetails[field.name]
                        ),
                      isDisabled: field.readOnly,
                    }}
                    required={field.required}
                    value={basicDetails && basicDetails[field.name]}
                    onChange={(data) => handleSelectChange(data, field.name)}
                  />
                </fieldset>
              ) : field.type === "date-picker" ? (
                <fieldset className="form-filed col-md-6 " key={index}>
                  <FormsyDatePicker
                    name="dob"
                    selectedDate={
                      basicDetails &&
                      basicDetails[field.name] &&
                      new Date(basicDetails[field.name])
                    }
                    placeholder={field.label}
                    value={basicDetails && basicDetails[field.name]}
                    onDateSelect={(date) => handleDateChange(date, field.name)}
                    dateFormat="dd MMM, yyyy"
                    validations={field.validations}
                    validationError={
                      basicDetails[field.name] ? field.validationError : ""
                    }
                    required={field.required}
                    hasError={
                      basicDetails.hasOwnProperty(field.name) &&
                      !basicDetails[field.name]
                        ? true
                        : false
                    }
                  />
                </fieldset>
              ) : null
            )}
          </div>

          <div className="btn-save-remarks">
            <button type="submit" className="btn-primary">
              Save & next
            </button>
          </div>
        </Formsy>
      </div>
    </>
  );
};

export default BreBasicDetails;
