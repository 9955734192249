import React, { useEffect, useState } from 'react';
import DocumentGallery from '../DocumentGallery';
import { useOutletContext,useNavigate } from 'react-router-dom';
import BreOfferSection from "../../leads/BreOffer/BreOfferSection";
import BreProperty from "../../leads/BreOffer/BreProperty";
import { useSelector } from "react-redux";
import dateFormat from "dateformat";
import crypto from "../../../config/crypto";


const OfferDetails = (props) => {
  const [bankOffers, setBankOffers] = useState([]);
  const [showOfferTabs, setShowOfferTabs] = useState(false);
  const [fetchOfferByTabs, setFetchOfferByTabs] = useState(null);
  const [loadingOffer, setLoadingOffer] = useState(false);
  const [breData, setBreData] = useState({});
  const getContext = useOutletContext();
  const documentCategoryList = getContext.docList || [];
  const [activeTab, setActiveTab] = useState("basic_details");
  let navigate = useNavigate(); 


  const { lead } = useSelector(({ masterdata, lead }) => ({
    masterdata,
    lead,
  }));

  useEffect(() => {
    if (lead && lead.leadDetail && lead.leadDetail.lead_details) {
      setBreData((current) => ({
        ...current,
        profession: lead.leadDetail.lead_details.profession,
        first_name: lead.leadDetail.customer.first_name,
        last_name: lead.leadDetail.customer.last_name,
        phone_number: lead.leadDetail.customer.mobile,
        dob: dateFormat(lead.leadDetail.customer.dob, "dd mmm, yyyy"),
        loan_amount: lead.leadDetail.lead_details?.loan_amount,
        gross_monthly_salary: lead.leadDetail.lead_details?.gross_monthly_salary,
        monthly_salary: lead.leadDetail.lead_details?.monthly_salary, 
        work_experience: lead.leadDetail.lead_details?.work_experience,
        company_name: lead.leadDetail.lead_details?.company_name,
        tenure: lead.leadDetail.lead_details?.tenure,
        company_type: lead?.leadDetail?.lead_details?.company_type,
        loan_type: lead?.leadDetail?.loan_type,
        fulfillment_type: lead?.leadDetail?.fulfillment_type,
        industry: lead.leadDetail.customer?.industry,
        business_vintage: lead.leadDetail.customer?.business_vintage_years
      }));
    }
  }, [lead]);

  const handleSetBankOffers = (offers) => {
    setBankOffers(offers);
  };

  const handleFetchOffer = (isPropertyConsidered) => {
    setFetchOfferByTabs(isPropertyConsidered);
  };

  const handleOfferTabsVisiblity = (visiblity) => {
    setShowOfferTabs(visiblity);
  };

  const handleOfferLoader = (status) => {
    setLoadingOffer(status);
  };
  const movetoNext = ()=>{ 
    let lead_id = lead?.leadDetail?.id || ""; 
    navigate(`/lead-detail/customer-details/reference/${crypto.encode(lead_id)}`);  
  }

  return (
    <div className="image-form-outer">
      <div className="image-tab-panel">
        <DocumentGallery documentCategoryList={documentCategoryList} doc_id="5" />
      </div>
      <div className="form-tab-right-panel">
        <div className="lead-detail-from-outer">
          <div className="lead-form-heading">
            <h2>Offer Check</h2>
          </div>

          <BreProperty
            breData={breData}
            setBankOffersDetails={handleSetBankOffers}
            setOfferTabsVisiblity={handleOfferTabsVisiblity}
            fetchOfferByTab={fetchOfferByTabs}
            isLoadingOffer={handleOfferLoader}
            activeTab={activeTab}
            isGet={true}
          />

          <BreOfferSection
            checkBankOffers={bankOffers}
            showTabs={showOfferTabs}
            fetchOffer={handleFetchOffer}
            isLoadingOffer={loadingOffer}
          />

          <div className="btn-save-remarks btn-save-next">
            <button className="btn-primary-outline btn-next" onClick={movetoNext}>
              Next
            </button>
          </div>

        </div>
      </div>
    </div>
  );
}
export default OfferDetails;