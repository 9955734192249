import React, { useEffect, useMemo, useState } from 'react';
import Formsy from 'formsy-react';
import { useDispatch, useSelector } from 'react-redux';
import DocumentGallery from './DocumentGallery';
import FormFields from "./formFields/leadFormField.json";
import FormsyInputField from '../elements/FormsyInputField';
import FormsyDatePicker from '../elements/FormsyDatePicker';
import FormsySelect from '../elements/FormsySelect';
import { useOutletContext, useNavigate, useParams } from 'react-router-dom';
import dateFormat from 'dateformat';
import LeadRemarkHistory from '../lead-list/LeadRemarkHistory';
import Modal from '../elements/Modal';
import right from "../../webroot/images/angle-right.svg";
import { executeGraphQLMutation } from '../../common/executeGraphQLQuery';
import { UPDATE_LEAD_STATUS } from '../../services/leads.gql';
import { gql, useApolloClient } from '@apollo/client';
import { toast } from 'react-toastify';
import { getLoanDetail, getLeadRemarks, apiCallCheckList } from '../../store/action/allAction';
import { CHECK_ASSIGN_USER, IS_USER_ACCESS_PAGE, LOGGED_QUERY_ID } from '../../config/constants';
import GeneralService from '../../services/generalService'
import crypto from "../../config/crypto";
import Loader from '../elements/Loader';
import LoggedinPopup from '../elements/LoggedinPopup';
import LoggedinTabs from './LoggedinTabs';
import LostModal from './LostModal';
import dangericon from "./../../webroot/images/dangericon.png";
import { SAVE_CUST_DETAILS } from '../../services/customer.gql';
import LeadStatusService from "../../services/leadStatus";

const DOB_MAX_DATE = new Date().setFullYear(new Date().getFullYear() - 18);
const DOB_MIN_DATE = new Date("1900-01-01 00:00:00");
const FORM_FIELDS = FormFields["LOGGEDIN"];
const DocJson = FormFields['LOGGED_DOCS'];
const EXPECTED_LOGIN_DATE=FormFields['EXPECTED_LOGIN_DATE'];

const LoggedIn = (props) => {
  const [defaultActiveTab, setDefaultActiveTab] = useState("");
  const [basicDetails, setBasicDetails] = useState({});
  const [allowSubmit, setAllowSubmit] = useState(false);
  const [ViewTimeLine, setViewTimeLine] = useState(false);
  const [loggedinView, setLoggedinView] = useState(false);
  const [showremarkform, setShowremarkform] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  // const [leadDocCount, setLeadDocCount] = useState(0);
  const [disbursalDocuments, setDisbursalDocuments] = useState([]);
  let [loading, setIsLoading] = useState(false);
  const { masterdata, lead, remarksHistory } = useSelector(({ masterdata, lead }) => ({
    masterdata,
    lead,
    remarksHistory: lead.remarksHistory || []
  }));
  
  const user_information = localStorage.getItem('user_information');
  let authUser = user_information && JSON.parse(user_information);
  const client = useApolloClient();
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let { lead_id } = useParams();
  let accessCondition = !IS_USER_ACCESS_PAGE("loanDetail", "customer-basic-details")?.is_edit_access;
  let verifyAccess = IS_USER_ACCESS_PAGE("verify_login", "verify_login")?.is_edit_access; 

  let updatedStatusList = lead?.leadDetail?.lead_status_history?.map(data => data.status_id) || [];

  if (accessCondition !== 1) {
    accessCondition = CHECK_ASSIGN_USER(lead?.leadDetail?.assign_to);
  }

  const getContext = useOutletContext();
  const documentCategoryList = getContext.docList || []
  const [MarkAsLost, setMarkAsLost] = useState(false); 
  const [rejectionType,setRejection] = useState('')
  
  useEffect(() => {
    if (lead && lead.leadDetail && lead.leadDetail.lead_details) {
      let record = {
        login_amount: lead.leadDetail.lead_details.login_amount,
        login_number: lead?.leadDetail?.lead_details?.login_number,
        login_remark: "",
        bank_id: lead?.leadDetail?.lead_details?.bank_id || 0,
        login_date: (lead.leadDetail.lead_details.login_date) ? dateFormat(lead.leadDetail.lead_details.login_date, "dd mmm, yyyy") : "",
        expected_login_date: (lead?.leadDetail?.lead_details?.expected_login_date) ? dateFormat(lead?.leadDetail?.lead_details?.expected_login_date, "dd mmm, yyyy") : "",
      };
      setBasicDetails(record);
    }

  }, [lead]);

  useEffect(() => {
    let doc_list = [{
      "id": 42,
      "max_upload": 5,
      "min_upload": 1,
      "name": "Approval Image"
    }];
    let doc_array = [];
    DocJson && DocJson.map((field) => {
      doc_list?.map(doc => { return (doc.id === field.doc_id) ? doc_array.push(doc) : doc_array.push({}) })
    })
    doc_array = doc_array.filter(obj => Object.keys(obj).length !== 0);
    setDisbursalDocuments(doc_array);
  }, []);

  let ALL_OPTIONS = useMemo(() => {
    let options = masterdata ? { ...masterdata.data } : {};
    if (options?.bank_list && options?.bank_list?.length) {
      options.bank_list = options?.bank_list?.map(data => {
        data.value = data?.id || ''
        data.label = data?.banklang?.bank_name || ''
        return data;
      })
    }
    return options;
  }, [masterdata]);

  const handleInputChange = event => {
    if (event.target.id) {
      setBasicDetails(currentValue => ({
        ...currentValue,
        [event.target.id]: event.target.value
      }))
    }
  }

  const handlePatternFormatChange = (data, field_name) => {
    if (data.value && field_name) {
      setBasicDetails(currentValue => ({
        ...currentValue,
        [field_name]: data.value
      }))
    }
  }

  const handleDateChange = (date, field_name) => {
    if (date && field_name) {
      setBasicDetails(currentValue => ({
        ...currentValue,
        [field_name]: date
      }))
    }
  }

  const handleSelectChange = (data, field_name) => {
    if (data && field_name) {
      setBasicDetails(currentValue => ({
        ...currentValue,
        [field_name]: data.value
      }))
    }
  }
 
  const showModalMarkAsLost = (type) => {      
    setMarkAsLost(true);
    setRejection(type)
    document.body.classList.add("overflow-hidden");
  };
  const hideModalMarkAsLost = () => { 
    setMarkAsLost(false); 
    document.body.classList.remove("overflow-hidden");
  };
 
  const handleSubmit = () => {
      let lead_id = lead?.leadDetail?.id || "";
      let reqBody = {
        bank_id: basicDetails.bank_id || 0,
        login_number: basicDetails.login_number || 0,
        login_amount: basicDetails.login_amount || 0,
        login_remark: basicDetails.login_remark || '',
        login_date: basicDetails.login_date ? dateFormat(basicDetails.login_date, "yyyy-mm-dd") : null
      }

      let mutation = UPDATE_LEAD_STATUS,
        variables = {
          api_called_by: 'web',
          UpdateLeadStatus: {
            lead_id: lead_id,
            user_id: authUser && authUser.id || 1,
            update_type: "status",
            // save_type: ([2].filter(status_id => updatedStatusList.includes(status_id)).length >= 1) ? "update" : "update",
            save_type: ([2].filter(status_id => updatedStatusList.includes(status_id)).length >= 1) ? "save" : "save",            
            status_id: [2],
            login_form: reqBody,
            callFrom:"updateSideForm"
          }
        };

      executeGraphQLMutation(mutation, variables, client).then(resp => {
        let response = resp?.data?.UpdateLeadStatus || null;
        if (response && !response.success) {
          throw new Error(response.error || "Something went wrong");
        }
        toast.success("Success");
        dispatch(getLoanDetail(lead_id, client))
        if (basicDetails.login_remark) dispatch((getLeadRemarks({ lead_id: lead_id, source: 'WEB' }, client)))
        //  navigateToNext(lead_id);
      })
        .catch(err => {
          toast.error(err.message);
        })
  }

  const handleSubmitExpected = () => {
  let lead_id = lead?.leadDetail?.id || "";
  let mutation = SAVE_CUST_DETAILS(authUser?.id),
    variables = {
      api_called_by: 'web',
      LeadAdditionalInput: {
        lead_id: lead_id,
        lead_details: {
          expected_login_date: basicDetails.expected_login_date ? dateFormat(basicDetails.expected_login_date, "yyyy-mm-dd") : null
        }
      }
    };

    executeGraphQLMutation(mutation, variables, client).then(resp => {
      let response = resp?.data?.LeadAdditionalInput || null;
      if (response && !response.success) {
        throw new Error(response.error || "Something went wrong");
      } 

      toast.success("Success");
      // setTouch({})
      dispatch(getLoanDetail(lead_id, client))

    })
      .catch(err => {
        toast.error(err.message);
      })
 }


  let BasicDetailsForm = useMemo(() => {
    return FORM_FIELDS.map(field => {
      if (field.name === "dob") {
        field["maxDate"] = DOB_MAX_DATE;
        field["minDate"] = DOB_MIN_DATE;
      }
      return field;
    })
  }, []);

  BasicDetailsForm = useMemo(() => {
    if (basicDetails) {
      // To Show nationality input text box in case of non phillipines
      if (basicDetails.nationality !== "other") {
        return FORM_FIELDS.filter(v => v.id !== "other_nationality");
      }
    }
    return FORM_FIELDS;

  }, [basicDetails]);

  // All the conditions can be combined here for button enable/disable
  const isSubmitDisabled = useMemo(() => {
    return lead.leadDetail?.is_login_verified === '1' || lead.leadDetail?.lead_details?.expected_login_date === '' ? true : false;
  }, [allowSubmit, getContext]);

  const is_login_verified = useMemo(() => {
    return ([1].includes(lead.leadDetail.status_id) || lead.leadDetail?.is_login_verified === '2') ? true : false;
  }, [getContext]);

  const login_verified =useMemo( () => {
    return ([1].includes(lead.leadDetail.status_id) || lead.leadDetail?.is_login_verified === '2' || lead.leadDetail?.is_login_verified === '1') ? true : false;
  })
  const showModalViewTimeLine = () => {
    setViewTimeLine(true);
    setShowremarkform(true);
    document.body.classList.add("overflow-hidden");
  };

  const hideModalViewTimeLine = () => {
    setViewTimeLine(false);
    document.body.classList.remove("overflow-hidden");
  };

  const showLoggedInModal = () => {
    setLoggedinView(true);
    document.body.classList.add("overflow-hidden");
  };

  const hideLoggedInModal = () => {
    setLoggedinView(false);
    document.body.classList.remove("overflow-hidden");
  };

  const navigateToDocSection = () => {
    let lead_id = lead?.leadDetail?.id || "";
    navigate(`/lead-detail/docs-upload/${crypto.encode(lead_id)}`);
  }
  const handleFileChange = async (event, max_upload) => {
    let selectedImage = event && event.target && event.target.files && event.target.files.length && event.target.files[0].name || "";
    let publicUrl = '', docPathArray = [];
    if (!selectedImage) {
      toast.error("Please upload an image.");
      return;
    }
    if (selectedImage) {
      let lead_id = lead?.leadDetail?.id || "";
      // if ((max_upload - leadDocCount) > 0) {
      const formData = new FormData();
      formData.append("dealer_id", "0");
      formData.append("upload_type", "dealer_docs");
      for (let imgFile of event.target.files) {
        formData.append("images", imgFile);
      }

      try {
        publicUrl = await GeneralService.uploadFilesToS3(formData, { 'Content-Type': 'multipart/form-data' });
        if (publicUrl && publicUrl.data && publicUrl.data.data && publicUrl.data.data.length) {
          docPathArray = publicUrl.data.data;
          docPathArray = docPathArray.map(ob => ob.file_url);
          saveDocument(lead_id, docPathArray);
        }
      } catch (error) {
        console.error('Error uploading file:', error);
      }
    }
  }
  const saveDocument = (lead_id, docPathArray) => {
    let leadDocArr = [];
    for (let imgPath of docPathArray) {
      leadDocArr.push({
        lead_id: Number(lead_id),
        doc_id: 42,
        parent_doc_id: 9,
        doc_path: imgPath,
        doc_source: "web",
        customer_type: "customer",
        customer_id: lead?.leadDetail?.customer?.customer_id || 0,
        user_id: authUser && authUser.id || 1,
      })
    }
    let variables = {
      saveDocInput: {
        lead_id: lead_id,
        lead_docs: leadDocArr
      },
      api_called_by: 'web'
    };

    let mutation = gql`mutation save_doc($saveDocInput: SaveDocInput!, $api_called_by: API_CALL_BY!){
          save_doc(saveDocInput: $saveDocInput, api_called_by: $api_called_by) {
              lead_id
              doc_id
              doc_path
          }
      }`;
    executeGraphQLMutation(mutation, variables, client).then(result => {
      if (result && result.data) {
        toast.success('Document uploaded successfully');
        // dispatch(getLoanDetail(lead_id, client));
      }
    })
  }

  const MardkDisburedApproved = () => {
    let lead_id = lead?.leadDetail?.id || "";
    setIsLoading(true);
    let variables = ''
    let user_id = authUser && authUser.id || 1
    let mutation = gql`mutation{
        approve_lead(action_type:"login_verify",lead_id:${lead_id},user_id:${user_id}){
          message
        }
      }`;

    executeGraphQLMutation(mutation, variables, client).then(result => {
      if (result && result.data) {
        setIsLoading(false);
        toast.success('Logged In Verified');
        dispatch(getLoanDetail(lead_id, client))
      } else {
        setIsLoading(false);
      }
    }).catch((error) => {
      setIsLoading(false);
    });
  }


  const togglePopup = () => { 
    setIsOpen(!isOpen);
    if(!isOpen){
        document.body.classList.add("overflow-hidden");
    }else{
        document.body.classList.remove("overflow-hidden");
    }
  }

  const checkList = ( masterdata && masterdata.check_list && masterdata.check_list.length) ? masterdata?.check_list?.filter(ob=>ob?.statuslang?.status_id == LeadStatusService.LEAD_STATUS_MASTER.LOGIN)?.[0]?.checklist || [] : []; 
  const checkIdArray = lead?.leadDetail?.checklists?.map(ob=>ob.checklist_id) || [];  
  let allCheckList = []
  for(let itemCheck of checkList){
    if(checkIdArray.includes(+itemCheck.id)) {
      const valueArray = lead?.leadDetail?.checklists.filter(ob=>ob.checklist_id ==itemCheck.id)
      if(valueArray && valueArray[valueArray.length-1]?.checklist_sub_status_id) {
        allCheckList = [...allCheckList, {...itemCheck, value_id: valueArray[valueArray.length-1]?.checklist_sub_status_id}] 
      }
    }
  }    
  const loginQuery = allCheckList.map(ob=>ob.value_id).some(r=> LOGGED_QUERY_ID.includes(r));  

  const sectionInfo = { section: "Customer details", sub_section: "Basic details" }
  let pageSubmit = lead?.leadDetail?.lead_status_history?.filter(ob=>ob.status_id==2) || []; 
   
  const rejectDisable = (lead.leadDetail.is_login_verified === '1' || lead.leadDetail.is_disbursal_verified === '1' || !pageSubmit.length || lead.leadDetail.status_id == 5 ) ? true : false  
  const  IS_LOST = (lead?.leadDetail?.status_id ==5)? true: false 

  const maxDate = new Date();
maxDate.setDate(maxDate.getDate() + 90);

   return (
    <div className="image-form-outer" key={lead.leadDetail && lead.leadDetail?.status_id}>
      {loading ? <Loader /> : null}
      <div className="image-tab-panel">
        <DocumentGallery documentCategoryList={documentCategoryList} doc_id="42" />
      </div>
      <div className="form-tab-right-panel">
        <div className="lead-detail-from-outer">
          <div className="lead-form-heading">
            <div className='loggedinflex'>
            <h2>Logged IN</h2>       
         
            { 
              (isSubmitDisabled || !allowSubmit || getContext.markAsFreezed || loginQuery)? 
              <div className='updatedbtnDisabled'>
                Update
              </div> :
              <div className='updatedbtn' type="submit" onClick={handleSubmit}>
              Update
            </div>
            }
            
              </div>
          </div> 

          {
            pageSubmit.length ? 
            <div className='check-text' onClick={togglePopup}>
              <div className='loggedinflex'>
                <div style={{display:"flex"}}>
                <div style={{ fontSize: 20, fontWeight: "bold" }}>
                  <i className="ic-pending-application"><i className="path1"></i><i className="path2"></i><i className="path3"></i><i className="path4"></i><i className="path5"></i></i>
                </div>
                {/* <div style={{ fontSize: 13, marginLeft: 10 }}>Complete your checklist to update status</div>   */}
                <div style={{ fontSize: 13, marginLeft: 10 }}>Underwriting Checklist</div>  
              </div>
              <img src={right} alt='icon' width={10} height={10} />
              </div>

                {
                  allCheckList.map((item)=>{ 
                    if(LOGGED_QUERY_ID.includes(item.value_id)){
                      return <div style={{marginLeft:50}}>
                        <img style={{marginRight:10}} src={dangericon} alt='logo' width={20} height={20} />
                        Query Raised : &nbsp;
                        {item.checklist_name}
                      </div>
                    }else{
                      return null
                    } 
                  })
                }   
            </div>  : null
          }


          <Formsy
            className="lead-form-filed"
            autoComplete="off"
            onValid={() => setAllowSubmit(true)}
            onInvalid={() => setAllowSubmit(false)}
            onValidSubmit={handleSubmit}
          >
            <div className="row">

              {BasicDetailsForm.map((field) => (
                ["pattern-format", 'number-format'].includes(field.type) ? (
                  <fieldset className="form-filed col-md-6 ">
                    <FormsyInputField
                      id={field.id}
                      name={field.name}
                      type={field.type}
                      value={basicDetails && basicDetails[field.name]}
                      placeholder=" "
                      thousandsGroupStyle="lakh"
                      thousandSeparator={','}
                      decimalSeparator={'.'}
                      label={field.label}
                      onChange={
                        field.type === "number-format"
                          ? (data) => handlePatternFormatChange(data, field.name)
                          : handleInputChange
                      }
                      format={field.ApplyNumberformat ? field.format : ""}
                      validations={field.validations}
                      validationError={
                        basicDetails[field.name] ? field.validationError : ""
                      }
                      readOnly={field.readOnly}
                      required={field.required}
                    />
                  </fieldset>
                ) : ["text"].includes(field.type) ? (
                  <fieldset className="form-filed col-md-6 ">
                    <FormsyInputField
                      id={field.id}
                      name={field.name}
                      type={field.type}
                      value={basicDetails && basicDetails[field.name]}
                      label={field.label}
                      onChange={
                        field.type === "pattern-format"
                          ? (data) => handlePatternFormatChange(data, field.name)
                          : handleInputChange
                      }
                      format={field.ApplyNumberformat ? field.format : ""}
                      validations={field.validations}
                      validationError={
                        basicDetails[field.name] ? field.validationError : ""
                      }
                      readOnly={field.readOnly}
                      required={field.required}
                    />
                  </fieldset>
                ) :
                  field.type === "date-picker" ? (
                    <fieldset className="form-filed col-md-6 ">
                      <FormsyDatePicker
                        name={field.name}
                        selectedDate={basicDetails && basicDetails[field.name] && new Date(basicDetails[field.name])}
                        placeholder={field.label}
                        maxDate={new Date()}
                        value={basicDetails && basicDetails[field.name]}
                        onDateSelect={(date) => handleDateChange(date, field.name)}
                        dateFormat="dd MMM, yyyy"
                        validations={field.validations}
                        validationError={
                          basicDetails[field.name] ? field.validationError : ""
                        }
                        required={field.required}
                        hasError={(basicDetails.hasOwnProperty(field.name) && !basicDetails[field.name]) ? true : false}
                      />
                    </fieldset>
                  ) :
                    field.type === "dropdown" ? (
                      <fieldset className="single-select col-md-6">
                        <FormsySelect
                          name={field.name}
                          id={field.id}
                          inputProps={{
                            options: ALL_OPTIONS && ALL_OPTIONS[field.optionsKey],
                            placeholder: field.label,
                            className: "react-select",
                            classNamePrefix: "react-select",
                            value: ALL_OPTIONS && ALL_OPTIONS[field.optionsKey] && ALL_OPTIONS[field.optionsKey].filter(({ value }) => value == basicDetails[field.name]),
                            isDisabled: field.readOnly
                          }}
                          required={field.required}
                          value={basicDetails && basicDetails[field.name]}
                          onChange={(data) => handleSelectChange(data, field.name)}
                        />
                      </fieldset>
                    ) : field.type === "textarea" ? (
                      <fieldset className="col-md-12">
                        <div className="material">
                          <textarea
                            id={field.id}
                            name={field.name}
                            type={field.type}
                            value={basicDetails && basicDetails[field.name]}
                            placeholder=" "
                            onChange={handleInputChange}
                            className="form-input"
                            // onClick={e => (e.target.value = null)}
                          >
                          </textarea>
                          <label
                            data-label={field.label}
                            className="form-label"
                          >
                          </label>
                        </div>
                      </fieldset>
                    ) : null
              ))}

            </div>

           {/*  <div className="acco-doc-list">
              <ul>
                {
                  disbursalDocuments.map((fields) => (
                    <li>

                      <div className="img-bx-select-opt" style={{ width: "100%" }}>
                        <div className="image-bx more-file-upload">
                          <input type="file" name="" onChange={e => handleFileChange(e, fields.max_upload)} required={fields.is_required} multiple />
                          <a href='#' onClick={navigateToDocSection} >verify</a>
                        </div>
                      </div>
                    </li>
                  ))
                }
              </ul>
            </div>
 */}
            <br></br>
            <div className="btn-save-remarks">
              {/* <button type="submit" className="btn-primary" disabled={isSubmitDisabled || !allowSubmit || getContext.markAsFreezed || loginQuery}>
                Update
              </button> */}
              <button type="button" onClick={MardkDisburedApproved} className="btn-primary" disabled={login_verified || loginQuery || !pageSubmit.length || IS_LOST || verifyAccess !== 1}>
                {login_verified ? "Verified" : "Verify"}
              </button>
              { (!is_login_verified && verifyAccess ===1  ) && (
                <button type="button" onClick={()=>showModalMarkAsLost('ops_reject')} className="btn-primary"  disabled={rejectDisable}>
                  Verification Failed
                </button>
              )}
             {/*  <button type="button" onClick={()=>showModalMarkAsLost('bank_reject')} className="btn-primary"  disabled={rejectDisable}>
                Bank Reject
              </button> */}
              {/* <button type="button" className="btn-primary-outline" onClick={showModalViewTimeLine}>Remarks
                <span className="m-xs-l">({remarksHistory && remarksHistory.length || 0})</span>
              </button> */}
            </div>
          </Formsy>
        </div>
      </div>
      <div className="mark-lost-popup">
        <LostModal MarkAsLost={MarkAsLost} hideModalMarkAsLost={hideModalMarkAsLost} rejection_type={rejectionType} statusId={LeadStatusService.LEAD_STATUS_MASTER.LOGIN} bucketId={LeadStatusService.LEAD_STATUS_BUCKET_MASTER.LOGIN}/>
      </div>
      <div className="view-timeline-popup">
        
        <Modal show={ViewTimeLine} handleClose={hideModalViewTimeLine} >
          <div className="modal-header">
            <h2>Remark History</h2>
          </div>
          <LeadRemarkHistory showremarkform={showremarkform} markAsFreezed={getContext.markAsFreezed} sectionInfo={sectionInfo} />
        </Modal>
      </div>

      
      <LoggedinPopup isOpen={isOpen} togglePopup={togglePopup}> 
        <LoggedinTabs status_id={2} togglePopup={togglePopup}/>
      </LoggedinPopup>
    </div>
   
  );
}
export default LoggedIn;  
