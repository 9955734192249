import React from 'react';
import AccordionTabs from './AccordionTabs';

const LoggedinTabs = (props) => {
    return (
        <div>
            <AccordionTabs {...props}/>
        </div>
    );
};

export default LoggedinTabs;
