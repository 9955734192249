import Q from "q";
import React, { useEffect, useRef, useState } from "react";
import {
  executeGraphQLMutation,
  executeGraphQLQuery,
} from "../../../common/executeGraphQLQuery";
import { GET_DOCUMENTS_LIST } from "../../../services/leads.gql";
import {
  GET_DOC_REQUEST_LIST,
  UPDATE_DOC_REQUEST,
} from "../../../services/customer.gql";
import { gql, useApolloClient } from "@apollo/client";
import { toast } from "react-toastify";
import Loader from "../../elements/Loader";
import generalService from "../../../services/generalService";
import MobileModal from "../../elements/MobileModal";

const UploadDocsTab = ({ lead_id, customerDetails, user, coAppDetails }) => {
  const client = useApolloClient();
  const [documentList, setDocumentList] = useState([]);
  const [requestedDocList, setRequestedDocList] = useState([]);
  const [openAccordion, setOpenAccordion] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [previewModalVisiblity, setPreviewModalVisiblity] = useState(false);
  const previewModalImgRef = useRef(null);

  const getDocCategoryList = () => {
    const docsTypeObj = {
      profession: 1,
      loan_type: 1,
      property_type: 1,
    };
    executeGraphQLQuery(
      GET_DOCUMENTS_LIST(
        docsTypeObj.property_type,
        docsTypeObj.loan_type,
        docsTypeObj.loan_type
      ),
      client
    )
      .then((response) => {
        if (response && response.data?.get_document_list) {
          setDocumentList(response.data?.get_document_list);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getDocRequestList = (lead_id) => {
    executeGraphQLQuery(GET_DOC_REQUEST_LIST(lead_id, "customer"), client)
      .then((response) => {
        const reqList =
          response?.data?.get_doc_request_list.map((obj) => ({
            id: obj.id,
            lead_id: obj.lead_id,
            parent_doc_id: obj.parent_doc_id,
            doc_id: obj.doc_id,
            is_doc_uploaded: obj.is_doc_uploaded || 0,
            doc_upload_url: obj.doc_upload_url || "",
            customer_type: obj.customer_type,
            customer_id: obj.customer_id,
          })) || [];
        setRequestedDocList(reqList);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getDocCategoryList();
    getDocRequestList(lead_id);
  }, []);

  const handleSubmit = () => {
    toast.success("Success");
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  };

  const toggleAccordion = (docId) => {
    setOpenAccordion(openAccordion === docId ? null : docId);
  };

  const handleFileInputChange = (event) => {
    const fileInput = event.target;
    fileInput.previousSibling.style.display = "none";
    const parent_doc_id = +fileInput.getAttribute("parentdocid");
    const doc_id = +fileInput.getAttribute("docid");
    const customer_type = fileInput.getAttribute("customertype");
    const customer_id = fileInput.getAttribute("customerid");
    const docReqId = requestedDocList.filter(
      (obj) => +obj.parent_doc_id === +parent_doc_id && +obj.doc_id === +doc_id
    )[0].id;
    const filesArry = Array.from(fileInput.files),
      awsRes = [],
      leadDocArr = [],
      docPathUrls = [];

    if (filesArry && filesArry.length) {
      setIsLoading(true);
      let formData = new FormData();
      formData.append("dealer_id", lead_id);
      formData.append("upload_type", "dealer_docs");
      for (let imgFile of event.target.files) {
        formData.append("images", imgFile);
      }
      awsRes.push(
        generalService.uploadFilesToS3(formData, {
          "Content-Type": "multipart/form-data",
        })
      );
    } else {
      awsRes.push(
        Promise.resolve({
          data: { status: 200, message: "Success", data: ["not_updated"] },
        })
      );
    }

    Q.allSettled(awsRes).then((uploadOutput) => {
      uploadOutput.forEach((res, index) => {
        const response = res.value || [];
        if (res.state === "fulfilled") {
          if (response.data.data.length) {
            const resData = response.data.data;
            resData.forEach((row, i) => {
              const loanDetailsSaveDoc = {
                lead_id,
                doc_path: row.file_url,
                parent_doc_id,
                doc_id,
                doc_source: "web",
                user_id: 0,
                customer_type,
                customer_id: +customer_id,
              };
              leadDocArr.push(loanDetailsSaveDoc);
              docPathUrls.push(row.file_url);
            });
          }
        }
      });
      if (leadDocArr.length) {
        const variables = {
          saveDocInput: {
            lead_id,
            lead_docs: leadDocArr,
          },
          api_called_by: "web",
        };

        const mutation = gql`
          mutation save_doc(
            $saveDocInput: SaveDocInput!
            $api_called_by: API_CALL_BY!
          ) {
            save_doc(
              saveDocInput: $saveDocInput
              api_called_by: $api_called_by
            ) {
              lead_id
              doc_id
              doc_path
            }
          }
        `;

        executeGraphQLMutation(mutation, variables, client)
          .then((result) => {
            if (result && result.data) {
              setIsLoading(false);
              toast.success("Successfully uploaded");
            } else {
            //  console.log(result.data);
              toast.error("Something went wrong");
              setIsLoading(false);
            }
          })
          .catch((error) => {
            console.log(error.message);
            toast.error("Internal server error");
            setIsLoading(false);
          });

        const variables_doc_req = {
          updateDocRequest: {
            lead_id,
            doc_id,
            parent_doc_id,
            doc_upload_url: docPathUrls.join(","),
            customer_id: +customerDetails.customer_id,
          },
          docReqId,
        };

        const mutation_doc_req = UPDATE_DOC_REQUEST;

        executeGraphQLMutation(mutation_doc_req, variables_doc_req, client)
          .then((result) => {
            if (result.data.update_doc_request === "SUCCESS") {
           //   console.log("Success");
              toast.success("Success tagged");
              setTimeout(() => {
                window.location.reload();
              }, 2000);
            } else {
            //  console.log(result.data);
              toast.error("Something went wrong");
            }
          })
          .catch((error) => {
            console.log(error.message);
            toast.error("Internal server error");
          });
      } else {
        toast.error("Error in image upload!");
        setIsLoading(false);
      }
    });
  };

  const handlePreviewDoc = (event) => {
    setPreviewModalVisiblity(true);
    const { src } = event.target;
    setTimeout(() => {
      previewModalImgRef.current.src = src;
    }, 200);
  };

  const handleModalClose = () => {
    setPreviewModalVisiblity(false);
  };

  const listOfRequestedDocs = () => {
    const filteredList = documentList
      .filter((obj2) =>
        requestedDocList.some((obj1) => obj1.parent_doc_id === obj2.id)
      )
      .map((obj2) => ({
        ...obj2,
        child: obj2.child.filter((child) =>
          requestedDocList.some((obj1) => obj1.doc_id === child.id)
        ),
      }))
      .map((obj2) => ({
        ...obj2,
        uploadedCount: obj2.child.reduce((count, child) => {
          return (
            count +
            +requestedDocList.filter(
              (reqObj) =>
                reqObj.doc_id === child.id && reqObj.parent_doc_id === obj2.id
            )[0]?.is_doc_uploaded
          );
        }, 0),
      }));

    filteredList.sort((a, b) => a.uploadedCount - b.uploadedCount);

    const renderList = filteredList.map((doc, index) => {
      return (
        <>
          <div className="docnewaccordion" key={`${doc.id}_${index}`}>
            <div
              key={`${doc.id}_${index}_innerDiv`}
              className={`docnewaccordion-item ${
                openAccordion === doc.id ? "open" : ""
              }`}
            >
              <div className="docnewaccordionborder">
                <div
                  className="docnewaccordion-header"
                  onClick={() => toggleAccordion(doc.id)}
                >
                  <div className="docitemicon">
                    <span>
                      <i
                        className="ic-application"
                        style={{ fontSize: 16, marginRight: 10 }}
                      ></i>
                    </span>
                    {doc.doclang.name}
                  </div>
                  <span>
                    {openAccordion === doc.id ? (
                      <i className="ic-keyboard_arrow_up"></i>
                    ) : (
                      <i className="ic-keyboard_arrow_down"></i>
                    )}
                  </span>
                </div>
                {openAccordion === doc.id && (
                  <div className="docnewaccordion-content">
                    <div>
                      <ul className="doclist">
                        {doc.child.map((docChild, indexChild) => {
                          const docsUrl = requestedDocList.find(
                            (obj) =>
                              +obj.parent_doc_id === +doc.id &&
                              +obj.doc_id === +docChild.id
                          )?.doc_upload_url;

                          const custType = requestedDocList.find(
                            (obj) =>
                              +obj.parent_doc_id === +doc.id &&
                              +obj.doc_id === +docChild.id
                          )?.customer_type;

                          const custId = requestedDocList.find(
                            (obj) =>
                              +obj.parent_doc_id === +doc.id &&
                              +obj.doc_id === +docChild.id
                          )?.customer_id;

                          let custName = "";
                          if (custType === "customer") {
                            custName = customerDetails.first_name;
                          } else {
                            const coApp = coAppDetails.filter(
                              (obj) => obj.id === custId
                            );
                            if (coApp.length > 0) {
                              custName = coApp[0]?.ca_first_name;
                            }
                          }
                          return (
                            <>
                              <li key={`${docChild.id}_${indexChild}_child`}>
                                {docChild.doclang.name} {`(${custName})`}
                                {requestedDocList.filter(
                                  (obj) =>
                                    +obj.parent_doc_id === +doc.id &&
                                    +obj.doc_id === +docChild.id
                                )[0]?.is_doc_uploaded != "1" ? (
                                  <>
                                    <label
                                      htmlFor={`fileupload_${docChild.id}_${doc.id}`}
                                    >
                                      <i className="ic-upload iconstyle"></i>
                                    </label>
                                    <input
                                      type="file"
                                      id={`fileupload_${docChild.id}_${doc.id}`}
                                      parentdocid={doc.id}
                                      docid={docChild.id}
                                      customertype={custType}
                                      customerid={custId}
                                      style={{ display: "none" }}
                                      onChange={handleFileInputChange}
                                      multiple
                                    />
                                  </>
                                ) : (
                                  <>
                                    {requestedDocList
                                      .filter(
                                        (obj) =>
                                          +obj.parent_doc_id === +doc.id &&
                                          +obj.doc_id === +docChild.id
                                      )[0]
                                      .doc_upload_url.split(".")
                                      .pop()
                                      .toLowerCase() === "pdf" ? (
                                      <>
                                        <a
                                          href={docsUrl}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          <i className="ic-pdf-file icons-normal"></i>
                                        </a>
                                      </>
                                    ) : (
                                      <>
                                        <img
                                          src={docsUrl}
                                          width={40}
                                          onClick={handlePreviewDoc}
                                        />
                                      </>
                                    )}
                                  </>
                                )}
                              </li>
                            </>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      );
    });

    return renderList;
  };

  return (
    <>
      {isLoading ? <Loader /> : null}

      {previewModalVisiblity && (
        <>
          <div className="view-timeline-popup image-timeline-popup">
            <MobileModal
              show={previewModalVisiblity}
              handleClose={handleModalClose}
            >
              <div className="modal-header">
                <h2>Document Preview</h2>
              </div>
              <div className="modal-body">
                <img src="" ref={previewModalImgRef} />
              </div>
            </MobileModal>
          </div>
        </>
      )}

      <div className="pcdoccard">
        <div className="pcdocbox">
          <div className="uploadbox">
            <div className="partnername">
              Hi {customerDetails.first_name} {customerDetails.last_name},{" "}
            </div>
            <p className="uploadpara">
              Your Loan Advisor {user} has requested for these documents to
              process the request for your home loan.
            </p>
          </div>
          <h3 className="uploadheading">Pending Docs</h3>
          {listOfRequestedDocs()}

          <div className="submitbtncenter">
            <button className="docsubmitbtn" onClick={handleSubmit}>
              Submit
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default UploadDocsTab;
