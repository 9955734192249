import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useApolloClient } from '@apollo/client';
import CheckListForm from './CheckListForm';
import leadStatus, { GET_CHECKLIST_DATA_BY_STATUS_ID } from '../../services/leadStatus';
import { apiCallCheckList } from '../../store/action/allAction';

const AccordionTabs = (props) => {
  const client = useApolloClient();
  const dispatch = useDispatch();
  const [activeIndex, setActiveIndex] = useState(null);
  const [checkList, setCheckList] = useState([]);
  const [child, setChild] = useState({
    parent: '',
    data: []
  });

  const { masterdata, leadDetail } = useSelector(({ masterdata, lead }) => ({
    masterdata,
    leadDetail: lead.leadDetail
  }));
  let [saveChecklistData, setSaveChecklistData] = useState([]);

  useEffect(() => {
    const fetchStatusCheckList = async () => {
      const statusCheckList = await apiCallCheckList(client);
      
      if (statusCheckList && statusCheckList.length) {
        const checkList = GET_CHECKLIST_DATA_BY_STATUS_ID(statusCheckList, props.status_id);
        if (checkList.length) {
          // setCheckList(checkList)
          setChild({ parent: checkList[0].status_name, data: checkList })
        }
      }
    }
    fetchStatusCheckList();
  }, [])

  useEffect(() => {
    if (leadDetail.checklists.length) setSaveChecklistData(leadDetail.checklists);
  }, [leadDetail?.lead_status_history]);

  const handleAccordionItemClick = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const parentabStatus = (item) => {
    let submitArray = leadDetail.checklists?.filter(ob => ob?.sub_status_id == item?.id)
    //console.log(submitArray.length ,'===', item?.checkList?.length)

    let classNames = (submitArray.length === item?.checklist?.length) ? 'completed' : 'active'
    return classNames;
  };

  const tabStatus = (item) => {
    let submitArray = leadDetail.checklists?.filter(ob => ob?.checklist_id == item?.id)

    let classNames = submitArray.length ? 'completed' : 'active';
    return classNames;
  };
 
  return (
    <div className="menu-tab-left-panel-new">
      <h2>{child.parent} Checklist</h2>
      <ul>
        {/* {!child.data.length && checkList?.length && checkList.filter(ob=>ob?.substatuslang?.sub_status_name !='Without OTC').map((item, index) => (
          <li key={index + 'i'}>
            <NavLink style={{ display: "flex", justifyContent: "space-between" }}
              to={item.to}
              className={parentabStatus(item)}
              onClick={() => setChild({ parent: item?.substatuslang?.sub_status_name, data: item.checklist })}
            >
              <span>
                <span className="img-type"></span>
                {item?.substatuslang?.sub_status_name}
              </span>
              <span style={{ fontSize: 25, fontWeight: 500 }}>{activeIndex === index ? ' -' : ' +'}</span>
            </NavLink>
          </li>
        ))} */}

        {child?.data && child.data.length ? child.data.map((item, index) => (
          <li key={index + 'j'}>
            <NavLink style={{ display: "flex", justifyContent: "space-between" }}
              to={item.to}
              className={tabStatus(item)}
              onClick={() => handleAccordionItemClick(index)}
            >
              <span>
                <span className="img-type"></span>
                {item?.checklist_name}
              </span>
              <span style={{ fontSize: 25, fontWeight: 500 }}>{activeIndex === index ? ' -' : ' +'}</span>
            </NavLink>
            {activeIndex === index && (
              <div style={{ bottom: 0 }}>
                <CheckListForm checkList={item} checklist_status_id={item.id} togglePopup={props.togglePopup} />
              </div>
            )}
          </li>
        )) : null}
      </ul>
    </div>
  );
};

export default AccordionTabs;
