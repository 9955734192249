import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { getStatusUtility } from "../utility/getStatusUtility";
import right from "../../../../webroot/images/messageicon.svg";
import DateFormate from "dateformat";
import crypto from "../../../../config/crypto";
import { maskMobileNumber } from "../../../../config/constants";

const PreRegLeadList = (props) => {
  const [leadData, setLeadData] = useState();
  const leads = props.leads;

  useEffect(() => {
    setLeadData(leads);
  }, [leads]);

  const formatDate = (date) => {
    return (
      <>
        {date ? new Date(date).toLocaleDateString() : "N/A"}{" "}
        {date ? new Date(date).toLocaleTimeString() : ""}
      </>
    );
  };

  const capitalizeWords = (input) => {
    if (!input) return "";
    return input
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  };

  const handleOpenPartnerDetails = (url) => {
    window.open(url, "_blank");
  };

  const calculateAppInactivityStatus = (lastLoginDateTime) => {
    if (!lastLoginDateTime) {
      return { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }

    const lastLoginDate = new Date(lastLoginDateTime);
    const timeDifference = new Date() - lastLoginDate;

    const daysInactive = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const hoursInactive = Math.floor(
      (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutesInactive = Math.floor(
      (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
    );
    const secondsInactive = Math.floor((timeDifference % (1000 * 60)) / 1000);

    return {
      days: daysInactive > 0 ? daysInactive : 0,
      hours: hoursInactive > 0 ? hoursInactive : 0,
      minutes: minutesInactive,
      seconds: secondsInactive,
    };
  };

  return (
    <>
      <Table striped bordered hover>
        <thead>
          <tr style={{ backgroundColor: "#fff1d6" }}>
            <th>Lead Details</th>
            <th>
              Call Status/
              <br />
              Sub-Status
            </th>
            <th>Source/Sub-Source</th>
            <th>Followup(s)</th>
            {props.activeTab === "discarded" && (
              <>
                <th>Type</th>
                <th>Assigned BM</th>
                <th>Verification Status</th>
              </>
            )}
            <th>Onboarding BM</th>
            {/* <th>Field Visit</th> */}
            {/* <th>Successful Attempts</th> */}
            <th>Last Connected/ Time lapsed</th>
            <th>City</th>
            <th>
              Created On/
              <br />
              Updated On
            </th>
            <th>Follow-Up Date</th>
            {/* <th>Added By</th> */}
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {leadData && leadData.length
            ? leadData.map((lead) => {
                const status = getStatusUtility(lead.status).status;
                const inactivityStatus = calculateAppInactivityStatus(
                  lead.followup_date
                );
                return (
                  <tr
                    // style={{ cursor: "pointer" }}
                    key={lead.id}
                    // onClick={
                    //   () => handleOpenPartnerDetails(`/partner-lead-detals/${lead.dealer_id_hash}`)
                    // }
                  >
                    <td>
                      <span>
                        <b>{lead.first_name}</b>

                        <br />
                        {maskMobileNumber(lead.dealership_contact)}
                        <br />
                        {lead.dealership_email}
                        <br />
                        {lead.about_dealership &&
                        lead.about_dealership !== null ? (
                          <img
                            style={{ cursor: "pointer" }}
                            src={right}
                            alt="icon"
                            width={20}
                            height={20}
                            title={lead.about_dealership}
                          />
                        ) : null}
                      </span>
                    </td>
                    <td>
                      {lead.status_name || "--"} <br />
                      {lead.sub_status_name}
                    </td>
                    <td>
                      <span>
                        <b>
                          {lead.source ? capitalizeWords(lead.source) : "--"}
                        </b>
                        <br />
                        {lead.subsource ?? "--"}
                      </span>
                    </td>
                    <td>
                      <div className="statusflexlist">
                        {lead.followup_date ? (
                          <>
                            <span className="supportliststatus">
                              {DateFormate(new Date(), "dS mmm yyyy") ===
                              DateFormate(lead.followup_date, "dS mmm yyyy")
                                ? "Today"
                                : DateFormate(
                                    lead.followup_date,
                                    "dS mmm yyyy"
                                  )}
                            </span>
                            <span>
                              {DateFormate(lead.followup_date, "hh: MM TT")}
                            </span>
                          </>
                        ) : (
                          <span>N/A</span>
                        )}
                      </div>
                      {inactivityStatus.days !== 0 &&
                        inactivityStatus.hours !== 0 && (
                          <div className="supporttime">
                            Overdue by:{" "}
                            <span>
                              {" "}
                              {`${inactivityStatus.days} days ${inactivityStatus.hours} hours`}
                            </span>
                          </div>
                        )}
                    </td>
                    {props.activeTab === "discarded" && (
                      <>
                        <td>{lead.is_lead === "1" ? "Lead" : "Partner"}</td>
                        <td>
                          <div>{lead.sfa_refferal_user_name || "--"}</div>
                          <div>{lead.sfa_refferal_user_mobile}</div>
                        </td>
                        <td
                          style={{ color: getStatusUtility(lead.status).color }}
                        >
                          {getStatusUtility(lead.status).status}
                        </td>
                      </>
                    )}
                    <td>{lead.onboarding_bm_name || "--"}</td>
                    {/* <td>Field Visit</td> */}
                    {/* <td>Attempts</td> */}
                    <td>
                      {lead.connected_at ? formatDate(lead.connected_at) : "--"}
                    </td>
                    <td>{lead.cityname || "--"}</td>
                    <td>
                      {lead.lead_added_date
                        ? formatDate(lead.lead_added_date)
                        : "--"}{" "}
                      <br />
                      {formatDate(lead.updated_date)}
                    </td>
                    <td>
                      {lead.followup_date
                        ? formatDate(lead.followup_date)
                        : "--"}
                    </td>
                    {/* <td>{(lead.source === 'Website' ? "Self" : lead.sfa_refferal_user_name) || "--"}</td> */}
                    <td>
                      <div style={{ display: "flex" }}>
                        <a
                          style={{ color: "gray" }}
                          className="commoniconbtn"
                          href={`/partner-lead-detals/${
                            lead.dealer_id_hash
                          }`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i
                            className="ic-createmode_editedit icons-normal"
                            title="Edit"
                          ></i>
                        </a>
                      </div>
                    </td>
                  </tr>
                );
              })
            : null}
        </tbody>
      </Table>
    </>
  );
};

export default PreRegLeadList;
