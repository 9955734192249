import Formsy from "formsy-react";
import React, { useEffect, useState } from "react";
import FormsySelect from "../elements/FormsySelect";

const BankPOC = ({
  index,
  ALL_OPTIONS,
  bankPOCData,
  setBankPOCOptions,
  setBankPOCData,
  removeBankPOC,
  bankerList,
}) => {
  const [bankerPOC, setBankerPOC] = useState([]);

  const bankerPOCOptions = `filtered_banker_poc_${index}`;

  useEffect(() => {
    if (bankPOCData && bankPOCData.length > 0) {
      setBankerPOC(bankPOCData);
    }
  }, [bankPOCData]);

  const handleSelectChange = (data, field_name, index) => {
    if (data && field_name) {
      setBankPOCOptions(data, index);
      const exBankPOC = [...bankerPOC];
      exBankPOC[index] = {
        ...exBankPOC[index],
        [field_name]: data.value,
        status: "1",
      };
      setBankerPOC(exBankPOC);
      setBankPOCData(exBankPOC);
    }
  };

  const handleRemoveBankerPOC = (index) => {
    removeBankPOC(index);
  };

  return (
    <>
      <div style={{marginTop:20}}>
        <Formsy
          className="lead-form-filed"
          autoComplete="off"
          onValid={() => null}
          onInvalid={() => null}
        >
          <fieldset className={"single-select"} key={"bank_poc"}>
            <FormsySelect
              name={"banker_id"}
              id={"banker_id"}
              inputProps={{
                options: ALL_OPTIONS[bankerPOCOptions],
                placeholder: "Select Bank POC",
                className: "react-select",
                classNamePrefix: "react-select",
                value:
                  ALL_OPTIONS &&
                  ALL_OPTIONS[bankerPOCOptions] &&
                  ALL_OPTIONS[bankerPOCOptions]?.filter(
                    ({ value }) =>
                      value == bankerPOC[index]?.["banker_id"] &&
                      bankerPOC[index]?.["status"] === "1"
                  ),
                isDisabled: false,
              }}
              required={true}
              value={bankerPOC && bankerPOC[index]?.["banker_id"]}
              onChange={(data) => handleSelectChange(data, "banker_id", index)}
            />

            {bankerPOC[index]?.["banker_id"] && (
              <div
                type="button"
                className="removeBankPocBtn"
                onClick={() => handleRemoveBankerPOC(index)}
              >
                Remove
              </div>
            )}

            {bankerList
              ?.filter(
                (obj) =>
                  bankerPOC[index]?.banker_id == obj.id &&
                  bankerPOC[index]?.status === "1"
              )
              ?.map((obj, index) => (
                <div
                  key={index}
                  style={{
                    marginBottom: "15px",
                    // maxWidth: "180px",
                    wordWrap: "break-word",
                  }}
                >
                  <p>Phone : {obj.phone}</p>
                  <p>Email : {obj.email}</p>
                  <p>Escalation Matrix : {obj.escalation_matrix}</p>
                </div>
              ))}
          </fieldset>
        </Formsy>
      </div>
    </>
  );
};

export default BankPOC;
