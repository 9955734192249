import BreBasicDetails from "./BreBasicDetails";
import { Tab, Nav } from "react-bootstrap";
import BreProperty from "./BreProperty";
import React, { useEffect, useState } from "react";
import BreIncome from "./BreIncome";
import BreOfferSection from "./BreOfferSection";
import BreIncomeDetailsSE from "./BreIncomeDetailsSE";
import { useSelector } from "react-redux";

const BreOfferCheck = () => {
  const [activeTab, setActiveTab] = useState("basic_details");
  const [breData, setBreData] = useState({});
  const [bankOffers, setBankOffers] = useState([]);
  const [recalculate, setRecalculate] = useState({});
  const [isOfferFetched, setIsOfferFetched] = useState(false);
  const [loadingOffer, setLoadingOffer] = useState(false);

  const { lead } = useSelector(({ masterdata, lead }) => ({
    masterdata,
    lead,
  }));

  useEffect(() => {
    if (lead && lead.leadDetail && lead.leadDetail.lead_details) {
      setBreData((current) => ({
        ...current,
        profession: lead.leadDetail.lead_details.profession,
      }));
    }
  }, [lead]);

  const handleNext = () => {
    switch (activeTab) {
      case "basic_details":
        setActiveTab("income");
        break;
      case "income":
        setActiveTab("property");
        break;
      case "property":
        break;
      default:
        break;
    }
  };

  const handleSetBreDetails = (key, value, obj) => {
    if (!(key === null) || !(obj === null)) {
      setBreData((current) => ({
        ...current,
        ...(obj ? obj : { [key]: value }),
      }));
    }
  };

  const handleSetBankOffers = (offers, isOfferFetch = false) => {
    setBankOffers(offers);
    setIsOfferFetched(isOfferFetch);
  };

  const handleFetchOffer = (recalculatedObj) => {
    setRecalculate(recalculatedObj);
  };

  const handleOfferLoader = (status) => {
    setLoadingOffer(status);
  };

  return (
    <div className="document-upload-tabs">
      {/* ---- LEFT PANEL ---- */}
      <BreOfferSection
        checkBankOffers={bankOffers}
        recalculateOffer={handleFetchOffer}
        isLoadingOffer={loadingOffer}
        isOfferFetched={isOfferFetched}
      />

      {/* RIGHT PANEL */}
      <div className="form-tab-right-panel">
        <div className="lead-detail-from-outer">
          <Tab.Container
            id="left-tabs-example"
            activeKey={activeTab}
            onSelect={(tab) => setActiveTab(tab)}
          >
            <Nav variant="pills" className="flex-column">
              <div className="tab-list">
                <Nav.Item>
                  <Nav.Link eventKey="basic_details">Basic Details</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="income">Income</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="property">Property</Nav.Link>
                </Nav.Item>
              </div>
            </Nav>

            <Tab.Content>
              <Tab.Pane eventKey="basic_details">
                <BreBasicDetails
                  setBreBasicDetails={handleSetBreDetails}
                  setNextTabActive={handleNext}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="income">
                {breData["profession"] === 1 ? (
                  <BreIncome
                    setBreIncomeDetails={handleSetBreDetails}
                    setNextTabActive={handleNext}
                  />
                ) : breData["profession"] === 2 ||
                  breData["profession"] === 3 ? (
                  <BreIncomeDetailsSE
                    setBreIncomeDetails={handleSetBreDetails}
                    setNextTabActive={handleNext}
                  />
                ) : (
                  (breData["profession"] === null ||
                    breData["profession"] === "") && (
                    <h3>Please select profession</h3>
                  )
                )}
              </Tab.Pane>
              <Tab.Pane eventKey="property">
                <BreProperty
                  breData={breData}
                  setBankOffersDetails={handleSetBankOffers}
                  recalculate={recalculate}
                  isLoadingOffer={handleOfferLoader}
                  activeTab={activeTab}
                />
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
      </div>
    </div>
  );
};

export default BreOfferCheck;
