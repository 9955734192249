import React, { useRef, useState } from "react"; 
import { useApolloClient } from "@apollo/client";
import { executeGraphQLQuery } from "../../common/executeGraphQLQuery";
import { LOAN_ID_REPORT } from "../../services/mis.gql";
import { CSVLink } from "react-csv";
import dateFormat from 'dateformat';

const DownloadReport = (props) => {
  const client = useApolloClient();
  const csvLink = useRef() 
  const [reportDetail,setDetail] = useState([]); 


  function formatReport(data) {
    let tempArray = [];
    for(let item of data?.loan_id_report){
      tempArray.push({
        lead_id: item.lead_id,
        tranche_id: item.tranche_id,
        created_date: dateFormat(item.created_date , "yyyy-mm-dd"), 
        fulfillment_type: item.fulfillment_type,
        customer_name: item.customer_name,
        partner_name: item.partner_name,
        sub_partner_name: item.sub_partner_name,
        rm_name: item.rm_name,
        bank_name: item.bank_name,
        product_type: item.product_type,
        product_sub_type: item.product_sub_type,
        login_date: dateFormat(item.login_date , "yyyy-mm-dd"), 
        disbursed_date: dateFormat(item.disbursed_date , "yyyy-mm-dd"),
        disbursed_amount: item.disbursed_amount,
        transaction_date: dateFormat(item.transaction_date , "yyyy-mm-dd"),
        transaction_date: dateFormat(item.transaction_date , "yyyy-mm-dd"),
      })
    }
    return tempArray;
 }; 
 const downloadCSVReport = async (type='open') => {
  try {   

    const response = await executeGraphQLQuery(LOAN_ID_REPORT(props.report_id,props.user_id, type),client); 
    if (response.data?.loan_id_report) { 
      const tempArray =  formatReport(response.data);
      setDetail(tempArray);
      setTimeout(() => csvLink.current?.link?.click(), 400);
    }
  } catch (error) { 
    console.log("Error 🔻 ", error.message);
  }
};

const downloadUrl = () => downloadCSVReport();
const downloadFreezUrl = () => downloadCSVReport('close');


const reportheaders = [
  { label: "Lead ID", key: "lead_id" },
  { label: "Tranch ID", key: "tranche_id" },
  { label: "Tranch Number", key: "tranche_id" },
  { label: "Created Date", key: "created_date" },
  { label: "Fulfilment Type", key: "fulfillment_type" },
  { label: "Customer Name", key: "customer_name" },
  { label: "Partner Name", key: "partner_name" },
  { label: "Sub-Partner Name", key: "sub_partner_name" },
  { label: "RM Name", key: "rm_name" },
  { label: "Lender Name", key: "bank_name" },
  { label: "Product Type", key: "product_type" },
  { label: "Product Sub-Type", key: "product_sub_type" },
  { label: "Login Date", key: "login_date" },
  { label: "Disbursement Date", key: "disbursed_date" },
  { label: "Disbursement Amount", key: "disbursed_amount" },
  { label: "Disbursement Verified Date", key: "transaction_date" },
  { label: "Transaction Date", key: "transaction_date" },

];

// console.log('reportDetail===',reportDetail)

  return (
    <>
        <div class="btn-group-flex" onClick={downloadUrl}>
        <div class="btn-group btn-group-small mrg-r5">
          <div class="btn btn-default" title="Download Current Report">
            <img src="https://ambak.storage.googleapis.com/partner/4695/1731051675319.png" alt="Download Current Report" width={18}height={20}/>
            {/* <i class="ic-upload icons-normal"></i> */}
          </div>
        </div>
      </div> 

      
        {props.reporting_status == 'close' ? (
          <div class="btn-group-flex" onClick={downloadFreezUrl}>
            <div class="btn-group btn-group-small mrg-r5">
              <div class="btn btn-default" title="Download Freezed Report">
                {/* <img src="https://ambak.storage.googleapis.com/partner/4695/1731051675319.png" alt="Download Freezed Report" width={18}height={20}/> */}
                <i class="ic-arrow_drop_down_circle icons-normal" style={{color:"green"}}></i>
              </div>
            </div>
          </div> 
        ) :''}
        

      <CSVLink data={reportDetail} headers={reportheaders} filename={"scheme-level.csv"} className='hidden' ref={csvLink}/> 
    </>
  );
};


export default DownloadReport
