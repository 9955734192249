import React, { useEffect, useState } from "react";
import { LEADS_HISTORY_QUERY } from "../../../services/leads.gql";
import { executeGraphQLQuery } from "../../../common/executeGraphQLQuery";
import { useApolloClient } from "@apollo/client";
import DateFormate from "dateformat";

const LoanStatusTab = ({ lead_id }) => {
  const client = useApolloClient();
  const [loanStatus, setLoanStatus] = useState([]);

  const tab_status = {
    Disbursed: {
      is_completed: true,
      is_active: false,
    },
    Sanctioned: {
      is_completed: false,
      is_active: false,
    },
  };

  const getLeadHistory = (lead_id) => {
    executeGraphQLQuery(LEADS_HISTORY_QUERY(lead_id), client).then((result) => {
      if (result?.data?.get_history) {
        setLoanStatus(result?.data?.get_history);
       // console.log(result?.data?.get_history);
      }
    });
  };

  useEffect(() => {
    getLeadHistory(lead_id);
  }, []);

  return (
    <>
      <div className="pcdoccard">
        <div className="loandetailscardpc">
          <div className="docloandetailscard">
            <div className="lead-detail-tabs">
              <div className="menu-tab-left-panel-new">
                <ul>
                  {loanStatus.map((timelinedata, index) => {
                    let subheading = timelinedata?.sub_status_details
                      ?.substatuslang?.sub_status_name
                      ? " - " +
                        timelinedata?.sub_status_details?.substatuslang
                          ?.sub_status_name
                      : "";
                    let heading = timelinedata?.title + " " + subheading;

                    if (timelinedata.rejection_type) {
                      if (timelinedata.rejection_type == "ops_reject") {
                        heading = "Verification Failed";
                      } else if (
                        ["bank_hard_reject"].includes(
                          timelinedata.rejection_type
                        )
                      ) {
                        heading = "Bank Denied - Hard Reject";
                      } else if (
                        ["bank_soft_reject"].includes(
                          timelinedata.rejection_type
                        )
                      ) {
                        heading = "Bank Denied - Soft Reject";
                      }
                    }

                    return (
                      <>
                        <li key={index}>
                          <p
                            // className={
                            //   tab_status?.Sanctioned?.is_completed
                            //     ? "completed"
                            //     : tab_status?.Sanctioned?.is_active
                            //     ? "active"
                            //     : ""
                            // }
                          >
                            <span className="img-type"></span>
                            {heading}
                            <div className="statusdetails">
                              {timelinedata.created_date
                                ? DateFormate(
                                    timelinedata.created_date,
                                    "dd mmm h:MM TT"
                                  )
                                : ""}
                            </div>
                          </p>
                        </li>
                      </>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoanStatusTab;
