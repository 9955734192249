export default {
    LEAD_STATUS_BUCKET_MASTER: {
        NEW_LEAD: 1,
        CONTACTED: 2,
        QUALIFIED: 3,
        LOGIN: 4,
        SANCTIONED: 5,
        DISBURSED: 6,
        LOST: 7
    },
    LEAD_STATUS_MASTER: {
        NEW_LEAD: 1,
        CALL_BACK: 20,
        INTERESTED_CONTACTED: 21,
        NOT_INTERESTED_CONTACTED: 22,
        INTERESTED_QUALIFIED: 30,
        NOT_INTERESTED_QUALIFIED: 31,
        LOGIN: 2,
        SANCTIONED: 3,
        DISBURSED: 4,
        LOST: 5
    },
    LEAD_SUB_STATUS_MASTER: {
        INTERESTED_CONTACTED: {CALL_BACK_REQUESTED: 200, VISIT_REQUESTED: 205, DEAL_CANCELLED_POSTPONED__CONTACTED: 230},
        NOT_INTERESTED_CONTACTED: {NOT_APPLIED_FOR_LOAN: 300},
        INTERESTED_QUALIFIED: {CALL_BACK_REQUESTED: 400, VISIT_REQUESTED: 405},
        DISBURSE:{ 
            TRANSACTION: 810,
            PDD: 815,
            MIS: 820,
            PAYIN: 825,
            PAYOUT: 830,
            COLLECTION: 835,
            INCENTIVE: 840,
            SUBVENTION: 845,
        }
    }
}

export const GET_CHECKLIST_DATA_BY_STATUS_ID = (checklistData, statusId) => {
    let checkList = [];

    if (checklistData && Array.isArray(checklistData) && checklistData.length) {
        checkList = checklistData.map(status => {
            const { status_id, status_name } = status.statuslang;

            const updatedChecklist = status.checklist.map(checklist => ({
                ...checklist,
                status_id: status_id,
                status_name: status_name
            }));

            return {
                ...status,
                checklist: updatedChecklist
            };
        });
    }

    return checkList.find(ob => ob?.statuslang?.status_id === statusId)?.checklist || [];
}
