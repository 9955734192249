import React, { useEffect, useState } from 'react';

import Select from 'react-select';
import Pagination from '../elements/pagination';
import Loader from '../elements/Loader';
import Modal from '../elements/Modal';
import LeadHistory from './LeadHistory';
import LeadRemarkHistory from './LeadRemarkHistory';
import { LEADS_HISTORY_QUERY } from '../../services/leads.gql';
import { executeGraphQLMutation, executeGraphQLQuery } from '../../common/executeGraphQLQuery';
import { gql, useApolloClient } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import dateFormat from "dateformat";
import { useDispatch, useSelector } from 'react-redux';
// import generalService from '../../services/generalService';
import { getLeadRemarks } from '../../store/action/allAction';
import { GET_USER_LIST } from '../../services/role.gql';
import { toast } from 'react-toastify';
import { IS_USER_ACCESS_PAGE, MAKER_ROLE_ID, VERIFIER_ROLE_ID } from '../../config/constants';
import crossicon from "./../../webroot/images/crossicon.png";
import verifiedicon from "./../../webroot/images/verifiedicon.png";

import crypto from "../../config/crypto";

const LeadListTable = ({ leads, t, isLoading, onPaginate, totalPage, sortleadlist, loan_type }) => {
    const [AssignOption, setAssignOption] = useState(null);
    const [sortstate, setSortstate] = useState('desc');
    const [isviewHistory, setIsviewHistory] = useState("");
    const [isviewRemarks, setIsviewRemarks] = useState("");
    const [historyList, setHistoryList] = useState([]);
    const [remarkList, setRemarkList] = useState([]);
    const [followUphistoryList, setFollowUphistoryList] = useState([]);
    const [showhistorymodal, setShowhistorymodal] = useState(false);
    const [showremarkmodal, setShowremarkmodal] = useState(false);
    const [showremarkform, setShowremarkform] = useState(false);
    const [userList, setUserList] = useState([]);
    const [assignUser, setAssignUser] = useState([]);
    const [leadList, setLeadList] = useState([]);
    const client = useApolloClient();
    const accessCondition = IS_USER_ACCESS_PAGE("assign_user", "assign_user")?.is_edit_access
        || IS_USER_ACCESS_PAGE("assign_user", "assign_user")?.is_access_page;
    const { remarksHistory } = useSelector(({ lead }) => {
        return {
            remarksHistory: lead.remarksHistory || []
        }
    });

    const user_information = useSelector((state) => state.user.user_information);
    const UserInfo = JSON.parse(user_information)
    // useEffect(function(){
    //     setIsviewRemarks("");
    // },[remarksHistory])

    useEffect(() => {
        const fetchData = async () => {
            await getUserList();
        };
        fetchData();
    }, []);
    useEffect(() => {
        setLeadList(leads?.[0]?.lead_list);
    }, [leads]);
    useEffect(() => {
        if (remarksHistory.length && isviewRemarks != "") {

            setIsviewRemarks("");
        }
    }, [remarksHistory])

    let navigate = useNavigate();
    const dispatch = useDispatch();
    const handleChangeAssign = (selectedOption) => {
        setAssignOption(selectedOption);
    };

    const toggleModalBox = (lead) => {
        if (Object.keys(lead).length) {
            setIsviewHistory(lead.id);
            let historyData = {};
            setShowhistorymodal(true)

            executeGraphQLQuery(LEADS_HISTORY_QUERY(lead.id), client).then((result) => {
                setIsviewHistory("")
                setHistoryList(result.data.get_history)
            })
                .catch((error) => {
                    console.error('Query error:', error);
                });

        }
        else {
            setShowhistorymodal(false)
        }
    }

    const toggleModalBoxremark = (lead) => {
        if (Object.keys(lead).length) {
            setIsviewRemarks(lead.id);
            let historyData = {};

            setShowremarkmodal(true);
            dispatch((getLeadRemarks({ lead_id: lead.id, source: 'WEB' }, client)));
            setIsviewRemarks("")
        }
        else {
            setIsviewRemarks("")
            setShowremarkmodal(false)
        }
    }

    const navigateLeadDetail = (lead_id,status_id) => {
        let url =  `/lead-detail/update-status/${crypto.encode(lead_id)}`
        if(status_id == 1){
            url =  `/lead-detail/update-status/${crypto.encode(lead_id)}` 
        }else if(status_id == 2){
            url =  `/lead-detail/update-status/${crypto.encode(lead_id)}`
        }else if(status_id ==3){
            url =  `/lead-detail/update-status/${crypto.encode(lead_id)}` 
        }else if(status_id==4){
            url =  `/lead-detail/disbursed/${crypto.encode(lead_id)}`  
        }
        window.open(url, '_blank');
        // navigate(url)
    }

    const assignToUser=(e,lead_id,assign_to_type)=>{
        if(e.value){
            if(window.confirm("Are you sure want to assign?")){
                let mutation = gql`
                    mutation leadassignment($assignUserInput: AssignUserInput!) {
                        leadassignment(assignUserInput: $assignUserInput) {
                            assign_to
                            lead_id
                        }
                    }
                `;

                let variables = {
                    assignUserInput: { lead_id: lead_id,assign_to:e.value,assign_to_type:assign_to_type }
                  };
                if(leadList?.find(lead => lead.id === lead_id)?.assign_to === e.value) {
                    toast.error("Loan ID already assigned to this User.");
                } else if(leadList?.find(lead => lead.id === lead_id)?.verifier_id === e.value) {
                    toast.error("Loan ID already assigned to this User.");
                } else {
                    executeGraphQLMutation(mutation, variables, client)
                    .then((res) => {
                        if(res?.data?.message !== 'Error!') {
                            let leadObj = leadList?.find(data => data.id === +lead_id);
                             assign_to_type = assign_to_type === 'maker_id' ? 'assign_to' : 'verifier_id'; 
                            leadObj = { ...leadObj, [assign_to_type]: e.value };
                            let index = leadList.findIndex(data => data.id === +lead_id);
                            let updatedLeadList = [...leadList];
                            updatedLeadList[index] = leadObj;
                            setLeadList(updatedLeadList);
                            toast.success("Success");
                        }
                        else {
                            toast.error("Error");
                        }
                    })
                    .catch((err) => {
                        toast.error(err.toString().replace('ApolloError:',''));
                    });
                }
            }
        }
    }

    const getUserList = async () => {
        // setUserList([]);
         let user_list = [],userlist=[];
         await executeGraphQLQuery(GET_USER_LIST(5,1),client).then((response) => {
             if (response && response.data && response.data.get_user_list) {
                 user_list = response.data.get_user_list?.filter(obj => obj.status === '1');
                 user_list && user_list?.map((v)=>{
                     userlist.push({value:v.user_id,label:v.name,role_id:v.role_assigned_ids})
                     return v;
                 })
                 setUserList(userlist);
             }
         }).catch((error) => {
             console.log(error);
         });
 
        
     };

    // let leadList = []
    // if (leads && leads.length && leads[0].lead_list && leads[0].lead_list.length && leads[0].lead_list) {
    //     leadList = leads[0].lead_list
    // }
    //  leads && leads.length && leads[0].lead_list && leads[0].lead_list.length && leads[0].lead_list
    return (
        <div className="lead-list-data-table">

            <table>
                <thead>
                    <tr>
                        <th>Lead ID</th>
                        <th>Customer Details</th>
                        <th>Loan Amount</th>
                        <th>Bank Name</th>
                        <th>Product & fulfillment</th>
                        <th>Parent Partner</th>
                        <th>Sub Partner</th>
                        <th>Yoddha</th>
                        <th>Status and Verification</th>
                        <th>Status Update</th>
                        { accessCondition ?
                            <>
                                <th width="10%">Assign E2E Sales</th>
                                <th width="10%">Assign Verifier</th>
                            </>
                            : ''}
                        {/* <th>Action</th> */}
                    </tr>
                </thead>
                <tbody>
                    {leadList?.map((lead,index) => {
                        let leadStatus = lead?.status_info?.statuslang?.status_name || ''; 
                        let maker_user_list = userList.filter(data => data.role_id.includes(MAKER_ROLE_ID) && data.value != UserInfo?.id); 
                        let verifier_user_list = userList.filter(data => data.role_id.includes(VERIFIER_ROLE_ID) && data.value != UserInfo?.id); 
                        return <React.Fragment key={lead.id}>
                        <tr key={lead.id} style={{cursor:"pointer"}}>
                              <>
                             <td onClick={() => navigateLeadDetail(lead.id,lead.status_id)}> {lead.id}</td>
                             <td onClick={() => navigateLeadDetail(lead.id,lead.status_id)}>
                                 <div className="table-txt-data">
                                     <h5>{lead?.customer?.first_name} {lead?.customer?.last_name}</h5>
                                     {/* <span title={lead && lead?.customer}>{maskMobileNumber(lead?.customer?.mobile)}</span> */}
                                 </div>
                             </td>
     
                             <td id={lead?.status_id} onClick={() => navigateLeadDetail(lead.id,lead.status_id)}>
                                 <div className="table-txt-data">
                                 <h5 title={(lead?.status_id === 1) ? lead?.lead_details?.loan_amount : (lead?.status_id === 2) ? lead?.lead_details?.login_amount : (lead?.status_id === 3) ? lead?.lead_details?.approved_amount : (lead?.status_id === 4) ? lead?.lead_details?.loan_amount : lead?.lead_details?.loan_amount}>{(lead?.status_id === 1) ? "Loan" : (lead?.status_id === 2) ? "Login" : (lead?.status_id === 3) ? "Sanctioned" : (lead?.status_id === 4) ? "Disbursed" : "Loan"} Amount : {(lead?.status_id === 1) ? lead?.lead_details?.loan_amount : (lead?.status_id === 2) ? lead?.lead_details?.login_amount : (lead?.status_id === 3) ? lead?.lead_details?.approved_amount : (lead?.status_id === 4) ? lead?.splitpayment?.reduce((sum, payment) => sum + +(payment?.disbursed_amount || 0), 0) : lead?.lead_details?.loan_amount}</h5>
                                 {/* <span title={lead && lead?.fulfillment_type}>Fulfillment : {lead?.fulfillment_type.toUpperCase()}</span>
                                     <span title={lead?.lead_details && lead?.lead_details?.tenure_in_month}>Tenure : {lead?.lead_details?.tenure}</span>
                                     <span title={lead?.lead_details && lead?.lead_details?.loan_amount}>Product : {loan_type?.filter(data => data.id == lead?.loan_type)?.map(v => v.label)}</span> */}
                                 </div>
                             </td>
                             <td onClick={() => navigateLeadDetail(lead.id,lead.status_id)}>
                                 <div className="table-txt-data">
                                     <h5 title={lead?.lead_details?.bank?.banklang?.bank_name}>{lead?.lead_details?.bank?.banklang?.bank_name}</h5>
                                 </div>
                             </td>
                             <td id={lead?.status_id} onClick={() => navigateLeadDetail(lead.id,lead.status_id)}>
                                 <div className="table-txt-data">
                                 <span title={lead && lead?.fulfillment_type}>Fulfillment : {lead?.fulfillment_type.toUpperCase()}</span>
                                     <span title={lead?.lead_details && lead?.lead_details?.loan_amount}>Product : {loan_type?.filter(data => data.id == lead?.loan_type)?.map(v => v.label)}</span> 
                                 </div>
                             </td>
                             <td onClick={() => navigateLeadDetail(lead.id,lead.status_id)}>
                                 <div className="table-txt-data">
                                     <span title={lead?.parent_partner_name}>{lead?.parent_partner_name}</span>
                                     {/* <span title={lead.rmdetails && lead?.rmdetails.label}>Yodha RM : {lead?.rmdetails?.label}</span> */}
                                 </div>
                             </td>
                             <td>
                                <div className="table-txt-data">
                                    <span title={lead?.partner_name}>{lead?.partner_name}</span>
                                </div>
                             </td>
                             <td onClick={() => navigateLeadDetail(lead.id,lead.status_id)}>
                                 <div className="table-txt-data">
                                     <span title={lead.rmdetails && lead?.rmdetails.label}>{lead?.rmdetails?.label}</span>
                                 </div>
                             </td>
                             <td onClick={() => navigateLeadDetail(lead.id,lead.status_id)}>
                                 <div className="table-txt-data">
                                     {/* {lead?.splitpayment.map((data, index) => {
                                         return <span>Disbursal-{index + 1} on {dateFormat(data?.disbursed_date, 'd mmm,yyyy')}</span>
                                     })} */}
                                     <span style={{display:"flex",alignItems:"center"}}>{leadStatus}
                                     {
                                        lead.is_status_verified ==2 ? 
                                        <img style={{marginLeft:10}} src={crossicon} alt='logo' width={20} height={20} /> : null
                                     }
                                     {
                                        lead.is_status_verified == 1 ?
                                        <img style={{marginLeft:10}} src={verifiedicon} alt='logo' width={25} height={25} /> : null
                                     } 
                                     </span> 
                                 </div>
                             </td>
                             <td onClick={() => navigateLeadDetail(lead.id,lead.status_id)}>
                                  <span>{lead.updated_date ? `${dateFormat(lead.updated_date, 'd mmm,yyyy')}` : ""}</span>
                             </td>
                             { accessCondition ?
                             <>
                            <td>
                                <fieldset className="single-select  select-bx-table">
                                {lead?.fulfillment_type === 'ambak' ?
                                    <>
                                        <div className="material" title={userList.filter((v) => (v.value === lead.assign_to))?.[0]?.label}>            
                                        <Select
                                            options={accessCondition ===1 ? maker_user_list : []}
                                            placeholder={"Select E2E Sales"}
                                            name={'assign_to'}
                                            optionLabel="label"
                                            optionValue="value"
                                            className='react-select-table'
                                            classNamePrefix='react-select-table'
                                            value={userList.filter((v) => (v.value === lead.assign_to)) }
                                            onChange={(e) => assignToUser(e,lead.id,'maker_id')}
                                        />
                                        </div>
                                    </> :'NA'}
                                </fieldset>
                            </td>
                            <td>
                                <fieldset className="single-select  select-bx-table">
                                    <div className="material" title={verifier_user_list.filter((v) => (v.value === lead.verifier_id))?.[0]?.label}>            
                                    <Select
                                        options={accessCondition === 1 ? verifier_user_list : []}
                                        placeholder={"Select Verifier"}
                                        name={'verifier_id'}
                                        optionLabel="label"
                                        optionValue="value"
                                        className='react-select-table'
                                        classNamePrefix='react-select-table'
                                        value={verifier_user_list.filter((v) => (v.value === lead.verifier_id)) }
                                        onChange={(e) => assignToUser(e,lead.id,'verifier_id')}
                                    />
                                    </div>
                                </fieldset>
                            </td>
                            </>
                            : ''}
                             {/* <td>
                                 <ul className="action-btn">
                                     <li onClick={() => navigateLeadDetail(lead.id)}>
                                         <i className="ic-remove_red_eyevisibility"></i>
                                     </li>
                                     <li onClick={() => { toggleModalBox(lead) }}>
                                         <i className="ic-Call-history"></i>
                                         {isviewHistory == lead.id ? <Loader /> : null}
                                     </li>
                                 </ul>
                             </td> */}
                             </>
                         
                         </tr>
                         {lead.followup_status === '1' && lead.followup_date && new Date() <= new Date(lead.followup_date) ?
                                <tr key={lead.id + "-followup"} style={{border:"none"}}>
                                    <td colSpan={9} style={{ height: "0" }}>
                                        <span style={{ display: "flex" }}>
                                            <i className='ic-notifications' style={{ color: "#F94E4E", fontSize: 20 }}></i>
                                            <span style={{ color: "#F94E4E", marginLeft: 10 }}>Upcoming Follow-Up : {lead.followup_date ? `${dateFormat(lead.followup_date, 'd mmm,yyyy hh:MM')}` : ""}</span>
                                        </span>
                                    </td>
                                </tr>
                                : null
                            }
                         </React.Fragment>
                    })}
                </tbody>
            </table>

            <p className="align-center-no-record">{leads && leads[0] && leads[0].lead_list && leads[0].lead_list.length == 0 && "No record found"}</p>

            {isLoading ? <Loader /> : null}
            <Pagination pageCount={totalPage} activePage={leads && leads[0] && leads[0].pagination && leads[0].pagination.page_no && 1} onPaginate={onPaginate} />

            {showhistorymodal ? (
                <div className="view-timeline-popup image-timeline-popup">
                    <Modal show={showhistorymodal} handleClose={() => { toggleModalBox({}) }} >
                        <div className="modal-header">
                            <h2>{"CUSTOMER TIMELINE"}</h2>
                        </div>
                        <LeadHistory historyList={historyList} followUphistoryList={followUphistoryList} isLoading={isLoading} t={t} />
                    </Modal>
                </div>
            ) : ''}

            {showremarkmodal ? (
                <div className="view-timeline-popup image-timeline-popup">
                    <Modal show={showremarkmodal} handleClose={() => { toggleModalBoxremark({}) }} >
                        <div className="modal-header">
                            <h2>Remark History</h2>
                        </div>
                        <LeadRemarkHistory remarkList={remarkList} showremarkform={showremarkform} isLoading={isLoading} t={t} />
                    </Modal>
                </div>
            ) : ''}

        </div >
    )
}
export default LeadListTable;