import Formsy from 'formsy-react';
import dateFormat from 'dateformat';
import { useOutletContext, useNavigate } from 'react-router-dom';
import React, { useEffect, useMemo, useState } from 'react';
import { gql, useApolloClient } from '@apollo/client';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { executeGraphQLMutation } from '../../common/executeGraphQLQuery';
import { UPDATE_LEAD_STATUS } from '../../services/leads.gql';
import { getLoanDetail, getLeadRemarks } from '../../store/action/allAction';
import crypto from "../../config/crypto";
import Loader from '../elements/Loader';
import LoggedinPopup from '../elements/LoggedinPopup';
import LoggedinTabs from './LoggedinTabs'
import FormFields from "./formFields/leadFormField.json"
import FormsyInputField from '../elements/FormsyInputField';
import FormsyDatePicker from '../elements/FormsyDatePicker';
import FormsySelect from '../elements/FormsySelect';
import GeneralService from '../../services/generalService';  
import LeadStatusService from "../../services/leadStatus";

const CheckListForm = (props) => {
  const { SANCTIONED,DISBURSED} = LeadStatusService.LEAD_STATUS_MASTER;
  const CHECKLIST = FormFields["CHECKLIST"];
  const [checkListDetails, setChecklistData] = useState({});
  const [allowSubmit, setAllowSubmit] = useState(false);
  const [loggedinView, setLoggedinView] = useState(false);
  const [updloadDocList, setUploadDocList] = useState([]);
  const [statusList, setStatusList] = useState(props?.checkList?.checklist_substatus);
  let [saveChecklistData, setSaveChecklistData] = useState({});
  let [loading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const client = useApolloClient();
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const getContext = useOutletContext();
  const { lead } = useSelector(({ lead }) => ({ lead }));
  const user_information = localStorage.getItem('user_information');
  let authUser = user_information && JSON.parse(user_information);


  useEffect(() => {
    if (statusList?.length) setStatusList(statusList.map(v => { return { label: v.name,value:v.id,  ...v } }));
    if (lead && lead.leadDetail && lead.leadDetail.checklists.length) {
      let cheklistArray = lead?.leadDetail?.checklists?.filter(data => (data.sub_status_id === props?.checkList?.sub_status_id && data.checklist_id === props?.checkList?.id )); 
      let cheklistData = cheklistArray[cheklistArray.length-1];
      setSaveChecklistData(cheklistData);
      if (cheklistData?.id) {
        cheklistData = {
          checklist_id: cheklistData.id || 0,
          checklist_status_id: cheklistData.checklist_status_id || 2,
          checklist_sub_status_id: cheklistData.checklist_sub_status_id || 1,
          checklist_remark: cheklistData.checklist_remark || '',
          checklist_complete_date: cheklistData.checklist_complete_date ? dateFormat(cheklistData.checklist_complete_date, "yyyy-mm-dd") : null,
          is_done_cam: cheklistData.is_done_cam || '0',
          is_freezed_checklist: cheklistData.is_done_cam || '0',
        };
        setChecklistData(cheklistData);
      }
    }
  }, [lead]);

  let cheklistForm = useMemo(() => {
    return CHECKLIST;
  }, [checkListDetails]);

  const handleInputChange = event => {
    if (event.target.id) {
      setChecklistData(currentValue => ({
        ...currentValue,
        [event.target.id]: event.target.value
      }))
    }
  }

  const handlePatternFormatChange = (data, field_name) => {
    if (data.value && field_name) {
      setChecklistData(currentValue => ({
        ...currentValue,
        [field_name]: data.value
      }))
    }
  }

  const handleDateChange = (date, field_name) => {
    if (date && field_name) {
      setChecklistData(currentValue => ({
        ...currentValue,
        [field_name]: date
      }))
    }
  }

  const handleSelectChange = (data, field_name) => {
    if (data && field_name) {
      setChecklistData(currentValue => ({
        ...currentValue,
        [field_name]: data.id
      }))
    }
  }

  const handleDoneCheckList = event => {
    let checked = "0";
    if (event.target.checked) checked = "1"
    setChecklistData(currentValue => ({
      ...currentValue,
      ['is_done_cam']: checked
    }))
  }

  const handleSubmit = () => {
    let lead_id = lead?.leadDetail?.id || "";
    let reqBody = { 
      lead_id: lead_id,
      checklist_id: props?.checkList.id,
      sub_status_id: props?.checkList.sub_status_id,
      checklist_sub_status_id: checkListDetails.checklist_sub_status_id,
      checklist_remark: checkListDetails.checklist_remark || '', 
      checklist_complete_date: (checkListDetails.checklist_complete_date)? dateFormat(checkListDetails.checklist_complete_date, "yyyy-mm-dd") : null
    } 
     
    let mutation = UPDATE_LEAD_STATUS,
      variables = {
        api_called_by: 'web',
        UpdateLeadStatus: {
          lead_id: lead_id,
          user_id: authUser && authUser.id || 1,
          update_type: "checklist",
          status_id: [],
          checklist_form: reqBody,
          callFrom:"updateSideForm"
        }
      };

    executeGraphQLMutation(mutation, variables, client).then(resp => {
      let response = resp?.data?.UpdateLeadStatus || null;
      if (response && !response.success) throw new Error(response.error || "Something went wrong");
      if (updloadDocList.length) saveDocument(lead_id, updloadDocList);
      if (reqBody?.checklist_remark) dispatch((getLeadRemarks({ lead_id: lead_id, source: 'WEB' }, client)))

      toast.success("Success");
      togglePopup();
      dispatch(getLoanDetail(lead_id, client))
    })
      .catch(err => {
        toast.error(err.message);
      })
  }

  const navigateToNext = () => {
    setLoggedinView(false);
    document.body.classList.remove("overflow-hidden");
    let lead_id = lead?.leadDetail?.id || "";
    navigate(`/lead-detail/docs-upload/${crypto.encode(lead_id)}`);
  }

  const docsChecklistMapping = {1:46,2:47,3:48,4:49,5:50,6:51};

  // All the conditions can be combined here for button enable/disable
  const isSubmitDisabled = useMemo(() => {
    // let isDocsRequired = (lead?.leadDetail?.leaddocs?.length && (lead?.leadDetail?.leaddocs.filter(data => data.doc_id === docsChecklistMapping[checkListDetails[checklist_status_id]])).length && checkListDetails.id) ? false : true ;
    // let isDocsRequired = (checkListDetails.checklist_id || updloadDocList.length) ? false : true ;
  
    let CHECKLIST_DONE = props?.checkList?.checklist_substatus?.filter(ob=> ob.is_final_status =="1").map(ele=>ele.id); 
    let doneResult = lead?.leadDetail?.checklists.filter(ob=> ob.checklist_id == props?.checkList?.id && CHECKLIST_DONE.includes(+ob.checklist_sub_status_id)); 

    // let disburseLead = (lead?.leadDetail.is_disbursal_verified =="1") ? true : false 
    return (!allowSubmit || doneResult.length) ? true : false;

    // return ([1, 3, 4, 5].includes(lead.leadDetail.status_id) || [2, 4, 6, 8, 12].includes(saveChecklistData?.checklist_sub_status_id) || saveChecklistData?.is_done_cam === '1' || !allowSubmit || doneResult.length ) ? true : false;
  }, [allowSubmit, updloadDocList, getContext, checkListDetails]);

  const hideLoggedInModal = () => {
    setLoggedinView(false);
    document.body.classList.remove("overflow-hidden");
  };

  const handleFileChange = async (event, max_upload) => {
    setUploadDocList([]);
    setIsLoading(true);
    let selectedImage = event && event.target && event.target.files && event.target.files.length && event.target.files[0].name || "";
    let publicUrl = '', docPathArray = [];
    if (!selectedImage) {
      toast.error("Please upload an image.");
      return;
    }
    if (selectedImage) {
      let lead_id = lead?.leadDetail?.id || "";
      const formData = new FormData();
      formData.append("dealer_id", lead_id);
      formData.append("upload_type", "dealer_docs");
      for (let imgFile of event.target.files) {
        formData.append("images", imgFile);
      }
      try {
        publicUrl = await GeneralService.uploadFilesToS3(formData, { 'Content-Type': 'multipart/form-data' });
        if (publicUrl && publicUrl.data && publicUrl.data.data && publicUrl.data.data.length) {
          docPathArray = publicUrl.data.data;
          docPathArray = docPathArray.map(ob => ob.file_url);
          setUploadDocList(docPathArray);
          setIsLoading(false);
        }
      } catch (error) {
        console.error('Error uploading file:', error);
      }
    }
  }

  const saveDocument = (lead_id, docPathArray) => {
    let leadDocArr = [];
    for (let imgPath of docPathArray) {
      leadDocArr.push({
        lead_id: Number(lead_id),
        doc_id: 45 + +(props?.checklist_status_id || 1),
        parent_doc_id: 45,
        doc_path: imgPath,
        doc_source: "web",
        customer_type: "customer",
        customer_id: lead?.leadDetail?.customer?.customer_id || 0,
        user_id: authUser && authUser.id || 1,
      })
    }
    let variables = {
      saveDocInput: {
        lead_id: lead_id,
        lead_docs: leadDocArr
      },
      api_called_by: 'web'
    };

    let mutation = gql`mutation save_doc($saveDocInput: SaveDocInput!, $api_called_by: API_CALL_BY!){
          save_doc(saveDocInput: $saveDocInput, api_called_by: $api_called_by) {
              lead_id
              doc_id
              doc_path
          }
      }`;
    executeGraphQLMutation(mutation, variables, client).then(result => { })
  }

 
  const togglePopup = () => {
    props.togglePopup()
    setIsOpen(!isOpen);
  }; 
  const  IS_LOST = (lead?.leadDetail?.status_id ==5)? true: false  
  const IS_DISBURSE_SANSACTION = [SANCTIONED,DISBURSED].includes(lead.leadDetail?.status_id) ? true: false;
  
  return (
    <div className="image-form-outer" key={lead.leadDetail && lead.leadDetail?.status_id}>
      {loading ? <Loader /> : null}
      <div>
        <div className="checklist-outer">
          <Formsy
            className="lead-form-filed"
            autoComplete="off"
            onValid={() => setAllowSubmit(true)}
            onInvalid={() => setAllowSubmit(false)}
            onValidSubmit={handleSubmit}
          >
            <div className="row">
              {cheklistForm.map((field,itemKey) => (
                ["text", "pattern-format"].includes(field.type) ? (
                  <fieldset class="form-filed col-md-6 " key={itemKey}>
                    <FormsyInputField
                      id={field.id}
                      name={field.name}
                      type={field.type}
                      value={checkListDetails && checkListDetails[field.name]}
                      placeholder=" "
                      label={field.label}
                      onChange={
                        field.type === "pattern-format"
                          ? (data) => handlePatternFormatChange(data, field.name)
                          : handleInputChange
                      }
                      format={field.ApplyNumberformat ? field.format : ""}
                      validations={field.validations}
                      validationError={
                        checkListDetails[field.name] ? field.validationError : ""
                      }
                      readOnly={field.readOnly}
                      required={field.required}
                    />
                  </fieldset>
                ) : ["textarea"].includes(field.type) ? (
                  <fieldset class="col-md-12" key={itemKey}>
                    <div class="material">
                      <textarea
                        id={field.id}
                        name={field.name}
                        type={field.type}
                        value={checkListDetails && checkListDetails[field.name]}
                        placeholder=" "
                        onChange={handleInputChange}
                        class="form-input"
                      >
                      </textarea>
                      <label data-label={field.label} class="form-label"></label>
                    </div>
                  </fieldset>
                ) : field.type === "dropdown" && statusList?.length ? (
                  <fieldset class="single-select col-md-6" key={itemKey}>
                    <FormsySelect
                      name={field.name}
                      id={field.id}
                      inputProps={{
                        options: statusList,
                        placeholder: field.label,
                        className: "react-select",
                        classNamePrefix: "react-select",
                        value: statusList.filter(({ id }) => id == checkListDetails[field.name]),
                        isDisabled: field.readOnly
                      }}
                      required={field.required}
                      value={checkListDetails && checkListDetails[field.name]}
                      onChange={(data) => handleSelectChange(data, field.name)}
                    />
                  </fieldset>
                ) :
                  field.type === "date-picker" ? (
                    <fieldset class="form-filed col-md-6 " key={itemKey}>
                      <FormsyDatePicker
                        name={field.name}
                        selectedDate={checkListDetails && checkListDetails[field.name] && new Date(checkListDetails[field.name])}
                        placeholder={field.label}
                        minDate={new Date(lead?.leadDetail?.lead_details?.login_date)}
                        maxDate={new Date()}
                        value={checkListDetails && checkListDetails[field.name]}
                        onDateSelect={(date) => handleDateChange(date, field.name)}
                        dateFormat="dd MMM, yyyy"
                        validations={field.validations}
                        validationError={
                          checkListDetails[field.name] ? field.validationError : ""
                        }
                        required={field.required}
                        hasError={(checkListDetails.hasOwnProperty(field.name) && !checkListDetails[field.name]) ? true : false}
                      />
                    </fieldset>
                  ) : (field.type === "checkbox" && props.checklist_status_id === 1) ? (
                    <fieldset class="col-md-12 loggedcard " key={itemKey}>
                      <div className='loggedcheck'>
                        <input type='checkbox' onChange={handleDoneCheckList} id={field.id} name={field.name} value='1' disabled={checkListDetails?.is_freezed_checklist === '1'} checked={checkListDetails && checkListDetails[field.name] === '1' ? true : false} />
                        <label htmlFor="credit">{field.label}</label>
                      </div>
                    </fieldset>
                  ) : null
              ))}
            </div>

         {/*    <div className="acco-doc-list">
            <div className="lead-form-heading" style={{"margin-bottom": "7px"}}>
                <label>Upload Supporting Documents</label>
            </div>
              <div className="img-bx-select-opt" style={{ width: "100%" }}>
                <div className="image-bx more-file-upload">
                  <input multiple type="file" name="file" id='file' onChange={e => handleFileChange(e)} />
                  <a onClick={navigateToNext}>Verify</a>
                </div>
              </div>
            </div> */}
            <div className="checklist-save">
              <button type="submit" className="btn-success" disabled={isSubmitDisabled || IS_LOST || IS_DISBURSE_SANSACTION}>Update</button>
            </div>
          </Formsy>
        </div>
      </div>

     
        <LoggedinPopup isOpen={isOpen} togglePopup={togglePopup}>
          <div className="modal-header"><h2>Sub Status</h2></div>
          <LoggedinTabs />
        </LoggedinPopup>
     
    </div>
  );
}

export default CheckListForm;