import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";
import { executeGraphQLQuery, executeGraphQLMutation } from "../../common/executeGraphQLQuery";
import secureStorage from "../../config/encrypt";
import { REPORTING_STATUS } from "../../services/mis.gql";
import { useApolloClient, gql } from '@apollo/client';
import Select from "react-select";
import Modal from "../elements/Modal";
import DownloadReport from './DownloadReport';
import { toast } from 'react-toastify';

const ReportingMonth = (props) => {
  const client = useApolloClient();

  const [reportList, setReportList] = useState([]);
  const [reportingYear, setYearList] = useState([]);
  const [reportMonth, setMonthList] = useState([]);
  const [reportingStatus, setReportongStatusList] = useState([]);
  const [modalVisiblity, setModalVisiblity] = useState(false);
  const [filterData, setFilterData] = useState({});
  const [isButtonVisible, setIsButtonVisible] = useState(true);

  const afterLoginSetState = (loginResponse) => {
    let role =
      (loginResponse &&
        loginResponse.data &&
        loginResponse.data.user_data &&
        loginResponse.data.user_data.role) ||
      null;
    if (role != "dealercentral") {
      return <Navigate to={"/"} />;
    }
  };

  const updateReduxData = async () => {
    if (secureStorage.getItem("loginUserInfo")) {
      var loginResponse = secureStorage.getItem("loginUserInfo");
      props.updateStateIntoRedux("LOGIN_USER", loginResponse);
      afterLoginSetState(loginResponse);
    } else {
      return <Navigate to={"/login"} />;
    }
  };

  const getReportingSttaus = async () => {
    try {  
      const response = await executeGraphQLQuery(REPORTING_STATUS(5),client); 
      if (response.data?.reporting_status) { 
        setReportList(response.data?.reporting_status || []); 
      }
      if (response.data?.masterdata?.report_month_list) { 
        setMonthList(response.data?.masterdata?.report_month_list || []); 
      }
      if (response.data?.masterdata?.report_year_list) { 
        setYearList(response.data?.masterdata?.report_year_list || []); 
      }
      if (response.data?.masterdata?.report_status_list) { 
        setReportongStatusList(response.data?.masterdata?.report_status_list || []); 
      }
    } catch (error) { 
      console.log("Error 🔻 ", error.message);
    }
  };

  useEffect(() => {
     getReportingSttaus();
  }, []);

  const resetFilter = () => {
    window.location.reload();
  };

  const handleModalOpen = () => {
    setModalVisiblity(true);
  };

  const handleModalClose = () => {
    setModalVisiblity(false);
  };

  const handleFilterApply = () => {
    // getMisList(filterParams);
  };

  const handleChange = async (n, v, isMulti, opt, optl) => {
    switch (n) {
      case 'year':
        setFilterData((prev) => ({ ...prev, [n]: v.map(obj => obj[opt]) }));
        break;
      case 'month':
        setFilterData((prev) => ({ ...prev, [n]: v.map(obj => obj[opt]) }));
        break;
      case 'reporting_status':
        setFilterData((prev) => ({ ...prev, [n]: v.map(obj => obj[opt]) }));
        break;
    }
  }

  const closeReportingMonth = (month, year, report_id, api_called_by) => {
    let confirm = window.confirm('Are you sure to close reporting month?');
    if (confirm) {
      let user_id = props.user_id;
      
      const mutation = gql`
        mutation UpdateReportingStatus($month: Float!, $year: Float!, $report_id: Float!, $user_id: Float!, $api_called_by: API_CALL_BY!) {
          close_reporting_month(
            month: $month,
            year: $year,
            report_id: $report_id,
            user_id: $user_id,
            api_called_by: $api_called_by
          )
        }
      `;
  
      const variables = {
        month: Number(month),
        year: Number(year),
        report_id: Number(report_id),
        user_id: Number(user_id),
        api_called_by: "web"
      };

      executeGraphQLMutation(mutation, variables, client)
        .then(result => {
          if (result && result.data) {
            setIsButtonVisible(false);
            toast.success('Reporting Month Updated successfully');
          } else {
            toast.error('Failed to update reporting month');
          }
        })
        .catch(error => {
          console.error('Error:', error);
          toast.error('Error occurred during the mutation');
        });
    }
};

const formatToINR = (amount) => {
  return new Intl.NumberFormat('en-IN', {
      style: 'currency',
      currency: 'INR',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
  }).format(amount);
}


  return (
    <>
      <div className="container-fluid">
        <h2 className="top-heading">Disbursement Reporting Months</h2>
        <div className="search-wrap">
          <div className="row">
            <div className="col-sm-2 col-md-2">
              <label>Select Year</label>
              <Select
                  options={reportingYear}
                  placeholder={"Year"}
                  name={"year"}
                  optionLabel="label"
                  optionValue="id"
                  value=""
                  onChange={(e) => handleChange("year", e)}
                  styles={{
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: state.isSelected ? 'transparent' : provided.backgroundColor,
                      color: 'black',
                    }),
                  }}
                />
            </div>
            <div className="col sm-2 col-md-2">
            <label>Select Month</label>
              <Select
                  options={reportMonth}
                  placeholder={"Month"}
                  name={"month"}
                  optionLabel="label"
                  optionValue="id"
                  value=""
                  onChange={(e) => handleChange("month", e)}
                  styles={{
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: state.isSelected ? 'transparent' : provided.backgroundColor,
                      color: 'black',
                    }),
                  }}
                />
            </div>

            <div className="col sm-2 col-md-2">
            <label>Status</label>
              <Select
                  options={reportingStatus}
                  placeholder={"Status"}
                  name={"reporting_status"}
                  optionLabel="label"
                  optionValue="id"
                  value=""
                  onChange={(e) => handleChange("reporting_status", e)}
                  styles={{
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: state.isSelected ? 'transparent' : provided.backgroundColor,
                      color: 'black',
                    }),
                  }}
                />
            </div>
            
            <div className="col-sm-2 col-md-2" style={{ marginTop: 18 }}>
              <div className="btn-submit-reset top-btn-none pull-right">
                <button
                  type="submit"
                  className="btn btn-primary mrg-r10"
                  onClick={handleFilterApply}
                >
                  {"Search"}
                </button>
                <button
                  type="reset"
                  className="btn-reset"
                  onClick={resetFilter}
                >
                  {"Reset"}
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "right",
            marginBottom: "5px",
          }}
        >
          {/* { <button
             className="add-schemebtn"
            type="button"
            onClick={handleModalOpen}
          >
            Add Reporting Month
          </button> } */}

        </div>

        <div className="view-timeline-popup">
          <Modal show={modalVisiblity} handleClose={handleModalClose}>
            <>
              <div className="row">
                <div className="col-sm-6 col-md-6">
                  <label>Select Year</label>
                  <Select
                  options={reportingYear}
                  placeholder={"Year"}
                  name={"year"}
                  optionLabel="label"
                  optionValue="id"
                  value=""
                  onChange={(e) => handleChange("year", e)}
                  styles={{
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: state.isSelected ? 'transparent' : provided.backgroundColor,
                      color: 'black',
                    }),
                  }}
                />
                </div>
                <div className="col-sm-6 form-group">
                  <label>Reporting Month</label>
                  <Select
                  options={reportMonth}
                  placeholder={"Month"}
                  name={"month"}
                  optionLabel="label"
                  optionValue="id"
                  value=""
                  onChange={(e) => handleChange("month", e)}
                  styles={{
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: state.isSelected ? 'transparent' : provided.backgroundColor,
                      color: 'black',
                    }),
                  }}
                />                  
                </div>
              </div>
              <div className="text-center" style={{marginTop:20}}>
                <button className="btn btn-primary" type="button" >
                  Save
                </button>
              </div>
            </>
          </Modal>
          </div>    

        <div className="clearfix">
          <div className="card">
            <div className="table-responsive ">
              <table className="table table-bordered tablefontsize">
                <thead>
                  <tr>
                    <th>{"Month"}</th>
                    <th>{"Year"}</th>
                    <th>{"Status"}</th>
                    <th>{"Total Disbursement Count"}</th>
                    <th>{"Total Tranche Count"}</th>
                    <th>{"Total Disbursement Amount"}</th>
                    <th>{"Total Freezed Disbursement Count"}</th>
                    <th>{"Total Freezed Tranche Count"}</th>
                    <th>{"Total Freezed Disbursement Amount"}</th>
                    <th>{"Report"}</th>

                  </tr>
                </thead>
                <tbody>
                  
                  {reportList.map((point, index) => (
                    <>
                      <tr key={index}>
                        <td>{point.month_name}</td>
                        <td>{point.year}</td>
                        <td>{point.reporting_status}</td>
                        <td>{point.total_lead_count}</td>
                        <td>{point.total_disbursed_lead}</td>
                        <td>{point?.total_disbursed_amount ? formatToINR(point.total_disbursed_amount) : 'NA'}</td>
                        <td>{point.freezed_total_lead_count || 'NA' }</td>
                        <td>{point.freezed_total_disbursed_lead || 'NA'}</td>
                        <td>{point?.freezed_total_disbursed_amount ? formatToINR(point.freezed_total_disbursed_amount) : 'NA'}</td>
                        <td>{point.total_disbursed_lead ?
                           (
                            <div className="btn-submit-reset top-btn-none ">
                              {point.reporting_status == 'open' && isButtonVisible && point.month < new Date().getMonth() + 1 ? (
                                <div class="btn-group btn-group-small mrg-r5">
                                <div class="btn btn-default" title="Freeze Month" onClick={(e) => closeReportingMonth(point.month, point.year, point.id, 'web')}>
                                  <i class="ic-clearclose icons-normal" style={{color:"green"}}></i>
                                  {/* <img src="nfc-lock.png" alt="Freezed Report" width={18}height={20}/> */}
                                </div>
                              </div>
                              //   <button 
                              //   type="button"
                              //   className="btn btn-primary mrg-r10"
                              //   onClick={(e) => closeReportingMonth(point.month, point.year, 'web')}
                              // >
                              //   {"Close Reporting"}
                              // </button>
                              ):""}
                              <DownloadReport report_id={point.id} user_id={1} reporting_status={point.reporting_status}/>
                            </div>
                          ) : ("")}
                          
                        </td>
                      </tr>
                    </>
                  ))} 
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  let user_information = state.user?.user_information;
  
  // Parse the JSON string if it exists
  const userInfo = typeof user_information === 'string' ? 
    JSON.parse(user_information) : 
    user_information;

  return {
    auth_token: state.authToken,
    user_loggedin: state.userLoggedIn,
    dealer_id: state.dealer_id,
    user_id: userInfo?.id || null
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateStateIntoRedux: (actionType, stateData) =>
      dispatch({ type: actionType, componentState: stateData }),
  };
};

export default withTranslation("dealer")(
  connect(mapStateToProps, mapDispatchToProps)(ReportingMonth)
);
