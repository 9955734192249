import { gql, useApolloClient } from '@apollo/client';
import React, { useEffect, useState } from "react";
import { Dropdown } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { toast } from "react-toastify";
import { executeGraphQLMutation, executeGraphQLQuery } from '../../../common/executeGraphQLQuery';
import { LEAD_DOCS_REMARKS_QUERY } from '../../../services/leadDocsRemarks.gql';
import { getLoanDetail } from '../../../store/action/allAction';
import noImage from "../../../webroot/images/noimage.svg";
import Loader from '../../elements/Loader';
import DocumentHistory from "./DocumentHistory";
import GallaryCarousel from "./GallaryCarousal";
import ReSubmissionDocument from "./ReSubmissionDocument";

function UploadedTagDocs(props) {
    const dispatch = useDispatch();
    const client = useApolloClient();
    const [UploadedFiles, setUploadedFiles] = useState(props.UploadedFiles);
    const [filterFiles, setFilterFileList] = useState([]);
    const [filter_doc_list, setFilterDocList] = useState(props.filter_doc_list);
    const [filter_doc_child_list, setFilterDocChildList] = useState(props.filter_doc_child_list)
    const [isLoading, setIsLoading] = useState(true);
    const [currentFileId, setFileId] = useState(0);
    const [currentFileDocId, setFileDocID] = useState(0);
    const [currentFileParentId, setFileParentID] = useState(0);
    const [filterData, setFilterData] = useState(props.filterData);
    const [enableRemoveTag, setRemoveTag] = useState(false);
    const [documentHistoryList, setDocumentHistoryList] = useState([]);
    const [showDocumentHistoryModal, setShowDocumentHistoryModal] = useState(false);
    const [showResubmisisonModal, setShowResubmissionModal] = useState(false);
    const [docActionType, setDocActionType] = useState('');
    const [leadDetail, setLeadDeails] = useState(props.leadDetail);
   /*  let accessCondition = !IS_USER_ACCESS_PAGE('doc_upload', 'doc_upload').is_edit_access;
    if(!accessCondition){
        accessCondition=CHECK_ASSIGN_USER(leadDetail?.assign_to_m);
    } */
    let accessCondition = false

    useEffect(()=>{ 
        setFilterDocList(props.filter_doc_list);
        setFilterDocChildList(props.filter_doc_child_list)
    },[props.filter_doc_list])

    useEffect(() => { 
        if(props.filterData.file_id || props.filterData.doc_id){
            let singleimage = props.filterData.file_id ?
            props.filterFiles.filter(v => v.id === props.filterData.file_id) : props.filterFiles.filter(v => v.doc_id === props.filterData.doc_id);
            if(singleimage.length){
            setFileId(singleimage?.[0]?.id);
            if(singleimage?.[0]?.doc_id){
            setRemoveTag(true);
            setFileDocID(singleimage?.[0]?.doc_id);
            }
            else{
                setRemoveTag(false);
                setFileDocID(0);
            }
            if (singleimage[0].parent_doc_id)
            setFileParentID(singleimage?.[0].parent_doc_id)
            else setFileParentID(0)

            let newimageArray = [...singleimage]
                props.filterFiles.map(v => {
                    if (v.id !== singleimage.id) {
                        newimageArray.push(v);
                    }
                })
                setFilterFileList(newimageArray);
            }else{
                setFilterFileList(props.filterFiles);
            }
        }
        else
        setFilterFileList(props.filterFiles);
    }, [props.filterFiles ]);

    useEffect(() => { 
        setIsLoading(false);
        if (filterFiles && filterFiles.length) {
          
            setFileId(filterFiles[0].id);
            if (filterFiles[0]?.doc_id) {
                setRemoveTag(true);
                setFileDocID(filterFiles[0]?.doc_id);
            } else {
                setFileDocID(0);
            }
            if (filterFiles[0].parent_doc_id) {
                setFileParentID(filterFiles[0].parent_doc_id)
            } else {
                setFileParentID(0)
            }  
        } else {
            
            setFileId(0);
            setRemoveTag(false);
            setFileDocID(0);
            setFileParentID(0)
        }
    }, [filterFiles])

    useEffect(() => { 
        let filterFilesOrg = props.filterFiles || [];
        setFilterData(props.filterData);
        if (props.filterData.doc_id) {
            setIsLoading(false);
            let filterFilesnew = filterFilesOrg.filter(v => v.doc_id === props.filterData.doc_id);
            setFilterFileList(filterFilesnew);
        } else { 
            if (props.filterData.file_id) {
                let singleimage = filterFilesOrg.filter(v => v.id === props.filterData.file_id);
                
                setFileId(props.filterData.file_id);
                setRemoveTag(false);
                let newimageArray = [...singleimage] 
                filterFilesOrg.map(v => {
                    if (v.id !== singleimage.id) {
                        newimageArray.push(v);
                    }
                }) 
                setFilterFileList(newimageArray);
            }
        }
    }, [props.filterData]);

    const getFileId = (file_id, doc_id, parent_id) => { 
        if (file_id !== currentFileId) {
            setFileId(file_id);
            setFileDocID(doc_id);
            setFileParentID(parent_id);
        }
        if (doc_id) {
            setRemoveTag(true);
        } else {
            setRemoveTag(false);
        }

    }

    const tagDoc = (doc) => {
        if (currentFileId) {
            let confirm = window.confirm('Are you want to tag this file ?');
            if (confirm) {
                props.tagDoc(doc, currentFileId);
            }
        } else {
            toast.error("Invalid file for tagged");
        }


    }
    const removeTag = () => {
        if (currentFileId) { 
            let confirm = window.confirm('Are you sure to remove tag ?');
            if (confirm) {
                props.removeTag(currentFileId);
                setFilterData({ doc_id: 0 })
                setRemoveTag(false);
            }
        } else {
            toast.error("Invalid file");
        }
    }


    const markAsApproved = () => { 
        let data = {
            lead_id: leadDetail.id,
            customer_id: +props.uId,
            customer_type: props.uType,
            user_id: props.user_id || 0,
            doc_status: "2",
            doc_source: "web",
            remark:'',
            update_action_type: 'documents',
            lead_docs: [{
                id: currentFileId,
                doc_id: currentFileDocId,
                parent_doc_id: currentFileParentId
            }]
        };
        let message = "Doc Approved Successfully";
        manageDocAction(data, message);
    }

    /** will handle for both resubmission request as well as approval */
    const manageDocAction = async (leadData, message = null) => {
        try {
            if (leadData && leadData.lead_docs && leadData.lead_docs.length) {
                setIsLoading(true);
                let variables = { approvedDocInput: leadData };
                const mutation = gql`
                    mutation approved_document($approvedDocInput: ApprovedDocInput!) {
                        approved_document(approvedDocInput: $approvedDocInput) {
                        lead_id
                        }
                    }`;
                let result = await executeGraphQLMutation(mutation, variables, client);
                if (result && result.data) {
                    if (message) toast.success(message);
                    if (result.data) {
                        dispatch(getLoanDetail(leadDetail.id, client))
                        props.setFilterData({ doc_id: leadData?.lead_docs?.[0]?.doc_id, file_id: leadData?.lead_docs?.[0]?.id })
                    }
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                    toast.error('Docs already approved/requested. Please refresh the page and check');
                }
            } else {
                toast.error("No image found.");
            }
        } catch (error) {
            setIsLoading(false);
        }

    }

    const getDocumentHistoryList = async (doc_id, lead_id) => {
        if (doc_id) {
            executeGraphQLQuery(LEAD_DOCS_REMARKS_QUERY(lead_id, doc_id), client).then((result) => {
                setDocumentHistoryList(result.data.doc_remark_history);
            })
                .catch((error) => {
                    console.error('Query error:', error);
                });
        }
    }

    const showDocumentHistory = () => {
        setShowDocumentHistoryModal(true);
        document.body.classList.add("overflow-hidden");
        getDocumentHistoryList(currentFileDocId, leadDetail.id);
    }

    const hideModalDocumentHistory = () => {
        setShowDocumentHistoryModal(false);
        document.body.classList.remove("overflow-hidden");
    }

    const showResubmission = (action_type) => {
        setShowResubmissionModal(true);
        setDocActionType(action_type);
        document.body.classList.add("overflow-hidden");
    }

    const hideResubmissionModal = () => {
        setShowResubmissionModal(false);
        document.body.classList.remove("overflow-hidden");
    }

    let filterFilesArr = UploadedFiles.filter(o1 => filter_doc_child_list.some(o2 => (o1.doc_id === o2.id || o1.doc_id === 0)));
    let current_file = currentFileId && filterFiles && filterFiles.length? filterFiles.find(file => file.id === currentFileId) : null;

    const datawithCount = [...filterFilesArr.reduce((mp, o) => {
        if (!mp.has(o.doc_id)) mp.set(o.doc_id, { ...o, count: 0 });
        mp.get(o.doc_id).count++;
        return mp;
    }, new Map()).values()];

    let temp = [];
    let customerDetails = leadDetail?.customer || {};
    let coborrowerDetails = leadDetail?.co_borrower || {};
    let currCustomerType = customerDetails?.customer_type || 0,iswaterBillUploaded=0,iselectricBillUploaded=0,isbarangybusinsspermitUploaded=0,ismayorpermitUploaded=0,isstipermitUploaded=0,ispaySlipUploaded=0,isBankStatementUploaded=0,
    currCBCustomerType = coborrowerDetails?.cb_customer_type || 0,
    iscbwaterBillUploaded=0,iscbelectricBillUploaded=0,iscbbarangybusinsspermitUploaded=0,iscbmayorpermitUploaded=0,iscbstipermitUploaded=0,iscbpaySlipUploaded=0,iscbBankStatementUploaded=0;
    filter_doc_list.forEach((element, i) => {
        if (element.parent_id === 0) {
            element.count = datawithCount.filter(data => data.doc_id === element.id).map(v => v.count)[0] || 0;
            iswaterBillUploaded = datawithCount.filter(data => data.doc_id === 33).map(v => v.count)[0] || 0;
            iselectricBillUploaded = datawithCount.filter(data => data.doc_id === 34).map(v => v.count)[0] || 0;

            isstipermitUploaded = datawithCount.filter(data => data.doc_id === 39).map(v => v.count)[0] || 0;
            ismayorpermitUploaded = datawithCount.filter(data => data.doc_id === 40).map(v => v.count)[0] || 0;
            isbarangybusinsspermitUploaded = datawithCount.filter(data => data.doc_id === 41).map(v => v.count)[0] || 0;


            ispaySlipUploaded = datawithCount.filter(data => data.doc_id === 37).map(v => v.count)[0] || 0;
            isBankStatementUploaded = datawithCount.filter(data => data.doc_id === 38).map(v => v.count)[0] || 0;

            iscbwaterBillUploaded = datawithCount.filter(data => data.doc_id === 75).map(v => v.count)[0] || 0;
            iscbelectricBillUploaded = datawithCount.filter(data => data.doc_id === 76).map(v => v.count)[0] || 0;
            iscbstipermitUploaded = datawithCount.filter(data => data.doc_id === 81).map(v => v.count)[0] || 0;
            iscbmayorpermitUploaded = datawithCount.filter(data => data.doc_id === 82).map(v => v.count)[0] || 0;
            iscbbarangybusinsspermitUploaded = datawithCount.filter(data => data.doc_id === 83).map(v => v.count)[0] || 0;
            iscbpaySlipUploaded = datawithCount.filter(data => data.doc_id === 79).map(v => v.count)[0] || 0;
            iscbBankStatementUploaded = datawithCount.filter(data => data.doc_id === 80).map(v => v.count)[0] || 0;

            element["child"] = filter_doc_child_list.filter(data => data.parent_id === element.id).map(v=>{
                let docObj=Object.assign({},v);
                let is_required;
                    if(docObj.doc_section === "customer_details"){
                        is_required = v.customer_type && v.customer_type.includes(currCustomerType) ? "1" : '0';
                     //   console.log(is_required)
                        if(is_required === '1'){
    
                            if(v.id===33 && iselectricBillUploaded && !iswaterBillUploaded) is_required='0';
                            if(v.id===34){
                                is_required='1';
                                if(iswaterBillUploaded && !iselectricBillUploaded) is_required='0'
                            }
    
                            if(v.id===39 && (ismayorpermitUploaded || isbarangybusinsspermitUploaded)) is_required='0';
                            if(v.id===40 && (isstipermitUploaded || isbarangybusinsspermitUploaded)) is_required='0';
                            if(v.id===41){
                                is_required='1'; 
                                if(ismayorpermitUploaded || isstipermitUploaded) is_required='0'; 
                            }
                            
    
                            if(v.id===37 && isBankStatementUploaded && !ispaySlipUploaded) is_required='0';  
                            if(v.id===38){
                                is_required='1';
                                if(ispaySlipUploaded && !isBankStatementUploaded)  is_required='0';
                            }
                        }
                    }
                    else if(docObj.doc_section === "co_borrower_details"){
                        is_required = v.customer_type && v.customer_type.includes(currCBCustomerType) ? "1" : '0';
                        //console.log(docObj)
                        if(is_required === '1'){
    
                            if(v.id===75 && iscbelectricBillUploaded && !iscbwaterBillUploaded) is_required='0';
                            if(v.id===76){
                                is_required='1';
                                if(iscbwaterBillUploaded && !iscbelectricBillUploaded) is_required='0'
                            }
    
                            if(v.id===81 && (iscbmayorpermitUploaded || iscbbarangybusinsspermitUploaded)) is_required='0';
                            if(v.id===82 && (iscbstipermitUploaded || iscbbarangybusinsspermitUploaded)) is_required='0';
                            if(v.id===83){
                                is_required='1'; 
                                if(iscbmayorpermitUploaded || iscbstipermitUploaded) is_required='0'; 
                            }
                            
    
                            if(v.id===79 && iscbBankStatementUploaded && !iscbpaySlipUploaded) is_required='0';  
                            if(v.id===80){
                                is_required='1';
                                if(iscbpaySlipUploaded && !iscbBankStatementUploaded)  is_required='0';
                            }
                        }
                    }

                let uploaddocsCount = datawithCount.filter(data => data.doc_id === v.id).map(v => v.count)[0] || 0;
                docObj.is_required = is_required;
                docObj.count = uploaddocsCount
                return docObj;
            }) || [];
            temp.push(element);
        }
    }); 

    // comment this to show all documents for co_applicant
    // if(props.uType === 'co_applicant'){   
    //     if(props.caType === 'non_financing'){
    //         temp = temp.filter(ob=>ob.is_coapplicant === '1' && ob.is_financial === '0')
    //     }else{
    //         temp = temp.filter(ob=>ob.is_coapplicant === '1')
    //     }   

    // } 
    let doc_list_arr = temp.map((doc, index) => {
        var childCountArray = doc.child.filter(val => val.count > 0);
        var childRequiredCount = doc.child.filter(val => (val.count >= val.min_upload && val.is_required === '1') || (val.count > 0 && val.count >= val.min_upload));
        var childCountRequired = (doc.child.length) ? doc.child.filter(v => (v.is_required === '1') || (v.count > 0)) : [];

        let isUserDoc = props.leadDetail?.leaddocs.filter(ob=> ob.customer_id === props.uId && ob.parent_doc_id === doc.id); 

        let docUploadStatus = ((doc.child.length === 0 && doc.count >= doc.min_upload) || (doc.child.length > 0 && childRequiredCount.length === childCountRequired.length && childCountArray.length > 0 && isUserDoc.length > 0)
        ) ? 'done' : '';
 

        return (
            <div className="taging-list-drop-down" key={doc.id}>
                <ul>
                    <li>
                        {`${doc.doclang && doc.doclang.name} ${doc.is_required === '1' ? ' *' : ''}`}
                        {
                            (docUploadStatus === 'done') ? (<span className="stepprogress"><i className="ic-check"></i></span>) : null
                        }
                        <ul>
                            {
                                (doc.child.length) ?

                                    doc.child.map((doc, index) => {
                                        let docarray = props.leadDetail?.leaddocs.filter(ob=> ob.customer_id === props.uId && ob.doc_id == doc.id);
                                        let docNo = docarray.length
                                        return (<li key={`${doc.id}${index}`} onClick={e => tagDoc(doc)} >{`${doc.doclang.name} ${(doc.is_required === '1') ? '*' : ''}`} <span className="doc_count">{docNo} /{doc.max_upload}</span>
                                        </li>)
                                    })
                                    : <li key={`${doc.id}${index}`} onClick={e => tagDoc(doc)}>{`${doc.doclang.name} ${(doc.is_required === '1') ? '*' : ''}`}
                                        <span className="doc_count">{doc.count} /{doc.max_upload}</span>
                                    </li>
                            }
                        </ul>
                    </li>
                </ul>
            </div>
        )
    })


    let mainCatName = '', subCatName = '', doc_id = (filterData && filterData.doc_id) ? filterData.doc_id : currentFileDocId;

    let AppliedDocs = (doc_id) ? filter_doc_child_list.filter(v => v.id === doc_id) : [];

    subCatName = (AppliedDocs && AppliedDocs.length) ? AppliedDocs[0].doclang.name || "" : "";
    if (AppliedDocs && AppliedDocs.length && AppliedDocs[0].parent_id) {
        let AppliedDocsParent = filter_doc_child_list.filter(v => v.id === AppliedDocs[0].parent_id);
        mainCatName = (AppliedDocsParent && AppliedDocsParent.length) ? AppliedDocsParent[0].doclang.name || "" : "";
    }   
    return (
        <div className="tag-image-bx" key={`${filterFiles.length}${filterData.doc_id}`}>
            <div className={`${(mainCatName || subCatName) ? "image-tagning-drop-down tag-category-sub-category" : "image-tagning-drop-down mark-tag-txt"}`}>
                {
                    (isLoading) ? (<Loader />) : null
                }
                {(
                    <Dropdown>
                        <Dropdown.Toggle variant="success" id="dropdown-basic" disabled={accessCondition || (current_file && current_file.doc_id && [0, 2].includes(current_file.doc_status))}>

                            <div className="select-category">
                                {
                                    (mainCatName || subCatName) ? (<span className="tag-main-category">
                                        {(mainCatName || subCatName) ? mainCatName : ''}
                                    </span>) : "Mark as a Tag"
                                }

                                {
                                    (subCatName) ? subCatName : ""
                                }

                            </div>


                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            {
                                doc_list_arr.length ? doc_list_arr : null
                            }
                        </Dropdown.Menu>
                    </Dropdown>
                )}
            </div>            
            {
                (enableRemoveTag && (current_file && (([0, 2].includes(current_file.doc_status) === false)))) ? (<div className="remove-tag-txt" onClick={removeTag}>
                    Remove Tag
                </div>) : null
            }
            <div className="doument-view-list-gallery">
                {
                    filterFiles && filterFiles.length ?
                        <div className="verfication-img">
                            <GallaryCarousel isLoading={isLoading} slides={filterFiles} document_list={filter_doc_list} getFileId={getFileId} filter_doc_child_list={filter_doc_child_list} />
                        </div>
                        :
                        <div className="noImgTagging">
                            <div className="verfication-img-no-image">
                                <img src={noImage} className="" />
                                <span className="no-img-txt">No Document Attached</span>
                            </div>
                        </div>
                }
                <div className="image-opt doc-image-opt">                    
                    {(!["pre-disbursal","car_transfer","post_disbursal"].includes(props.activeTab)) && (
                        <div className="btn-submission-approved">
                            {enableRemoveTag && (
                                <>
                                    {current_file && current_file.doc_status === '2' ? <><button className="btn-line btn-approve"><i className="ic-check"></i>Approved</button></>                                            
                                      : (![0,2].includes(+current_file?.doc_status) ) ? 
                                        <button className="btn-line" onClick={markAsApproved}>Mark as Approved</button> : null
                                    }
                                </>
                            )}                            
                            {current_file?.doc_id ? <button className="btn-line"  onClick={showDocumentHistory}>View History</button>:""}
                            {(![0,2].includes(+current_file?.doc_status) && current_file?.doc_id) ? <button className="btn-primary-outline" onClick={() => showResubmission("resubmission")}>Mark As Reject</button> : null} 
                        </div>
                    )}
                </div>
                <div className="view-timeline-popup image-timeline-popup doc-history-timeline">
                    <DocumentHistory documentHistoryList={documentHistoryList} documentHistoryModelShowFlag={showDocumentHistoryModal} hideModalDocumentHistory={hideModalDocumentHistory} />
                </div>
                <ReSubmissionDocument leadId={leadDetail.id} documentId={currentFileDocId} documentParentId={currentFileParentId} filterData={filterData} actionType={docActionType} showResubmissionModal={showResubmisisonModal} hideModalReSubmission={hideResubmissionModal} requestResubmission={manageDocAction} user_id={props.user_id} uType={props.uType} uId={props.uId} />
            </div>

        </div>
    )
}
export default UploadedTagDocs;