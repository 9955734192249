import React, {useState, useEffect} from 'react';
import { models } from 'powerbi-client';
import { PowerBIEmbed } from 'powerbi-client-react';
import { useParams } from "react-router-dom";
import GeneralService from './../../services/generalService';
import { toast } from 'react-toastify';

const PowerBiReportView  = () => {
    const {clicked_index} = useParams();
    const [reportConfig, setReportConfig] = useState({
      type: 'report',
      embedUrl: '',
      accessToken: '',
      id: '',
      tokenType: models.TokenType.Embed,
      settings: {
        panes: {
          filters: {
            expanded: false,
            visible: false,
            bars: {
              statusBar:{ 
                visible: true 
              }
            }
          }
        },
        background: models.BackgroundType.Transparent,
      }
    });

    useEffect( () => {
      getPowerBiData();
    }, []);

   const getPowerBiData = async ()=>{
      let user_info = JSON.parse(localStorage.getItem("user_information"));
      let userReportAccess = JSON.parse(user_info.report_access);
      let powerBiInfo      = userReportAccess.report_access[clicked_index];
      const {report_id, workspace_id} = powerBiInfo;
      let formData = {'report_id': report_id, 'workspace_id':workspace_id}
      let powerBIRes = await GeneralService.getPowerBiAccess(formData, { 'Content-Type': 'application/json' });
      if(powerBIRes && powerBIRes.status && powerBIRes.status == '200'){
        setReportConfig({
          ...reportConfig,
          embedUrl: powerBIRes.data.data.embedUrl[0].embedUrl,
          accessToken: powerBIRes.data.data.accessToken,
          id: powerBIRes.data.data.embedUrl[0].reportId
        });
      }else{
        toast.error("You don't have PowerBI access.");
      }
    };

   // console.log('reportConfig====',reportConfig)
    return (<>
      <div>
        <PowerBIEmbed
          embedConfig = {reportConfig}
          cssClassName = 'power-bi-report-class'
        />
      </div>
    </>)
  }

export default PowerBiReportView;
