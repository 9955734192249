import { gql } from '@apollo/client';

export const SAVE_CUSTOMER_DETAILS = (user_id) => gql`
mutation save_additional_info($LeadAdditionalInput: LeadAdditionalInput!) {
    save_additional_info(additionalInput: $LeadAdditionalInput, api_called_by: web, user_id: ${user_id}) {
      co_applicant{
            ca_first_name
            ca_last_name
            ca_gender
            ca_dob
            ca_mobile
            ca_email
            ca_aadhar_no
            ca_pancard_no
            ca_cra_city
            ca_cra_address1
            ca_cra_address2
            ca_cra_state
            ca_no_of_emi
            ca_marital_status
            ca_mother_name
            ca_qualification
        }
        customer{
            father_name
            last_name
            gender
            dob
            mobile
            email
            pancard_no
            aadhar_no
            marital_status
            cra_address1
            cra_address2
            cra_city
            cra_pincode
            cra_state
            mother_name
            qualification
        }
        lead_details{
            loan_amount
            bank_id
            tenure
            annual_income
            profession
            salary_credit_mode
            company_name
            existing_emi
            no_of_emi
            existing_emi_amount
        }
    }
  }
`;


export const SAVE_LOAN_DETAILS = (user_id) => gql`
mutation save_additional_info($LeadAdditionalInput: LeadAdditionalInput!) {
    save_additional_info(additionalInput: $LeadAdditionalInput, api_called_by: web, user_id: ${user_id}) {
     
        lead_details{
            property_value
            property_type
            usage_type
            agreement_type 
            loan_amount 
            bank_id
        }
    }
  }
`;
export const SAVE_CUST_DETAILS = (user_id) => gql`
mutation save_additional_info($LeadAdditionalInput: LeadAdditionalInput!) {
    save_additional_info(additionalInput: $LeadAdditionalInput, api_called_by: web, user_id: ${user_id}) {
     
        lead_details{
            property_value
            property_type
            usage_type
            agreement_type 
            loan_amount 
            bank_id
        }
        co_applicant{
          id
          ca_first_name
        }
    }
  }
`;

export const SAVE_BANK_OFFER = (user_id )=> gql`
mutation save_additional_info($LeadAdditionalInput: LeadAdditionalInput!) {
    save_additional_info(additionalInput: $LeadAdditionalInput, api_called_by: web, user_id: ${user_id}) {
        
        customer{
            first_name
            last_name
            mobile
        }
        lead_details{
            loan_amount 
            company_type
            profession
            monthly_salary
            cibil_score
        }
    }
  }
`;

export const SAVE_NEW_DETAILS = gql`
mutation save_additional_info($LeadAdditionalInput: LeadAdditionalInput!) {
    save_additional_info(additionalInput: $LeadAdditionalInput, api_called_by: web) {
        customer{
            father_name
            last_name
            mobile
            email 
        }
    }
  }
`;

export const SAVE_DOC_REQUEST = gql`
  mutation save_doc_request($saveDocRequest: DocRequestInput!) {
    save_doc_request(saveDocRequest: $saveDocRequest)
  }
`;

export const SAVE_DOC_RECOMMENDED_LIST = gql`
  mutation save_doc_recommended_list($saveDocRecommendedList: DocRecommendedInputList!) {
    save_doc_recommended_list(saveDocRecommendedList: $saveDocRecommendedList)
  }
`;

export const UPDATE_DOC_REQUEST = gql`
  mutation update_doc_request(
    $updateDocRequest: DocRequestInput!
    $docReqId: Int!
  ) {
    update_doc_request(updateDocRequest: $updateDocRequest, docReqId: $docReqId)
  }
`;

export const GET_DOC_REQUEST_LIST = (lead_id, customerType) => { 
    return gql`
    query{
        get_doc_request_list(lead_id:${lead_id}, customer_type:"${customerType}")
        {
            id
            lead_id
            doc_id
            parent_doc_id
            is_doc_uploaded
            doc_upload_url
            customer_type
            customer_id
            status
        }
    }`
}

export const SEND_NOTIFICATION_TO_CUSTOMER = (customerName, mobile, url, lead_id) => { 
    return gql`
    query{
        send_notification_to_customer(customer_name: "${customerName}", mobile: "${mobile}", url: "${url}", lead_id: ${lead_id})
    }`
}

export const GET_DETAILS_BY_PANCARD = (panCard) => gql`
  query {
    get_details_by_pancard(pan_card: "${panCard}")
  }
`;
export const GET_DETAILS_BY_PANCARD_UPDATE = (panCard,customer_type,customer_id) => gql`
  query {
    get_details_by_pancard(pan_card: "${panCard}",customer_type:"${customer_type}",customer_id:${customer_id})
  }
`;

export const GENERATE_CIBIL_PDF = (panCard,lead_id) => gql`
  query {
    generate_cibil_report(pan_card: "${panCard}",lead_id:${lead_id})
  }
`;

export const CHECK_CIBIL_CONSIGNMENT = gql`
mutation check_cibil_consignment($checkConsignmentInput: CheckConsignmentInput!,$user_id: Float!, $lead_id: Float!) {
    check_cibil_consignment(checkConsignmentInput: $checkConsignmentInput, user_id: $user_id,lead_id: $lead_id)
  }
`;

export const GET_CIBIL_DETAILS = gql`
mutation get_cibil_details($cibilDetailsInput: CibilDetailsInput!,$type: String!, $id: Float!) {
    get_cibil_details(cibilDetailsInput: $cibilDetailsInput,type:$type,id:$id)
  }
`;

export const SEND_CONSIGNMENT_LINK =(user_id, lead_id) => gql`
mutation send_consignment_link($sendConsignmentLinkInput: CheckConsignmentInput!) {
    send_consignment_link(sendConsignmentLinkInput: $sendConsignmentLinkInput, user_id: ${user_id},lead_id: ${lead_id})
  }
`;

export const GET_CIBIL_INFO = (pan_card,report_type, mobile = null, partner_id = null, lead_id = null, user_id = null) => gql`
  query {
    cibil_info(pan_card: "${pan_card}",report_type:"${report_type}",mobile:"${mobile}",partner_id:"${partner_id}",lead_id:"${lead_id}",user_id:"${user_id}")
  }
`;

export const CRIF_CONSENT_INITIATE =() => gql`
mutation cibil_crif_consent_initiate($cibilCrifInput: CibilCrifInitiateInput!) {
    cibil_crif_consent_initiate(cibilCrifInput: $cibilCrifInput)
  }
`;

export const CRIF_CONSENT_VERIFY =() => gql`
mutation cibil_crif_consent_verify($cibilCrifInput: CibilCrifVerifyInput!) {
    cibil_crif_consent_verify(cibilCrifInput: $cibilCrifInput)
  }
`;

export const CRIF_REPORT_DOWNLOAD =() => gql`
mutation cibil_crif_report_download($crifInput: CrifDownloadReportInput!) {
    cibil_crif_report_download(crifInput: $crifInput)
  }
`;

export const GET_LAST_CIBIL_INFO = (pan_card) => gql`
  query {
    last_cibil_info(pan_card: "${pan_card}")
  }
`; 

export const SAVE_BRE_OFFER = (user_id) => gql`
mutation save_additional_info($LeadAdditionalInput: LeadAdditionalInput!) {
    save_additional_info(additionalInput: $LeadAdditionalInput, api_called_by: web, user_id: ${user_id}) {
        
        customer{
            first_name
            last_name
            mobile
        }
        lead_details{
            loan_amount 
            company_type
            profession
            monthly_salary
            cibil_score
        }
    }
  }
`;

export const SAVE_REPORT_ACCESS = gql`
mutation SaveReportAccess($userId: Float!, $reportId: Float!, $reportName: String!) {
  save_report_access(details: { user_id: $userId, report_id: $reportId, report_name: $reportName }) {
    user_id
    report_id
    report_name
  }
}
`;

export const DELETE_COAPPLICANT = (id) => gql`
  mutation{
   delete_coapplicant(id:${id} , api_called_by: web){ 
    id
  }
}`
