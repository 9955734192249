import Formsy from "formsy-react";
import React, { useEffect, useMemo, useState } from "react";
import FormFields from "./formFields/bankPOCFormFields.json";
import FormsySelect from "../elements/FormsySelect";
import { useDispatch, useSelector } from "react-redux";
import { getMasterCityState } from "../../store/action/allAction";
import FormsyInputField from "../elements/FormsyInputField";
import {
  SAVE_BANK_POC_MAPPING,
  SAVE_BANK_RECORD,
} from "../../services/bank.gql";
import { toast } from "react-toastify";
import { executeGraphQLMutation } from "../../common/executeGraphQLQuery";
import { useApolloClient } from "@apollo/client";

const NEW_POC_FORM_FIELDS = FormFields["NEW_POC"];
const ESCALATION_MATRIX = [
  { id: "L1", label: "L1", value: "L1" },
  { id: "L2", label: "L2", value: "L2" },
  { id: "L3", label: "L3", value: "L3" },
];

const NewBankerPOC = ({ bankNameId = 0 }) => {
  const client = useApolloClient();
  const [newBankPOCData, setNewBankPOCData] = useState({});

  const dispatch = useDispatch();
  const { masterdata, lead } = useSelector(({ masterdata, lead }) => ({
    masterdata,
    lead,
  }));

  useEffect(() => {
    if (!masterdata.data || !masterdata.data.state_list) {
      dispatch(getMasterCityState());
    }
  }, []);

  useEffect(() => {
    if (bankNameId) {
      ALL_OPTIONS["filtered_bank_list"] = ALL_OPTIONS["bank_list"]?.filter(
        (obj) => obj.id === bankNameId
      );
    }
  }, [bankNameId]);

  let ALL_OPTIONS = useMemo(() => {
    let options = masterdata ? { ...masterdata.data } : {};
    options["escalation_matrix"] = ESCALATION_MATRIX;
    return options;
  }, [masterdata]);

  const handleInputChange = (event) => {
    const { id, value } = event.target;
    setNewBankPOCData((currentValue) => ({
      ...currentValue,
      [id]: value,
    }));
  };

  const handleSelectChange = (data, field_name) => {
    if (data && field_name) {
      setNewBankPOCData((currentValue) => ({
        ...currentValue,
        [field_name]: data.value,
      }));
    }
  };

  const handleSubmit = async () => {
    try {
      const {
        bank_name,
        bank_poc_name,
        email,
        phone_number,
        escalation_matrix,
        region,
      } = newBankPOCData;

      const bankName =
        ALL_OPTIONS["bank_list"]?.find((obj) => obj.id === bank_name)?.label ||
        "";
      const branchName =
        ALL_OPTIONS["state_list"]?.find((obj) => obj.id === region)?.label ||
        "";

      const variables = {
        createBankerRecordInput: {
          name: bank_poc_name,
          email,
          phone: phone_number,
          bank: bankName,
          bank_id: bank_name,
          branch: branchName,
          escalation_matrix,
        },
      };
      const bank_variables = {
        bank_mapping_list: {},
      };

      const response = await executeGraphQLMutation(
        SAVE_BANK_RECORD,
        variables,
        client
      );
      if (response.data?.save_banker_records?.id) {
        const banker_id = response.data?.save_banker_records?.id;
        const lead_id = lead?.leadDetail?.id;
        bank_variables.bank_mapping_list["bank_mapping_list"] = [
          {
            banker_id,
            lead_id,
            status: "1",
          },
        ];
        await executeGraphQLMutation(
          SAVE_BANK_POC_MAPPING,
          bank_variables,
          client
        );
        toast.success("Success");
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        toast.error(response.data?.save_banker_records);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <>
      <div className="modal-header">
        <h2 style={{fontSize:16,color:"#000"}}>Create new POC</h2>
      </div>

      <div className="modal-body">
        <Formsy
          className="lead-form-filed"
          autoComplete="off"
          onValid={() => null}
          onInvalid={() => null}
          onValidSubmit={handleSubmit}
        >
          <div className="row">
            {NEW_POC_FORM_FIELDS.map((field, index) =>
              ["text", "number"].includes(field.type) ? (
                <fieldset
                  className="form-filed col-md-6 "
                  style={{ marginBottom: "20px" }}
                  key={index}
                >
                  <FormsyInputField
                    id={field.id}
                    name={field.name}
                    type={field.type}
                    value={(newBankPOCData && newBankPOCData[field.name]) || ""}
                    maxLength={field.maxLength}
                    label={field.label}
                    onChange={handleInputChange}
                    format={field.ApplyNumberformat ? field.format : ""}
                    validations={null}
                    validationError={""}
                    readOnly={field.readOnly}
                    required={field.required}
                  />
                </fieldset>
              ) : field.type === "dropdown" ? (
                <fieldset className="single-select col-md-6" key={index}>
                  <FormsySelect
                    name={field.name}
                    id={field.id}
                    inputProps={{
                      options:
                        field.name === "bank_name"
                          ? ALL_OPTIONS["filtered_bank_list"] ||
                            ALL_OPTIONS[field.optionsKey]
                          : ALL_OPTIONS[field.optionsKey],
                      placeholder: field.label,
                      className: "react-select",
                      classNamePrefix: "react-select",
                      value:
                        ALL_OPTIONS &&
                        ALL_OPTIONS[field.optionsKey] &&
                        ALL_OPTIONS[field.optionsKey].filter(
                          ({ value }) => value == newBankPOCData[field.name]
                        ),
                      isDisabled: field.readOnly,
                    }}
                    required={field.required}
                    value={newBankPOCData && newBankPOCData[field.name]}
                    onChange={(data) => handleSelectChange(data, field.name)}
                  />
                </fieldset>
              ) : null
            )}
          </div>

          <div className="btn-save-remarks" style={{ textAlign: "center" }}>
            <button
              type="submit"
              className="btn-primary"
              style={{ marginTop: "50px" }}
            >
              Add new
            </button>
          </div>
        </Formsy>
      </div>
    </>
  );
};

export default NewBankerPOC;
