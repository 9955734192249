import { AMOUNT_TYPE_OPTIONS } from "../../../config/constants";
import FormFields from "../formFields/breOfferFormFields.json";
import FormsyInputField from "../../elements/FormsyInputField";
import React, { useState, useMemo, useEffect, useRef } from "react";
import BreCoApplicant from "./BreCoApplicant";
import { useDispatch, useSelector } from "react-redux";
import Formsy from "formsy-react";
import { SAVE_CUST_DETAILS } from "../../../services/customer.gql";
import { executeGraphQLMutation } from "../../../common/executeGraphQLQuery";
import { useApolloClient } from "@apollo/client";
import { toast } from "react-toastify";
import AdditionalIncome from "../../elements/AdditionalIncome";
import {
  SAVE_OFFER_BANK_DETAILS,
  SAVE_OFFER_ITR_DETAILS,
} from "../../../services/bre.gql";
import BreObligations from "./BreObligations";
import { getLoanDetail } from "../../../store/action/allAction";

const MONTHY_INCOME_FIELDS = FormFields["MONTHLY_INCOME"];

const BreIncome = ({ setBreIncomeDetails, setNextTabActive }) => {
  const client = useApolloClient();
  const dispatch = useDispatch();
  const radioRef = useRef(null);
  const caRadioRef = useRef(null);
  const [additionalIncomeFormFields, setAdditionalIncomeFormFields] = useState(
    []
  );
  const [hasAdditionalIncome, setHasAdditionalIncome] = useState(false);
  const [additionalIncomeCount, setAdditionalIncomeCount] = useState(1);
  const [ShowCoApplicant, setShowCoApplicant] = useState(false);
  const [breIncome, setBreIncome] = useState({});
  const [isFormTouch, setTouch] = useState({});
  const user_information = localStorage.getItem("user_information");
  let authUser = user_information && JSON.parse(user_information);
  let user_id = authUser?.id || 1;

  const { masterdata, lead } = useSelector(({ masterdata, lead }) => ({
    masterdata,
    lead,
  }));

  let ALL_OPTIONS = useMemo(() => {
    const options = masterdata ? { ...masterdata.data } : {};
    const LIQUID_INCOME_ID = 6;
    options["amount_type"] = AMOUNT_TYPE_OPTIONS;
    options["additional_income_type"] =
      options["additional_income_type"]
        ?.map((obj) => ({
          ...obj,
          value: obj.id,
        }))
        ?.filter((obj) => obj.value != LIQUID_INCOME_ID) || [];
    options["filtered_additional_income_type_0"] = options[
      "additional_income_type"
    ]?.map((ob) => ({
      ...ob,
      value: +ob.id,
    }));
    return options;
  }, [masterdata]);

  const handleInputChange = (event) => {
    if (event.target) {
      const { id, value } = event.target;
      setBreIncome((currentValue) => ({
        ...currentValue,
        [id]: value,
      }));
      setBreIncomeDetails(id, value);
    }
  };

  const handlePatternFormatChange = (data, field_name) => {
    if (data.value && field_name) {
      setTouch({
        ...isFormTouch,
        [field_name]: true,
      });
      setBreIncome((currentValue) => ({
        ...currentValue,
        [field_name]: data.value,
      }));
      setBreIncomeDetails(field_name, data.value);
    }
  };

  useEffect(() => {
    if (lead && lead.leadDetail && lead.leadDetail.lead_details) {
      const salary_details = {
        monthly_salary: lead.leadDetail.lead_details.monthly_salary,
        gross_monthly_salary: lead.leadDetail.lead_details.gross_monthly_salary,
        bureau_score: lead.leadDetail.lead_details.cibil_score,
        additional_income: lead.leadDetail?.customer.additional_income,
        is_additional_income: lead.leadDetail?.customer.is_additional_income,
        existing_emi_amount: lead.leadDetail?.customer.existing_emi_amount,
        emi_ending_six_month: lead.leadDetail?.customer.emi_ending_six_month,
        is_obligation: lead.leadDetail?.customer.is_obligation,
        co_applicant: lead.leadDetail.co_applicant || [],
        is_co_applicant: lead.leadDetail.co_applicant.length > 0 ? "yes" : "no",
      };

      if (salary_details.is_additional_income === "yes") {
        setHasAdditionalIncome(true);
        for (let i = 0; i < salary_details.additional_income.length; i++) {
          handleAddAdditionalIncome(i);
          ALL_OPTIONS[`filtered_additional_income_type_${i + 1}`] = ALL_OPTIONS[
            `filtered_additional_income_type_${i}`
          ]?.filter(
            (obj) =>
              obj.value !== salary_details.additional_income[i].income_type_id
          );
        }
      }

      if (salary_details.co_applicant.length > 0) {
        setShowCoApplicant(true);
      }
      setBreIncome({...salary_details});
      delete salary_details.co_applicant
      setBreIncomeDetails(null, null, salary_details);
    }
  }, [lead]);

  const handleRadioChange = (event) => {
    const { id, name } = event.target;
    if (id === "yes" && name === "is_additional_income") {
      setHasAdditionalIncome(true);
      handleAddAdditionalIncome(0);
    } else if (id === "no" && name === "is_additional_income") {
      setHasAdditionalIncome(false);
      setAdditionalIncomeCount(0);
      setAdditionalIncomeFormFields([]);
    }

    // add slight delay to state updates and component re-renders
    setTimeout(() => {
      setBreIncome((current) => ({
        ...current,
        [name]: id,
      }));
      setBreIncomeDetails(name, id);
    }, 0);
  };

  const handleAddAdditionalIncome = (index) => {
    if (index < 5) {
      const isExisting = additionalIncomeFormFields.filter(
        (obj) => obj.id === `additional_income_type_${index}`
      );
      if (
        isExisting.length === 0 ||
        additionalIncomeFormFields[additionalIncomeFormFields.length - 1]
          .canAddMore
      ) {
        const dropDownObj = { id: `additional_income_type_${index}` };
        setAdditionalIncomeCount(index + 1);
        setAdditionalIncomeFormFields((current) => [...current, dropDownObj]);
      }
    }
  };

  const handleCoApplicantChange = (event) => {
    const { id, name } = event.target;
    if (id === "yes") {
      setShowCoApplicant(true);
    } else {
      setShowCoApplicant(false);
    }

    // add slight delay to state updates and component re-renders
    setTimeout(() => {
      setBreIncome((current) => ({
        ...current,
        [name]: id,
      }));
      setBreIncomeDetails(name, id);
    }, 0);
  };

  const handleCoApplicantDetails = (coApplicants) => {
    setBreIncome((current) => ({
      ...current,
      co_applicant: coApplicants,
    }));
    setBreIncomeDetails("co_applicant", coApplicants);
  };

  const isNotEmpty = (value) => value !== null && value !== "";

  const handleSaveIncomeDetails = async () => {
    try {
      setBreIncomeDetails(null, null, breIncome);
      let co_applicant = [],
        ca_income_calculation_mode = "";
      const itrDetails = [],
        bankDetails = [];
      const isCustAddInc = breIncome["is_additional_income"] || "no";
      const isCoApplicant = breIncome["is_co_applicant"] || "no";
      const isObligation = breIncome["is_obligation"] || "no";
      const lead_id = lead?.leadDetail?.id || "";
      const customer_id = lead?.leadDetail?.customer.customer_id;
      const customer = {
        is_additional_income: isCustAddInc,
        first_name: lead?.leadDetail?.customer.first_name,
        last_name: lead?.leadDetail?.customer.last_name,
        mobile: lead?.leadDetail?.customer.mobile,
        is_obligation: isObligation,
        ...(isObligation === "yes"
          ? {
              existing_emi_amount: breIncome["existing_emi_amount"],
              emi_ending_six_month: breIncome["emi_ending_six_month"],
            }
          : {}),
      };

      if (isCustAddInc === "yes") {
        const additionalIncome = breIncome.additional_income?.map((income) => {
          return {
            ...(income.id ? { id: income?.id } : {}),
            lead_id,
            customer_id,
            customer_type: "customer",
            income_type_id: income.income_type_id,
            amount_type: income.amount_type,
            amount: income.amount,
            status: income.status,
          };
        });
        customer["additional_income"] = additionalIncome;
      }

      if (isCustAddInc === "no") {
        const obj = {
          lead_id,
          customer_id,
          customer_type: "customer",
        };
        customer["additional_income"] = [obj];
      }

      if (isCoApplicant === "yes") {
        const filteredCoApp =
          breIncome["co_applicant"]?.filter((coApp) => {
            if (
              isNotEmpty(coApp.ca_first_name) &&
              isNotEmpty(coApp.ca_profession) ||
              (isNotEmpty(coApp.ca_monthly_salary) ||
                isNotEmpty(coApp.ca_gross_monthly_salary))
            ) {
              return { ...coApp };
            }
          }) || [];
        co_applicant = filteredCoApp?.map((coApp) => {
          let ca_additionalIncome = [];
          if (coApp["ca_is_additional_income"] === "yes") {
            ca_additionalIncome = coApp.ca_additional_income?.map((income) => {
              return {
                ...(income.id ? { id: income?.id } : {}),
                lead_id,
                customer_id: coApp.id,
                customer_type: "co_applicant",
                income_type_id: income.ca_income_type_id,
                amount_type: income.ca_amount_type,
                amount: income.ca_amount,
              };
            });
          }

          if (+coApp.ca_profession === 3) {
            if (coApp.ca_income_calculation_mode === "itr") {
              ca_income_calculation_mode = "itr";
              if (coApp?.year_1) {
                itrDetails.push({
                  ...(coApp?.year_1?.id ? { id: coApp.year_1?.id } : {}),
                  lead_id,
                  is_itr_filled: coApp?.is_coapplicant_file_itr,
                  npat: coApp?.year_1.npat || "",
                  depreciation: coApp?.year_1.depreciation || "",
                  interest: coApp?.year_1.interest || "",
                  tax_paid: coApp?.year_1.tax_paid || "",
                  itr_year: "1",
                  customer_id: coApp?.id,
                  customer_type: "co_applicant",
                });
              }
              if (coApp?.year_2) {
                itrDetails.push({
                  ...(coApp?.year_2?.id ? { id: coApp.year_2?.id } : {}),
                  lead_id,
                  is_itr_filled: coApp?.is_coapplicant_file_itr,
                  npat: coApp?.year_2.npat || "",
                  depreciation: coApp?.year_2.depreciation || "",
                  interest: coApp?.year_2.interest || "",
                  tax_paid: coApp?.year_2.tax_paid || "",
                  itr_year: "1",
                  customer_id: coApp?.id,
                  customer_type: "co_applicant",
                });
              }
            }
            if (coApp.ca_income_calculation_mode === "bank") {
              for (let i = 0; i < coApp.no_of_accounts; i++) {
                bankDetails.push({
                  ...(coApp[`bank_details_id_${i}`]
                    ? { id: coApp[`bank_details_id_${i}`] }
                    : {}),
                  lead_id,
                  account_type: coApp[`bank_account_type_${i}`] || "",
                  amount: coApp[`bank_account_avg_amount_${i}`] || "",
                  customer_id: coApp?.id,
                  customer_type: "co_applicant",
                });
              }
            }
          }

          return {
            id: coApp.id,
            ca_profession: +coApp.ca_profession,
            ca_annual_income: coApp.ca_annual_income,
            ca_gross_monthly_salary: coApp.ca_gross_monthly_salary,
            ca_monthly_salary: coApp.ca_monthly_salary,
            ca_first_name: coApp.ca_first_name,
            ca_is_additional_income: coApp?.ca_is_additional_income || "no",
            ca_additional_income: ca_additionalIncome,
            ca_offer_type: coApp?.ca_income_calculation_mode || "",
            ca_existing_emi_amount: coApp?.ca_existing_emi_amount || null,
            ca_emi_ending_six_month: coApp?.ca_emi_ending_six_month || null,
            ca_is_obligation: coApp?.ca_is_obligation || "",
            ca_no_of_accounts: coApp?.no_of_accounts || null,
          };
        });
      }

      const lead_details = {
        gross_monthly_salary: breIncome.gross_monthly_salary,
        monthly_salary: breIncome.monthly_salary,
        cibil_score: breIncome.bureau_score,
      };

      const variables = {
        api_called_by: "web",
        LeadAdditionalInput: {
          lead_id: lead_id,
          lead_details,
          co_applicant,
          customer,
        },
      };

      const resp = await executeGraphQLMutation(
        SAVE_CUST_DETAILS(user_id),
        variables,
        client
      );
      let response = resp?.data?.save_additional_info || [];
      let finalItr = [],
        finalBankInc = [];

      for (let i = 0; i < response[0]?.co_applicant?.length; i++) {
        const coApp = response[0].co_applicant[i];
        finalItr = itrDetails?.map((obj) => {
          if (obj.customer_type === "co_applicant") {
            return {
              ...obj,
              ...(obj.customer_id ? {} : {customer_id: coApp?.id}),
            };
          } else {
            return obj;
          }
        });
        finalBankInc = bankDetails?.map((obj) => {
          if (obj.customer_type === "co_applicant") {
            return {
              ...obj,
              ...(obj.customer_id ? {} : {customer_id: coApp?.id}),
            };
          } else {
            return obj;
          }
        });
      }

      if (itrDetails.length > 0) {
        const itrVariable = {
          offerItrDetails: { offer_itr_details: finalItr },
        };
        await executeGraphQLMutation(
          SAVE_OFFER_ITR_DETAILS,
          itrVariable,
          client
        );
      }

      if (bankDetails.length > 0) {
        const bankVariable = {
          offerBankDetails: { offer_bank_details: finalBankInc },
        };

        await executeGraphQLMutation(
          SAVE_OFFER_BANK_DETAILS,
          bankVariable,
          client
        );
      }

      dispatch(getLoanDetail(lead_id, client));
      toast.success("Success");
      setNextTabActive();
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleAdditionalIncomeType = (data, index) => {
    if (data) {
      ALL_OPTIONS[`filtered_additional_income_type_${index + 1}`] = ALL_OPTIONS[
        `filtered_additional_income_type_${index}`
      ].filter((obj) => obj.value !== data.value);
    }
  };

  const handleSetAdditionalIncomeData = (data) => {
    setBreIncome((currentValue) => ({
      ...currentValue,
      additional_income: data,
    }));
    setBreIncomeDetails("additional_income", data);
  };

  const handleRemoveAdditionalIncome = (index) => {
    const existingAddIncomeField = [...additionalIncomeFormFields];
    const existingAddIncome = [...breIncome["additional_income"]];
    const removedEle = {
      ...existingAddIncome[index],
      status: "0",
      income_type_id: null,
      amount_type: "",
      amount: "",
    };
    existingAddIncomeField.splice(index, 1);
    if (existingAddIncomeField.length > 0) {
      existingAddIncomeField[
        existingAddIncomeField.length - 1
      ].canAddMore = true;
    }
    existingAddIncome.splice(index, 1);
    existingAddIncome.push(removedEle);
    setAdditionalIncomeCount(additionalIncomeCount - 1);
    setAdditionalIncomeFormFields(existingAddIncomeField);
    setBreIncome((currentValue) => ({
      ...currentValue,
      additional_income: existingAddIncome,
    }));
    setBreIncomeDetails("additional_income", existingAddIncome);
  };

  const handleSetObligationData = (key, value, obj) => {
    if (!(key === null) || !(obj === null)) {
      setBreIncome((current) => ({
        ...current,
        ...(obj ? { ...obj } : { [key]: value }),
      }));
      if (obj) {
        setBreIncomeDetails(null, null, obj);
      } else {
        setBreIncomeDetails(key, value);
      }
    }
  };

  return (
    <>
      <div>
        <Formsy
          className="lead-form-filed"
          autoComplete="off"
          onValid={() => null}
          onInvalid={() => null}
        >
          <div className="row">
            {MONTHY_INCOME_FIELDS.map((field, index) =>
              ["number-format", "number"].includes(field.type) ? (
                <fieldset className={"form-filed col-md-6"} key={index}>
                  <FormsyInputField
                    id={field.id}
                    name={field.name}
                    type={field.type}
                    value={(breIncome && breIncome[field.name]) || ""}
                    placeholder=" "
                    thousandsGroupStyle="lakh"
                    thousandSeparator={","}
                    decimalSeparator={"."}
                    maxLength={field.maxLength}
                    label={field.label}
                    format={field.ApplyNumberformat ? field.format : ""}
                    validations={
                      isFormTouch[field.name] ? field.validations : null
                    }
                    validationError={
                      breIncome[field.name] || breIncome[field.name] == ""
                        ? isFormTouch[field.name]
                          ? field.validationError
                          : ""
                        : ""
                    }
                    readOnly={field.readOnly}
                    onChange={
                      ["number-format"].includes(field.type)
                        ? (data) => handlePatternFormatChange(data, field.name)
                        : handleInputChange
                    }
                  />
                </fieldset>
              ) : null
            )}
          </div>
        </Formsy>

        <h4 className="additionalincome">
          Does the customer have any obligation?
        </h4>
        <div className="propertyflex">
          <input
            type="radio"
            id="yes"
            name="is_obligation"
            onChange={handleRadioChange}
            checked={breIncome["is_obligation"] === "yes" ? true : false}
          />
          <label htmlFor="yes">Yes</label>

          <input
            type="radio"
            id="no"
            name="is_obligation"
            onChange={handleRadioChange}
            checked={breIncome["is_obligation"] === "no" ? true : false}
          />
          <label htmlFor="no">No</label>
        </div>

        {breIncome["is_obligation"] === "yes" && (
          <BreObligations
            setObligationDetails={handleSetObligationData}
            customerType={"customer"}
          />
        )}

        <h4 className="additionalincome">
          Does the customer have any additional Income?
        </h4>
        <div className="propertyflex">
          <input
            type="radio"
            id="yes"
            name="is_additional_income"
            onChange={handleRadioChange}
            ref={radioRef}
            checked={breIncome["is_additional_income"] === "yes" ? true : false}
          />
          <label htmlFor="yes">Yes</label>
          <input
            type="radio"
            id="no"
            label="No"
            name="is_additional_income"
            onChange={handleRadioChange}
            checked={breIncome["is_additional_income"] === "no" ? true : false}
          />
          <label htmlFor="no">No</label>
        </div>

        {hasAdditionalIncome && (
          <>
            {additionalIncomeFormFields.map((data, index) => (
              <AdditionalIncome
                index={index}
                ALL_OPTIONS={ALL_OPTIONS}
                setAdditionalIncomeType={handleAdditionalIncomeType}
                removeAdditionalIncome={handleRemoveAdditionalIncome}
                setAdditionalIncomeData={handleSetAdditionalIncomeData}
                additionalIncomeData={breIncome["additional_income"]}
                key={index}
              />
            ))}

            {additionalIncomeCount < 5 && (
              <div
                className="additionalincome"
                onClick={() => handleAddAdditionalIncome(additionalIncomeCount)}
              >
                <span>+</span>
                <div>Additional Income</div>
              </div>
            )}
          </>
        )}
      </div>

      <h4 className="additionalincome">
        Does the customer have any co-applicants
      </h4>
      <div className="propertyflex">
        <input
          type="radio"
          id="yes"
          name="is_co_applicant"
          onChange={handleCoApplicantChange}
          ref={caRadioRef}
          checked={breIncome["is_co_applicant"] === "yes" ? true : false}
        />
        <label htmlFor="yes">Yes</label>

        <input
          type="radio"
          id="no"
          name="is_co_applicant"
          onChange={handleCoApplicantChange}
          checked={breIncome["is_co_applicant"] === "no" ? true : false}
        />
        <label htmlFor="no">No</label>
      </div>

      {ShowCoApplicant && (
        <>
          <BreCoApplicant
            setCoApplicantIncomeDetails={handleCoApplicantDetails}
          />
        </>
      )}

      <div className="btn-save-remarks">
        <button
          type="submit"
          className="btn-primary"
          onClick={handleSaveIncomeDetails}
        >
          Save & next
        </button>
      </div>
    </>
  );
};

export default BreIncome;
