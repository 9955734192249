import React, { useEffect, useState ,Redirect } from 'react'
import { useHistory, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { connect } from 'react-redux';
import secureStorage from './../../config/encrypt';
import { Navigate, useParams } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import InputField from "../elements/InputField";
import { FINEX_MASTER_LIST , PAYOUT_POINT_DETAILS} from "../../services/scheme.gql";
import { executeGraphQLMutation, executeGraphQLQuery } from "../../common/partnerExecuteGraphQLQuery";
import { toast } from 'react-toastify';
import { SAVE_PAYOUT_POINT, DETAILS_PAYOUT_POINT } from '../../services/payout.gql';
import DateFormate from 'dateformat';
import { Placeholder } from 'react-bootstrap';
const AddPayoutPoint = (props) => {
    // const History = useHistory()
    const param = useParams()
    const id = param.id ? param.id : null;   
    const [formData, setFormData] = useState({ financier_id: null, start_date: null, end_date: null, point_id: [], status: 1, payout_per: "", product_type: null });
    const [financers, setFinancers] = useState([]);
    const [bookingpoints, setBookingPoints] = useState([]);
    const [is_disabled, setIsDisabled] = useState(true)
    const [payout_points, setPayoutpoints] = useState([])
    const [product_type, setProductType] = useState([]);
    const [errors, setErrors] = useState({})

    const navigate = useNavigate();
    const updateReduxData = async () => {
        if (secureStorage.getItem('loginUserInfo')) {
            var loginResponse = secureStorage.getItem('loginUserInfo');
            props.updateStateIntoRedux('LOGIN_USER', loginResponse);
            afterLoginSetState(loginResponse)
        } else {
            return (<Navigate to={"/login"} />);
        }
    }

    const afterLoginSetState = (loginResponse) => {
        let role = loginResponse && loginResponse.data && loginResponse.data.user_data && loginResponse.data.user_data.role || null;
        if (role != "dealercentral") {
            return (<Navigate to={"/"} />);
        }
    }

    const getFinexMasterData = async () => {
        const masterData = await executeGraphQLQuery(FINEX_MASTER_LIST()).catch(err => console.log(err));
    
        if (masterData && masterData.data && masterData.data.masterdata) {
            const bankData = []
            const bookingPointsData = []
            const producttypedata = [];
            const data = masterData.data.masterdata;
            const bank_list = data.bank_list && data.bank_list.length > 0 ? data.bank_list : [];
            const products = data.loan_type && data.loan_type.length > 0 ? data.loan_type : [];
            if (bank_list && bank_list.length > 0) {
                bank_list.map(banks => {
                    bankData.push({ id: banks.banklang.bank_id, name: banks.banklang.bank_name })
                })
                setFinancers(bankData)
                payout_points.map(point => {
                    bookingPointsData.push({ id: point.lead_status, name: point.point_name });
                });
                setBookingPoints(bookingPointsData);
                if (products && products.length > 0) {
                    products.map(ptype => {
                        producttypedata.push({ id: ptype.id, name: ptype.label })
                    })
                    setProductType(producttypedata)
                }
               
            }

        }

    }
    const getPayoutDataList =  async() => {
         const result = await executeGraphQLQuery(PAYOUT_POINT_DETAILS());
         const PayoutDataList = []
         result.data.points.map(point => {
            PayoutDataList.push({ id: point.id, name: point.booking_point });
        });
       // console.log("...................................." ,PayoutDataList)
        setPayoutpoints(PayoutDataList)
      //  console.log("....................................+." ,  bookingpoints )
    }


    const handleChange = async (n, v, isMulti, opt, optl) => {
        setErrors({ ...errors, [n]: "" })        
        switch (n) {
            case 'financier_id':
                setFormData({ ...formData, financier_id: v.id });
                break;
            case 'point_id':
                const value = Array.isArray(v) ? v.map(option => option.id) : v.id;
                setFormData({ ...formData, point_id: value });
                break;
            case 'payout_per':
                setFormData({ ...formData, payout_per: v.target.value });
                break;
            case 'status':
                setFormData({ ...formData, status: v.value });
                break;
            case 'start_date':
                setFormData({ ...formData, start_date: v.target.value });
                break;
            case 'end_date':
                setFormData({ ...formData, end_date: v.target.value });
                break;
            case 'product_type':
                setFormData({ ...formData, product_type: v.id });
                break;    
        }
    }

    const status = [
        { value: 1, label: "Active" }, 
        { value: 2, label: "Inactive" }
    ]

    const validateForm = () => {
        const errors = {}
        let is_error = false;

        if (formData.financier_id === null) {
            errors.financier_id = 'Financier ID is required';
            is_error = true
        }

        if (formData.start_date === null) {
            errors.start_date = 'Start Date is required';
            is_error = true
        }

        if (formData.end_date === null || formData.end_date <= formData.start_date) {
            errors.end_date = 'End Date is not valid!';
            is_error = true
        }
       // console.log("formData: ", formData)
        if (formData.point_id?.length === 0 || !formData.point_id) {
            errors.point_id = 'Payout Point is not valid';
            is_error = true
        }

        if (typeof formData.status !== 'number') {
            errors.status = 'Status must be a number';
            is_error = true
        }       

        if (formData.product_type === null || !formData.product_type) {
            errors.product_type = 'Payout Point is not valid';
            is_error = true
        }
        // if (typeof formData.payout_per !== 'number' || typeof formData.payout_per !== 'number' || formData.payout_per == 0 || formData.payout_per > 100) {
        if (formData?.payout_per === '' || (parseFloat(formData.payout_per) <= 0 || parseInt(formData.payout_per) > 100)) {
            errors.payout_per = 'Payout Point should be between 1 to 100!';
            is_error = true
        }
        setErrors(errors)
        return is_error;

    }

    useEffect(() => {
        updateReduxData()
        getFinexMasterData()
        getPayoutDataList()
     
        if (id && id != '') {            
           // getPayoutPointDetails(id)
           setFormData({ ...formData, financier_id:base64DecodeId(id) });           
        }
    }, []);

    const base64DecodeId = (encodedId) => {        
        return parseInt(atob(encodedId), 10);
    }

    const getPayoutPointDetails = async (id) => {
        try {
            const responseData = await executeGraphQLQuery(DETAILS_PAYOUT_POINT(id))
            if (responseData?.data?.payoutpoint_details && responseData?.data?.payoutpoint_details?.id) {
                setFormData(prevFormData => ({ ...prevFormData, ...responseData.data.payoutpoint_details }))
            } else {
                toast.success("Something went wrong!");
            }

        } catch (error) {
            toast.success("Something went wrong!");
        }
    }


    const addPayoutPoints = async () => {
        if (validateForm()) {
           // console.log("validateForm errror");
        } else {
            try {
                delete formData.__typename
                formData.product_type = formData.product_type.toString()
                formData.payout_per = parseInt(formData.payout_per)
                const result = await executeGraphQLMutation(SAVE_PAYOUT_POINT, {
                    "addPayoutpointsArgs": formData
                });
                
                if (result && result.data && result.data.save_payout_points && result.data.save_payout_points.status) {
                    toast.success(result?.data?.save_payout_points?.msg || "Success");
                    setFormData({})
                    navigate("/bank/bank-onboarding")
                } else {
                    toast.error(result?.data?.save_payout_points?.msg || "Failed");
                }
            } catch (error) {
                console.log({error})
                toast.error("Something went wrong!");
            }
        }

    }

    return (
        <>
           <div className='container-fluid'>
                {/* {JSON.stringify(formData)} */}
                <div className='search-wrap'>
                    <div className='card-body'>
                        <h2 className='top-heading'>{formData?.id ? "Update": "Add"} Payout Point</h2>

                        <div className='row'>
                            <div className='col-sm-2 col-md-2' >
                                <label>Financiers</label>
                                <Select
                                    id="payout_point"
                                    onChange={(e) => handleChange('financier_id', e)}
                                    options={financers}
                                    label={"Financier"}
                                    name="financier_id"
                                    getOptionLabel={({ name }) => name}
                                    getOptionValue={({ id }) => id}
                                    dataerror={errors.financier_id}
                                    value={financers.filter(({ id }) => id === formData.financier_id)}
                                    // isDisabled = {is_disabled}
                                   
                                />
                                {errors.financier_id &&
                                    <div className="error show" >{errors.financier_id}</div>
                                }
                            </div>
                            <div className="col-sm-2 col-md-2">

                                <label>Payout Point</label>
                                <Select
                                    id="point_id"
                                    onChange={(e) => handleChange('point_id', e)}
                                    options={payout_points}
                                    label={"Payout Point"}
                                    name="point_id"
                                    getOptionLabel={({ name }) => name}
                                    getOptionValue={({ id }) => id}
                                    // dataerror={errors.point_id}
                                    // value={payout_points.filter(({ id }) => id === formData.point_id)}
                                    value={payout_points?.filter(({ id }) => formData?.point_id?.includes(id))}
                                    // isDisabled = {is_disabled}
                                    isMulti={true}
                                />
                                {errors.point_id &&
                                    <div className="error show" >{errors.point_id}</div>
                                }
                            </div>
                            <div className="col-sm-2 col-md-2">
                                <InputField
                                    inputProps={{
                                        id: "payout_per",
                                        type: "text",
                                        name: "payout_per",
                                        autocompleate: "off",
                                        label: "Payout Percentage",
                                        validationreq: "true",
                                        label_before_input: "true",
                                        maxLength: 3,
                                        value: formData?.payout_per ? formData?.payout_per : '',
                                        placeholder: "Payout Percentage %"
                                    }}
                                    onChange={(e) => handleChange('payout_per', e)}
                                    dataerror={errors.payout_per}
                                />
                                {errors.payout_per &&
                                    <div className="error show" >{errors.payout_per}</div>
                                }
                            </div>
                            <div className="col-sm-2 col-md-2">
                                <InputField
                                    inputProps={{
                                        id: "start_date",
                                        type: "date",
                                        name: "start_date",
                                        autocompleate: "off",
                                        label: ('Start Date'),
                                        value: formData.start_date ? DateFormate(new Date(formData.start_date), 'yyyy-mm-dd') : '',
                                        // disabled : {is_disabled}

                                    }}
                                    onChange={(e) => handleChange('start_date', e)}
                                    dataerror={errors.start_date}
                                    // disabled = {is_disabled}

                                />
                                {errors.start_date &&
                                    <div className="error show" >{errors.start_date}</div>
                                }
                            </div>
                            <div className="col-sm-2 col-md-2">
                                <InputField
                                    inputProps={{
                                        id: "end_date",
                                        type: "date",
                                        name: "end_date",
                                        autocompleate: "off",
                                        label: ('End Date'),
                                        value: formData.end_date ? DateFormate(new Date(formData.end_date), 'yyyy-mm-dd') : '',
                                        // disabled : {is_disabled}

                                        
                                    }}
                                    onChange={(e) => handleChange('end_date', e)}
                                    dataerror={errors.end_date}
                                  
                                    
                                />
                                {errors.end_date &&
                                    <div className="error show" >{errors.end_date}</div>
                                }
                            </div>
                             <div className="col-sm-2">
                                <label>Product Type</label>
                                <Select
                                    id="product_type"
                                    name="product_type"
                                    options={product_type}
                                    onChange={(e) => handleChange('product_type', e)}
                                    dataerror={errors.product_type}
                                    value={product_type.filter(({ id }) => id === formData.product_type)}
                                    getOptionLabel={({ name }) => name}
                                    getOptionValue={({ id }) => id}
                                />

                         


                                {errors.product_type &&
                                    <div className="error show" >{errors.product_type}</div>
                                }
                            </div> 

                        </div>
                        <div className="card-footer text-center">
                            <button className="btn btn-primary mrg-r30" onClick={() => addPayoutPoints()}>Save</button>
                        </div>


                    </div>

                </div>

            </div>
        </>
    )
}


const mapStateToProps = state => {
    return {
        auth_token: state.authToken,
        user_loggedin: state.userLoggedIn,
        dealer_id: state.dealer_id
    };
}

const mapDispatchToProps = dispatch => {
    return {
        updateStateIntoRedux: (actionType, stateData) => dispatch({ type: actionType, componentState: stateData })
    }
}

export default withTranslation('dealer')(connect(mapStateToProps, mapDispatchToProps)(AddPayoutPoint));
