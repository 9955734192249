// import html2pdf from 'html2pdf.js/dist/html2pdf.min';
import * as moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Button, Card, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import "../../assets/css/crif-view.modal.css";
import { executeGraphQLMutation } from '../../../common/executeGraphQLQuery';
import { CRIF_REPORT_DOWNLOAD } from '../../../services/customer.gql';
import { useApolloClient } from '@apollo/client';

const CrifViewModal = ({ crifActiveDetails, crifCibil }) => {
    const client = useApolloClient();
    const [isLoading, setIsLoading] = useState(false);
    const [crifReport, setCrifReport] = useState({});

    const crifTable = { fontSize: '14px', width: '100%', borderCollapse: 'collapse', backgroundColor: '#ffffff', fontFamily: 'poppins, sans-serif', color: '#595959', "page-break-inside": "avoid" };
    const crifTableHead_TH = { fontSize: '14px', backgroundColor: '#473391', color: '#ffffff', textAlign: 'center', fontWeight: '500', padding: '5px 12px', borderBottom: '1px solid #d9d9d9', };
    const crifTableBody_TH = { fontSize: '12px', fontWeight: '700', backgroundColor: '#fafafa', border: '1px solid #f0f0f0', color: '#262626', textAlign: 'left', verticalAlign: 'middle', padding: '8px 12px', };
    const crifTableBody_TD = { fontSize: '12px', color: '#000', border: '1px solid #f0f0f0', textAlign: 'left', verticalAlign: 'middle', padding: '8px 12px', };

    // Preloader Component
    const Preloader = () => (
        <div className="preloader">
            <Spinner animation="border" variant="primary" />
            <span>Generating PDF...</span>
        </div>
    );

    const downloadCrifReport = async () => {
        setIsLoading(true);
        if (crifCibil?.pdf_path && crifCibil.pdf_path.endsWith(".pdf")) {
            window.open(crifCibil.pdf_path, "_blank", "noopener,noreferrer");
        } else {
            const variables = {
                crifInput: {
                    pan_card: crifCibil.pan_no,
                    mobile: Number(crifCibil.mobile),
                    crif_report: btoa(JSON.stringify(crifReport)),
                    crif_ref: crifCibil.ref_no,
                    partner_id: `${crifActiveDetails?.partner_id || 1}`,
                    user_id: crifActiveDetails.user_id,
                    lead_id: crifActiveDetails.lead_id
                },
            };

            await executeGraphQLMutation(CRIF_REPORT_DOWNLOAD(), variables, client)
                .then((resp) => {
                    if (resp?.data?.cibil_crif_report_download?.pdf_path) {
                        window.open(resp?.data?.cibil_crif_report_download?.pdf_path, "_blank", "noopener,noreferrer");
                    } else {
                        const errMsg = resp?.data?.cibil_crif_report_download?.data?.errors || resp?.data?.cibil_crif_report_download?.errors || `Unable to donwload CRIF report.`;
                        toast.error(errMsg)
                    }
                })
                .catch((error) => {
                    console.error("\n\n downloadCrifReport >> error => ", error)
                    toast.error(`Unable to verify OTP.`)
                })
                .finally(() => {
                    setIsLoading(false)
                });
        }
    }

    const ConsumerInformationCrif = ({ data, idData }) => {
        return (
            <div style={{ paddingBottom: '20px' }}>
                <table style={crifTable}>
                    <colgroup>
                        <col style={{ width: '10%' }} />
                        <col style={{ width: '23%' }} />
                        <col style={{ width: '10%' }} />
                        <col style={{ width: '23%' }} />
                        <col style={{ width: '10%' }} />
                        <col style={{ width: '23%' }} />
                    </colgroup>
                    <thead>
                        <tr><th colSpan="6" style={crifTableHead_TH} >
                            Consumer Information
                        </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th style={crifTableBody_TH}>Name</th>
                            <td style={crifTableBody_TD}>{data?.Name?.FullName || 'N/A'}</td>
                            <th style={crifTableBody_TH}>Date Of Birth</th>
                            <td style={crifTableBody_TD}>{data?.DateOfBirth ? moment(data.DateOfBirth).format('DD-MMM-YYYY') : 'N/A'}</td>
                            <th style={crifTableBody_TH}>Gender</th>
                            <td style={crifTableBody_TD}>{data?.Gender || 'N/A'}</td></tr>
                        <tr>
                            <th style={crifTableBody_TH}>Occupation</th>
                            <td style={crifTableBody_TD}>{data?.Occupation || 'N/A'}</td>
                            <th style={crifTableBody_TH}>Age</th>
                            <td style={crifTableBody_TD}>{data?.Age?.Age || 'N/A'}</td>
                            <th style={crifTableBody_TH}>Total Income</th>
                            <td style={crifTableBody_TD}>{data?.TotalIncome || 'N/A'}</td></tr>
                        <tr>
                            <th style={crifTableBody_TH}>National ID</th>
                            <td style={crifTableBody_TD}>{idData?.NationalIDCard?.length ? idData.NationalIDCard[0]?.IdNumber : 'N/A'}</td>
                            <th style={crifTableBody_TH}>PAN ID</th>
                            <td style={crifTableBody_TD}>{idData?.PANId?.length ? idData.PANId[0]?.IdNumber : 'N/A'}</td>
                            <th style={crifTableBody_TH}>Voter ID</th>
                            <td style={crifTableBody_TD}>{idData?.VoterID?.length ? idData.VoterID[0]?.IdNumber : 'N/A'}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    };

    const SummaryCrif = ({ data }) => {
        return (
            <div style={{ paddingBottom: '20px' }}>
                <table style={crifTable}>
                    <colgroup>
                        <col style={{ width: '10%' }} />
                        <col style={{ width: '10%' }} />
                        <col style={{ width: '10%' }} />
                        <col style={{ width: '10%' }} />
                        <col style={{ width: '10%' }} />
                        <col style={{ width: '10%' }} />
                        <col style={{ width: '10%' }} />
                        <col style={{ width: '10%' }} />
                        <col style={{ width: '10%' }} />
                        <col style={{ width: '10%' }} />
                    </colgroup>
                    <thead>
                        <tr><th colSpan="10" style={crifTableHead_TH} > Summary </th></tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th colSpan="2" style={crifTableBody_TH}>Total Balance Amount</th>
                            <th colSpan="2" style={crifTableBody_TH}>Total Sanction Amount</th>
                            <th colSpan="2" style={crifTableBody_TH}>Total High Credit</th>
                            <th colSpan="2" style={crifTableBody_TH}>Total Monthly Payment Amount</th>
                            <th colSpan="2" style={crifTableBody_TH}>Total Past Due</th>
                        </tr>
                        <tr>
                            <td colSpan="2" style={crifTableBody_TD}>{data?.TotalBalanceAmount || 'N/A'}</td>
                            <td colSpan="2" style={crifTableBody_TD}>{data?.TotalSanctionAmount || 'N/A'}</td>
                            <td colSpan="2" style={crifTableBody_TD}>{data?.TotalHighCredit || 'N/A'}</td>
                            <td colSpan="2" style={crifTableBody_TD}>{data?.TotalMonthlyPaymentAmount || 'N/A'}</td>
                            <td colSpan="2" style={crifTableBody_TD}>{data?.TotalPastDue || 'N/A'}</td>
                        </tr>
                        <tr>
                            <th colSpan="2" style={crifTableBody_TH}>No Of Accounts</th>
                            <th colSpan="2" style={crifTableBody_TH}>No Of Active Accounts</th>
                            <th colSpan="2" style={crifTableBody_TH}>No Of Past Due Accounts</th>
                            <th colSpan="2" style={crifTableBody_TH}>No Of Write Offs</th>
                            <th colSpan="2" style={crifTableBody_TH}>No Of Zero Balance Accounts</th>
                        </tr>
                        <tr>
                            <td colSpan="2" style={crifTableBody_TD}>{data?.NoOfAccounts || 'N/A'}</td>
                            <td colSpan="2" style={crifTableBody_TD}>{data?.NoOfActiveAccounts || 'N/A'}</td>
                            <td colSpan="2" style={crifTableBody_TD}>{data?.NoOfPastDueAccounts || 'N/A'}</td>
                            <td colSpan="2" style={crifTableBody_TD}>{data?.NoOfWriteOffs || 'N/A'}</td>
                            <td colSpan="2" style={crifTableBody_TD}>{data?.NoOfZeroBalanceAccounts || 'N/A'}</td>
                        </tr>
                        <tr>
                            <th colSpan="4" style={crifTableBody_TH}>Single Highest Sanction Amount</th>
                            <th colSpan="4" style={crifTableBody_TH}>Single Highest Balance</th>
                            <th colSpan="4" style={crifTableBody_TH}>Single Highest Credit</th>
                        </tr>
                        <tr>
                            <td colSpan="4" style={crifTableBody_TD}>{data?.SingleHighestSanctionAmount || 'N/A'}</td>
                            <td colSpan="4" style={crifTableBody_TD}>{data?.SingleHighestBalance || 'N/A'}</td>
                            <td colSpan="4" style={crifTableBody_TD}>{data?.SingleHighestCredit || 'N/A'}</td>
                        </tr>
                        <tr>
                            <th colSpan="4" style={crifTableBody_TH}>Average Open Balance</th>
                            <th colSpan="4" style={crifTableBody_TH}>Oldest Account</th>
                            <th colSpan="4" style={crifTableBody_TH}>Recent Account</th>
                        </tr>
                        <tr>
                            <td colSpan="4" style={crifTableBody_TD}>{data?.AverageOpenBalance || 'N/A'}</td>
                            <td colSpan="4" style={crifTableBody_TD}>{data?.OldestAccount || 'N/A'}</td>
                            <td colSpan="4" style={crifTableBody_TD}>{data?.RecentAccount || 'N/A'}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    };

    const EnquiriesSummaryCrif = ({ data }) => {
        return (
            <div style={{ paddingBottom: '20px' }}>
                <table style={crifTable}>
                    <colgroup>
                        <col style={{ width: '25%' }} />
                        <col style={{ width: '25%' }} />
                        <col style={{ width: '25%' }} />
                        <col style={{ width: '25%' }} />
                    </colgroup>
                    <thead>
                        <tr>
                            <th colSpan="4" style={crifTableHead_TH}>
                                Enquiries Summary
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th style={crifTableBody_TH}>Past 12 Months</th>
                            <td style={crifTableBody_TD}>{data?.Past12Months || 'N/A'}</td>
                            <th style={crifTableBody_TH}>Past 24 Months</th>
                            <td style={crifTableBody_TD}>{data?.Past24Months || 'N/A'}</td>
                        </tr>
                        <tr>
                            <th style={crifTableBody_TH}>Past 30 Days</th>
                            <td style={crifTableBody_TD}>{data?.Past30Days || 'N/A'}</td>
                            <th style={crifTableBody_TH}>Purpose</th>
                            <td style={crifTableBody_TD}>{data?.Purpose || 'N/A'}</td>
                        </tr>
                        <tr>
                            <th style={crifTableBody_TH}>Recent</th>
                            <td style={crifTableBody_TD}>
                                {data?.Recent ? moment(data.Recent).format('DD-MMM-YYYY') : 'N/A'}
                            </td>
                            <th style={crifTableBody_TH}>Total</th>
                            <td style={crifTableBody_TD}>{data?.Total || 'N/A'}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    };

    const EnquiriesCrif = ({ data }) => {
        return (
            <div style={{ paddingBottom: '20px' }}>
                <table style={crifTable}>
                    <colgroup>
                        <col style={{ width: '15%' }} />
                        <col style={{ width: '20%' }} />
                        <col style={{ width: '20%' }} />
                        <col style={{ width: '40%' }} />
                    </colgroup>
                    <thead>
                        <tr>
                            <th colSpan="4" style={crifTableHead_TH}>
                                Enquiries
                            </th>
                        </tr>
                        <tr>
                            <th style={crifTableBody_TH}>S No.</th>
                            <th style={crifTableBody_TH}>Amount</th>
                            <th style={crifTableBody_TH}>Date</th>
                            <th style={crifTableBody_TH}>Institution</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.length > 0 && data.map((item, index) => (
                            <tr key={index}>
                                <td style={crifTableBody_TD}>{index + 1}</td>
                                <td style={crifTableBody_TD}>{item.Amount}</td>
                                <td style={crifTableBody_TD}>{moment(item.Date, "YYYY-MM-DD").format("DD-MMM-YYYY")}</td>
                                <td style={crifTableBody_TD}>{item.Institution}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    };

    const CheckSummaryCrif = ({ data }) => {
        return (
            <div style={{ paddingBottom: '20px' }}>
                <table style={crifTable}>
                    <colgroup>
                        <col style={{ width: '25%' }} />
                        <col style={{ width: '25%' }} />
                        <col style={{ width: '25%' }} />
                        <col style={{ width: '25%' }} />
                    </colgroup>
                    <thead>
                        <tr>
                            <th colSpan="4" style={crifTableHead_TH}>
                                Check Summary
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th style={crifTableBody_TH}>Deliquency Status</th>
                            <td style={crifTableBody_TD}>{data?.DeliquencyStatus || 'N/A'}</td>
                            <th style={crifTableBody_TH}>No Of Addresses</th>
                            <td style={crifTableBody_TD}>{data?.NoOfAddresses || 'N/A'}</td>
                        </tr>
                        <tr>
                            <th style={crifTableBody_TH}>No Of Phone Numbers</th>
                            <td style={crifTableBody_TD}>{data?.NoOfPhoneNumbers || 'N/A'}</td>
                            <th style={crifTableBody_TH}>Score Range</th>
                            <td style={crifTableBody_TD}>{data?.ScoreRange || 'N/A'}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    };

    const CibilScoreCrif = ({ data }) => {
        return (
            <div style={{ paddingBottom: '20px' }}>
                <table style={crifTable}>
                    <colgroup>
                        <col style={{ width: '25%' }} />
                        <col style={{ width: '25%' }} />
                        <col style={{ width: '25%' }} />
                        <col style={{ width: '25%' }} />
                    </colgroup>
                    <thead>
                        <tr>
                            <th colSpan="4" style={crifTableHead_TH}>
                                Score Summary
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th style={crifTableBody_TH}>Score</th>
                            <td style={crifTableBody_TD}>{data?.Value || 'N/A'}</td>
                            <th style={crifTableBody_TH}>Score Card Type</th>
                            <td style={crifTableBody_TD}>{data?.Type || 'N/A'}</td>
                        </tr>
                        <tr>
                            <th style={crifTableBody_TH}>Score Card Version</th>
                            <td style={crifTableBody_TD}>{data?.Version || 'N/A'}</td>
                            <th style={crifTableBody_TH}>Score Name</th>
                            <td style={crifTableBody_TD}>{data?.Name || 'N/A'}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    };

    const RecentActivityCrif = ({ data }) => {
        return (
            <div style={{ paddingBottom: '20px' }}>
                <table style={crifTable}>
                    <colgroup>
                        <col style={{ width: '25%' }} />
                        <col style={{ width: '25%' }} />
                        <col style={{ width: '25%' }} />
                        <col style={{ width: '25%' }} />
                    </colgroup>
                    <thead>
                        <tr>
                            <th colSpan="4" style={crifTableHead_TH}>
                                Recent Activity
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th style={crifTableBody_TH}>Accounts Deliquent</th>
                            <td style={crifTableBody_TD}>{data?.AccountsDeliquent || 'N/A'}</td>
                            <th style={crifTableBody_TH}>Accounts Opened</th>
                            <td style={crifTableBody_TD}>{data?.AccountsOpened || 'N/A'}</td>
                        </tr>
                        <tr>
                            <th style={crifTableBody_TH}>Accounts Updated</th>
                            <td style={crifTableBody_TD}>{data?.AccountsUpdated || 'N/A'}</td>
                            <th style={crifTableBody_TH}>Total Inquiries</th>
                            <td style={crifTableBody_TD}>{data?.TotalInquiries || 'N/A'}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    };

    const TelephonesCrif = ({ data }) => {
        return (
            <div style={{ paddingBottom: '20px' }}>
                <table style={crifTable}>
                    <colgroup>
                        <col style={{ width: '19%' }} />
                        <col style={{ width: '40%' }} />
                        <col style={{ width: '40%' }} />
                        {/* <col style={{ width: '29%' }} /> */}
                    </colgroup>
                    <thead>
                        <tr>
                            <th colSpan="4" style={crifTableHead_TH}>
                                Telephone
                            </th>
                        </tr>
                        <tr>
                            <th style={crifTableBody_TH}>S No.</th>
                            <th style={crifTableBody_TH}>Reported Date</th>
                            <th style={crifTableBody_TH}>Telephone Number</th>
                            {/* <th style={crifTableBody_TH}>Telephone Type</th> */}
                        </tr>
                    </thead>
                    <tbody>
                    {data.length > 0 && data.map((item, index) => (
                            <tr key={index}>
                                <td style={crifTableBody_TD}>{index + 1}</td>
                                <td style={crifTableBody_TD}>{moment(item.ReportedDate, "YYYY-MM-DD").format("DD-MMM-YYYY")}</td>
                                <td style={crifTableBody_TD}>{item.Number}</td>
                                {/* <td style={crifTableBody_TD}>{item.typeCode}</td> */}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    };

    const IdentificationCrif = ({ data }) => {
        return (
            <div style={{ paddingBottom: '20px' }}>
                <table style={crifTable}>
                    <colgroup>
                        <col style={{ width: '19%' }} />
                        <col style={{ width: '40%' }} />
                        <col style={{ width: '40%' }} />
                    </colgroup>
                    <thead>
                        <tr>
                            <th colSpan="4" style={crifTableHead_TH}>
                                Identification
                            </th>
                        </tr>
                        <tr>
                            <th style={crifTableBody_TH}>S No.</th>
                            <th style={crifTableBody_TH}>Reported Date</th>
                            <th style={crifTableBody_TH}>Email</th>
                        </tr>
                    </thead>
                    <tbody>
                    {data.length > 0 && data.map((item, index) => (
                            <tr key={index}>
                                <td style={crifTableBody_TD}>{index + 1}</td>
                                <td style={crifTableBody_TD}>{moment(item.ReportedDate, "YYYY-MM-DD").format("DD-MMM-YYYY")}</td>
                                <td style={crifTableBody_TD}>{item.EmailAddress}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    };

    const AddressesCrif = ({ data }) => {
        return (
            <div style={{ paddingBottom: '20px' }}>
                <table style={crifTable}>
                    <colgroup>
                        <col style={{ width: '10%' }} />
                        <col style={{ width: '18%' }} />
                        <col style={{ width: '18%' }} />
                        <col style={{ width: '18%' }} />
                        <col style={{ width: '18%' }} />
                        <col style={{ width: '18%' }} />
                    </colgroup>
                    <thead>
                        <tr>
                            <th colSpan="6" style={crifTableHead_TH}>
                                Addresses
                            </th>
                        </tr>
                        <tr>
                            <th style={crifTableBody_TH}>S No.</th>
                            <th style={crifTableBody_TH}>Address Category</th>
                            <th style={crifTableBody_TH}>Address</th>
                            <th style={crifTableBody_TH}>State Code</th>
                            <th style={crifTableBody_TH}>Pin Code</th>
                            <th style={crifTableBody_TH}>Last Reported Date</th>
                        </tr>
                    </thead>
                    <tbody>
                    {data.length > 0 && data.map((item, index) => (
                            <tr key={index}>
                                <td style={crifTableBody_TD}>{index + 1}</td>
                                <td style={crifTableBody_TD}>{item.Type}</td>
                                <td style={crifTableBody_TD}>{item.Address}</td>
                                <td style={crifTableBody_TD}>{item.State}</td>
                                <td style={crifTableBody_TD}>{item.Postal}</td>
                                <td style={crifTableBody_TD}>{moment(item.ReportedDate, "YYYY-MM-DD").format("DD-MMM-YYYY")}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    };

    const AccountCrif = ({ data }) => {
        return (
            <>
                {data.length && data?.map((item, idx) => {
                    const {
                        seq,
                        AccountNumber,
                        AccountStatus,
                        AccountType,
                        Balance,
                        DateOpened,
                        DateReported,
                        Institution,
                        LastPayment,
                        LastPaymentDate,
                        Open,
                        OwnershipType,
                        RepaymentTenure,
                        SanctionAmount,
                        TermFrequency,
                        source,
                        AssetClassification,
                        CollateralType,
                        CollateralValue,
                        History48Months,
                        InstallmentAmount,
                        InterestRate,
                        CreditLimit,
                        DisputeCode,
                        HighCredit,
                    } = item;
                    return (
                        <>
                            <div style={{ paddingBottom: '20px' }}>
                                <table style={crifTable}>
                                    <colgroup>
                                        <col style={{ width: '18%' }} />
                                        <col style={{ width: '15%' }} />
                                        <col style={{ width: '18%' }} />
                                        <col style={{ width: '15%' }} />
                                        <col style={{ width: '18%' }} />
                                        <col style={{ width: '15%' }} />
                                    </colgroup>
                                    <thead>
                                        <tr>
                                            <th colSpan="6" style={crifTableHead_TH} >
                                                {`Account Info ${seq}`}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th style={crifTableBody_TH}>Account Number</th>
                                            <td style={crifTableBody_TD}>{AccountNumber}</td>
                                            <th style={crifTableBody_TH}>Account Type</th>
                                            <td style={crifTableBody_TD}>{AccountType}</td>
                                            <th style={crifTableBody_TH}>Account Status</th>
                                            <td style={crifTableBody_TD}>{AccountStatus}</td>
                                        </tr>
                                        <tr>
                                            <th style={crifTableBody_TH}>Asset Classification</th>
                                            <td style={crifTableBody_TD}>{AssetClassification}</td>
                                            <th style={crifTableBody_TH}>Date Opened</th>
                                            <td style={crifTableBody_TD}>{moment(DateOpened).format("DD-MMM-YYYY")}</td>
                                            <th style={crifTableBody_TH}>Last Payment</th>
                                            <td style={crifTableBody_TD}>{moment(LastPaymentDate).format("DD-MMM-YYYY")}</td>
                                        </tr>
                                        <tr>
                                            <th style={crifTableBody_TH}>Date Reported</th>
                                            <td style={crifTableBody_TD}>{moment(DateReported).format("DD-MMM-YYYY")}</td>
                                            <th style={crifTableBody_TH}>High Credit Amount</th>
                                            <td style={crifTableBody_TD}>{HighCredit ?? "NULL"}</td>
                                            <th style={crifTableBody_TH}>Current Balance</th>
                                            <td style={crifTableBody_TD}>{Balance}</td>
                                        </tr>
                                        <tr>
                                            <th style={crifTableBody_TH}>Installment Amount</th>
                                            <td style={crifTableBody_TD}>{InstallmentAmount}</td>
                                            <th style={crifTableBody_TH}>Institution</th>
                                            <td style={crifTableBody_TD}>{Institution}</td>
                                            <th style={crifTableBody_TH}>Last Payment</th>
                                            <td style={crifTableBody_TD}>{LastPayment}</td>
                                        </tr>
                                        <tr>
                                            <th style={crifTableBody_TH}>Last Payment Date</th>
                                            <td style={crifTableBody_TD}>{moment(LastPaymentDate).format("DD-MMM-YYYY")}</td>
                                            <th style={crifTableBody_TH}>Open</th>
                                            <td style={crifTableBody_TD}>{Open}</td>
                                            <th style={crifTableBody_TH}>Ownership Type</th>
                                            <td style={crifTableBody_TD}>{OwnershipType}</td>
                                        </tr>
                                        <tr>
                                            <th style={crifTableBody_TH}>Repayment Tenure</th>
                                            <td style={crifTableBody_TD}>{RepaymentTenure}</td>
                                            <th style={crifTableBody_TH}>Sanction Amount</th>
                                            <td style={crifTableBody_TD}>{SanctionAmount}</td>
                                            <th style={crifTableBody_TH}>Term Frequency</th>
                                            <td style={crifTableBody_TD}>{TermFrequency}</td>
                                        </tr>
                                        <tr>
                                            <th style={crifTableBody_TH}>Source</th>
                                            <td style={crifTableBody_TD}>{source}</td>
                                            <th style={crifTableBody_TH}>Collateral Type</th>
                                            <td style={crifTableBody_TD}>{CollateralType}</td>
                                            <th style={crifTableBody_TH}>Collateral Value</th>
                                            <td style={crifTableBody_TD}>{CollateralValue}</td>
                                        </tr>
                                        <tr>
                                            <th style={crifTableBody_TH}>Dispute Code</th>
                                            <td style={crifTableBody_TD}>{DisputeCode}</td>
                                            <th style={crifTableBody_TH}>Credit Limit</th>
                                            <td style={crifTableBody_TD}>{CreditLimit}</td>
                                            <th style={crifTableBody_TH}>Interest Rate</th>
                                            <td style={crifTableBody_TD}>{InterestRate}</td>
                                        </tr>
                                    </tbody>
                                </table>

                                <HistoryTable
                                    data={
                                        !isEmptyObject(History48Months)
                                            // !(History48Months && Object.keys(History48Months).length === 0 && History48Months.constructor === Object)
                                            ? [History48Months]
                                            : History48Months?.length
                                                ? History48Months
                                                : []
                                    }
                                    startDate={DateOpened}
                                    endDate={DateReported}
                                />
                            </div>
                        </>
                    );
                })}
            </>
        );
    };

    const isEmptyObject = (obj) => {
        return !!obj && Object.keys(obj).length === 0 && obj.constructor === Object;
    }

    const HistoryTable = ({ startDate, endDate, data }) => {
        const columns = [
            {
                title: 'Year',
                dataIndex: 'year',
                key: 'year',
                width: "60px"
            },
            // Generate columns for each month
            ...Array.from({ length: 12 }, (_, i) => ({
                title: (new Date(2000, i, 1)).toLocaleString('default', { month: 'short' }),
                dataIndex: `month_${i + 1}`,
                key: `month_${i + 1}`,
                width: "70px"
            })),
        ];

        const tableData = generateTableData(startDate, endDate, data);

        return (
            <>
                <div style={{ paddingBottom: '20px' }}>
                    <table style={crifTable}>
                        <colgroup>
                            {columns.map((item, index) => (<col style={{ width: '7.5%' }} />))}
                        </colgroup>
                        <thead>
                            <tr>
                                <th colSpan={columns.length} style={crifTableHead_TH}>
                                    History
                                </th>
                            </tr>
                            <tr>
                                {columns.map((item, index) => (<th style={crifTableBody_TH} key={index}>{item.title}</th>))}
                            </tr>
                        </thead>
                        <tbody>
                            {tableData.map((item, index) => (
                                <tr key={index}>
                                    {columns.map((item1, index1) => (<td style={crifTableBody_TD} key={`${index}-${index1}`}>{item[item1.dataIndex]}</td>))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </>
        )
    };

    const generateTableData = (startDate, endDate, data) => {
        const startYear = parseInt(startDate?.slice(0, 4));
        const endYear = parseInt(endDate?.slice(0, 4));
        const tableData = [];
        function isKeyInRange(key, startDate, endDate) {
            const [keyMonth, keyYear] = key?.split('-').slice(0);
            const startMoment = moment(startDate);
            const endMoment = moment(endDate);
            const keyDate = moment(`${keyYear}-${keyMonth}-01`, 'YY-MM-DD');
            return keyDate.isBetween(startMoment, endMoment, null, '[]');
        }

        for (let year = startYear; year <= endYear; year++) {
            const rowData = {
                year: year.toString(),
            };
            for (let month = 1; month <= 12; month++) {
                const key = `${month.toString().padStart(2, '0')}-${year.toString().slice(2)}`;
                if (isKeyInRange(key, startDate, endDate)) {
                    const cellData = data.find(item => item.key == key);
                    rowData[`month_${month}`] = cellData ? cellData.AssetClassificationStatus : '000';
                } else {
                    rowData[`month_${month}`] = '-';
                }
            }
            tableData.push(rowData);
        }
        return tableData;
    };

    useEffect(() => {
        if (crifCibil && crifCibil?.report && typeof crifCibil.report === 'string' && crifCibil.report.length) {
            setCrifReport(JSON.parse(crifCibil.report))
        }
    }, [crifCibil])

    return (
        <>
            <div style={{ width: '100%', paddingTop: "20px" }}            >
                <div
                    className="crif-view-container"
                    id="crif-pdf-content"
                    style={{ width: '100%' }}
                >
                    <h2>CRIF Report</h2>
                    {!!(crifReport && Object.keys(crifReport).length) && (
                        <Card className="crif-card" style={{ maxHeight: '500px', overflowY: 'auto', "page-break-inside": "avoid" }}>
                            <ConsumerInformationCrif
                                data={crifReport?.IDAndContactInfo?.PersonalInfo || {}}
                                idData={crifReport?.IDAndContactInfo?.IdentityInfo || {}}
                            />
                            <SummaryCrif data={crifReport?.RetailAccountsSummary ? crifReport.RetailAccountsSummary : ''} />
                            <EnquiriesSummaryCrif data={crifReport?.EnquirySummary ? crifReport.EnquirySummary : ''} />
                            {!!(crifReport?.Enquiries && Array.isArray(crifReport?.Enquiries) && crifReport?.Enquiries.length) && (
                                <EnquiriesCrif data={crifReport.Enquiries} />
                            )}
                            <CheckSummaryCrif data={crifReport?.CreditCheckSummary ? crifReport.CreditCheckSummary : ''} />
                            <CibilScoreCrif data={crifReport?.ScoreDetails ? crifReport.ScoreDetails[0] : ''} />
                            <RecentActivityCrif data={crifReport?.RecentActivities ? crifReport.RecentActivities : ''} />
                            <TelephonesCrif data={crifReport?.IDAndContactInfo?.PhoneInfo ? crifReport.IDAndContactInfo?.PhoneInfo : ''} />
                            <IdentificationCrif data={crifReport?.IDAndContactInfo?.EmailAddressInfo ? crifReport.IDAndContactInfo?.EmailAddressInfo : ''} />
                            <AddressesCrif data={crifReport?.IDAndContactInfo?.AddressInfo ? crifReport.IDAndContactInfo?.AddressInfo : ''} />
                            <AccountCrif data={crifReport?.RetailAccountDetails ? crifReport.RetailAccountDetails : ''} />
                        </Card>
                    )}
                </div>
                <div className="crif-download-button-container">
                    {isLoading ? (
                        <Button variant="primary" style={{ backgroundColor: 'lightgray' }}>
                            Generating PDF...
                        </Button>
                    ) : (
                        <Button variant="primary" onClick={downloadCrifReport}>
                            Download Report
                        </Button>
                    )}
                </div>
            </div>
        </>
    );
};

export default CrifViewModal;
