import React, { useState, useEffect } from "react";
import "../../../pre-register-lead-manager/pre-register-lead.css";
import { MasterService, DealerService } from "../../../../../services";
import { getCurrentUserDetails } from "../../utility/getUserInfo";
import Formsy from "formsy-react";
import FormsyInputField from "../../../../elements/FormsyInputField";
import { toast } from "react-toastify";
import {
  getCurrentDateTime,
  getISODateAndTimeFormat,
} from "../../utility/dateFormateUtility";

const INTERESTED_SUB_STATUS_ID = 35;
const NOT_HAVE_LEAD_SUB_STATUS_ID = 70;
const POOR_EXPERIENCE_STATUS_ID = 65;
const NOT_RELEVANT = 85;
const PHYSICAL_VISIT_STATUS_ID = 68;
const RM_ASSIGNED_STATUS_ID = 69;
const CONNECTED_STATUS_ID = 1;
const NOT_CONNECTED_STATUS_ID = 2;
const INVALID_NUMBER_SUB_STATUS_ID = 72;

const INTEREST_LEVEL_OPTIONS = [
  { id: "low", label: "Low" },
  { id: "medium", label: "Medium" },
  { id: "high", label: "High" },
];

const SalesRegisteredCallPopup = (props) => {
  const leadData = props.leadData;
  const [status, setStatus] = useState([]);
  const [subStatus, setSubStatus] = useState([]);
  const [callStatus, setCallStatus] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const [reasonsList, setReasonsList] = useState([]);
  const [values, setValues] = useState({
    partner_id: "",
    status_id: leadData.status_id || "",
    sub_status_id: leadData.sub_status_id || "",
    call_status_id: leadData.call_status_id || "",
    followup_date: leadData.followup_date || "",
    comment: leadData.comment || "",
    source: props.source,
    status: 1,
    created_by: "",
    updated_by: "",
    remarks: leadData.remarks || "",
    title: leadData.title || "",
  });

  const userInfo = getCurrentUserDetails();

  useEffect(() => {
    fetchData();
    getStatusList();
    getCallStatus();
  }, []);

  useEffect(() => {
    if (values.status_id) {
      getSubStatusList(values.status_id);
    }
  }, [values.status_id, leadData]);

  useEffect(() => {
    if (isSubmitDisabled) {
      setIsSubmitDisabled(false);
    }
  }, [values]);

  const fetchData = async () => {
    try {
      const dealerHashId = window.location.pathname.split("/")[2];
      const response = await DealerService.getBasicDetails({
        dealer_id_hash: dealerHashId,
        _with: ["DIF"],
      });
      const data = response.data.data[0];
      setValues((prevValues) => ({
        ...prevValues,
        comment: data.comment || "",
        remarks: data.remarks || "",
        reason_id: data.reason_id || "",
        interest_level: data.interest_level || "",
        title: data.title || "",
        status_id: data.status_id || "",
        sub_status_id: data.sub_status_id || "",
        followup_date: data.followup_date || "",
      }));
      if (
        data.sub_status_id === POOR_EXPERIENCE_STATUS_ID ||
        data.sub_status_id === NOT_RELEVANT
      )
        getReasonsList(data.sub_status_id);
    } catch (error) {
      toast.error("Error fetching data");
    }
  };

  const getStatusList = async () => {
    try {
      const response = await MasterService.get("/partner/partner-call/status");
      setStatus(response.data.data);
    } catch (error) {
      console.error("Error fetching status list:", error);
      toast.error("Error fetching status list");
    }
  };

  const getSubStatusList = async (statusId) => {
    try {
      const response = await MasterService.get(
        `/partner/partner-call/sub-status?status_id=${statusId}&source=${props.source}`
      );
      let subStatusList = response.data.data;

      setSubStatus(subStatusList);
    } catch (error) {
      console.error("Error fetching sub-status list:", error);
      toast.error("Error fetching sub-status list");
    }
  };

  const getReasonsList = async (sub_status_id) => {
    const response = await MasterService.get(
      `/partner/partner-call/reasons?sub_status_id=${sub_status_id}&source=${props.source}`
    );
    if (response.data.data) {
      setReasonsList(response.data.data);
    }
  };

  const getCallStatus = async () => {
    try {
      const response = await MasterService.get(
        "/partner/partner-call/call-status"
      );
      setCallStatus(response.data.data);
    } catch (error) {
      console.error("Error fetching call status list:", error);
      toast.error("Error fetching call status list");
    }
  };

  const handleStatusChange = async (event) => {
    event.preventDefault();
    const selectedId = parseInt(event.target.value);
    setValues((prevValues) => ({
      ...prevValues,
      status_id: selectedId,
    }));
    await getSubStatusList(selectedId);
  };

  const handleSubStatusChange = (event) => {
    event.preventDefault();
    const selectedId = parseInt(event.target.value);
    if (selectedId === POOR_EXPERIENCE_STATUS_ID || selectedId === NOT_RELEVANT)
      getReasonsList(selectedId);
    setValues((prevValues) => ({
      ...prevValues,
      sub_status_id: selectedId,
    }));
  };

  const handleChange = (event) => {
    const { id, value } = event.target;
    setValues((current) => ({
      ...current,
      [id]: value,
    }));
  };

  const handleDateTimeChange = (event) => {
    const dateTime = event.target.value;
    setValues({
      ...values,
      followup_date: dateTime,
    });
  };

  const handleComment = (e) => {
    setValues((prevValues) => ({
      ...prevValues,
      comment: e.target.value,
    }));
  };

  const handleRemarks = (e) => {
    setValues((prevValues) => ({
      ...prevValues,
      remarks: e.target.value,
    }));
  };

  const handleTitle = (e) => {
    setValues((prevValues) => ({
      ...prevValues,
      title: e.target.value,
    }));
  };

  const handleSubmit = async () => {
    setIsSubmitDisabled(true);
    setIsSubmitted(true);

    // Validate inputs
    if (!values.status_id) {
      toast.error("Please select a status");
      return;
    }
    if (!values.sub_status_id) {
      toast.error("Please select a sub-status");
      return;
    }
    if (
      values.sub_status_id !== NOT_HAVE_LEAD_SUB_STATUS_ID &&
      values.sub_status_id !== NOT_RELEVANT &&
      values.status_id !== NOT_CONNECTED_STATUS_ID &&
      values.sub_status_id !== INVALID_NUMBER_SUB_STATUS_ID
    ) {
      if (values.followup_date < getCurrentDateTime()) {
        toast.error(
          "Followup date should be greater than or equal to the current date and time"
        );
        return;
      }
    }

    // Prepare request data
    const reqData = {
      partner_id: props?.leadData?.id,
      ...(values.status_id && { status_id: values.status_id }),
      ...(values.sub_status_id && { sub_status_id: values.sub_status_id }),
      ...(values.call_status_id && { call_status_id: values.call_status_id }),
      ...(values.followup_date && { followup_date: values.followup_date }),
      ...(values.comment && { comment: values.comment }),
      ...(values.title && { title: values.title }),
      ...(values.remarks && { remarks: values.remarks }),
      ...(values.reason_id && { reason_id: values.reason_id }),
      ...(values.interest_level && { interest_level: values.interest_level }),
      source: props.source,
      status: 1,
      created_by: userInfo.userId,
    };

    if (values.status_id == 1) {
      reqData.connected_at = new Date();
    } else {
      reqData.connected_at = null;
    }

    // Handle form submission
    try {
      const response = await MasterService.post(
        "/partner/partner-call/save-partner-call-details",
        reqData
      );
      if (response.status === 200) {
        toast.success("Success");
        props.nextActive();
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      console.error("Error saving details:", error);
      toast.error("Error saving details");
    }
  };

  return (
    <>
      <div className="mb-b20">Call Status</div>

      <Formsy autoComplete="off">
        <div className="row">
          <div className="btnmarkdoneflex col-sm-6 mb-b20">
            <div className="box">
              <h4 className="dropdown-heading1">Status*</h4>
              <select
                value={values.status_id}
                onChange={handleStatusChange}
                required
              >
                <option value="">Select Status</option>
                {status.map((status) => (
                  <option key={status.status_id} value={status.status_id}>
                    {status.status_name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="btnmarkdoneflex col-sm-6 mb-b20">
            <div className="box">
              <h4 className="dropdown-heading2">Sub-Status*</h4>
              <select
                value={values.sub_status_id}
                onChange={handleSubStatusChange}
                required
              >
                <option value="">Select Sub-Status</option>
                {subStatus.map((option) => (
                  <option
                    key={option.sub_status_id}
                    value={option.sub_status_id}
                  >
                    {option.sub_status_name}
                  </option>
                ))}
              </select>
            </div>
          </div>

          {values.status_id === CONNECTED_STATUS_ID &&
            (values.sub_status_id === POOR_EXPERIENCE_STATUS_ID ||
              values.sub_status_id === NOT_RELEVANT) && (
              <div className="btnmarkdoneflex col-sm-12 mb-b20">
                <div className="box">
                  <h4 className="dropdown-heading2">Reasons*</h4>
                  <select
                    value={values.reason_id}
                    id="reason_id"
                    onChange={handleChange}
                    required
                  >
                    <option value="">Select reason</option>
                    {reasonsList.map((option) => (
                      <option key={option.reason_id} value={option.reason_id}>
                        {option.reason_name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            )}

          <div className="btnmarkdoneflex col-sm-6 mb-b20">
            <div className="box">
              <h4 className="dropdown-heading2">
                Interest Level
                {values.sub_status_id === INTERESTED_SUB_STATUS_ID ? "*" : ""}
              </h4>
              <select
                id="interest_level"
                value={values.interest_level}
                onChange={handleChange}
                required={
                  values.sub_status_id === INTERESTED_SUB_STATUS_ID
                    ? true
                    : false
                }
              >
                <option value="">Select Interest Level</option>
                {INTEREST_LEVEL_OPTIONS.map((option) => (
                  <option key={option.id} value={option.id}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
          </div>

          {values.sub_status_id === RM_ASSIGNED_STATUS_ID ||
          values.sub_status_id === NOT_RELEVANT ||
          values.sub_status_id === PHYSICAL_VISIT_STATUS_ID ? (
            () => null
          ) : (
            <>
              {values.status_id === NOT_CONNECTED_STATUS_ID &&
              values.sub_status_id === INVALID_NUMBER_SUB_STATUS_ID ? null : (
                <>
                  <div className="btnmarkdoneflex col-sm-12">
                    <div className="mb-b20">Set Followup</div>
                  </div>

                  <fieldset className="col-sm-6 mb-b20">
                    <FormsyInputField
                      id="followup_time"
                      name="followup_time"
                      type="datetime-local"
                      placeholder=" "
                      onChange={handleDateTimeChange}
                      value={
                        values.followup_date
                          ? getISODateAndTimeFormat(values.followup_date)
                          : ""
                      }
                      label="Date/Time"
                      min={getCurrentDateTime()}
                      disabled={values.status_id === 4}
                    />
                  </fieldset>

                  <fieldset className="col-sm-6 mb-b20">
                    <FormsyInputField
                      id="title"
                      name="title"
                      type="text"
                      placeholder=" "
                      onChange={handleTitle}
                      value={values?.title}
                      label="Title"
                    />
                  </fieldset>
                </>
              )}

              <fieldset className="col-sm-12 mb-b20">
                <div className="material">
                  <textarea
                    name="remarks"
                    id="remarks"
                    placeholder=" "
                    className="form-input"
                    rows="=2"
                    onChange={handleRemarks}
                    value={values.remarks}
                  />
                  <label data-label="Add Remark" className="form-label"></label>
                  <span className="error-msg"></span>
                </div>
              </fieldset>
            </>
          )}

          <fieldset className="col-sm-12 mb-b10">
            <div className="material">
              <textarea
                name="comment"
                id="comment"
                placeholder=" "
                className="form-input"
                rows="2"
                onChange={handleComment}
                value={values.comment}
              />
              <label data-label="Comment" className="form-label"></label>
              <span className="error-msg"></span>
            </div>
          </fieldset>
        </div>
      </Formsy>
      {isSubmitted ? (
        <>
          {values.status_id === "" ? (
            <span style={{ color: "red" }}>
              Please select a status
              <br />
            </span>
          ) : null}
          {values.sub_status_id === "" ? (
            <span style={{ color: "red" }}>Please select a sub-status</span>
          ) : null}
        </>
      ) : null}
      <button
        className="partnerregisterbtn1"
        type="submit"
        onClick={handleSubmit}
        disabled={isSubmitDisabled}
      >
        Save
      </button>
    </>
  );
};

export default SalesRegisteredCallPopup;
