import { gql } from "@apollo/client";

export const BANK_ONBOARDING_PAYIN_LIST = (page_number) =>  gql`
  query payin_config_list($filter_params: ConfigPayinFiltersArgs!) {
    payin_config_list(filter_params: $filter_params, page_no: ${page_number}) {
      payinlist {
        financer_id
        bank_name
        is_on_payin
        is_partnership
        logo
        total_payout
      }
      pagination {
      totalpage
      totalrecords
      nextpage
      prevpage
      __typename
    }
    total_count
    productData {
        product_type
        bank_id
        dsa_code
    }
    }
  }
`;


export const UPDATE_BANK_ONBOARDING_PAYIN = gql`
  mutation save_on_payin($addpayinconfigargs: AddPayinConfigArgs!) {
    save_on_payin(addpayinconfigargs: $addpayinconfigargs)
  }
`;

export const MIS_LIST = (page_number) => gql`
  query mis_file_list($filter_params: LeadMisFiltersArgs!) {
    mis_file_list(filter_params: $filter_params, page_no: ${page_number}) {
      list {
        bank_id
        created_at
        created_by
        csv_file
        id
        is_processed
        process_at
        processed_file
        update_for
        lead_id_count
        tranche_id_count
        lead_id_update_count
        tranche_id_update_count
      }
      pagination {
        page_no
        prevpage
        totalpage
        totalrecords
        nextpage
        __typename
      }
      __typename
    }
  }
`;

export const UPLOAD_MIS_LIST = gql`
  mutation mis_upload($uploadPaymentArgs: UploadMisArgs!) {
    mis_upload(uploadPaymentArgs: $uploadPaymentArgs){
      totalRows
      mis_id
      data{
        id
        lead_id
        tranche_id
        bank_name
        partner_id
        payin_per
        payin_amount
        payin_type
        payin_status
        is_duplicate
        disbursed_amount
        sanction_amount
        disbursal_date
        sanction_date
        fulfilment_type
        product_type
        sub_product_type
        Invoice_id
        Invoice_date
        utr
        payment_date
        pf_amount
        pf_per
        pf_type
        login_fees
        payin_tds_per
        payin_tds_amount
        tds_amount_via_gst
        tds_per_via_gst
        tds_via_gst_applicable
        is_valid
        reason
      }
    }
  }
`;


export const REPORTING_STATUS = (user_id) => gql`
  query{
    reporting_status(api_called_by:web,user_id:${user_id},report_filter_params:{}){
      id
      month
      month_name
      year
      created_date
      reporting_status
      total_lead_count
      total_disbursed_lead
      total_disbursed_amount
      freezed_total_lead_count
      freezed_total_disbursed_lead
      freezed_total_disbursed_amount
    }
      masterdata(api_called_by:web,is_partnership:"0"){
        report_month_list{
          id
          label
        }
        report_year_list{
          id
          label
        }
        report_status_list{
          id
          label
        }
      }
  }
`;
export const LOAN_ID_REPORT = (report_id, user_id, status = 'open' ) => gql`
query{
  loan_id_report(api_called_by:web, user_id:${user_id}, report_id:${report_id}, status:"${status}" ){
    lead_id
    created_date
    login_date
    disbursed_date
    disbursed_amount
    tranche_id
    fulfillment_type
    product_type
    product_sub_type
    partner_id
    partner_name
    sub_partner_name
    rm_name
    customer_name
    bank_name
    transaction_date
  }
}
`;

export const CLOSED_REPORTING = (user_id, year) => gql`
  query{
    get_closed_reporting_month(api_called_by:web,user_id:${user_id},year:${year}){
      months
      year
    }
  }
`;

export const MIS_UPDATE_DATA = gql`
  mutation mis_update_data($updatePaymentArgs: UpdateMisArgs!) {
    mis_update_data(updatePaymentArgs: $updatePaymentArgs)
  }
`;