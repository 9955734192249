import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import Button from "../../elements/Button";
import { toast } from "react-toastify";
import MasterService from "../../../services/MasterService";
// import { DealerService } from './../../../service';
import secureStorage from "../../../config/partnerEncrypt";
import {USERS_FOR_VIEW_AMBAK_LEADS, IS_USER_ACCESS_PAGE} from '../../../config/constants';


class ApprovePartner extends Component {
  constructor(props) {
      super(props);
      this.state = {
          user_id: "",
          get_dealer_id_hash: "",
          bussiness_approve: 0,
          payout_approve: 0,
          dealer_id_hash: "",
          basic_details: props.basic_details || {},
          disable_button : false
      }
  }

  componentWillReceiveProps(nextProps) {
      this.setState({
          basic_details: nextProps.basic_details,
          //bussiness_approve: nextProps.bussiness_approve,
      });
  }

  // componentDidMount = async () => {

  // }
  
  
   

  approveForBussiness = async (event) => {
      event.preventDefault();
      const approve =window.confirm("Are you sure want to approve this partner for bussiness?");
      if(approve===true){
      this.setState({ bussiness_approve: 1 }, () => {
          this.saveBasicDetail();
          // setTimeout(() => {
          //     window.location.reload();
          // }, 2500)
      });
  }
  }

  approveForPayout = async (event) => {
      event.preventDefault();
      const approve =window.confirm("Are you sure want to approve this partner for payouts?");
      if(approve===true){
      this.setState({ payout_approve: 1 }, () => {
          this.saveBasicDetail();
          // setTimeout(() => {
          //     window.location.reload();
          // }, 3500)
      });
  }
  }

  saveBasicDetail = async () => {
      if (this.props.basic_details) {
          const saveDetails = this.state.basic_details;
          let token = JSON.parse(secureStorage.storage.user_information)
          const requestData = {
              name: saveDetails?.first_name,
              email: saveDetails?.dealership_email,
              mobile: saveDetails?.dealership_contact,
              is_pan_verified: 1,
              //birth_date: saveDetails?.dealership_dob,
              applicant_type_id: saveDetails?.applicant_type ? Number(saveDetails?.applicant_type) : 1,
              profession_id: saveDetails?.profession_id,
              address: saveDetails?.address,
              pincode: saveDetails?.pincode ? String(saveDetails?.pincode) : "",
              user_type: "1",
              term_condition: true,
              partner_id: saveDetails?.id > 0 ? saveDetails.id : 0,
              user_id: saveDetails?.user_id > 0 ? saveDetails.user_id : 0,
              source: "Sangam Backend",
              created_by: 0,
              pan_no: saveDetails?.pan_no,
              pan_number: saveDetails?.pan_no,
              sfa_referral_user_id: saveDetails?.sfa_referral_user_id > 0 ? saveDetails.sfa_referral_user_id : 0,
              //assigned_bm_id: saveDetails?.assigned_bm_id > 0 ? saveDetails.assigned_bm_id : 0,
              //sfa_referral_user_id: saveDetails?.sfa_referral_user_id > 0 ? saveDetails.sfa_referral_user_id : saveDetails?.assigned_bm_id > 0 ? saveDetails.assigned_bm_id : 0,
              //assigned_bm_id: saveDetails?.assigned_bm_id > 0 ? saveDetails.assigned_bm_id : 0,
          }
          if (saveDetails?.latitude) {
              requestData.latitude = saveDetails?.latitude ? String(saveDetails?.latitude) : "";
          }
          if (saveDetails?.longitude) {
              requestData.longitude = saveDetails?.longitude ? String(saveDetails?.longitude) : "";
          }
          if (saveDetails?.dealership_dob) {
              let date_of_joining = new Date(saveDetails?.dealership_dob);
              let join_date = date_of_joining.getFullYear() + '-' + (date_of_joining.getMonth() + 1) + '-' + date_of_joining.getDate();
              requestData.birth_date = join_date;
          }

          if (saveDetails.aadhar_no) {
              requestData.aadhar_no = saveDetails?.aadhar_no;
          }
          if (saveDetails.applicant_type === "2") {
              requestData.company_type = saveDetails?.company_type;
          }

          if (saveDetails.is_aadhar_pan_linked === 1) {
              requestData.is_aadhar_pan_linked = 1;
              requestData.is_pan_verified = 1;
          }

          if (saveDetails?.applicant_type === "2" || saveDetails?.applicant_type === "3") {
              requestData.is_gst = saveDetails?.is_gst;
              requestData.gst_number = saveDetails?.gst_number;
              requestData.is_msme = saveDetails?.is_msme;
              requestData.msme_number = saveDetails?.msme_number;
              requestData.spoc_name = saveDetails?.spoc_name || "";
              requestData.spoc_email = saveDetails?.spoc_email || "";
              requestData.spoc_mobile = saveDetails?.spoc_phone_number || "";
              requestData.spoc_designation = saveDetails?.spoc_designation || "";
          }
        //   if (secureStorage.getItem('loginUserInfo')) {
        //       var loginResponse = secureStorage.getItem('loginUserInfo');
        //       this.props.updateStateIntoRedux('LOGIN_USER', loginResponse);
        //   }
          //if (this.state.bussiness_approve > 0 || saveDetails?.is_bussiness_approve >0) {
              requestData.is_bussiness_approve = 1;
              requestData.bussiness_approved_by = token.user_data.user_id;
          //}
          if (this.state.payout_approve > 0 || saveDetails?.is_payout_approve >0) {
              requestData.is_payout_approve = this.state.payout_approve  || saveDetails?.is_payout_approve;
              requestData.payout_approved_by =token.user_data.user_id
          }
          
        
          requestData.updated_by = token.user_data.user_id
        //  console.log("requestData", requestData)
          await MasterService.post('/partner/partner/save-basic-details', requestData)
              .then(response => {
               // console.log ("responseresponseresponseresponseresponseresponseresponse" , response)
                  this.setState({ loading: false });
                  this.setState({disable_button : true})
                //  console.log("response.data",);
                  if (response.data.status == 200 && response.status == 200) {
                      let responseData = response.data;
                      let other = {};

                      this.setState({ basic_details: this.state.basic_details, owner_details: this.state.owner_details, ...other }, () => {
                          toast.success((response.data.message) ? "Approved" : "Success")
                      })
                  } else {
                    console.log ("error message")
                    toast.error("Approval is only possible after all required basic details and all documents have been marked as verified!");
                      this.setState({ basic_details: this.state.basic_details, owner_details: this.state.owner_details }, () => {
                         // toast.error("Approval is only possible after all required basic details and all documents have been marked as verified!");
                          let errorMsg = (response.data.message && response.data.message.length > 0) ? response.data.message[0]:"Error";
                          let finalError  = errorMsg==="Incorrect Referral Code"?"Please Select onboarding/assigned RM":errorMsg;
                          toast.error(finalError);
                      });
                          
                  }
              })
              .catch(error => console.log(error))
      }
  }

  render() {
    let approve_partner =  IS_USER_ACCESS_PAGE("partner", "approve_payout")?.is_edit_access;

      const { is_bussiness_approve, is_payout_approve , disable_button} = this.state;
     // console.log("disable_buttondisable_buttondisable_buttondisable_buttondisable_button" , disable_button);
      const businessButtonDisabled = is_bussiness_approve === 1 || this.state.basic_details.is_bussiness_approve === 1;
      const payoutButtonDisabled = is_payout_approve === 1 || this.state.basic_details.is_payout_approve === 1;
      return (
          <div className='card'>
            {approve_partner === 1?
              <div className='card-body'>

              <div className='card-heading'>
                  <div className='flexcard'>
                      <div className='common-details'>Approval</div>
                  </div>
              </div>

              {/* <div className='approvalflex'>
                  <div className='approvalpartner'>Approve this Partner for Business </div>
                  <Button
                      disabled={businessButtonDisabled}
                      btnClass="approvebtn"
                      title={(is_bussiness_approve === 1 || this.state.basic_details.is_bussiness_approve === 1) ? 'Approved' : 'Approve'}
                      onClick={this.approveForBussiness}
                  />

              </div> */}
              <div className='approvalflex'>
                  <div className='approvalpartner'>Approve this Partner for Payout </div>
                  <Button
  disabled={payoutButtonDisabled || disable_button}
  btnClass="approvebtn"
  title={(is_payout_approve === 1 || this.state.basic_details.is_payout_approve === 1) ? 'Approved' : 'Approve'}
  onClick={this.approveForPayout}
/>

              </div>
          </div>
           : ""}
            
          </div>

      )
  }
}

const mapStateToProps = state => {
  return {
      auth_token: state.authToken,
      user_loggedin: state.userLoggedIn,
      dealer_id: state.dealer_id
  };
}

const mapDispatchToProps = dispatch => {
  return {
      updateStateIntoRedux: (actionType, stateData) => dispatch({ type: actionType, componentState: stateData })
  }
}
export default withTranslation('dealers')(connect(mapStateToProps, mapDispatchToProps)(ApprovePartner));
