import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { withFormsy } from 'formsy-react';
import dateFormat from "dateformat";
function FormsyDatePicker(props) {
    let { selectedDate, onDateSelect, placeholder = "Date", maxDate = "", minDate = "", hasError = false, applyLabelTxtClass = true, allowKeyDown = false,col="", fieldTitle = null } = props;

    let [ dateUpdated, setDateUpdated ] = useState(false);
    const [ isValid, setIsValid ] = useState(true);
    const [errorMessage, setErrorMessage] = useState("");
    const [showTimeSelect, setShowTimeSelect] = useState(false);

    useEffect(() => {
        setIsValid(!hasError);
    }, [hasError]);

    useEffect(() => {
        if(props.errorMessage) setErrorMessage(props.errorMessage);
    }, [props.errorMessage]);


    useEffect(() => {
        if (dateUpdated && errorMessage) setIsValid(false);
    }, [dateUpdated, errorMessage]);
    
    const keyboardInput = (e) => {
        setIsValid(true);
        setErrorMessage("");
        if (!allowKeyDown) {
            // To disallow keyboard input
            return e.preventDefault();
        }
        e.target.value = e.target.value && e.target.value.replace(/[^0-9\-]/g, '') || "";

    }

    // const dateChangeHandler = (date) => {
    //     setDateUpdated(true);
    //     if (date) {
    //         setShowTimeSelect(true);
    //         setIsValid(true);
    //         setErrorMessage("");
    //     }else{
    //         setShowTimeSelect(false);
    //     }
    //     if (typeof onDateSelect === 'function') {
    //         onDateSelect(date);
    //     }
    //     props.setValue(date);

    // }

    const dateChangeHandler = (date) => {
        setDateUpdated(true);
        if (date) {
            const hasTimeSelected = date.getHours() || date.getMinutes();    
            if (hasTimeSelected) setShowTimeSelect(true);  
            setIsValid(true);
            setErrorMessage("");
        } else {
            setShowTimeSelect(false);
        }
        if (typeof onDateSelect === 'function') {
            onDateSelect(date);
        }
        props.setValue(date);

    }

    const onBlurHandler = (e) => {
        setDateUpdated(true);
        /** Checking for required validation */
        if (props.isRequired && !e.target.value) {
            setIsValid(false);
        } else {
            setIsValid(true);
        }

        /** Checking for date format and min max validation */
        if (e.target.value) {
            let parsedDate = new Date(e.target.value); 

            const date_regex = /^\d{4}\-\d{1,2}\-\d{1,2}$/g;
            const dateRegex_dmy = /^\d{1,2}\/\d{1,2}\/\d{4}( \d{1,2}:\d{2} (AM|PM))?$/;

            if (e.target.value.match(date_regex)) {
                let [ _, month, date ] = e.target.value.split('-');

                /** Validating month and date values */
                if (
                  (Number(month) > 12 || Number(month) < 1) ||
                  (Number(date) > 31 || Number(date) < 1)
                ) {
                  onDateSelect(""); // Clearing date
                  setErrorMessage("Invalid date format");
                  setIsValid(false);
                }
            } else if (e.target.value.match(dateRegex_dmy)) {
                const [day, month, year, timePart] = e.target.value.split(/\/| /);
                const formattedDate = `${month}/${day}/${year}`;
                parsedDate = new Date(formattedDate);
                
                if (timePart) {
                    const [time, period] = timePart.split(" ");
                    let [hours, minutes] = time.split(":").map(Number)
                
                    // Convert to 24-hour format if needed
                    if (period === "PM" && hours !== 12) {
                        hours += 12;
                    } else if (period === "AM" && hours === 12) {
                        hours = 0;
                    }
                    parsedDate.setHours(hours, minutes, 0, 0);
                }
            } else {
                onDateSelect(""); // Clearing date
            }

            if (maxDate && parsedDate > new Date(maxDate)) {
                onDateSelect(""); // Clearing date
                setErrorMessage(`Date cannot be greater than ${dateFormat(new Date(maxDate), "yyyy-mm-dd")}`);
                setIsValid(false);
            } else if (parsedDate < new Date(minDate)) {
                onDateSelect(""); // Clearing date
                setErrorMessage(`Date cannot be less than ${dateFormat(minDate, "yyyy-mm-dd")}`);
                setIsValid(false);
            } 
        }
    }

    let labeltxtClass = (applyLabelTxtClass && placeholder && placeholder.length && placeholder.length >=22) && col!="col-md-12" ? 'label-txt':'';
    if (props?.isRequired || props?.isFieldRequired) placeholder += "*";

    if(typeof selectedDate === 'string' && selectedDate) {
        selectedDate = new Date(selectedDate)
    }

    // console.log(`\n\n FormsyDatePicker -> props.minDate >> [${props.name}]=> `, props.minDate, `[`,selectedDate,`]`)
    // console.log(`\n\n FormsyDatePicker -> props.maxDate >> [${props.name}]=> `, props.maxDate, `[`,selectedDate,`]`)

    return (
            <div className={`material datepicker form-control ${props.isRequired && selectedDate ? (!isValid ? 'error-date' : '') : ""}`}>
                <DatePicker
                selected={selectedDate}
                dateFormat={showTimeSelect ? "dd/MM/yyyy h:mm aa" : "dd/MM/yyyy"} 
                onChange={dateChangeHandler}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                maxDate={maxDate}
                title={placeholder}
                placeholderText={placeholder.length >25 ? placeholder.substring(0,25)+'...' : placeholder}
                {...props}
                autoComplete="off"
                minTime={new Date().setHours(10, 0)}
                maxTime={props.maxTime !== undefined ? props.maxTime : new Date().setHours(21, 0)}
                onKeyDown={keyboardInput}
                onBlur={onBlurHandler}
                />
                <label
                data-label={fieldTitle === 'NA' ? '' : (fieldTitle || placeholder)}
                className={`form-label datepick-heading ${labeltxtClass}`}
                ></label>
                {dateUpdated && errorMessage ? (
                <div className="error-msg">{errorMessage}</div>
                ) : (
                ""
                )}
          </div>
    );
}

export default withFormsy(FormsyDatePicker);
