import React, { useRef, useState, useEffect } from "react";
import sendicon from "../../webroot/images/sendicon.png";
import Modal from "../elements/Modal";
import { useSelector } from "react-redux";
import downloadicon from "../../webroot/images/downloadicon.png";
import {
  executeGraphQLMutation,
  executeGraphQLQuery,
} from "../../common/executeGraphQLQuery";
import { CHAT_TEMPLATE_LIST, CHAT_LIST  , GET_UNREAD_MESSAGE_COUNT} from "../../services/leads.gql";
import { useApolloClient } from "@apollo/client";
import docImage from "../../webroot/images/saveicon.svg";
import { CHAT_SAVE } from "../../services/leadCreate.gql";
import GeneralService from "../../services/generalService";
import dateFormat from "dateformat";
import Q from "q";
import PartnerTemplate from "./PartnerTemplate";
import { NoUndefinedVariablesRule } from "graphql";

const WhatsAppTemplate = (props) => {
  const fileInputRef = useRef(null);
  const chatContainerRef = useRef(null); 
  const [showtemplate, setShowTemplate] = useState(false);
  const [initiate, setInitiate] = useState(false);
  let refresh = 0;
  const [inputField, setField] = useState({
    chat_type: "TEXT",
    mode_type: 1,
    message: "",
    files: "",
  });
  const [list, setList] = useState([]);
  const [chatList, setChatList] = useState([]);
  const [templateKey , setTemplateKey] = useState()
  const client = useApolloClient();

  const intervalRef = useRef(null);

  useEffect(() => {
    intervalRef.current = setInterval(async () => {
      if (props?.lead?.dealership_contact) {
        getMessageList(props?.lead?.dealership_contact);
      }
    }, 3000);


    return () => {
      clearInterval(intervalRef.current);
    };
  }, []);
  const { leadDetail, userInfo } = useSelector(({ lead, user }) => {
    return {
      leadDetail: lead.leadDetail,
      userInfo: user.user_information ? JSON.parse(user.user_information) : {},
    };
  });

  useEffect(() => {
    //getTemplateList( props?.lead?.id,props?.lead?.first_name , "partner");
    //getMessageList(props?.lead?.dealership_contact);
    handleDataFromChild()
  }, [leadDetail?.id]);



  const handleDataFromChild = (data) => {
     setTemplateKey(data)
  }

  const getTemplateList = (lead_id, username , partner) => {
    executeGraphQLQuery(CHAT_TEMPLATE_LIST(lead_id, username , partner) , client)
      .then((result) => {
        setList(result?.data?.chat_template_list);
      })
      .catch((error) => {});
  };

  const getMessageList = (mobile) => {
    executeGraphQLQuery(CHAT_LIST(mobile), client)
      .then((result) => {
        let replyResult = result?.data?.chat_list.filter(
          (ob) => ob.mode_type == 2
        );
        if (!replyResult.length) {
          setInitiate(true);
        } else {
          setInitiate(false);
        }
        let curCount = result?.data?.chat_list?.length;
        if (refresh == curCount) {
        } else {
          setTimeout(() => scrollToBottom(), 200);
        }
        refresh = curCount;
        setChatList(result?.data?.chat_list);
      })
      .catch((error) => {});
  };
  

  const handleIconClick = () => {
    fileInputRef.current.click();
  };



  const handleChange = (event) => {
    const { value } = event.target;
    setField({
      ...inputField,
      message: value,
    });
  };



  const sendMessage = (reqParam = null, initiate = false) => {


    // getTemplateList(props?.lead?.id,props?.lead?.first_name , "partner")
    // showTemplate()
   



    if (inputField.message.trim() !== "" || reqParam || initiate) {
      let chatField = {
        ...inputField,
        lead_id: props.lead.id,
        user_id: props.lead.user_id,
        customer_id: leadDetail?.customer?.customer_id,
        mobile: props?.lead?.dealership_contact,
        
      };
      if (initiate) {
        chatField.initiate = 1;
      }
      if (reqParam && reqParam?.chat_type) {
        chatField = { ...chatField, ...reqParam };
      }
      let variables = {
        api_called_by: "partner",
        chatInput: chatField,
        call_by : "partner_detail",
        tempelate_name : templateKey
      };
      let mutation = CHAT_SAVE;
      executeGraphQLMutation(mutation, variables, client)
        .then((result) => {
          getMessageList(props?.lead?.dealership_contact);
        })
        .catch((error) => {});
      setField({
        ...inputField,
        message: "",
      });
    }
  };


  

  const showTemplate = () => {
    setShowTemplate(true);
    document.body.classList.add("overflow-hidden");
  };

  const hideTemplate = () => {
    setShowTemplate(false);
    document.body.classList.remove("overflow-hidden");
  };

  const capitalizeFirstLetter = (string) => {
    if (typeof string !== 'string' || !string) {
      return '';
    }
    if (string.length === 0) {
      return '';
    }
    return string.charAt(0).toUpperCase();
  };

  // const capitalizeFirstLetter = (string) => {
  //   return string.charAt(0).toUpperCase();
  // };

  const handleChangeImage = (event) => {
    let files = Array.from(event.target.files),
      awsRes = [],
      leadDocArr = [];
    if (files && files.length) {
      var formData = new FormData();
      formData.append("dealer_id", leadDetail.id);
      formData.append("upload_type", "dealer_docs");
      for (let imgFile of event.target.files) {
        formData.append("images", imgFile);
      }
      awsRes.push(
        GeneralService.uploadFilesToS3(formData, {
          "Content-Type": "multipart/form-data",
        })
      );
      Q.allSettled(awsRes).then((uploadOutput) => {
        uploadOutput.forEach((res, index) => {
          let response = res.value || [];
          if (res.state === "fulfilled") {
            let docPath = response?.data?.data?.length
              ? response?.data?.data?.[0]?.file_url
              : "";
            let chat_type = "DOCUMENT";
            if (
              docPath.endsWith(".jpg") ||
              docPath.endsWith(".jpeg") ||
              docPath.endsWith(".png") ||
              docPath.endsWith(".gif")
            ) {
              chat_type = "IMAGE";
            }
            let params = {
              files: docPath,
              chat_type: chat_type,
            };
            sendMessage(params);
          }
        });
      });
    }
  };


  useEffect (() => {
    sendMessage() 
  } , [templateKey])
  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  };

  return (
    <>
      <div className="whatsappheader">
        <div className="whatsappdetails">
          <div className="whatsappdetails">
            <div className="whatsappname">
              {capitalizeFirstLetter(props?.lead?.first_name)}
            </div>
            <div>
              <div className="detailsname">
                {props?.lead?.first_name 
                }
              </div>
              <div>
                Chat ID: <span>{leadDetail?.customer?.customer_id}</span>
              </div>
            </div>
          </div>
          <button className="templatesbtn" onClick={showTemplate}>
            Templates
          </button>
        </div>
      </div>
      <div
        ref={chatContainerRef}
        className="chat-container"
        style={{ maxHeight: "95%", overflowY: "scroll" }}
      >
        {" "}
        {/* Ensure the container is scrollable */}
        <div className="chatsdate">
          {chatList && chatList.length
            ? dateFormat(chatList[0].created_date, "dd mmm yyyy")
            : null}
        </div>
        {chatList && chatList.length
          ? chatList.map((item, key) => {
              let messageElement = <div>{item.message}</div>;
              if (item.chat_type == "IMAGE") {
                messageElement = (
                  <div>
                    <a
                      href={item.files}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={item.files}
                        width="100px"
                        height="100px"
                        alt="chat-img"
                      />
                    </a>{" "}
                    <br />
                    <span>{item.message}</span>
                  </div>
                );
              } else if (item.chat_type == "DOCUMENT") {
                messageElement = (
                  <div>
                    <a
                      href={item.files}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={docImage}
                        width="100px"
                        height="50px"
                        alt="chat-doc"
                      />
                    </a>
                  </div>
                );
              }
              return (
                <div
                  className={`${
                    item.mode_type == "2" ? "leftchats" : "rightchats"
                  }`}
                  key={key}
                >
                  <div className="chatmsgdetails">
                    {item.mode_type == "2" ? (
                      <div className="otherchat">
                        {capitalizeFirstLetter(
                          props?.lead?.first_name
                        )}
                      </div>
                    ) : null}
                    <div
                      className={`${
                        item.mode_type == "2"
                          ? "chatsviewleft"
                          : "chatsviewright"
                      }`}
                    >
                      {messageElement}
                      <div className="chattime">
                        {dateFormat(item.created_date, "dd mmm yyyy, h:MM TT")}
                      </div>
                    </div>
                    {item.mode_type == "1" ? (
                      <div className="mychat">
                        {capitalizeFirstLetter(props?.lead?.first_name)}
                      </div>
                    ) : null}
                  </div>
                </div>
              );
            })
          : null}
        {initiate ? (
          <div className="btn-save-remarks">
            <center>
              {/* <button
                className="btn-primary"
                type="button"
                onClick={() => sendMessage(null, 1)}
              >
                Initiate Chat
              </button> */}
            </center>
          </div>
        ) : (
          <div className="sendchatsdetails">
            <div className="inputbox">
              <div className="sendinput">
                <textarea
                  type="text"
                  placeholder="Type Something..."
                  value={inputField.message}
                  onChange={handleChange}
                  rows={3}
                />
                {inputField.message && (
                  <span onClick={() => sendMessage()}>
                    <img src={sendicon} alt="icon" width={16} height={16} />
                  </span>
                )}
              </div>
              <div className="attachiconwidth">
                <i
                  className="ic-attach_file"
                  alt="icon"
                  onClick={handleIconClick}
                ></i>
                <input
                  type="file"
                  placeholder="Type Something..."
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  onChange={handleChangeImage}
                />
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="mark-lost-popup">
        <Modal show={showtemplate} handleClose={hideTemplate}>
          <div className="modal-body">
            <PartnerTemplate list={list} setInputValue={(message) => setField({ ...inputField, message })}  hideTemplate={hideTemplate} onData = {handleDataFromChild}/>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default WhatsAppTemplate;
