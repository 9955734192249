import { gql } from '@apollo/client';

export const LEADS_QUERY=(filterData)=>{
  let statusIds = [];
  if(filterData?.status_id){ 
    if(Array.isArray(filterData.status_id) && filterData.status_id.length) {
      filterData.status_id = filterData.status_id.filter((item) => typeof item === 'number' && !isNaN(item))
      statusIds = [...new Set(filterData.status_id)];
    } else if(typeof filterData.status_id === 'string'){
      statusIds = [...new Set(filterData.status_id.split(","))];
    } else if(typeof filterData.status_id === 'number'){
      statusIds = [filterData.status_id];
    }
  }
  return gql`query{
    leads(
      page_no:${filterData.page_no},
      user_id:${filterData.user_id},
      role_id:1,
      api_called_by:web,
      filter_params :{
        ${!!filterData.date_type ? `date_type: "${filterData.date_type}"` : ''}
        ${!!filterData.from_date ? ` , from_date: "${filterData.from_date}"` : ''}
        ${!!filterData.to_date ? ` , to_date: "${filterData.to_date}"` : ''}
        ${statusIds.length ? ` , status_id: [${statusIds}]` : []}
        ${+filterData.sub_status_id ? ` , sub_status_id: [${filterData.sub_status_id}]` : []}
        ${!!filterData.bank_id ? ` , bank_id: [${filterData.bank_id}]` : []}
        ${!!filterData.search_text ? ` , search_text: "${filterData.search_text}"` : ''}
        ${!!filterData.lead_source ? ` , lead_source: "${filterData.lead_source}"` : ''}
        ${!!filterData.fulfillment_type ? ` , fulfillment_type: "${filterData.fulfillment_type}"` : ''}
        ${!!filterData.column ? ` , column: "${filterData.column}"` : ''}
        ${!!filterData.sort ? ` , sort: "${filterData.sort}"` : ''}
        ${!!filterData.doc_status ? ` , doc_status: "${filterData.doc_status}"` : ''}
        ${!!filterData.search_type ? ` , search_type: "${filterData.search_type}"` : ''}
        ${!!filterData.disbursement ? ` , disbursement: "${filterData.disbursement}"` : ''}
        ${!!filterData.action_type ? ` , search_type: "${filterData.action_type}"` : ''}
        ${!!filterData.rm_id ? ` , rm_id: [${filterData.rm_id}]` : []}
        ${!!filterData.verification_status ? ` , verification_status: "${filterData.verification_status}"` : ''}
        ${!!filterData.followup_type ? ` , followup_type: "${filterData.followup_type}"` : ''} 
        ${!!filterData.loan_type ? ` , loan_type: ${filterData.loan_type}` : ''}
        ${!!filterData.loan_sub_type ? ` , loan_sub_type: ${filterData.loan_sub_type}` : ''}
        ${!!filterData.property_type ? ` , property_types: ${filterData.property_type}` : ''}
        ${!!filterData.tranche_type ? ` , tranche_type: "${filterData.tranche_type}"` : ''}  
        ${!!filterData.partner_id ? ` , partner_id: ${filterData.partner_id}` : ''}
        ${!!filterData.is_demo ? ` , is_demo: "${filterData.is_demo}"` : `, is_demo: "0"`}
        ${!!filterData.login_number ? ` , login_number: ${filterData.login_number}` : ''}
        ${!!filterData.approval_number ? ` , approval_number: ${filterData.approval_number}` : ''}
        ${!!filterData.disbursed_id ? ` , disbursed_id: ${filterData.disbursed_id}` : ''}
        ${!!filterData.source ? ` , source: ${filterData.source}` : ''}
        ${!!filterData.is_revisit ? ` , is_revisit: ${filterData.is_revisit}` : ''}
        ${!!filterData.is_otp_verified ? ` , is_otp_verified: ${filterData.is_otp_verified}` : ''}
        ${!!filterData.assign_to ? ` , assign_to: ${filterData.assign_to}` : ''}
        ${!!filterData.followup_status ? ` , followup_status: "${filterData.followup_status}"` : ''}
        ${!!filterData.followup_with ? ` , followup_with: "${filterData.followup_with.join(",")}"` : []}
        ${!!filterData.is_qualified ? ` , is_qualified: "${filterData?.is_qualified}"` : ''}
        ${+filterData.calling_reason_id ? ` , calling_reason_id: ${filterData?.calling_reason_id}` : ''}
        ${!!filterData.pre_login ? ` , pre_login: ${JSON.stringify(filterData?.pre_login)}` : []}
        ${!!filterData.docs_collected ? ` ,docs_collected: ${filterData?.docs_collected}` : ''}
        ${!!filterData.is_calling ? ` ,is_calling: ${filterData?.is_calling}` : ''}
        ${!!filterData.pre_login_data ? ` ,pre_login_data: ${filterData?.pre_login_data}` : ''}
      })
      {
      lead_list{
          id 
          fulfillment_type
          is_login_verified
          is_sanction_verified
          is_status_verified
          is_disbursal_verified
          created_date
          updated_date
          sub_status_id 
          followup_date
          followup_type
          loan_type
          loan_sub_type
          status_id
          partner_name
          assigned_partner_name
          parent_partner_name
          followup_status 
          assign_to
          verifier_id         
          status_info{
            statuslang{
              status_name
            }
          }
          sub_status_info{
            substatuslang{
              sub_status_name
            }
          } 
          customer{
            first_name
            last_name
            mobile
          }
          co_applicant{
            ca_first_name
            ca_last_name
            ca_mobile
          }
          rmdetails{
            label
            mobile
          } 
          splitpayment{
            disbursed_date
            disbursed_amount
          }         
          lead_details{
            bank{
              id
              banklang{
                bank_name
              }
            }
            tenure
            login_date
            approval_date            
            loan_amount
            login_amount
            approved_amount
            annual_income
          }
        }
        pagination{
          page_no,
          totalrecords,
          nextpage,
          prevpage,
          totalpage
        } 
    }
   
  
  }`;
  }
  
  export const LEADS_COUNT_DATA = (filterData) => gql`
   query{
    leads(
      page_no: 2

      api_called_by: web
      filter_params: {search_text: "10053" , column: "last_updated_date", sort: "desc", search_type: "partner_id"}
    ) {
      lead_list {
        id
        fulfillment_type
        is_login_verified
        is_sanction_verified
        is_status_verified
        is_disbursal_verified
        created_date
        updated_date
        sub_status_id
        followup_date
        followup_type
        loan_type
        loan_sub_type
        status_id
        partner_name
        followup_status
        status_info {
          statuslang {
            status_name
            __typename
          }
          __typename
        }
        sub_status_info {
          substatuslang {
            sub_status_name
            __typename
          }
          __typename
        }
        customer {
          first_name
          last_name
          mobile
          __typename
        }
        co_applicant {
          ca_first_name
          ca_last_name
          ca_mobile
          
        }
        rmdetails {
          label
          mobile
          
        }
        splitpayment {
          disbursed_date
          disbursed_amount
          
        }
        lead_details {
          bank {
            id
            banklang {
              bank_name
              
            }
            
          }
          tenure
          login_date
          approval_date
          loan_amount
          login_amount
          approved_amount
          annual_income
          
        }
        
      }
      pagination {
        page_no
        totalrecords
        nextpage
        prevpage
        totalpage
        
      }
      
    }
  }`;

  
export const LEADS_REMARK_QUERY=(lead_id)=>gql`
query{
  get_remarks(lead_id:${lead_id}){
    remark
    created_date
    section
    sub_section
  	user_details{
      added_by_name
    }
  }
}
`
export const LEADS_HISTORY_QUERY=(lead_id)=>gql`
query{
  get_history(lead_id:${lead_id}, api_called_by: web){
    created_date
    history_type
    rejection_type
    status_id
    title
    sub_title
    remark
    reason_list{
     name
    }
    reason_name
    user_details{
      added_by_name
    }
    checklist_details{
      id
      checklist_name
    }
    checklist_sub_status_details{
        name
    }
    status_details{
      statuslang{
        status_name
      }
    }
    sub_status_details{
      substatuslang{
        sub_status_name
      }
    }
    followup_details{
      comment      
    }
  }
}
`
export const LEADSCOUNT = (filterData) => gql`
query{
  get_partner_lead(api_called_by:web,user_id:66,role_id:3,PartnerLeadFilter:{
    partner_ids:[${filterData.id}],
    # filter_type:"last_6months"
    from_date:"${filterData.from_date}"
    to_date: "${filterData.to_date}"
  }){
    new_lead
    login_lead
    total_lead
  }
}
`
export const LEAD_DETAIL_QUERY=(lead_id)=>gql`
query{
  get_lead_detail(api_called_by:web,lead_id:${lead_id}){
    id
    partner_id
    assign_to
    is_login_verified
    is_sanction_verified
    reject_reason_id
    rejection_type
    is_disbursal_verified
    partner_name
    created_by
    loan_type
    loan_sub_type
    tranche_type
    fulfillment_type
    followup_date
    followup_type
    followup_status 
    assign_to
    doc_status  
    bucket_id 
    status_id 
    created_date
    sub_status_id
    last_sub_status_id
    tat_report_data
    is_qualified
    is_otp_verified
    is_revisit
    docs_collected
    last_updated_date
    lead_breadcrumb{
        name
        status
    }
    rmdetails{
      label
      mobile
    }
    disbursement_done   
    co_applicant{
      cibil_pdf
      cibil_status
      id
      ca_mobile
      ca_email
      ca_dob
      ca_type
      same_as_cus_addr
      ca_first_name
      ca_last_name
      ca_gender
      ca_house_type
      ca_mother_name
      ca_father_name
      ca_nationality
      ca_qualification
      ca_pa_city
      ca_pa_house_number
      ca_pa_sameas_cra
      ca_pa_state
      ca_pa_street
      ca_pa_zipcode
      ca_pancard_no
      ca_alt_phone
      ca_aadhar_no
      ca_marital_status
      ca_existing_emi
      ca_existing_emi_amount
      ca_annual_income
      ca_profession
      ca_salary_credit_mode
      ca_company_name
      ca_is_itr_filled
      ca_is_form_16_filled
      ca_gross_monthly_salary
      relationship_with_customer
      ca_company_type
      ca_loan_amount
      ca_tenure
      ca_no_of_emi
      ca_property_type
      ca_property_value
      ca_usage_type
      ca_agreement_type
      ca_cra_pincode
      ca_cra_address1
      ca_cra_address2
      ca_cra_city
      ca_cra_state
      recommended_docs{
        id
        lead_id
        doc_id
        parent_doc_id
        is_doc_uploaded
        updated_by_source
        status
        doc_upload_url
        customer_type
        customer_id
        is_recommended
      }
      ca_monthly_salary
      ca_business_proof
      ca_gross_monthly_salary
      ca_is_additional_income
      ca_no_of_accounts
      ca_offer_type
      ca_emi_ending_six_month
      ca_salutation
      ca_additional_income{
        id
        lead_id
        customer_id
        customer_type
        income_type_id
        amount_type
        amount
        status
      }
      ca_is_obligation
      ca_obligation{
        id
        lead_id
        customer_id
        customer_type
        obligation_type_id
        emi_amount
        pending_emi_months
        status
      }
      ca_offer_itr_details{
        id
        lead_id
        is_itr_filled
        npat
        depreciation
        interest
        tax_paid
        itr_year
        customer_id
        customer_type
      }
      ca_offer_bank_details{
        id
        lead_id
        account_type
        amount
        customer_id
        customer_type
      }
      employment_details{
          ca_business_address
          ca_company_phone
          ca_designation
          ca_employer_name
          ca_gross_monthly_income
          ca_industry
          ca_year_with_company
          co_applicant_id
          }
          bank_details{
          ca_account_name
          ca_account_number
          ca_account_type
          ca_bank_id
          ca_branch_name
          co_applicant_id
          ca_branch_name
        }
      __typename
    }
    customer{
      cibil_pdf
      cibil_status
      customer_id
      mobile
      email
      pancard_no
      aadhar_no
      first_name
      last_name
      dob
      pa_pincode
      gender
      no_of_dependent
      pa_house_number
      pa_street
      pa_sameas_cra
      cra_house_number
      cra_city
      cra_state
      cra_street
      cra_pincode
      cra_address1
      cra_address2
      house_type
      father_name
      mother_name
      marital_status
      qualification
      duration_of_stay
      recommended_docs{
        id
        lead_id
        doc_id
        parent_doc_id
        is_doc_uploaded
        updated_by_source
        status
        doc_upload_url
        customer_type
        customer_id
        is_recommended
      }
      no_of_accounts
      business_vintage_years
      business_proof
      industry
      offer_type
      existing_emi_amount
      emi_ending_six_month
      is_additional_income
      salutation
      additional_income{
        id
        lead_id
        customer_id
        customer_type
        income_type_id
        amount_type
        amount
        status
      }
      is_obligation
      obligation{
        id
        lead_id
        customer_id
        customer_type
        obligation_type_id
        emi_amount
        pending_emi_months
        status
      }
      offer_itr_details{
        id
        lead_id
        is_itr_filled
        npat
        depreciation
        interest
        tax_paid
        itr_year
        customer_id
        customer_type
      }
      offer_bank_details{
        id
        lead_id
        account_type
        amount
        customer_id
        customer_type
      }
      employment{
        employer_name
        business_address
        company_phone
        designation
        industry
        gross_monthly_income
        year_with_company
        telephone_number
        __typename
      }
      bank_details {
        bank_id
        branch_name
        account_name
        account_type
        account_number
        __typename
      }
      __typename
    }
    
    lead_details{
      bank{
        id
        dsa_code
        banklang{
          bank_name          
        }
      }
      lead_id
      bank_id
      login_amount
      annual_income
      no_of_emi
      tenure
      loan_amount
      existing_emi
      company_name
      profession
      salary_credit_mode
      existing_emi_amount
      is_property_identified
      usage_type
      agreement_type
      property_type
      property_sub_type
      property_agreement_value
      expected_property_value
      property_value
      property_city
      property_state
      property_address1
      property_address2
      property_pincode
      login_number
      approval_date
      login_date
      loan_amount
      approved_emi
      approval_date
      approval_number
      approved_amount
      monthly_salary
      cibil_score
      is_calling
      call_status
      calling_tag
      company_type
      company_name
      is_itr_filled
      is_form_16_filled
      gross_monthly_salary
      work_experience
      calling_reason_id
      subvension_type_id
      subvension_amount
      cross_sell_type_id
      cross_sell_product_amount
      project_name
      builder_name_id
      check_oc_cc
      ready_for_registration
      gross_monthly_salary
      emi_ending_six_month
      expected_login_date
      expected_approved_date
      expected_disbursed_date
      lod_applied_date
      lod_apply
      offer_share_date
      house_item_value
      approved_roi
      is_offer
      offer_bank
      offer_loan_amount
      offer_roi
      __typename
    }
    checklists{
      id
      lead_id
      checklist_id
      sub_status_id
      checklist_complete_date
      checklist_sub_status_id
    }
    splitpayment{
      id
      lead_id
      product_sub_type
      disbursed_id
      disbursed_date
      disbursed_amount
      disbursed_tenure
      disbursed_date
      is_tranch_verified
      transaction_done
      transaction_mode
      transaction_date
      payout_expected_date
      transaction_id
      pdd_status
      pdd_remark
      pdd_date
      pay_in_done
      pay_in_confirm_mode
      pay_in_confirm_date
      pay_in_confirm_amount
      is_transaction_verified
      is_pdd_verified
      is_payout_verified
      is_pay_in_verified
      pay_in_confirm_id
      disbursed_roi
      reporting_month
      __typename
    }
    lead_status_history{
      status_id
      sub_status_id
      __typename
    }
    status_info{
      statuslang{
        status_name
        __typename
      }
      __typename
    }
    sub_status_info {
      substatuslang {
        sub_status_name
        __typename
      }
      __typename
    }
    rmdetails{
      id
      label
      mobile
    }
    leaddocs{
      id
      lead_id
      doc_id
      parent_doc_id
      doc_path
      doc_status
      customer_type
      customer_id
      ext
      docmaster {
        
        __typename
      }
      __typename
    }
    insurance_lead_details{
      id
      ref_id
      insurance_url
      created_date
      last_updated_date
      insurance_type
      selected_premium
      content_sum_insured
      lead_status_id
      insurance_lead_status{
        id
        label
      }
    } 
    whatsAppUnread {
        mobile
        unread_count
        latest_created_date
    }
    leadSourceInfo {
        sourceId
        source
        subSourceId
        subSource
      __typename
    }
  }
}
`

export const DOCUMENT_LIST=(bank_id,template_for)=>gql`query{
  
  financierListbyID(bank_id:${bank_id},template_for:"${template_for}"){
    id
    bank_id
    template_type
    bank_group_name
    bank_group_email
    template_body
    bank_group_mobile
    bankers_email
  }
}
`

export const GET_SUB_STATUS = (status_ids) => gql`
  query{
    sub_status_list(status_ids:"${status_ids}"){
      substatuslang{
        sub_status_name,
        sub_status_id
      }
    }
  }`

export const MARK_AS_LOST = gql`
  mutation markAsLost($action_type: String!,$lead_id:Float! ,$sub_status_id: Float!,$comment: String!,$user_id: Float!){
    markAsLost(action_type:$action_type,lead_id:$lead_id,status_id:5,sub_status_id:$sub_status_id,remark:$comment,user_id:$user_id) {
      message
    }
  }`

export const ROLLBACK = gql`
  mutation rollback($rollback_type: String!, $lead_id:Float!){
    rollback(rollback_type: $rollback_type, lead_id: $lead_id) {
      message
    }
  }`

export const GET_DOCUMENTS_LIST = (property_type_id,profession_type_id,loan_type_id,bank_id=null,city_id=null) => gql`
  query{
      get_document_list(api_called_by: web,property_type_id:${property_type_id},profession_type_id:${profession_type_id},loan_type_id:${loan_type_id},bank_id:${bank_id},city_id:${city_id}) {
        id
        parent_id
        is_coapplicant
        is_financial
        is_required
        is_required_app
        show_in_app
        min_upload
        max_upload
        tab_name
        doclang {
          name
          doc_id
        }
        child {
          id
          parent_id
          is_required
          is_required_app
          show_in_app
          min_upload
          max_upload
          doclang {
            name
            doc_id
          }
        }
    }
  }
`

export const GET_FOLLOWUP = (lead_id,user_id) => gql`
query{
  get_followup(lead_id:${lead_id},api_called_by:web,user_id:${user_id}){
    followup_date
    followup_with
    followup_type
    comment
    followup_status
    updated_date
  }
}`

export const GET_ALL_FOLLOWUP = (lead_id, user_id) => gql`
query{
  get_followup(
      lead_id:${lead_id}
      api_called_by:web
      user_id:${user_id}
      ){
          id
          followup_date
          followup_with
          followup_type
          comment
          followup_status
          updated_date
        }
}`;

export const SEND_DOC_TO_FINANCEIR = gql`
mutation sendtemplatenotification($sendTemplateNotificationinput: SendTemplateNotificationInput!){
  sendtemplatenotification(sendTemplateNotificationinput : $sendTemplateNotificationinput,api_called_by:"web") {
    message
  }
}`
  
export const STATUS_REJECTION_QUERY=gql`query{
  get_reason_list{
      id
      rejection_type
      status_id
      rejectreasonlang{
          name
      }
  }  
}`;
  
export const BUCKET_STATUS_REJECTION_QUERY=gql`query{
  get_reason_bucket_wise{
      id
      rejection_type
      status_id
      bucket_id
      rejectreasonlang{
          name
      }
  }  
}`;

export const STATUS_QUERY=(page_from = null, lead_id = 0) => gql`query{
  status_list(page_from:"${page_from}", lead_id:${lead_id}){
    status_id
    status_name
    subStatusList {
      id
      name
    }
  }  
}`;

export const UPDATE_LEAD_STATUS = gql`mutation update_lead_status($UpdateLeadStatus: UpdateLeadStatus!, $api_called_by: API_CALL_BY!){
  update_lead_status(updateleadstatus: $UpdateLeadStatus, api_called_by: $api_called_by) {
    message
  }
}`

export const UPDATE_LEAD_STATUS_UPDATE_FORM = (variables) => gql`
  mutation{
    update_lead(updatelead:{
      lead_id: ${variables.lead_id},
      status_id: ${variables.status_id}, 
      sub_status_id: ${variables.sub_status_id} 
      ${variables?.is_qualified === 1 ? ` , is_qualified: "${variables.is_qualified}"` : ''}
      ${!!variables?.visit_date ? ` , visit_date: "${variables.visit_date}"` : ''}
      ${!!variables?.cra_address1 ? ` , cra_address1: "${variables.cra_address1}"` : ''}
      ${!!variables?.cra_address2 ? ` , cra_address2: "${variables.cra_address2}"` : ''}
      ${!!variables?.cra_state ? ` , cra_state: ${variables.cra_state}` : ''}
      ${!!variables?.cra_city ? ` , cra_city: ${variables.cra_city}` : ''}
      ${!!variables?.cra_pincode ? ` , cra_pincode: ${variables.cra_pincode}` : ''}
    }, api_called_by: web) {
      message
    }
  }
`;

export const RM_LIST=gql`query{
    rm_list{
        id
        label
    }
} `

export const CHECK_LIST=gql`query{
check_list{
    statuslang {
      status_id
      status_name
    }
    checkListStatus {
        id
        checklist_name
        sub_status_id
        checklist_substatus {
          id
          name
          is_final_status
        }
    }
}
}`;

export const REJECTED_REASONS_LIST=gql`query{
check_list{ 
  substatus{
    checklist{
      id
      checklist_name
      sub_status_id
      checklist_substatus{
        id
        name
        is_final_status
      }
    }
  } 
}
}`;

export const LEAD_PAYOUT_HISTORY=(lead_id,partner_id)=>gql`
  query{
    payout_history(lead_id:${lead_id}, partner_id:${partner_id})
}`;

export const EMI_FORM_FIELD=()=>gql`
  query{
    emi_cal_form
}`;

export const EMI_CALCULATOR=({loan_amount,loan_tenure,roi})=>gql`
  query{
    emi_calcultor(loan_amount: ${+loan_amount},loan_tenure:${+loan_tenure},roi:${+roi}) 
}`;

export const CHAT_TEMPLATE_LIST =(lead_id,username , tempelate_type,user_id,is_whatsapp,is_email)=>gql`
  query{
    chat_template_list(lead_id:${lead_id},user_name:"${username}" , tempelate_type: "${tempelate_type}",user_id:${user_id},is_whatsapp:"${is_whatsapp}",is_email:"${is_email}"){
      name
      template
    }
}`;

export const CHAT_LIST =(mobile, leadId = 0, userId = 0, source = 'Partner')=>gql`
  query{
    chat_list(mobile:${mobile}, leadId: ${leadId}, userId: ${userId}, source: "${source}"){   
      chat_type
      mobile
      message,
      files,
      mode_type,
      is_read,
      created_date,
    }
}`;


export const GET_UNREAD_MESSAGE_COUNT = (mobile, leadId = 0, userId = 0, source = 'Partner')=> gql`
  query  {
    unread_message_count(mobile: ${mobile}, leadId: ${leadId}, userId: ${userId}, source: "${source}") {
      mobile
      unread_count
      latest_created_date
    }
  }
`;

export const GET_UNREAD_MESSAGE_LIST = (mobile = 0, userId = 0, source = 'Sangam')=> gql`
  query  {
    unread_message_list(mobile: ${mobile}, userId: ${userId}, source: "${source}") {
      id
      lead_id
      user_id
      customer_id
      customer_name
      message
      files
      mode_type
      chat_type
      mobile
      is_read
      status
      created_date
    }
  }
`;

export const UPDATE_CHAT_READ_STATUS = gql`mutation chat_read($chatId: Float!, $userId: Float!){
  chat_read(chatId: $chatId, userId: $userId) {
    message
  }
}`

export const PARTNER_CHAT_LIST= (yoddha_code) => gql`
  query {
    partner_chat_list(yoddha_code:${yoddha_code}) {
      lead_id
      user_id
      customer_id
      message
      message_count
      files
      mode_type
      chat_type
      mobile
      is_read
      status
      created_date
    }
  }`;

export const GET_DOCUMENTS_LIST_UPDATED = (docInput) => gql`
  query{
    get_doc_list(docInput: {
      property_type_id: ${docInput?.property_type_id || 0},
      property_sub_type_id: ${docInput?.property_sub_type_id || 0},
      profession_type_id:${docInput?.profession_type_id || 0},
      product_type_id:${docInput?.product_type_id || 0},
      product_sub_type_id:"${docInput?.product_sub_type_id || ""}"
    }, api_called_by: web)
  }
`;

export const UPDATE_PARTNER_ID = (lead_id, partner_id) => gql`
  mutation{
    update_partner_id(lead_id:${lead_id},partner_id:${partner_id})
  }
`;

export const SAVE_EXPECTED_DATES__LEAD_DETAILS = gql`
mutation SaveExpectedDate($lead_id: Float!, $status_id: Float!, $expected_date: DateTime!) {
    save_expected_date(lead_id: $lead_id, status_id: $status_id, expected_date: $expected_date)
}
`;
