import React from "react";

const OpenModal = ({ handleClose, show, children , removeHeight=false, customStyle,width}) => {
  const showHideClassName = show ? 'modal display-block' : 'modal display-none';
  return (
    <div className={showHideClassName}>
      <section style={{borderRadius: 8, ...customStyle, width: width ? width : 'auto'}} className='modal-main'>

        <div className={!removeHeight ? "modal-height":""}>

          {children}
          <button onClick={handleClose} className="close_icn">
            <i className="ic-clearclose"></i>
          </button>
        </div>
      </section>
    </div>
  );
};

export default OpenModal;