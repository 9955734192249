import { getLeadRemarks, saveLeadRemarks } from '../../../store/action/allAction';
import style from "../../../style/leadRemark/leadRemark.module.scss";
import { useSelector,useDispatch } from 'react-redux'
import { useApolloClient } from '@apollo/client';
import { toast } from "react-toastify";
import { useState } from "react";
import React from 'react';

const LeadRemarkModal = ({ handleClose }) => {
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({});
    const [error, setError] = useState({});
    const client = useApolloClient()
    const dispatch = useDispatch();

    const { leadDetail,userInfo } = useSelector(({ lead, user }) => {
        return {
            remarksHistory: lead.remarksHistory || [],
            leadDetail: lead.leadDetail,
            userInfo: user.user_information,
        }
    });
    let userDetail=userInfo && JSON.parse(userInfo)
    let user_id = userDetail && userDetail.id || 0;

    const saveRemarks = async () => {
        if (!formData?.remarks || /^\s*$/.test(formData?.remarks)) {
            setError({ 'remarks': ("This is a Required Field") })
            return false;
        }
        setLoading(true);
        const LeadData = {
            lead_id: leadDetail.id,
            remark_type: 1,
            remark: formData.remarks,
            user_id: String(user_id),
            section : "",
            sub_section : ""
        }

        let { status } = await dispatch(saveLeadRemarks({ LeadData }, client))
        if (status) {
            setFormData(formData => ({
                ...formData,
                remarks: ""
            }));
            setLoading(false);
            toast.success("Success");
            setFormData({})
            dispatch((getLeadRemarks({ lead_id: leadDetail.id, source: 'WEB' }, client)));
            handleClose();
        }else{
            toast.error("Error")
            setLoading(false);
        }
        //window.location.reload();
    }

    return (
        <div className={style.container}>
            <div className={style.headerSection}>
                <div className={style.heading}>Add Remarks</div>
            </div>
            <div className={style.contentSection}>
                <label for="remark" className={style.remarkLabel}>Remark</label>
                <textarea id="remark" rows="6" className={style.remarkArea} onChange={(e)=>setFormData({remarks:e.target.value})} />
            </div>
            <button type="button" className={style.submitButton} onClick={saveRemarks} disabled={!formData?.remarks}>Submit</button>
        </div>
    )
}

export default LeadRemarkModal;