import React, { useEffect, useState } from "react";
import secureStorage from "../../config/encrypt";
import InputField from "../elements/InputField";
import Select from "react-select";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import {
  executeGraphQLMutation,
  executeGraphQLQuery,
} from "../../common/partnerExecuteGraphQLQuery";
import {
  FINEX_MASTER_LIST,
  GET_SCHEME_FINANCER_GROUPS,
  SAVE_CHILD_PARTNER_RANGE,
} from "../../services/scheme.gql";
import { toast } from "react-toastify";

const SubPartnerPayout = (props) => {
  let role;
  if (secureStorage.getItem("loginUserInfo")) {
    let loginResponse = secureStorage.getItem("loginUserInfo");
    if (loginResponse && loginResponse.data && loginResponse.data.user_data) {
      role = loginResponse.data.user_data.role;
    }
  }


  const [financerGroupList, setFinancerGroupList] = useState([]);
  const [financers, setFinancers] = useState([]);
  const [child_payout, setChild_payout] = useState("");
  const [initialFormData, setInitialFormData] = useState({
    child_payout: "",
  });
  const [formdata, setformdata] = useState({});
  const [productType, setProductType] = useState([]);
  const [loanType, setLoanType] = useState([]);
  const [rangeErrors, setRangeErrors] = useState({
    range_overlap_error: "",
    payout: [],
    rangeblank: [],
  });
  const [rangeIndex, setRangeIndex] = useState(0);

  useEffect(() => {
    setformdata(props.schemedetailsData);
    setInitialFormData(props.schemedetailsData);
    getSchemeFinacerGroups(props?.schemedetailsData?.id, props?.parent_id, props?.subPartnerId);
    getFinexMasterData();
  }, [props?.subPartnerId, props?.parent_id]);

  const getSchemeFinacerGroups = async (scheme_id, parent_id, sub_partner_id) => {
    //console.log("parent_id",parent_id)
    try {
      const schemeFGDataList = await executeGraphQLQuery(
        GET_SCHEME_FINANCER_GROUPS(scheme_id, parent_id,sub_partner_id),
        {
          notifyOnNetworkStatusChange: true,
        }
      );

      if (
        schemeFGDataList &&
        schemeFGDataList.data &&
        schemeFGDataList.data.schemeFinancerGroupbySchemeId
      ) {
        const financerGroupListNew =
          schemeFGDataList.data.schemeFinancerGroupbySchemeId;

          financerGroupListNew[0].schemerange.map(scm => {
            const filteredRange = financerGroupListNew[0]?.schemepartnerrange?.filter(scmpartner => scmpartner.range_id===scm.id)
            if(filteredRange?.length>0){
              scm.child_payout = filteredRange[0]?.payout
            }
            return scm
          })

        setFinancerGroupList(financerGroupListNew);
      }
    } catch (error) {}
  };

  const saveSchemeRanges = async (index) => {
    let payload = [];
    let flag = true
    let child_payout_value
    let payout_value
  
   // console.log("financerGroupList[index]: ", index, financerGroupList);

    financerGroupList[index]?.schemerange.forEach((element) => {
      if(flag){
        child_payout_value = Number(element.child_payout) || 0;
        payout_value = Number(element?.payout) || 0;        
       // console.log("child_payout_value > payout_value : ", child_payout_value, payout_value, (child_payout_value > payout_value))
        if (child_payout_value > payout_value) {
          flag =false
        } else {
          flag = true
        }
        payload.push({
          range_id: element?.id,
          scheme_id: element?.scheme_id,
          financer_group_id: element?.financer_group_id,
          partner_id: props?.subPartnerId ,
          child_payout: child_payout_value,
        });
      }
    });
    if(!flag){
      toast.error("Child payout cannot be greater than payout value.");
      return ;
    }
    if (flag) {      
      const variables = {
        schemeRangeInputArgs: { schemerange: [...payload] },
      };
    
      try {
        const result = await executeGraphQLMutation(SAVE_CHILD_PARTNER_RANGE, variables);
        if (result) {
          toast.success(result?.data?.schemerange?.message || "Financers Ranges Saved Successfully");
        } else {
          toast.error("Failed");
        }
      } catch (error) {
        toast.error("An error occurred while saving the ranges.");
      }
    }
  };
  

  const showFinancers = (financer_id) => {
    const financerobj = financers.find((o) => o.id === financer_id);
    return <div>{financerobj && financerobj.name}</div>;
  };

  const searchingUtility = (arr, element, objKey) => {
    for (let i = 0; i < arr.length; i = i + 5) {
      if (arr[i][objKey] === element) return arr[i];
      if (i + 1 < arr.length && arr[i + 1][objKey] === element) return arr[i + 1];
      if (i + 2 < arr.length && arr[i + 2][objKey] === element) return arr[i + 2];
      if (i + 3 < arr.length && arr[i + 3][objKey] === element) return arr[i + 3];
      if (i + 4 < arr.length && arr[i + 4][objKey] === element) return arr[i + 4];
    }
    return null;
  };

  const getFinexMasterData = async () => {
    const masterData = await executeGraphQLQuery(FINEX_MASTER_LIST());
    if (masterData && masterData.data && masterData.data.masterdata) {
      const bankData = [];
      const loan_type = []
      const producttypedata = []
      const data = masterData.data.masterdata;
      const bank_list =
        data.bank_list && data.bank_list.length > 0 ? data.bank_list : [];
      const product_type = data.loan_type && data.loan_type.length > 0 ? data.loan_type : []
      const loan_sub_type = data.loan_sub_type && data.loan_sub_type.length > 0 ? data.loan_sub_type : []
      if (bank_list && bank_list.length > 0) {
        bank_list.map((banks) => {
          bankData.push({
            id: banks.banklang.bank_id,
            name: banks.banklang.bank_name,
          });
        });
        setFinancers(bankData);
      }
      if (product_type && product_type.length > 0) {
          product_type.map(ptype => {
              producttypedata.push({ id: ptype.id, name: ptype.label })
          })
          setProductType(producttypedata)
      }
      if (loan_sub_type && loan_sub_type.length > 0) {
          loan_type.push({ id: "all", name: "All" })
          loan_type.push({ id: "0", name: "Fresh Loan" })
          loan_sub_type.map(ptype => {
              loan_type.push({ id: ptype.id.toString(), name: ptype.label })
          })
          setLoanType(loan_type)
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setformdata({ ...formdata, [name]: value });
  };

  const handleInputarray = (parentIndex, childIndex, e, field_name = "") => {
    let name = "";
    let val = "";
    
    if (e.target.name === "child_payout") {
      setChild_payout(e.target.value);
    }
    
    if (field_name && (field_name === "start_range" || field_name === "end_range")) {
      name = field_name;
      val = e.id;
    } else {
      name = e.target.name;
      val = e.target.value;
    }
  
    const updatedFinancerGroupList = [...financerGroupList];
    const currentSchemerange = updatedFinancerGroupList[parentIndex]?.schemerange || [];
    
    if (currentSchemerange[childIndex]) {
      currentSchemerange[childIndex][name] = val;
    }
  
    updatedFinancerGroupList[parentIndex] = {
      ...updatedFinancerGroupList[parentIndex],
      schemerange: currentSchemerange,
    };
   //console.log("currentSchemerange",currentSchemerange)
    setFinancerGroupList(updatedFinancerGroupList);
    
    // Update formdata to ensure it holds the latest values
    setformdata({
      ...formdata,
      [`${parentIndex}-${childIndex}`]: val,
    });
  };
  const getRangeLabel = (amount) => {
    if (amount >= 1000000000) return "100cr";
    if (amount >= 900000000) return "90cr";
    if (amount >= 800000000) return "80cr";
    if (amount >= 700000000) return "70cr";
    if (amount >= 600000000) return "60cr";
    if (amount >= 500000000) return "50cr";
    if (amount >= 400000000) return "40cr";
    if (amount >= 300000000) return "30cr";
    if (amount >= 200000000) return "20cr";
    if (amount >= 100000000) return "10cr";
    if (amount >= 90000000) return "9cr";
    if (amount >= 80000000) return "8cr";
    if (amount >= 70000000) return "7cr";
    if (amount >= 60000000) return "6cr";
    if (amount >= 50000000) return "5cr";
    if (amount >= 40000000) return "4cr";
    if (amount >= 30000000) return "3cr";
    if (amount >= 20000000) return "2cr";
    if (amount >= 10000000) return "1cr";
    return amount || "0"; // Default case if amount is below the lowest range
  };
  

  return (
    <>
      <div className="card">
       {/* {JSON.stringify(formdata)} */}
        {financerGroupList &&
          financerGroupList.map((groupData, parentIndex) => {
            return (
              <div key={parentIndex} className="card-body">
                <div className="row">
                  <div className="col-sm-3">
                    <div>Financers</div>
                    <div className="finence">
                      {groupData.schemebanks &&
                        groupData.schemebanks.length > 0 &&
                        groupData.schemebanks.map((banksdata, index) => (
                          <div key={index} className="finencegroup">
                            <b>
                              {showFinancers(banksdata.financer_id, groupData.id)}
                            </b>
                          </div>
                        ))}
                    </div>
                  </div>
                  <div className="col-sm-2">
                    <div>Employment Type</div>
                    <div style={{ marginTop: 10 }}>
                      <b>{groupData.employment_type}</b>
                    </div>
                  </div>
                  <div className="col-sm-2">
                    <div>Product Type</div>
                    <div style={{ marginTop: 10 }}>
                      <b>
                        {productType &&
                          productType.length > 0 &&
                          productType.filter(
                            (type) => type.id === groupData.product_type
                          )[0]?.name}
                      </b>
                    </div>
                  </div>

                  <div className="col-sm-2">
                    <div>Sub Product Type</div>
                    <div style={{ marginTop: 10 }}>
                      <b>
                        {groupData.loan_type
                          ? loanType &&
                            loanType.length > 0 &&
                            loanType.filter(
                              (type) => type.id === groupData.loan_type
                            )[0]?.name
                          : "All"}
                      </b>
                    </div>
                  </div>

                  <div className="col-sm-2">
                    <div>On Payin</div>
                    <div style={{ marginTop: 10 }}>
                      <b>{groupData.is_on_payin ? "Yes" : "No"}</b>
                    </div>
                  </div>
                </div>
                <>
                  {groupData.schemerange && groupData.schemerange.length > 0 && (
                    <div className="row">
                      <div className="col-sm-3 payouttable">
                        <div>From</div>
                      </div>
                      <div className="col-sm-3 payouttable">
                        <div>To</div>
                      </div>
                      <div className="col-sm-3 payouttable">
                        <div>Payout(%)</div>
                      </div>
                      <div className="col-sm-3 payouttable">
                        <div>Child Payout</div>
                      </div>
                    </div>
                  )}

                  <div>
                    {groupData.schemerange &&
                      groupData.schemerange.length > 0 &&
                      groupData.schemerange.map((rangedata, childIndex) => {
                        const child_payout_value = searchingUtility(
                          groupData.schemepartnerrange,
                          rangedata.id,
                          "range_id"
                        )?.payout || "";

                        const isInitial =
                          formdata[`${parentIndex}-${childIndex}`] === undefined;

                        return (
                          <div key={childIndex} className="payout-row">
                            <div className="row" style={{ marginBottom: 10 }}>
                              <div className="col-sm-3">
                              {getRangeLabel(rangedata.start_range)}
                                {/* {rangedata.start_range} */}
                              </div>
                              <div className="col-sm-3">
                                {getRangeLabel(rangedata.end_range)}
                                
                              </div>
                              <div className="col-sm-3">
                                <div>{   rangedata.payout || ""}</div>
                                
                                {rangeErrors &&
                                rangeErrors.payout &&
                                rangeErrors.payout.length > 0 &&
                                rangeErrors.payout[childIndex] &&
                                parentIndex === rangeIndex ? (
                                  <div className="error show">
                                    {rangeErrors.payout[childIndex].error}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>

                              <div className="col-sm-1">
                                <InputField
                                  inputProps={{
                                    type: "text",
                                    name: "child_payout",
                                    id: "child_payout",
                                    maxLength: "5",
                                    value: isInitial
                                      ? child_payout_value
                                      : formdata[`${parentIndex}-${childIndex}`] ||
                                        "",
                                  }}
                                  onChange={(e) => {
                                    handleInputarray(
                                      parentIndex,
                                      childIndex,
                                      e
                                    );
                                    setformdata({
                                      ...formdata,
                                      [`${parentIndex}-${childIndex}`]: e.target.value,
                                    });
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>

                  <div className="btn-submit-reset top-btn-none">
                    <button
                      style={{ marginLeft: 10 }}
                      className="btn btn-reset-new"
                      onClick={() => saveSchemeRanges(parentIndex)}
                    >
                      {" "}
                      Save Ranges
                    </button>
                  </div>
                </>
              </div>
            );
          })}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    auth_token: state.authToken,
    user_loggedin: state.userLoggedIn,
    dealer_id: state.dealer_id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateStateIntoRedux: (actionType, stateData) =>
      dispatch({ type: actionType, componentState: stateData }),
  };
};

export default withTranslation("ac_manager")(
  connect(mapStateToProps, mapDispatchToProps)(SubPartnerPayout)
);
