import React from "react";
import { getDateAndTimeFormat } from "../../utility/dateFormateUtility";

const CallHistoryTab = ({ data }) => {
  return (
    <div className="pre-lead-activity">
      <table className="activity-log-table">
        <thead>
          <tr>
            <th>Call Status</th>
            <th>Title</th>
            <th>Remarks</th>
            <th>Comment</th>
            <th>Activity Date</th>
            <th>Next Follow-up Date</th>
            <th>Activity Done By</th>
          </tr>
        </thead>
        <tbody>
          {data.length ? (
            data.map((log, index) => (
              <tr key={index}>
                <td>
                  <span className="activity-details">
                    <strong>Status: </strong>
                    {log.status_name}
                    <br />
                    <strong>Sub Status: </strong>
                    {log.sub_status_name}
                    <br />
                  </span>
                </td>
                <td>{log.title || "N/A"}</td>
                <td>{log.remarks || "N/A"}</td>
                <td>{log.comment || "N/A"}</td>
                <td>{getDateAndTimeFormat(log.created_date)}</td>
                <td>
                  {log.followup_date
                    ? getDateAndTimeFormat(log.followup_date)
                    : "N/A"}
                </td>
                <td>
                  {log.created_by === 1 ? (
                   "System"
                  ) : (
                   <>
                  {`AMB${log.created_by}`}
                  <span className="activity-details">
                  <strong>Name: </strong>
                  {log.created_by_name}
                   <br />
                  <strong>Email: </strong>
                  {log.created_by_email}
                   </span>
                 </>
                 )}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="7">No call activity available.</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default CallHistoryTab;
