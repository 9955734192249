import React, { useEffect, useState, useRef } from "react";
import { toast } from 'react-toastify';
import MasterService from "../../services/MasterService";
import { Add_SCHEME, Add_Scheme_Range, GET_SCHEMA_LIST_BYID, Update_SCHEME, GET_SCHEMA_LIST_BY_CODE } from "../../services/bankscheme.gql";
import { executeGraphQLQuery, executeGraphQLMutation } from "../../common/partnerExecuteGraphQLQuery";
import { NavLink,Link,useParams,Navigate } from 'react-router-dom';
import secureStorage from '../../config/encrypt';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import BankSchemedetails from "./BankSchemedetails";
import BankPayoutRanges from "./BankPayoutRanges";

const BankScheme = (props) => {

    const param = useParams()
    // console.log(props.match.params)
    const scheme_code = param.scheme_id_hash ? param.scheme_id_hash : "";
    const currentTab = param.tab ? param.tab : "details";
    const [formData, setFormData] = useState({
        city_id: [],
        scheme_type: "base",
        scheme_name: "",
        start_date: "",
        end_date: "",
        scheme_for: ""
    });

    const [selectedBuilders, setSelectedBuilders] = useState([]);
    const [selectstatus, setselectstatus] = useState("");
    const [hide, sethide] = useState(false);
    const [cityAllList, setcityAllList] = useState([]);
    const [errors, setErrors] = useState("");
    const [activeTab, setActiveTab] = useState(param.tab ? param.tab : "details");
    const [formValues, setFormValues] = useState([
        { "start_range": null, "end_range": null, "payout": null }
    ]);
    const childRef = useRef(null);
    const handleInput = (e, key = "") => {
        const name = e.target ? e.target.name : key;
        const val = e.target ? e.target.value : e.value;
        setFormData({ ...formData, [name]: val })
        let newErrors = { ...errors };
        newErrors[name] = ('');
        setErrors(newErrors);

    }

    const handleInputarray = (i, e) => {
        const name = e.target.name;
        const val = e.target.value;
        let newFormValues = [...formValues];
        newFormValues[i][name] = val;
        setFormValues(newFormValues);
    };

    const addMoreFields = () => {
        setFormValues([...formValues,
        { "start_range": '', "end_range": '', "payout": '' }
        ])
    }

    const removeFieled = (i) => {
        let newformValues = [...formValues]
        newformValues.splice(i, 1)
        setFormValues(newformValues)
    }

    useEffect(() => {
        getCityList()
        if (scheme_code && scheme_code != "") {
            getSchemeListBYCode(scheme_code)
        }
        setActiveTab(currentTab)
        // updateReduxData()
       
    }, []);

    const getCityList = () => {
        MasterService.get('core/commonservice/state_city_all')
            .then(function (response) {
                //console.log("hhhhhhhhhhhhhhh",response);
                if (response.data.status == 200) {
                    setcityAllList(response.data.data.city)
                }
            })
            .catch(function (response) {
            });
    }




    const AddSchemeSave = async (e) => {
        e.preventDefault();
        if (param.id) {
            updateScheme();
        } else {
            addScheme();
        }
    }

    const handelCheckbox = (value) => {
        let newFormData = { ...formData };
        newFormData['scheme_type'] = value;
        setFormData(newFormData);
    }

    const handleCheckboxall = (e) => {
        const checked = e.target.checked
        if (checked) {
            sethide(true)
        }
        else {
            sethide(false)
        }
    }

    const addScheme = async () => {
        const formDataNew = { ...formData };
        formDataNew.status = selectstatus?.value;
        const data = {};
        const variables = {
            addSchemeArgs: {
                scheme_type: formData.scheme_type || null,
                scheme_name: formData.scheme_name,
                start_date: formData.start_date,
                end_date: formData.end_date,
                scheme_for: formData.scheme_for ? formData.scheme_for : null,
                status: selectstatus ? parseInt(selectstatus.value) : null,
            },
        };
        data.addSchemeArgs = variables;
        if (validateForm()) {
            try {
                const result = await executeGraphQLMutation(Add_SCHEME, variables);
                if (result) {
                    toast.success(result?.data?.save_role?.message || "Success");
                    setFormData({ ...formData });
                }
            } catch (error) {
                toast.error("Failed");
                console.error(error);
            }
        }
    }

    const updateScheme = async () => {
        const formDataNew = { ...formData };
        formDataNew.status = selectstatus?.value;
        formDataNew.scheme_for = formData?.scheme_for;
        const data = {};
        const variables = {
            updateSchemeArgs: {
                scheme_id: param.id,
                scheme_type: formData.scheme_type || null,
                scheme_name: formData.scheme_name,
                start_date: formData.start_date,
                end_date: formData.end_date,
                scheme_for: formData.scheme_for ? formData.scheme_for : null,
                status: selectstatus ? parseInt(selectstatus.value) : null,
            },
        };
        data.updateSchemeArgs = variables;
        if (validateForm()) {
            try {
                const result = await executeGraphQLMutation(Update_SCHEME, variables);
                if (result) {
                    toast.success(result?.data?.update_scheme?.message || "Success");
                }
            } catch (error) {
                toast.error("Failed");
                console.error(error);
            }
        }
    }

    const getSchemeListBYCode = async (scheme_code) => {
        const schemeData = await executeGraphQLQuery(GET_SCHEMA_LIST_BY_CODE(scheme_code));
        if (schemeData && schemeData.data && schemeData.data.bank_scheme_by_code) {
            setFormData(schemeData.data.bank_scheme_by_code);
        }
    }

    const validateForm = () => {
        let validForm = true;
        let newErrors = { ...errors };
        let newFormData = { ...formData };

        if (!newFormData['scheme_name'] || newFormData['scheme_name'] === '') {
            newErrors['scheme_name'] = ('Please enter scheme name');
            validForm = false;
        }
        if (!newFormData['start_date'] || newFormData['start_date'] === '') {
            newErrors['start_date'] = ('Please enter start date');
            validForm = false;
        }
        if (!newFormData['end_date'] || newFormData['end_date'] === '') {
            newErrors['end_date'] = ('Please enter end date');
            validForm = false;
        }
        setErrors(newErrors);
        return validForm;
    };


   const updateReduxData = async () => {
        if (secureStorage.getItem('loginUserInfo')) {
          var loginResponse = secureStorage.getItem('loginUserInfo');
          props.updateStateIntoRedux('LOGIN_USER', loginResponse);
          afterLoginSetState(loginResponse)
        } else {
            return (<Navigate to={"/login"} />);
        }
      }

      const   afterLoginSetState = (loginResponse) => {
        let role = loginResponse && loginResponse.data && loginResponse.data.user_data && loginResponse.data.user_data.role || null;
        if (role != "dealercentral") {
            return (<Navigate to={"/"} />);
        }
      }

    const handleTabClick = (tabId) => {
       setActiveTab(tabId);
        let url ="";
        if(scheme_code){
         url = "/scheme/"+scheme_code+"/"+tabId;
        }else{
            url = "/scheme/"+tabId; 
        }
       // console.log("url",url);
       return (<Navigate to={url} />);
     //   window.location.href = url;
    };

    const amountRangeDisbursal = {
        fromArray: [
            { id: 0, name: '0+' },
            { id: 10000001, name: '1Cr+' },
            { id: 20000001, name: '2Cr+' },
            { id: 30000001, name: '3Cr+' },
            { id: 40000001, name: '4Cr+' },
        ],
        toArray: [
            { id: 10000000, name: '1Cr' },
            { id: 20000000, name: '2Cr' },
            { id: 30000000, name: '3Cr' },
            { id: 40000000, name: '4Cr' },
            { id: 990000000, name: 'All' },
        ]
    };

    const amountRangeInsurance = {
        fromArray: [
            { id: 0, name: '0+' },
            { id: 100001, name: '1L+' },
            { id: 200001, name: '2L+' },
            { id: 300001, name: '3L+' },
            { id: 500001, name: '4L+' },
            { id: 1000001, name: '10L+' },
            { id: 2000001, name: '20L+' },
            { id: 3000001, name: '30L+' },
            { id: 5000001, name: '50L+' },
        ],
        toArray: [
            { id: 100000, name: '1L' },
            { id: 200000, name: '2L' },
            { id: 300000, name: '3L' },
            { id: 500000, name: '4L' },
            { id: 1000000, name: '10L' },
            { id: 2000000, name: '20L' },
            { id: 3000000, name: '30L' },
            { id: 5000000, name: '50L' },
            { id: 99000000, name: 'All' },
        ]
    };
   

    return (

        <>

            <div className="container-fluid">
               
                <div className="search-wrap">
               
                <div>
                    <ul className="list-inline nav-tabs">
                        
                        <li
                            className={activeTab === 'details' ? 'tabs-item active' : 'tabs-item'}
                            onClick={() => handleTabClick('details')}
                        >
                            Scheme Details
                        </li>
                        {formData && formData.id>0 ? 
                        <li
                            className={activeTab === 'range' ? 'tabs-item active' : 'tabs-item'}
                            onClick={() => handleTabClick('range')}
                        >
                            Payout Ranges
                        </li> : ""}
                        {formData && formData.id>0 && formData.is_insurance=='1' ? 
                        <li
                            className={activeTab === 'insurancerange' ? 'tabs-item active' : 'tabs-item'}
                            onClick={() => handleTabClick('insurancerange')}
                        > 
                           Insurance Ranges
                        </li> : ""}
                    </ul>
            </div>
            
            </div>
                    <div className="tab-content">
                        {activeTab === 'details' && <div>
                            <BankSchemedetails ref={childRef} schemedetailsData={formData} handleTabClick={handleTabClick}></BankSchemedetails>
                        </div>
                        }
                        {activeTab === 'range' && scheme_code ? <div>
                            <BankPayoutRanges ref={childRef} schemedetailsData={formData} handleTabClick={handleTabClick} business_type="payin" rangeAmount={amountRangeDisbursal}></BankPayoutRanges>
                        </div>  : ""}

                        {activeTab === 'insurancerange' && scheme_code ? <div>
                            <BankPayoutRanges ref={childRef} schemedetailsData={formData} handleTabClick={handleTabClick} business_type="insurance" rangeAmount={amountRangeInsurance}></BankPayoutRanges>
                        </div>  : ""}

                       
                    </div>
               
                </div>
           

        </>
    )
};

const mapStateToProps = state => {
    return {
        auth_token: state.authToken,
        user_loggedin: state.userLoggedIn,
        dealer_id: state.dealer_id
    };
}

const mapDispatchToProps = dispatch => {
    return {
        updateStateIntoRedux: (actionType, stateData) => dispatch({ type: actionType, componentState: stateData })
    }
}

export default withTranslation('ac_manager')(connect(mapStateToProps, mapDispatchToProps)(BankScheme));
//export default AddScheme;