import Q from 'q';
import React, { useEffect, useState } from "react";
// import { useTranslation } from "react-i18next";
import { gql, useApolloClient } from '@apollo/client';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from "react-toastify";
import { CHECK_ASSIGN_USER, IS_USER_ACCESS_PAGE } from '../../../../src/config/constants';
import { executeGraphQLMutation } from '../../../common/executeGraphQLQuery';
import GeneralService from '../../../services/generalService';
import { getLoanDetail } from '../../../store/action/allAction';
import UploadMoreICn from "../../../webroot/images/upload_icn.svg";
import Loader from '../../elements/Loader';
// import { getFileExtensionFromURL } from '../../../config/constants'

function UploadedDocumentList(props) {
    const dispatch = useDispatch();
    const client = useApolloClient();
    const [filterFiles, setFilterFiles] = useState([]);
    const [document_list, setDocList] = useState([]);
    const [filter_doc_child_list, setFilterDocChildList] = useState()
    const [isLoading, setIsLoading] = useState(true);
    const [downloadList, setDownloadFiles] = useState([]);

    const { leadDetail } = useSelector(({ lead, user }) => ({
        leadDetail: lead && lead.leadDetail
    }));
    const user_information = localStorage.getItem('user_information');
    let authUser = user_information && JSON.parse(user_information);
    let accessCondition = !IS_USER_ACCESS_PAGE('doc_upload', 'doc_upload').is_edit_access;
    if(accessCondition !==1 ){
        accessCondition=CHECK_ASSIGN_USER(leadDetail?.assign_to);
    }
    useEffect(() => { 
        setFilterDocChildList(props.filter_doc_child_list)
    }, [props]);

    useEffect(() => {
        if(props.document_list){ 
            setDocList(props.document_list); 
        }
    }, [props.document_list]);

    useEffect(() => {
        setIsLoading(false);
    }, []);
    useEffect(() => { 
        let docRecord = props.filterFiles.filter(ob=> ob.customer_id ==props.uId && props.uType==ob.customer_type);
        setFilterFiles(docRecord);
    }, [props.filterFiles])

    // Upload Image 
    const handleChangeImage = (event) => {
        let files = Array.from(event.target.files), awsRes = [], leadDocArr = [];
        if (files && files.length) {
            setIsLoading(true);
            var formData = new FormData(); 
            formData.append("dealer_id", leadDetail.id);
            formData.append("upload_type", "dealer_docs"); 
            for(let imgFile of event.target.files){ 
              formData.append("images", imgFile); 
            } 
            awsRes.push(GeneralService.uploadFilesToS3(formData, { 'Content-Type': 'multipart/form-data' }))
        } else {
            awsRes.push(Promise.resolve({ data: { status: 200, message: 'Success', data: ['not_updated'] } }));
        }

        Q.allSettled(awsRes).then((uploadOutput) => {
            uploadOutput.forEach((res, index) => {
                let response = res.value || [];
                if (res.state === 'fulfilled') {
                    if (response.data.data.length) {
                        let resData = response.data.data;
                        resData.forEach((row, i) => {
                            let loanDetailsSaveDoc = {
                                lead_id: leadDetail.id,
                                doc_path: row.file_url,
                                parent_doc_id: 0,
                                doc_id: 0,
                                doc_source: 'web',
                                user_id: authUser?.id || 0,
                                customer_type:props.uType,
                                customer_id: +props.uId
                            }
                            leadDocArr.push(loanDetailsSaveDoc);
                        });
                    }
                }
            });
            if (leadDocArr.length) {
                let variables = {
                    saveDocInput: {
                        lead_id: leadDetail.id,
                        lead_docs: leadDocArr
                    },
                    api_called_by: 'web'
                };

                let mutation = gql`mutation save_doc($saveDocInput: SaveDocInput!, $api_called_by: API_CALL_BY!){
                    save_doc(saveDocInput: $saveDocInput, api_called_by: $api_called_by) {
                        lead_id
                        doc_id
                        doc_path
                    }
                }`;

                executeGraphQLMutation(mutation, variables, client).then(result => {
                    if (result && result.data) {
                        setIsLoading(false);
                        document.getElementById("uploadForm").reset();
                        toast.success('Document uploaded successfully');
                        let newArrayfiles = filterFiles.concat(result.data.save_doc); 
                        setFilterFiles(newArrayfiles);
                        // props.getDocumentlist();
                        dispatch(getLoanDetail(leadDetail.id, client))
                    } else {
                        setIsLoading(false);
                    }
                }).catch((error) => {
                    setIsLoading(false);
                });
            } else {
                toast.error('Error in image upload!');
                setIsLoading(false);
                document.getElementById("uploadForm").reset();
            }
        })
    }

    // delete Image 

    const deleteDocument = (docId, index) => {
        let UploadedFilesArr = [...filterFiles];
        setIsLoading(true);        
        let variables = {
            updateDocInput: {
                id: docId,
                lead_id: leadDetail.id,
                status: '0',
                parent_doc_id: 0,
                doc_id: 0,
                doc_source: 'web',
                user_id: authUser?.id || 0,
                tab_name:props.activeTab
            }
        };

        const mutation = gql`
            mutation update_doc($updateDocInput: UpdateDocInput!) {
                update_doc(updateDocInput: $updateDocInput) {
                id,
                lead_id
                doc_id
                }
            }
            `;

        executeGraphQLMutation(mutation, variables, client).then(result => {
            if (result && result.data) {
                toast.success("Success");
                if (result.data) {
                    dispatch(getLoanDetail(leadDetail.id, client))
                }
                setIsLoading(false);
            } else {
                setIsLoading(false);
            }
        }).catch(error => {
            setIsLoading(false);
        });
        if (index !== -1) {
            UploadedFilesArr.splice(index, 1);
        }
        setFilterFiles(UploadedFilesArr);
    }

    // markedChecked

    const markedChecked = (event) => {
        let inpvalue = event.target.value;
        let downloadListNew = [...downloadList];
        let itemIndex = downloadListNew.indexOf(inpvalue);
        if (event.target.checked && itemIndex === -1) {
            if (inpvalue === 'all') {
                downloadListNew = [...filterFiles.map(file => {
                    if (file.doc_id && file.doc_status !== '0') {
                        return file.id.toString();
                    }
                })];
            }
            downloadListNew.push(inpvalue);

        } else {
            if (itemIndex > -1) {
                if (inpvalue === 'all') {
                    downloadListNew = [];
                } else {
                    downloadListNew.splice(itemIndex, 1);
                    let allIndex = downloadListNew.indexOf('all');
                    if (allIndex > -1) downloadListNew.splice(allIndex, 1);
                }

            }
        }

        if (downloadListNew.length === filterFiles.length && !downloadListNew.includes('all')) downloadListNew.push('all');

        setDownloadFiles(downloadListNew);
    }

    // download All

    const DownloadAll = () => {
        let links = []
        if (filterFiles.length) {
            setIsLoading(true);
            links = filterFiles.filter(v => downloadList.includes(v.id.toString())).map(v => v.doc_path);
            var zip = new JSZip();
            var count = 0;
            let zipfoldername = (leadDetail.id) ? leadDetail.id : "docs"
            var zipFilename = zipfoldername + ".zip";
            links.forEach((url, i) => {
                let image_url = url.split("?")[0];
                let base_name = image_url.split('/').pop()

                // var filename = links[i];
                // filename = filename.replace(/[\/\*\|\:\<\>\?\"\\]/gi, '').replace("httpsi.imgur.com", "");

                JSZipUtils.getBinaryContent(url, (err, data) => {
                    if (err) {
                        throw err; // or handle the error
                    }
                    zip.file(base_name, data, { binary: true });
                    count++;
                    if (count === links.length) {
                        zip.generateAsync({ type: 'blob' }).then((content) => {
                            saveAs(content, zipFilename);
                            setIsLoading(false);
                        });
                    }
                });
            });

        }
    }

    const markAllAsApproved = async () => {
        let leadDocs = [];
        if (filterFiles.length) {
            leadDocs = filterFiles.filter(v => downloadList.includes(v.id.toString()) && ![2, 0].includes(v.doc_status)).map(file => ({
                id: file.id,
                doc_id: file.doc_id,
                parent_doc_id: file.parent_doc_id
            }));
            /** If selected docs already 2 */
            if (leadDocs.length < 1) {
                return toast.error("Already Approved");
            }
            let data = {   
                lead_id: leadDetail.id,
                user_id: authUser.id,
                doc_status: "2",
                doc_source:"web",
                customer_id: +props.uId,
                customer_type: props.uType,
                remark: '',
                update_action_type: 'file',
                lead_docs: leadDocs   
            }
            manageDocAction(data);
            setIsLoading(true);

        }
    }
        

    const manageDocAction = async (leadData) => {
        if (leadData && leadData.lead_docs && leadData.lead_docs.length) {
            setIsLoading(true);
            let variables = { approvedDocInput: leadData };
            const mutation = gql`
                mutation approved_document($approvedDocInput: ApprovedDocInput!) {
                    approved_document(approvedDocInput: $approvedDocInput) {
                    lead_id
                    }
                }`;
            executeGraphQLMutation(mutation, variables, client).then(result => {
                if (result && result.data) {
                    toast.success("Doc Approved Successfully");
                    if (result.data) {
                        setDownloadFiles([]);
                        dispatch(getLoanDetail(leadDetail.id, client))
                    }
                    setIsLoading(false);
                } else {
                    setDownloadFiles([]);
                    setIsLoading(false);
                }
            }).catch(error => {
                toast.error(error.message);
                setIsLoading(false);
            });
        } else {
            toast.error("No image found.");
        }
    }

    const send4TagFile = (file_id) => {
        props.send4TagFile(file_id);
    }

    let isReadOnly = 0;
    let status_history=leadDetail && leadDetail.lead_status_history.filter((v)=>v.sub_status_id ===3);
    let status_historyci=leadDetail && leadDetail.lead_status_history.filter((v)=>v.sub_status_id===6);
    let add_more_disabled=(status_history.length && props.activeTab==='first_submit') || leadDetail.status_id===3 || leadDetail.status_id===5 || (status_historyci.length && props.activeTab==='pre-disbursal');
    // let removeAddmoreBtn = ([5].includes(leadDetail.status_id)) || ([1,9,10,11,27].includes(leadDetail.sub_status_id)) ? true :false;
 
     
    return (
        <div className="upload-document-view-bx" key={filterFiles.length}>
             {/* <Heading doc={document_lists}/> */}
            
            {
                (filterFiles && filterFiles.length) ? 
                <div className="select-all-opt">
                    <div className="custom-control custom-checkbox check-box-small select-check-bx">
                        <input id="selectall" type="checkbox" className="custom-control-input " name="Buyer Type" checked={downloadList.includes('all') ? true : false} onClick={markedChecked} value="all" onChange={()=>{}}/>
                        <label className="custom-control-label" htmlFor="selectall">Select All </label>
                    </div>
                </div> : null
            }
            <div className="row" key={filterFiles.length}>
                <div className="col-md-3">
                    <div className="img-bx-select-opt">
                        <div className="image-bx more-file-upload">
                            <img src={UploadMoreICn} className="upload-icn" alt='' />
                            <form id="uploadForm" method="post" encType="multipart/form-data">
                                <input type="file" name="fileName[]" multiple id="fileupload" onChange={handleChangeImage} />
                            </form>
                            <p>Add More</p>
                        </div>

                    </div>
                </div>
                {
                    (filterFiles && filterFiles.length) ? filterFiles.map((file, index) => {
                        // file.ext = (file.doc_path) ? getFileExtensionFromURL(file.doc_path) : '';
                        return (<React.Fragment key={`${file.id}${index}`}>
                            <div className="col-md-3">
                                <div className="img-bx-select-opt">
                                    <div className="image-bx">
                                        {
                                            (file.ext === 'pdf' || file.ext === 'PDF') ?
                                                <a href={file.doc_path} target="_blank" rel="noopener noreferrer">
                                                    <i className="ic-pdf-file icons-normal"></i>
                                                </a> : <img src={file.doc_path} alt="images doc_id here" className="" style={{ "maxHeight": "135px" }} />

                                        }
                                        {
                                            (file.id) ? (<span className="imgTag" data-key="tag_img" data-id={file.id} data-document_id={file.id} onClick={() => send4TagFile(file.id)}>Tag</span>) : null
                                        }

                                        {(file && file.doc_id && file.doc_status == '2') ? <span className="image-status">Approved</span> : (file && file.doc_id && file.doc_status == '0') ? <span className="image-requested">Rejected</span> : ''}

                                    </div>
                                    {
                                        (add_more_disabled || isReadOnly || (file.doc_id && file.doc_status !== '1' && ["first_submit", "pre_disbursal", "car_transfer"].includes(props.activeTab))) ? null : (<div className="close-icn" onClick={() => { if (window.confirm('Confirm, do you want to delete?')) deleteDocument(file.id, index) }}>
                                            <i className="ic-clearclose"></i>
                                        </div>)
                                    }

                                    <div className="custom-control custom-checkbox check-box-small">
                                        {/* Checking if document is tagged */}
                                        {file.doc_id ? (
                                            <>
                                                <input id={`Individual${file.id}`} type="checkbox" className="custom-control-input "
                                                    disabled={file && file.doc_status === '0'} 
                                                    onChange={()=>{}}
                                                    name="selectFile" checked={downloadList.includes(file.id.toString()) ? true : false} onClick={markedChecked} value={file.id} />
                                                <label className="custom-control-label" htmlFor={`Individual${file.id}`}>
                                                    {(file.parent_doc_id) ? document_list?.filter(v => v.id === file.parent_doc_id).map(v => v.doclang.name)[0] + ' | ' + filter_doc_child_list.filter(v => v.id === +file.doc_id).map(v => v.doclang.name)[0] : filter_doc_child_list.filter(v => v.id === +file.doc_id).map(v => v.doclang.name)[0]}
                                                </label>
                                            </>
                                        ) : null}
                                    </div>
                                </div>
                            </div>

                        </React.Fragment>)

                    }) : null
                }                

                {(isLoading) ? (<Loader />) : null}
            </div>
            {
                (downloadList && downloadList.length) ? (<div className="btn-line-img">
                    <button className="btn-line" onClick={DownloadAll}>{`${downloadList.length > 1 ? 'Download All Photos' : 'Download Photo'}`}</button>
                    <button className="btn-line" onClick={markAllAsApproved}>Mark as Verify</button>
                   {/* {(!["pre-disbursal","first_submit","post_disbursal"].includes(props.activeTab)) && (<button className="btn-line" onClick={markAllAsApproved}>Mark as Verify</button>)} */}

                </div>) : null
            }

        </div>
    )
}
export default UploadedDocumentList;
