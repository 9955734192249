import DateFormate from 'dateformat';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RoleId } from '../../config/constants';
import secureStorage from '../../config/encrypt';
import MasterService from '../../services/MasterService';
import SearchResult from './Manageresult';
import SearchDealer from './search-dealer';
import sfaService from '../../services/sfaService';
import { executeGraphQLQueryFinex } from '../../common/executeGraphQLQueryFinex';
import CallerSearchResult from './caller'
import { LEADSCOUNT } from "../../services/leads.gql";
import {USERS_FOR_VIEW_AMBAK_LEADS, IS_USER_ACCESS_PAGE} from '../../config/constants';
import Pagination from '../elements/pagination';

class ManageDealerList extends Component {
   
    constructor(props) {
        
        super(props);
        this.state = {
        
            loading:false,
            pageloading:true,
            dealerListData: [],
            searchByList: [],
            cityAllList: [],
            dcSkusList: [],
            mergeData : [],
            leadsCount : [] ,
            totalPages : 0,
            currentPage :1,
            itemsPerPage : 10,
            total : 0,

            user_id: null ,
            dealerCountStatusDetail: {},
            page: 0,
            showDealerCount : false,
            filterData: {
                status: 4,
                list_type:"pending"
            },
            redirect: false,
            mappingCsvData:[],
            dealerPremiumTypeList:[],
            leadsCount : [] , 
            getProfessionlist: [],
            rmList: {},
            role_type:""
            
        };
        // test = useApolloClient()
        // this.onScroll = this.onScroll.bind(this);
        this.getDealerList = this.getDealerList.bind(this);
        this.flag = true;
        // this.client = useApolloClient();
        this.csvLink = React.createRef();
        
    }
    
   
    
    updateReduxData = async () => {
  


        const token = JSON.parse(secureStorage.storage.user_information) 

        const user_id = token.access_token.id;
       
        return (token && token.access_token)
        ? user_id : null;
    }
    componentDidMount = async () => {
        const searchParams = new URLSearchParams(window.location.search);
        const type = searchParams.get('type');
        const role = searchParams.get('role')
        this.state.role_type = role;
        
        
        let filterData = this.state.filterData;
        if (type === 'active' ||  type === "verified")  {
            filterData['status'] = '1';
            filterData['list_type'] = '';
        } else if (type === 'inactive') {
            filterData['status'] = '2';
            filterData['list_type'] = '';
        }else if (type === 'incomplete') {
            filterData['status'] = '3';
            filterData['list_type'] = '';
        }else if (type === 'pending') {
            filterData['status'] = '4';
            filterData['list_type'] = 'pending';
        }
        else if (type === 'blacklist') {
            filterData['status'] = '5';
            filterData['list_type'] = '';
        }else if (type === 'rejected') {
            filterData['status'] = '4';
            filterData['list_type'] = 'rejected';
        }  else if (type === 'all') {
            filterData['status'] = '';
            filterData['list_type'] = '';
        }else if (type == 'ready_for_verification') {
            filterData['status'] = '4';
            filterData['list_type'] = 'ready_for_verification';
        }else{
            filterData['status'] = '';
            filterData['list_type'] = '';
        }
        this.setState({ filterData });

        await this.updateReduxData();
        // this.onScroll('Mounter');
        window.addEventListener('scroll', this.onScroll);
        await this.getSearchByList();
        await this.getCityList();
        await this.getDealerCountStatusDetail();
        await this.getLanguageList();
        // await this.getLeadsCount()
        this.getRmList();
        // const { state } = this.props.location;
                let current_page = this.state.page;
                let loading=false,pageloading=false;
        this.setState({ page: current_page + 1,loading:loading,pageloading:pageloading}, () => {
            this.getDealerList();
        });
        
    }

    componentDidUpdate(prevProps) {
        if (prevProps.status !== this.props.status) {
            this.setState({ filterData: { ...this.state.filterData.status, status: this.props.status } });
        }
        if (prevProps.listType !== this.props.listType) {
            this.setState({ filterData: { ...this.state.filterData.list_type, list_type: this.props.list_type } });
        }
    }
    

    getRmList = async () => {
        const requestData = {
            role_id: [1,2,3,4,5,6,7,8]
        }
        const rmData = await sfaService.getRMList(requestData);
        this.setState({ rmList: rmData });

    }

    getLanguageList() {
        let self = this
        MasterService.getMasters(['dealer_premium_type','professions_list'],
            { 'headers': { 'Authorization': self.props.auth_token } })
            .then((response) => {
                if (response.status === 200 && response.data.status === 200) {
                    self.setState({
                        dealerPremiumTypeList: response.data.data.dealer_premium_type,
                        getProfessionlist: response.data.data.professions_list
                    }, () => {
                    })
                    
                } else if (response.status === 401 && response.data.status === 401) {
                    self.setState({ redirect: true })
                }
            }).catch((error) => {
            });
    }

    afterLoginSetState = (loginResponse) => {
        let role = loginResponse && loginResponse.data && loginResponse.data.user_data && loginResponse.data.user_data.role || null;
        if (role != "admin") {
            this.setState({ redirect: '/' })
        }
    }
    handleOptionChange = (sname, oValue) => {
        let filterData = this.state.filterData;
        if (sname === "profession_id" || sname==="assigned_bm_id" || sname==="kyc") {
            filterData[sname] = (oValue.id != null) ? oValue.id : oValue.id;
          }else{
          filterData[sname] = (oValue.value != null) ? oValue.value : oValue.target.value;
          }
        this.setState({
            filterData
        });
    }
    handleCityOptionChange = (sname, oValue) => {
        let filterData = this.state.filterData;
        filterData[sname] = (oValue.id) != null ? oValue.id : oValue.target.id;
        this.setState({
            filterData
        });
    }
    handleSkuOptionChange = (sname, oValue) => {
        let filterData = this.state.filterData;
        filterData[sname] = (oValue.sku_short_name) != null ? oValue.sku_short_name : oValue.target.sku_short_name;
        this.setState({
            filterData
        });
    }
    handlePremiumChange = (sname, oValue) => {
        let filterData = this.state.filterData;
        filterData[sname] = (oValue.code) != null ? oValue.code : oValue.target.code;
        this.setState({
            filterData
        })
    }

    handleStatusClick = async (sname, ovalue) => {
        let filterData = this.state.filterData;
        if (sname === 'active' || sname === "verified") {
            filterData['status'] = '1';
            filterData['list_type'] = '';
        } else if (sname === 'inactive') {
            filterData['status'] = '2';
            filterData['list_type'] = '';
        }else if (sname === 'incomplete') {
            filterData['status'] = '3';
            filterData['list_type'] = '';
        }else if (sname === 'pending') {
            filterData['status'] = '4';
            filterData['list_type'] = 'pending';
        }else if (sname === 'finmissed' || sname ==='kycmissed') {
            filterData['status'] = '4';
            filterData['list_type'] = ovalue;
        }
        else if (sname === 'blacklist') {
            filterData['status'] = '5';
            filterData['list_type'] = '';
        }else if (sname === 'rejected') {
            filterData['status'] = '4';
            filterData['list_type'] = 'rejected';
        }  else if (sname === 'all') {
            filterData['status'] = '';
            filterData['list_type'] = '';
        }else if (sname == 'ready_for_verification') {
            filterData['status'] = '4';
            filterData['list_type'] = 'ready_for_verification';
        } else if (sname == 'doc_pending') {
            filterData['status'] = '4';
            filterData['list_type'] = 'doc_pending';
        }else if (sname == 'all_pending') {
            filterData['status'] = '4';
            filterData['list_type'] = 'pending';
        }
        this.setState({ filterData });
        this.state.currentPage=1;
        this.getDealerList();
       this.getDealerCountStatusDetail();
    }

    getDealerList() {
        let thisObj = this;
        let caller_dashboard = IS_USER_ACCESS_PAGE("partner", "direct_partner_sales_executive")?.is_edit_access;
        let parter_dashboard = IS_USER_ACCESS_PAGE("partner", "partner_dashboard")?.is_edit_access;
    
        if (caller_dashboard == true || parter_dashboard == false) {
            let token = JSON.parse(secureStorage.storage.user_information);
            if (token.yodha_code) {
                let yodha_id = token.yodha_code;
                this.state.filterData['assigned_bm_id'] = yodha_id;
                
            }
        }
    
        MasterService.post('dealer/dealer/dealer-list', { 'page_no': this.state.currentPage, ...this.state.filterData , "call_by_source" : "sangam" })
            .then(async function (response) {
                thisObj.flag = false;
                thisObj.setState({ loading: false, pageloading: false, total: response.pagination });
    
                if (response.data.status === 200) {
                    const RECORDS_PER_PAGE = 10;
                    thisObj.setState({
                        totalPages: Math.ceil(response.data.pagination.total / RECORDS_PER_PAGE)
                    });
                    if (response.data.data.length) {
                        if (thisObj.state.page <= 1) {
                            thisObj.setState({ dealerListData: response.data.data, loading: false, pageloading: false }, async () => {
                                thisObj.flag = true;
                                await thisObj.leadsdataCount();
                            });
                        } else {
                            thisObj.setState({
                                dealerListData: thisObj.state.dealerListData.concat(response.data.data),
                                loading: false,
                                pageloading: false
                            }, async () => {
                                thisObj.flag = true;
                                await thisObj.leadsdataCount();
                            });
                        }
                    } else {
                        if (thisObj.state.page <= 1) {
                            thisObj.setState({ dealerListData: response.data.data });
                        }
                    }
                }
            })
            .catch(function (response) {
                thisObj.setState({ loading: false, pageloading: false });
            });
    }
    
    leadsdataCount = async () => {
        for (const data of this.state.dealerListData) {
          try {
            let filterData = {}
            filterData.id = data.id
            filterData.to_date =this.getFormattedDate();
            const toDate = new Date();
            toDate.setMonth(toDate.getMonth() - 6);
            filterData.from_date= this.formatDate(toDate);
            const result = await executeGraphQLQueryFinex(LEADSCOUNT(filterData));
            let total_lead = result?.data?.get_partner_lead[0]?.total_lead;    
            
            this.setState(prevState => ({
              dealerListData: prevState.dealerListData.map(d =>
                d.id === data.id ? { ...d, total_leads: total_lead } : d
              )
            }));
          } catch (error) {
            console.error("Error fetching lead count:", error);
          }
        }
      };
       getFormattedDate() {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const day = String(today.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
      }
      formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
      };
    
    submitFilterForm = () => {
        this.state.page=1;
        // this.setState({pageloading:true})
        this.getDealerList();
        this.getDealerCountStatusDetail();
    }

    submitExportData = () => {
        this.getExportDataList();
    }

    getExportDataList() {
        var thisObj = this;
        MasterService.post('dealer/dealer/get_export_data', { 'page_no': this.state.currentPage, ...this.state.filterData })
            .then(function (response) {
                thisObj.flag = false;
                thisObj.setState({ loading: false, pageloading: false })
                if (response.data.status === 200) {
                    let csv_object = response.data.data.map(elm => ({
                        "Partner Status": elm.status ? elm.status === 1 ? 'Active' : elm.status === 2 ? 'Inactive' : elm.status === 3 ? 'Incomplete' : elm.status === 4 ? 'Pending' : elm.status === 5 ? 'Blacklist' : 'Unknown' : "",
                        "Ambak Code": elm.gcd_code ? elm.gcd_code : "",
                        "Partner Name": elm.organization ? elm.organization : "",
                        "Partner Email": elm.dealership_email ? elm.dealership_email : "",
                        "Owner Name": elm.owner_name ? elm.owner_name : "",
                        "Date of Joining": elm.date_of_joining ? DateFormate(new Date(elm.date_of_joining),"yyyy-mm-dd") : "",
                        "Created Date": elm.created_date ? DateFormate(new Date(elm.created_date),"yyyy-mm-dd") : "",
                        "State": elm.statename ? elm.statename : "",
                        "City": elm.cityname ? elm.cityname : "",
                        "Location": elm.localityname ? elm.localityname : "",
                        "Onboarded By": elm.onboarded_by ? elm.onboarded_by : "Admin",
                        "UserRole": elm.user_role ? RoleId[elm.user_role] : "Admin",
                        "Partner Type": elm.premium_code && thisObj.state.dealerPremiumTypeList.findIndex(({ code }) => code === elm.premium_code) > -1 ? thisObj.state.dealerPremiumTypeList.filter(({ code }) => code === elm.premium_code)[0].title : "",
                    }));
                    thisObj.setState({ mappingCsvData: csv_object }, () => {
                        if (thisObj.csvLink.current && thisObj.csvLink.current.link) thisObj.csvLink.current.link.click();
                    })
                }
            })
            .catch(function (error) {
                console.log("error", error)
                thisObj.setState({ loading: false, pageloading: false })
            });
    }

    getSearchByList() {
        MasterService.get('dealer/dealer/search-by-list')
            .then((searchResult) => {
                this.flag = false;
                if (searchResult && searchResult.data && searchResult.data.status === 200 && searchResult.data.data) {
                    // change the value of Contact no to Mob no (note: this is temp change. it should be handle in api res) 
                    const updatedData = searchResult?.data?.data?.map(item => 
                        item.label === "Contact no" 
                        ? { ...item, label: "Mobile Number" } 
                        : item
                    );
                    this.setState({
                        searchByList: updatedData
                    })
                }
            })
            .catch(function (searchError) {
                console.error("searchError", searchError)
            });
    }
    getCityList() {
        var parentObj = this;
        
        var thisObj = this;
        MasterService.get('core/commonservice/state_city_all')
            .then(function (response) {
               
                thisObj.flag = false;
                if (response.data.status === 200) {

                    parentObj.setState({
                        cityAllList: response.data.data.city 
                    })
                }
            })
            .catch(function (response) {
            });
    }
    getDealerSkus() {
        var parentObj = this;
        
        var thisObj = this;
        MasterService.get('core/commonservice/master/?master[]=dealer_sku')
            .then(function (response) {
                thisObj.flag = false;
                if (response.status === 200 && response.data.status === 200) {
                    parentObj.setState({
                        dcSkusList: response.data.data.dealer_sku || []
                    })
                }
            })
            .catch(function (response) {
            });
    }
    getDealerCountStatusDetail() {
        let caller_dashboard =  IS_USER_ACCESS_PAGE("partner", "direct_partner_sales_executive")?.is_edit_access;
        let parter_dashboard =  IS_USER_ACCESS_PAGE("partner", "partner_dashboard")?.is_edit_access;

        if (caller_dashboard == true || parter_dashboard == false ) {
            let token = JSON.parse(secureStorage.storage.user_information)
            let yodha_id = token.yodha_code
            this.state.filterData['assigned_bm_id'] = yodha_id
        }
        var parentObj = this;
        
        MasterService.post('dealer/dealer/count_dealer_status', { 'page_no': this.state.currentPage, ...this.state.filterData })
            .then(function (response) {
                if (response.status === 200 && response.data.status === 200) {
                    parentObj.setState({
                        dealerCountStatusDetail: response.data.data || {}
                    },()=>{
                    })
                }
            })
            .catch(function (response) {
            });
    }
    handlePageChange = (pageNumber) => {
        this.setState({ currentPage: pageNumber }, () => {
            
            this.getDealerList();
            this.setState({pageloading:true})
          //  console.log("currenPage " , this.state.currentPage)
        });
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // Optional: smooth scrolling behavior
          });
    }
   
  
    render() {
        const { currentPage, itemsPerPage, dealerListData } = this.state;
        const indexOfLastItem = currentPage * itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - itemsPerPage;
        const currentItems = dealerListData.slice(indexOfFirstItem, indexOfLastItem);
        const totalPages = Math.ceil(dealerListData.length / itemsPerPage +1 );
        const pagination = [];
        for (let i = 1; i <= totalPages; i++) {
            pagination.push(
                <li key={i} className="page-item" style={currentPage === i ? { backgroundColor: 'red', color: 'white' } : null}>
            <button className="page-link" onClick={() => this.handlePageChange(i)}>{i}</button>
        </li>
            );
        }


        const loaderContainerStyle = {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            width: '100%',
          };

        return this.state.pageloading ? (
            <div style={loaderContainerStyle} >
                <div className='loading result-wrap'></div>
            </div>
            ) :(
            <div className="container-fluid">
                <div className="addStock">
                    
                    <SearchDealer 
                    dcSkusList={this.state.dcSkusList} 
                    rmList={this.state.rmList} 
                    getProfessionlist={this.state.getProfessionlist} 
                    cityAllList={this.state.cityAllList} 
                    searchByList={this.state.searchByList} 
                    dealerPremiumTypeList={this.state.dealerPremiumTypeList} 
                    optionChange={this.handleOptionChange} 
                    optionCityChange={this.handleCityOptionChange} 
                    optionSkuChange={this.handleSkuOptionChange} 
                    optionPremiumChange={this.handlePremiumChange} 
                    onSubmitFilter={this.submitFilterForm}
                    filterData={this.state.filterData} 
                    componentDidUpdate = {this.componentDidUpdate}
                    leadsCount = {this.state.leadsCount}
                    />


                    
                    <div className="clearfix">
                     
                    {this.state.role_type === 'admin' ?    <SearchResult 
                        leadsCount = {this.state.leadsCount}
                        pageloading={this.state.pageloading} 
                        getProfessionlist={this.state.getProfessionlist} 
                        loading={this.state.loading} 
                        dealerListData={this.state.dealerListData} 
                        // mergeData = {this.merger(this.state.leadsCount , this.state.dealerListData)}
                    
                        statusClick={this.handleStatusClick} 
                        dealerCountStatusDetail={this.state.dealerCountStatusDetail}
                        filterData={this.state.filterData} 
                        componentDidUpdate = {this.componentDidUpdate}
                        onExportData={this.submitExportData}/>: ""}

                        {this.state.role_type === 'caller' ?    <CallerSearchResult 
                        leadsCount = {this.state.leadsCount}
                        pageloading={this.state.pageloading} 
                        getProfessionlist={this.state.getProfessionlist} 
                        loading={this.state.loading} 
                        dealerListData={this.state.dealerListData} 
                        // mergeData = {this.merger(this.state.leadsCount , this.state.dealerListData)}
                    
                        statusClick={this.handleStatusClick} 
                        dealerCountStatusDetail={this.state.dealerCountStatusDetail}
                        filterData={this.state.filterData} 
                        componentDidUpdate = {this.componentDidUpdate}
                        onExportData={this.submitExportData}/>: ""}

                        <Pagination
                            pageCount={this.state.totalPages}
                            activePage={currentPage}
                            onPaginate={this.handlePageChange}
                        />
                        
                    </div>
                </div>
            </div>
        )
    }
}
const mapStateToProps = state => {
    return {
        auth_token: state.authToken,
        user_loggedin: state.userLoggedIn,
        dealer_id: state.dealer_id
    };
}

const mapDispatchToProps = dispatch => {
    return {
        updateStateIntoRedux: (actionType, stateData) => dispatch({ type: actionType, componentState: stateData })
    }
}
export default withTranslation('dealers')(connect(mapStateToProps, mapDispatchToProps)(ManageDealerList));
//export default connect(mapStateToProps, mapDispatchToProps)(ManageDealerList);
//export default ManageDealerList;