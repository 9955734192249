import { useApolloClient } from '@apollo/client';
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams,useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { executeGraphQLQuery } from "../../common/executeGraphQLQuery";
import { USERS_FOR_VIEW_AMBAK_LEADS } from '../../config/constants';
import crypto from "../../config/crypto";
import { LEADS_QUERY, RM_LIST, STATUS_QUERY } from '../../services/leads.gql';
import { getMasterData } from "../../store/action/allAction";
import LeadListTable from './LeadLIstTable';
import LeadListFilter from './LeadListTopFilter';

const LeadList = (props) => {
  const { t } = useTranslation('language');
  const client = useApolloClient();
  const [leads, setLeads] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const [financers, setFinancers] = useState([]);
  const [fulfillments, setFulfillment] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [subStatusList, setSubStatusList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [rmlist, setRMData] = useState([])
  const [filterParam, setFilterParam] = useState({ page_no: 1, action_type: "lead_id" })

  const { status, searchText, pre_login_data } = useParams();
  
  const user_information = useSelector((state) => state.user.user_information);
  const UserInfo = JSON.parse(user_information);

  const { masterdata } = useSelector(({ masterdata }) => ({ masterdata }));
  let loan_type = masterdata?.data?.loan_type || [];

  let params = crypto.decode(status);
  let decodedPreLoginData = crypto.decode(pre_login_data)
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search); 
  let paramArray = params?.split("##");
  useEffect(() => {
    getstatusList();
    get_master_data();
    getRMList()
  }, []);

  const dispatch = useDispatch();
  const get_master_data = () => {
    dispatch(getMasterData(client));
  }

  const getLeadList = (filterData = {}) => {
    filterData.user_id = UserInfo?.id;
    setIsLoading(true)
    if (filterData.search_type) delete filterData.action_type
    if (!filterData.column) filterData.column = "last_updated_date";
    if (!filterData.sort) filterData.sort = "desc"; 
    if (USERS_FOR_VIEW_AMBAK_LEADS.includes(UserInfo?.email)) filterData.fulfillment_type = 'ambak';
    executeGraphQLQuery(LEADS_QUERY(filterData), client).then((result) => {
      setLeads(result?.data?.leads)
      setTotalPage(result?.data?.leads?.[0]?.pagination?.totalpage)
      setIsLoading(false)
    })
      .catch((errors) => {
        toast.error("Somethig went wrong ,please try again")
      });
  }

  const getstatusList = () => {
    executeGraphQLQuery(STATUS_QUERY('leadList'), client).then((result) => {
      setStatuses(result?.data?.status_list)
      setSubStatusList(result?.data?.sub_status_list)
      setFinancers(result.data?.financier_list)
      setFulfillment(result.data?.fulfillment_type)
    })
      .catch((errors) => {
        toast.error("Somethig went wrong ,please try again")
      });
  }

  const getRMList = () => {
    executeGraphQLQuery(RM_LIST, client).then((result) => {
      setRMData(result?.data?.rm_list)
    })
      .catch((errors) => {
        toast.error("Somethig went wrong ,please try again")
      });
  }

  const onPaginate = (p) => {
    setFilterParam((prev) => ({ ...prev, ['page_no']: p }));
    filterParam.page_no = p; 
    getLeadList(filterParam);
  }


  const handleSearch = (filterData) => {  
    filterData.action_type = filterParam.action_type
    setFilterParam((prev) => ({ ...prev, ...filterData }));
    filterData.page_no = 1;  
    setFilterParam((prev) => ({ ...prev, ['page_no']: 1 }));
    if(decodedPreLoginData){
      filterData.pre_login_data = +decodedPreLoginData;
    } 
    if(queryParams.get('source')){
      filterData.source = queryParams.get('source');
    } 
    if(queryParams.get('is_revisit')){
      filterData.is_revisit = +queryParams.get('is_revisit');
    } 
    if(queryParams.get('is_otp_verified')){
      filterData.is_otp_verified = queryParams.get('is_otp_verified');
    } 
    getLeadList(filterData);
    document.getElementById("more-filter-dropdown").classList.remove('show');
  }

  return (
    <React.Fragment>
      <div className="container-fluid">
        <LeadListFilter paramArray={paramArray} financers={financers} statuses={statuses} subStatusList={subStatusList} fulfillments={fulfillments} handleSearch={handleSearch} setFilterParam={setFilterParam} rmlist={rmlist}
          leadPagination={
            leads && leads.length > 0 && leads[0].pagination
              ? {
                ...leads[0].pagination,
                currentPageCount: leads[0]?.lead_list?.length || 0
              }
              : null
          } />
        
        <LeadListTable leads={leads} t={t} onPaginate={onPaginate} totalPage={totalPage} isLoading={isLoading} loan_type={loan_type} />
      </div>
    </React.Fragment>
  )
}

export default LeadList;
